export const initialStateUser = {
  name: "",
  email: "",
  username: "",
  phone: "",
  loading: false,
  token: "",
  taxes: [],
};

export const USER_ACTIONS = {
  LOGIN_USER: "userReducer.LOGIN_USER",
  LOGOUT_USER: "userReducer.LOGOUT_USER",
  UPDATE_USER: "userReducer.UPDATE_USER",
  SET_LOADING: "userReducer.SET_LOADING",
};

export const userReducer = (state = initialStateUser, action) => {
  switch (action.type) {
    case USER_ACTIONS.SET_LOADING:
      return { ...state, loading: action.payload.loading };
    case USER_ACTIONS.LOGIN_USER:
      return { ...state, ...action.payload };
    case USER_ACTIONS.LOGOUT_USER:
      return initialStateUser;
    case USER_ACTIONS.UPDATE_USER:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
