import { CART_ACTIONS } from "../../reducers/cartReducer";

export const setOrderCreatedDate = (orderDate) => {
  return (dispatch) => {
    dispatch({
      type: CART_ACTIONS.ADD_ORDER_CREATED_DATE,
      payload: orderDate,
    });
  };
};
