import { AiOutlineCloseCircle } from 'react-icons/ai';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { removeUsedSettlement } from '../../Store/actions/settlement/removeUsedSettlement';

import { displayThreeDecimal } from '../../Utils/displayThreeDecimal';

function PaymentDetails({ settlementMethod, removeUsedSettlement }) {
  return (
    <div className='single-payment mb-4'>
      <h5>{settlementMethod.payment_type}</h5>
      <p>{displayThreeDecimal(settlementMethod.amount)}</p>
      <AiOutlineCloseCircle
        className='pointer'
        size={'1.5em'}
        onClick={(_) => removeUsedSettlement(settlementMethod.id)}
      />
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    removeUsedSettlement: bindActionCreators(removeUsedSettlement, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(PaymentDetails);
