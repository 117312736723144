import React, { useState } from "react";
import { Breadcrumb, Col, Nav, Row, Tab } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import CustomerDetailsModal from "../Components/Cart/CustomerDetailsModal";
import Link from "../Components/Common/Link";
import Payout from "../Components/Supplier/Payout";
import { putRequest } from "../Data/ApiService";
import { ENDPOINTS } from "../Data/Endpoint";
import Page from "../Layout/Page";
import { successToast } from "../Utils/showToast";
import Attachments from "../Components/Customer/Attachments";

const CustomerDetail = () => {
  const { state } = useLocation();
  const customerDetail = state.customer;
  const [customerDetails, setCustomerDetails] = useState(customerDetail);
  const handleCustomerDetails = (e) => {
    setCustomerDetails({
      ...customerDetails,
      [e.target.name]: e.target.value,
    });
  };

  const navigate = useNavigate();

  const saveCustomerDetails = async (_) => {
    if (customerDetails?.id) {
      let custId = customerDetails?.id;
      await putRequest(ENDPOINTS.updateCustomer + custId, {
        customer_name: customerDetails.customer_name,
        phone_number: customerDetails.phone_number,
        address_line1: customerDetails.address_line1,
        address_line2: customerDetails.address_line2,
        city: customerDetails.city,
        country: customerDetails.country,
        zipcode: customerDetails.zipcode,
        cpr_number: customerDetails.cpr_number
          ? customerDetails.cpr_number
          : "",
        anniversary: customerDetails.anniversary,
        date_of_birth: customerDetails.date_of_birth,
      }).then((res) => {
        if (res.status === 200) {
          navigate(-1);
          successToast(res.data?.data?.msg);
        }
      });
    }
  };

  const tabs = [
    {
      key: "about",
      title: "About",
      component: (
        <CustomerDetailsModal
          customerDetails={customerDetail}
          saveCustomerDetails={saveCustomerDetails}
          setCustomerDetails={setCustomerDetails}
          defaulValue={{}}
          isModal={false}
        />
      ),
    },
    {
      key: "attachments",
      title: "Attachments",
      component: (
        <div className="w-80">
          <Attachments customerDetail={customerDetail} />
        </div>
      ),
    },
    {
      key: "orders",
      title: "Orders",
      component: <Payout detail={customerDetail} />,
    },
  ];

  return (
    <Page>
      <div className="container">
        <Breadcrumb>
          <Breadcrumb.Item className="custom-breadcrumb">
            <Link to={"/dashboard"}>Dashboard</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item className="custom-breadcrumb">
            <Link to={"/customers"}> Customers</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Detail</Breadcrumb.Item>
        </Breadcrumb>
        <div className="row mt-5">
          <div className="col-md-12">
            <h5 className="bold-font p-0 m-0">
              {customerDetail?.customer_name}
            </h5>
          </div>
          <div className="col-md-12 mt-5">
            <Tab.Container
              id="left-tabs-example"
              className="mt-0"
              defaultActiveKey={tabs[0].key}
            >
              <Row>
                <Col sm={12} className="tabContainer">
                  <Nav variant="pills" className="flex-row">
                    {tabs?.map((tab, index) => {
                      return (
                        <Nav.Item key={index}>
                          <Nav.Link eventKey={tab.key} className="custom-tab">
                            {tab.title}
                          </Nav.Link>
                        </Nav.Item>
                      );
                    })}
                  </Nav>
                </Col>
                <Col sm={12}>
                  <Tab.Content>
                    {tabs?.map((tab, index) => {
                      return (
                        <Tab.Pane key={index} eventKey={tab.key}>
                          {tab.component}
                        </Tab.Pane>
                      );
                    })}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default CustomerDetail;
