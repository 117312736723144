import { useEffect, useState } from "react";
import { getRequest } from "../Data/ApiService";
import { ENDPOINTS } from "../Data/Endpoint";

const usePaymentMethods = () => {
  const [paymentTypes, setPaymentTypes] = useState([]);

  const allPaymentTypes = async () => {
    let res = await getRequest(ENDPOINTS.getPaymentType);

    setPaymentTypes(
      res.data?.data?.paymenttypes?.map((method) => ({
        ...method,
        value: method?.id,
        label: method?.payment_type,
      }))
    );
  };

  useEffect(() => {
    allPaymentTypes();
  }, []);
  return { paymentTypes };
};

export default usePaymentMethods;
