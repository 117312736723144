import React from "react";
import { Table } from "react-bootstrap";
import ProductWiseSaleItem from "./ProductWiseSaleItem";
import language from "../../Strings/en";

const ProductWiseSection = ({ data, totalData }) => {
  return (
    <div className="card w-100">
      <h5 className="card-title card-header">Product Wise Sale</h5>
      <div className="card-body">
        {data?.length > 0 ? (
          <div>
            <Table borderless>
              <thead>
                <tr>
                  <th>{language.name}</th>
                  <th>{language.totalQty}</th>
                  <th>{language.totalSale}</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, index) => {
                  return <ProductWiseSaleItem data={item} key={index} />;
                })}
                {totalData && (
                  <>
                    <tr>
                      <td colSpan={3}>
                        <div className="divider my-3" />
                      </td>
                    </tr>
                    <tr>
                      <th>Gross Sale:</th>
                      <td />
                      <th colSpan={2}>{totalData?.gross_sale}</th>
                    </tr>
                    <tr>
                      <th>Tax:</th>
                      <td />
                      <th colSpan={2}>{totalData?.total_tax}</th>
                    </tr>
                    <tr>
                      <th>Net Sale:</th>
                      <td />
                      <th colSpan={2}>{totalData?.net_sale}</th>
                    </tr>
                  </>
                )}
              </tbody>
            </Table>
          </div>
        ) : (
          <span>No data available</span>
        )}
      </div>
    </div>
  );
};

export default ProductWiseSection;
