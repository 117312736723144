import XLSX from "sheetjs-style";
import * as FileSaver from "file-saver";
export const exportToExcel = async ({
  exportData,
  fileName,
  skipDefaultTitle = true,
}) => {
  // const exdata = [
  //   { A: { v: "Products" } },
  //   { A: { v: "Demo", p: "Header" }, B: { v: "Testing", p: "Header" } },
  //   { A: { v: "Hello" }, B: { v: "Vk" } },
  // ];

  // const newData = exdata?.map((item, index) => {
  //   let filte = {};
  //   Object.keys(item)?.map((value, columnIndex) => {
  //     filte[value] = { ...item[value], column: columnIndex, row: index };
  //   });
  //   return filte;
  // });

  // console.log(newData);

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const workbook = XLSX.utils.book_new();

  console.log(exportData);

  Object.keys(exportData)?.forEach((key) => {
    const ws = XLSX.utils.json_to_sheet(exportData[key], {
      skipHeader: skipDefaultTitle,
    });
    XLSX.utils.book_append_sheet(workbook, ws, key);
  });

  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);

  // ws["A1"].s = {
  //   font: {
  //     name: "arial",
  //     // sz: 24,
  //     bold: true,
  //     color: { rgb: "F56D6D00" },
  //   },
  //   alignment: {
  //     vertical: "center",
  //     horizontal: "center",
  //   },
  //   // border: {
  //   //   diagonal: {
  //   //     style: "thick",
  //   //     color: { rgb: "F56D6D00" },
  //   //   },
  //   // },
  // };
  // const merge = [{ s: { r: 0, c: "A" }, e: { r: 0, c: "B" } }];
  // ws["!merges"] = merge;
  // const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
};

// export const exportToExcel = () => {
//   const workbook = XLSX.utils.book_new();

//   // Add each table as a worksheet in the workbook
//   const table1Data = [
//     ["ID", "Name"],
//     [1, "John"],
//     [2, "Jane"],
//   ];
//   const table2Data = [
//     ["ID", "Email"],
//     [1, "john@example.com"],
//     [2, "jane@example.com"],
//   ];

//   const worksheet1 = XLSX.utils.aoa_to_sheet(table1Data);
//   XLSX.utils.book_append_sheet(workbook, worksheet1, "Table 1");

//   const worksheet2 = XLSX.utils.aoa_to_sheet(table2Data);
//   XLSX.utils.book_append_sheet(workbook, worksheet2, "Table 2");

//   const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
//   const excelBlob = new Blob([excelBuffer], {
//     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
//   });
//   FileSaver.saveAs(excelBlob, "data.xlsx");
// };
