import React, { useEffect, useRef, useState } from "react";
import { FcAddImage } from "react-icons/fc";
import { HiPlus } from "react-icons/hi";
import { acceptFileType } from "../../Config";
import { errorToast } from "../../Utils/showToast";

const UploadFile = ({ onChange, detail, msg }) => {
  const inputRef = useRef(null);
  const uploadDivRef = useRef(null);
  const [dragOver, setDragOver] = useState(false);
  const detailRef = useRef(detail);

  useEffect(() => {
    detailRef.current = detail;
  }, [detail]);

  const fileProcessing = (files) => {
    for (var i = 0; i < files.length; i++) {
      if (acceptFileType.includes(files[i].type)) {
        onChange({ file: files[i] });
      } else {
        // e.target.value = null;
        errorToast("Only supported file types are excel or pdf.");
      }
    }
  };

  const handleFile = async (e) => {
    if (e.target.value.length > 0) {
      fileProcessing(e.target.files);
      //   if (e) {
      //     e.target.value = null;
      //   }
    }
  };

  useEffect(() => {
    uploadDivRef.current.addEventListener("dragover", handleDragOver);
    uploadDivRef.current.addEventListener("drop", handleDrop);
    uploadDivRef.current.addEventListener("dragenter", handleDragEnter);
    uploadDivRef.current.addEventListener("dragleave", handleDragLeave);

    return () => {
      uploadDivRef.current?.removeEventListener("dragover", handleDragOver);
      uploadDivRef.current?.removeEventListener("drop", handleDrop);
      uploadDivRef.current?.removeEventListener("dragenter", handleDragEnter);
      uploadDivRef.current?.removeEventListener("dragleave", handleDragLeave);
    };
  }, []);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target !== uploadDivRef.current) {
      setDragOver(true);
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target === uploadDivRef.current) {
      setDragOver(false);
    }
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(false);
    if (!detailRef.current?.id) {
      alert(msg || "Please create purchase first.");
    } else {
      const { files } = e.dataTransfer;

      if (files && files.length) {
        fileProcessing(files);
      }
    }
  };
  return (
    <div
      className={`uploadFileDiv ${dragOver ? "dragActive" : ""}`}
      onClick={() => inputRef.current.click()}
      ref={uploadDivRef}
    >
      <FcAddImage size={80} />
      <span style={{ fontSize: 12 }}>Upload pdf, doc, excel here.</span>
      <label className="btn btn-outline-secondary btn-sm mt-4 d-flex align-items-center justify-content-center">
        <HiPlus /> Select File
      </label>
      <input
        type="file"
        id="inputFile"
        className="form-control d-none"
        multiple
        onChange={handleFile}
        ref={inputRef}
        accept={acceptFileType.join(", ")}
        onClick={(e) => {
          if (!detail?.id) {
            alert("Please create purchase first.");
            e.preventDefault();
          }
        }}
      />
    </div>
  );
};

export default UploadFile;
