import React from "react";

import Metal from "./Metal";
import language from "../../Strings/en";

export default function MetalSelection({
  metalOptions,
  metal,
  setMetal,
  labelStyle = { textTransform: "uppercase" },
  label = language.selectionMatal,
  isBorder = true,
  isRequired = false,
  filedForUse = "metal",
  index = 0,
  error,
}) {
  return (
    <div
      className="selection--metal selection"
      style={{
        ...(isBorder && {
          borderBottom: "1px solid #ececec",
          marginBottom: "1rem",
          paddingBottom: "1rem",
        }),
      }}
    >
      <label style={labelStyle}>
        {label}
        {isRequired && <span className="text-danger">*</span>}
      </label>
      <div className="mt-2 radio-group metal-radio-group">
        {metalOptions &&
          metalOptions?.map((row) => (
            <Metal
              key={row?.id}
              id={row?.id}
              color={row?.[filedForUse]}
              groupName={`metalSelection-${index}`}
              metal={metal}
              setMetal={(id) => setMetal(id)}
              isRequired={isRequired}
            />
          ))}
      </div>
      <span className="error-label">{error}</span>
    </div>
  );
}
