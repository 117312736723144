import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Page from "../Layout/Page";
import LoadingBtn from "../Components/Common/Button";

import { bulkAddToCart } from "../Store/actions/cart/bulkAddCart";
import { addCustomerDetails } from "../Store/actions/customerDetails/addCustomerDetails";
import { setOrderId } from "../Store/actions/cart/setOrderId";
import { setOrderNo } from "../Store/actions/cart/setOrderNo";
import { addTaxDetails } from "../Store/actions/cart/addTaxDetails";

import { getRequest } from "../Data/ApiService";
import { ENDPOINTS } from "../Data/Endpoint";
import Loading from "../Components/Common/Loading";
import { setOrderCreatedDate } from "../Store/actions/cart/setOrderCreatedDate";
import BackButton from "../Components/Common/BackButton";
import { updateUsedSettlementModes } from "../Store/actions/settlement/updateUsedSettlement";
import { setIsOrderDone } from "../Store/actions/cart/setIsOrderDone";
import Input from "../Components/Common/Input";
import DatePicker from "../Components/Common/DatePicker";
import { isEmpty } from "../Utils/empty";
import language from "../Strings/en";
import Stats from "../Components/Common/Stats";
import useStats from "../Hooks/useStats";
import { setOrderStatus } from "../Store/actions/cart/setOrderStatus";
import { ORDER_STATUS } from "../Config";

function OrderHistory({
  addCustomerDetails,
  bulkAddToCart,
  setOrderId,
  setOrderNo,
  addTaxDetails,
  setOrderCreatedDate,
  updateUsedSettlementModes,
  settlementMethods,
  setIsOrderDone,
  setOrderStatus,
}) {
  const [orders, setOrders] = useState([]);
  // new Date().toLocaleDateString("en-CA", { year: "numeric", month: "numeric", day: "numeric" }),
  const [filter, setFilter] = useState({
    order_no: "",
    from: "",
    to: "",
  });
  const [hasMorePages, setHasMorePages] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const navigate = useNavigate();

  const allOrders = async () => {
    setLoading(true);
    let res = await getRequest(ENDPOINTS.listOrders, {
      page: page,
      ...(!isEmpty(filter.order_no) && { order_no: filter.order_no }),
      ...(!isEmpty(filter.from) && { from: filter.from }),
      ...(!isEmpty(filter.to) && { to: filter.to }),
    });

    setLoading(false);
    if (page > 1) {
      setOrders((prev) => [...prev, ...res?.data?.data?.orders?.orders?.data]);
    } else {
      setOrders(res?.data?.data?.orders?.orders?.data);
    }
    setHasMorePages(res?.data?.data?.orders?.hasMorePages === 0 ? false : true);
    // if (res.data.data.orders.orders.data.length === 0) {
    //   setPage(null);
    //   return;
    // }
    // setLoading(false);
    // const oldOrders = orders || [];
    // oldOrders.push(...res.data.data.orders.orders.data);
    // setOrders(oldOrders);
    // setDefaultOrders(oldOrders);
    // setPage(page + 1);
  };
  useEffect(() => {
    allOrders();
    // eslint-disable-next-line
  }, [page, filter]);

  const loadMore = () => {
    setPage(page + 1);
  };

  const handleFetchOrderDetails = async (id) => {
    const res = await getRequest(`${ENDPOINTS.getOrder}/${id}`);
    const products = res.data?.data?.orders?.order_items;
    const customerDetails = res.data?.data?.orders?.ordercustomer;
    const orderId = res.data?.data?.orders?.id;
    const orderDate = res.data?.data?.orders?.local_created?.datetime;
    const orderStatus = res.data?.data?.orders?.order_status_id;

    const usedSettlements = res.data?.data?.orders?.orderpaymenttypes?.map(
      (settlements) => {
        return {
          id: settlements?.payment_type_id,
          amount: settlements?.amount,
          payment_type: settlementMethods.find(
            (settlement) => settlement.id === settlements?.payment_type_id
          ).payment_type,
        };
      }
    );

    //create usedSettlement array of obejct format  from api response
    if (orderStatus === 5) {
      updateUsedSettlementModes(usedSettlements);
      setIsOrderDone(true);
    }
    setOrderStatus(orderStatus);

    setOrderId(orderId);
    setOrderNo(res.data?.data?.orders?.order_no);
    setOrderCreatedDate(orderDate);
    bulkAddToCart(products);
    addCustomerDetails(customerDetails);
    let billRes = await getRequest(ENDPOINTS.cartTotal + orderId);
    addTaxDetails({
      tax: billRes.data.data.tax,
      subTotal: billRes.data.data.subtotal,
      total: billRes.data.data.total,
      taxName: billRes.data?.data?.tax_name,
    });
    // addTaxDetails({
    //   tax: res.data.data.orders.tax
    //     ? parseFloat(res.data.data.orders.tax)
    //     : 0.0,
    //   subTotal: res.data.data.orders.subtotal
    //     ? parseFloat(res.data.data.orders.subtotal)
    //     : 0.0,
    //   total: res.data.data.orders.total
    //     ? parseFloat(res.data.data.orders.total)
    //     : 0.0,
    //   discount: res.data.data.orders.discount_amount
    //     ? parseFloat(res.data.data.orders.discount_amount)
    //     : 0.0,
    // });
    navigate("/sale");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilter((prev) => ({ ...prev, [name]: value }));
    setPage(1);
  };
  const [searchString, setSearchString] = useState("");
  const triggerSearch = (e) => {
    e.preventDefault();
    setFilter((prev) => ({ ...prev, order_no: searchString }));
    setPage(1);
  };

  const { stats } = useStats("order");
  return (
    <Page>
      <div className="container mt-2">
        <div className="row">
          <div className="col-sm-12 mb-4">
            <div className="row">
              <Stats
                title="Total Orders"
                count={stats?.total_orders}
                col="col-md-3"
              />
              <Stats
                title="Total Sales"
                count={stats?.total_sales}
                col="col-md-3"
              />
              <Stats
                title="Sales Before Tax"
                count={stats?.sales_before_tax}
                col="col-md-3"
              />
              <Stats
                title="Tax Amount"
                count={stats?.tax_amount}
                col="col-md-3"
              />
            </div>
          </div>
          <div className="col-sm-12">
            <div className="row align-items-center mb-4 ">
              <div className="col-md-3">
                <h1>Order</h1>
              </div>
              <div className="col-md-3 offset-md-2">
                <form onSubmit={triggerSearch}>
                  <Input
                    placeholder="Order Number"
                    label="Search"
                    name="order_no"
                    value={searchString}
                    onChange={(e) => setSearchString(e.target.value)}
                  />
                </form>
              </div>
              <div className="col-md-2">
                {}
                <DatePicker
                  label="From"
                  name="from"
                  value={filter.from}
                  onChange={handleChange}
                  size={"xl"}
                />
              </div>
              <div className="col-md-2">
                <DatePicker
                  label="To"
                  name="to"
                  value={filter.to}
                  onChange={handleChange}
                  size={"xl"}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Table className="table table--orders" striped bordered hover>
              <thead>
                <tr>
                  <td>{language.orderNo}</td>
                  <td>{language.orderCustomerName}</td>
                  <td>{language.orderDate}</td>
                  <td>{language.orderSalesPerson}</td>
                  <td>{language.orderTotalAmount}</td>
                  <td>{language.orderTotalWOTax}</td>
                  <td>{language.orderTax}</td>
                  <td>{language.orderStatus}</td>
                </tr>
              </thead>
              <tbody>
                {orders?.length > 0 ? (
                  orders?.map((o) => (
                    <tr key={o?.id}>
                      <td
                        className="hyperlink"
                        onClick={(e) => {
                          e.preventDefault();
                          handleFetchOrderDetails(o.id);
                        }}
                      >
                        {o?.order_no}
                      </td>
                      <td>
                        {o?.ordercustomer
                          ? o?.ordercustomer?.customer_name
                          : "--"}
                      </td>
                      <td>
                        {o?.local_created?.datetime} (
                        {o?.local_created?.for_human})
                      </td>
                      <td>{o?.createdBy}</td>
                      <td>{o?.total || "-"}</td>
                      <td>{o?.subtotal || "-"}</td>
                      <td>{o?.tax || "-"}</td>
                      <td>
                        <button
                          className={`btn ${
                            o?.order_status_id === ORDER_STATUS.orderSettled
                              ? "btn-success"
                              : "btn-secondary"
                          }`}
                          onClick={(e) => {
                            e.preventDefault();
                            handleFetchOrderDetails(o.id);
                          }}
                        >
                          {o?.order_status_id === ORDER_STATUS.orderSettled
                            ? "Settled"
                            : o?.order_status_id === ORDER_STATUS.orderRefund
                            ? "Refunded"
                            : "Pending"}
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    {loading ? (
                      <td colSpan={8}>
                        <Loading />
                      </td>
                    ) : (
                      <td colSpan={8}>No orders available</td>
                    )}
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
        {hasMorePages && (
          <div style={{ textAlign: "center" }}>
            <LoadingBtn onClick={loadMore}>Load More</LoadingBtn>
          </div>
        )}
        {loading && page > 1 && <Loading />}
      </div>
    </Page>
  );
}

const mapStateToProps = (state) => {
  return {
    settlementMethods: state.settlementReducer.settlementMethods,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOrderId: bindActionCreators(setOrderId, dispatch),
    setOrderNo: bindActionCreators(setOrderNo, dispatch),
    bulkAddToCart: bindActionCreators(bulkAddToCart, dispatch),
    addCustomerDetails: bindActionCreators(addCustomerDetails, dispatch),
    addTaxDetails: bindActionCreators(addTaxDetails, dispatch),
    setOrderCreatedDate: bindActionCreators(setOrderCreatedDate, dispatch),
    updateUsedSettlementModes: bindActionCreators(
      updateUsedSettlementModes,
      dispatch
    ),
    setIsOrderDone: bindActionCreators(setIsOrderDone, dispatch),
    setOrderStatus: bindActionCreators(setOrderStatus, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistory);
