import React from "react";
import { Tabs } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";

import CategoriesList from "./CategoriesList";
import InlineCategoryList from "../Common/InlineCategoryList";

function CategoryTabs({
  categories,
  index,
  activeTab,
  setActiveTab,
  forMobile,
}) {
  return (
    <Tabs
      className="category-tabs"
      activeKey={activeTab}
      onSelect={(k) => setActiveTab(k)}
    >
      {categories.map((category, index) => (
        <Tab eventKey={index} title={category.name} key={category.id}>
          {forMobile ? (
            <div className="mt-3">
              <InlineCategoryList
                categories={category?.groups?.map((group) => ({
                  value: group.id,
                  label: group.name,
                }))}
              />
            </div>
          ) : (
            <div className="category">
              <CategoriesList
                index={index}
                category={category}
                forMobile={forMobile}
              />
            </div>
          )}
        </Tab>
      ))}
    </Tabs>
  );
}

export default CategoryTabs;
