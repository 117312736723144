import { PRICE_ACTIONS } from "../../reducers/priceReducer";

export const setLoading = (loading) => {
  return (dispatch) => {
    dispatch({
      type: PRICE_ACTIONS.SET_LOADING,
      payload: {
        loading,
      },
    });
  };
};
