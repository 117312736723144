import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Bill from "../Components/Cart/Bill";
import Cart from "../Components/Cart/Cart";
import CustomerDetailsModal from "../Components/Cart/CustomerDetailsModal";
import ProductOffCanvas from "../Components/Cart/ProductOffCanvas";
import Categories from "../Components/Categories/Categories";
import Loading from "../Components/Common/Loading";
import Products from "../Components/Products/Products";
import Settle from "../Components/Settle/Settle";
import Page from "../Layout/Page";

// import language from "../Strings/en";

import { clearCart } from "../Store/actions/cart/clearCart";
import { setGoldPrice } from "../Store/actions/cart/setGoldPrice";
import { setLoading } from "../Store/actions/cart/setLoading";
import { addCustomerDetails } from "../Store/actions/customerDetails/addCustomerDetails";

import axios from "axios";
import BarcodeModal from "../Components/AttributeSelectors/BarcodeModal";
import { postRequest, putRequest } from "../Data/ApiService";
import { ENDPOINTS, EXTERNAL_ENDPOINTS } from "../Data/Endpoint";

function AttributeSelection({
  setGoldPrice,
  setLoading,
  addCustomerDetails,
  orderId,
  customer,
  loading,
}) {
  const [barcodeModalOpen, setBarcodeModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [isOffCanvasOpen, setOffCanvasOpen] = useState(false);
  const [currentEditProduct, setCurrentEditProduct] = useState(null);
  const [currentAddProduct, setCurrentAddProduct] = useState(null);
  const [isSettleOpen, setIsSettleOpen] = useState(false);
  const [customerId, setCustomerId] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isCustomerDetailOpen, setIsCustomerDetailOpen] = useState(false);
  const [customerDetails, setCustomerDetails] = useState({
    customer_name: "",
    phone_number: "",
    address_line1: "",
    address_line2: "",
    city: "",
    country: "",
    zipcode: "",
    cpr_number: "",
    anniversary: "",
    date_of_birth: "",
    ...customer,
  });

  const handleCustomerDetails = (e) => {
    setCustomerDetails({
      ...customerDetails,
      [e.target.name]: e.target.value,
    });
  };

  const openAttributeSelection = (action = null, value) => {
    value && setActionType(action);
    setOffCanvasOpen(value);
    !value && setCurrentEditProduct(null);
    !value && setCurrentAddProduct(null);
  };

  const saveCustomerDetails = async (_) => {
    addCustomerDetails({
      ...customerDetails,
    });

    if (customerDetails?.id || (isEdit && customerId)) {
      let custId = customerDetails?.id ? customerDetails?.id : customerId;
      await putRequest(ENDPOINTS.updateCustomer + custId, {
        order_id: orderId,
        customer_name: customerDetails.customer_name,
        phone_number: customerDetails.phone_number,
        address_line1: customerDetails.address_line1,
        address_line2: customerDetails.address_line2,
        city: customerDetails.city,
        country: customerDetails.country,
        zipcode: customerDetails.zipcode,
        cpr_number: customerDetails.cpr_number
          ? customerDetails.cpr_number
          : "",
        anniversary: customerDetails.anniversary,
        date_of_birth: customerDetails.date_of_birth,
      });
    } else {
      let res = await postRequest(ENDPOINTS.createCustomer, {
        order_id: orderId,
        customer_name: customerDetails.customer_name,
        phone_number: customerDetails.phone_number,
        address_line1: customerDetails.address_line1,
        address_line2: customerDetails.address_line2,
        city: customerDetails.city,
        country: customerDetails.country,
        zipcode: customerDetails.zipcode,
        cpr_number: customerDetails.cpr_number
          ? customerDetails.cpr_number
          : "",
        anniversary: customerDetails.anniversary,
        date_of_birth: customerDetails.date_of_birth,
      });
      setCustomerId(res.data.data.customer.id);
      setIsEdit(true);
    }
  };

  useEffect(() => {
    // const goldPriceTimer = setInterval(async (_) => {
    const fetchGoldPrice = async () => {
      setLoading(true);
      try {
        const res = await axios.get(EXTERNAL_ENDPOINTS.goldRates, {
          headers: {
            "Access-Control-Allow-Headers":
              "Content-Type, Accept, Access-Control-Allow-Origin, Access-Control-Allow-Methods",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            "Content-type": "application/json",
          },
        });
        console.log(res);
        setGoldPrice(res.data.price);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    // fetchGoldPrice();
    // }, 86400000);

    // return (_) => {
    //   clearInterval(goldPriceTimer);
    // };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page>
      {loading && (
        <div className="loader-wrapper">
          <Loading />
        </div>
      )}
      <div className="d-flex flex-column w-100">
        <div className="dashboard">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-8 p-0">
                <Cart
                  setIsOpen={setIsOpen}
                  openAttributeSelection={openAttributeSelection}
                  setCurrentEditProduct={setCurrentEditProduct}
                  setCurrentAddProduct={setCurrentAddProduct}
                  isOpen={isOpen}
                  clearCart={clearCart}
                />
              </div>
              <div className="col-md-4 p-0">
                <Bill
                  setIsSettleOpen={setIsSettleOpen}
                  isSettleOpen={isSettleOpen}
                  isCustomerDetailOpen={isCustomerDetailOpen}
                  setIsCustomerDetailOpen={setIsCustomerDetailOpen}
                  orderId={orderId}
                />
              </div>
            </div>
          </div>
        </div>
        {isSettleOpen ? (
          <Settle setIsSettleOpen={setIsSettleOpen} orderId={orderId} />
        ) : (
          <>
            <hr className="mt-0 mb-1"></hr>
            <Categories />
            <hr className="mt-0"></hr>
            <Products
              openAttributeSelection={openAttributeSelection}
              // openAttributeSelection={() => setBarcodeModalOpen(true)}
              setCurrentAddProduct={setCurrentAddProduct}
            />
          </>
        )}
      </div>
      {(currentAddProduct || currentEditProduct) && (
        <ProductOffCanvas
          actionType={actionType}
          placement="end"
          show={isOffCanvasOpen}
          openAttributeSelection={openAttributeSelection}
          cartId={actionType !== "add" && currentEditProduct?.cartId}
          productId={
            actionType === "add"
              ? currentAddProduct?.id
              : currentEditProduct?.id || currentEditProduct?.purchase_id
          }
          productTitle={
            actionType === "add"
              ? currentAddProduct?.title_en ||
                currentAddProduct?.product?.title_en
              : currentEditProduct?.title_en ||
                currentEditProduct?.product?.title_en
          }
          currentProductDetail={
            actionType === "add" ? currentAddProduct : currentEditProduct
          }
        />
      )}
      {isCustomerDetailOpen && (
        <CustomerDetailsModal
          isCustomerDetailOpen={isCustomerDetailOpen}
          setIsCustomerDetailOpen={setIsCustomerDetailOpen}
          customerDetails={customerDetails}
          handleCustomerDetails={handleCustomerDetails}
          saveCustomerDetails={saveCustomerDetails}
          setCustomerDetails={setCustomerDetails}
        />
      )}
      <BarcodeModal
        open={barcodeModalOpen}
        onHide={() => setBarcodeModalOpen(false)}
        openAttributeSelection={openAttributeSelection}
        setCurrentAddProduct={setCurrentAddProduct}
      />
    </Page>
  );
}

const mapStateToProps = (state) => {
  return {
    orderId: state.cartReducer.orderId,
    customer: state.customerDetailsReducer,
    loading: state.cartReducer.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setGoldPrice: bindActionCreators(setGoldPrice, dispatch),
    setLoading: bindActionCreators(setLoading, dispatch),
    clearCart: bindActionCreators(clearCart, dispatch),
    addCustomerDetails: bindActionCreators(addCustomerDetails, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AttributeSelection);
