import React from "react";
import { Modal as BootstrapModal } from "react-bootstrap";
const Modal = ({
  show,
  onHide,
  children,
  size = "xl",
  footer,
  header,
  centered,
  className,
  closeButton = true,
  alertDialog = false,
  ...props
}) => {
  return (
    <BootstrapModal
      show={show}
      onHide={onHide}
      className={`customer-detail-modal ${className}`}
      size={size}
      scrollable={true}
      centered={centered}
      {...props}
    >
      {header && (
        <BootstrapModal.Header closeButton={closeButton}>
          {header}
        </BootstrapModal.Header>
      )}
      {children && (
        <BootstrapModal.Body>
          <div>{children}</div>
          {footer && !alertDialog && (
            <div className="py-4 footer-modal">{footer}</div>
          )}
        </BootstrapModal.Body>
      )}
      {alertDialog && <BootstrapModal.Footer>{footer}</BootstrapModal.Footer>}
    </BootstrapModal>
  );
};

export default Modal;
