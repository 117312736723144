import { dummyPlaceholder } from "../Assets";

export function isEmpty(data) {
  if (
    // Object.keys(data).length === 0 ||
    (typeof data === "object" && data?.length === 0) ||
    (Array.isArray(data) && data?.length === 0) ||
    typeof data == "undefined" ||
    data === undefined ||
    data === "" ||
    data === null ||
    (typeof data === "number" && data < 0)
  ) {
    return true;
  } else {
    return false;
  }
}

export const showImage = (image) => {
  return image.includes(null) || image.includes("_placeholder")
    ? dummyPlaceholder
    : image;
};

//return true if given string is empty otherwise false
