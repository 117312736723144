import React, { useEffect, useRef, useState } from "react";
import { getRequest, postRequest, putRequest } from "../Data/ApiService";
import { ENDPOINTS } from "../Data/Endpoint";
import {
  errorToast,
  filterSimpleError,
  successToast,
} from "../Utils/showToast";
import { Table } from "react-bootstrap";
import language from "../Strings/en";
import { displayThreeDecimal } from "../Utils/displayThreeDecimal";
import RedIndicatorInput from "../Components/Inventory/RedIndicatorInput";
import Page from "../Layout/Page";
import BackButton from "../Components/Common/BackButton";
import Loading from "../Components/Common/Loading";
import Input from "../Components/Common/Input";
import AlertDialog from "../Components/Common/AlertDialog";

const StockTake = ({ storeId }) => {
  const initialState = {
    product: "",
    sysWeight: 0,
    sysQty: 0,
    weight: null,
    qty: "",
  };
  const [formData, setFormData] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [inventory, setInventory] = useState([]);
  const [createLoading, setCreateLoading] = useState(false);
  const [refNo, setRefNo] = useState("");
  const [isLessThanSys, setIsLessThanSys] = useState({
    qty: false,
  });

  const [showAlert, setShowAlert] = useState(false);

  const resetFields = () => {
    setFormData(initialState);
  };

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);

  const fetchInventory = async () => {
    setLoading(true);
    const res = await getRequest(ENDPOINTS.getInventory, {
      // page,
      storeId,
      instock: 1,
      viewall: 1,
    });

    if (res.status === 200) {
      setLoading(false);
      setHasMore(res?.data?.data?.hasMorePages === 0 ? false : true);
      const data = res?.data?.data?.products?.map((product) => {
        return { ...product, onHandWeight: "", onHandQty: "", ref_no: "" };
      });
      if (page > 1) {
        setInventory((prev) => [...prev, ...data]);
      } else {
        setInventory(data);
      }
    } else {
      setHasMore(false);
    }
  };
  useEffect(() => {
    fetchInventory();
  }, [page]);

  const loadMore = () => {
    setPage((prev) => prev + 1);
  };

  const divRef = useRef();

  // const scrollBottom = () => {
  //   if (divRef.current) {
  //     const { scrollTop, scrollHeight, clientHeight } = divRef.current;
  //     if (scrollTop + clientHeight >= scrollHeight - 5) {
  //       if (hasMore) {
  //         loadMore();
  //       }
  //     }
  //   }
  // };

  const handleChange = (e, data) => {
    const { name, value } = e.target;
    const field = data?.storeproduct_variant_id
      ? "storeproduct_variant_id"
      : "id";
    setInventory((prev) =>
      prev.map((inventory) => {
        if (inventory?.[field] === data?.[field]) {
          return {
            ...inventory,
            [name]: value,
          };
        } else {
          return inventory;
        }
      })
    );
  };

  const saveStock = async () => {
    setCreateLoading(true);
    const filter = inventory
      ?.filter((data) => data.onHandWeight !== "" && data.onHandQty !== "")
      .map((data) => {
        return {
          storeproduct_id: data?.id,
          total_weight: data?.onHandWeight,
          quantity: data?.onHandQty,
          storeproduct_variant_id: data?.storeproduct_variant_id,
          stocktake: "Manual",
          ref_no: refNo,
        };
      });

    await postRequest(ENDPOINTS.setStock, filter).then((response) => {
      if (response.data?.data?.success) {
        setCreateLoading(false);
        successToast(response.data.data.msg);
        setShowAlert(false);
      } else {
        errorToast(response?.data?.data?.msg);
        setShowAlert(false);
        // filterSimpleError(response?.data);
      }
    });
  };

  return (
    <Page>
      <div className="container">
        <div className="row mb-3">
          <div className="col-sm-12 ">
            <div className="d-flex justify-content-end align-items-center">
              <div>
                <input
                  type="text"
                  name={"ref_no"}
                  value={refNo}
                  onChange={(e) => {
                    setRefNo(e.target.value);
                  }}
                  placeholder={language.refNo}
                  className="form-control w-10"
                />
              </div>
              <button
                className="btn btn-warning mb-3 mt-3 ms-3"
                onClick={() => {
                  setShowAlert(true);
                }}
              >
                Submit Stock
              </button>
            </div>
          </div>
          <div
            className="col-sm-12"
            style={{ width: "100%", overflow: "auto" }}
          >
            {/* <div style={{ position: "absolute", top: 0, overflow: "auto", height: "100%", width: "100%" }} ref={divRef}> */}
            {loading ? (
              <Loading />
            ) : (
              <Table borderless>
                <thead
                  style={{
                    position: "sticky",
                    top: 0,
                    backgroundColor: "#fff",
                  }}
                >
                  <tr>
                    <th>{language.purchaseName}</th>
                    <th>{language.sku}</th>
                    <th>{language.barcode}</th>
                    <th>{language.expectedQty}</th>
                    <th>{language.expectedWeight}</th>
                    <th>{language.actualQty}</th>
                    <th>{language.actualWeight}</th>
                  </tr>
                </thead>
                <tbody>
                  {inventory?.map((data) => {
                    return (
                      <tr>
                        <td>{data?.product_name}</td>
                        <td>{data?.sku || "-"}</td>
                        <td>{data?.barcode_number || "-"}</td>
                        <td>{data?.quantity}</td>
                        <td>{displayThreeDecimal(data?.total_weight)}</td>

                        <td>
                          <RedIndicatorInput
                            compareTo={data?.quantity}
                            value={data?.onHandQty}
                            onChange={(e) => {
                              handleChange(e, data);
                            }}
                            name="onHandQty"
                          />
                        </td>
                        <td>
                          <RedIndicatorInput
                            compareTo={data?.total_weight}
                            value={data?.onHandWeight}
                            name="onHandWeight"
                            onChange={(e) => {
                              handleChange(e, data);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
          </div>
        </div>
      </div>
      <AlertDialog
        show={showAlert}
        successAction={saveStock}
        title={language.stockTakeAlert}
        button={["Yes, submit", "No, let me review again"]}
        cancleAction={() => setShowAlert(false)}
        closeButton={false}
      />
    </Page>
  );
};

export default StockTake;
