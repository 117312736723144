import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";

import PaymentDetails from "./PaymentDetails";
import PaymentModesList from "./PaymentModesList";
import TenderModal from "./TenderModal";

import { getRequest, putRequest } from "../../Data/ApiService";
import { ENDPOINTS } from "../../Data/Endpoint";

import { resetCartState } from "../../Store/actions/cart/resetCartState";
import { resetCategoryState } from "../../Store/actions/category/resetCategoryState";
import { resetCustomerState } from "../../Store/actions/customerDetails/resetCustomerState";
import { resetProductState } from "../../Store/actions/product/resetProductState";

import { displayThreeDecimal } from "../../Utils/displayThreeDecimal";
import language from "../../Strings/en";
import { resetSettlementState } from "../../Store/actions/settlement/resetSettlementState";
import { errorToast, successToast } from "../../Utils/showToast";
import Invoice from "../../Pages/Invoice";
import ReactToPrint from "react-to-print";

function Settle({
  setIsSettleOpen,
  subTotal,
  total,
  discount,
  tax,
  usedSettlementMethods,
  orderId,
  isOrderDone,
  totalPaid,
  resetSettlementState,
  resetCartState,
  resetCustomerState,
  resetProductState,
  resetCategoryState,
  isReOpenOrder,
}) {
  const [selectedMode, setSelectedMode] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [getPaymentMethod, setPaymentMethod] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    allPaymentTypes();
  }, []);

  const allPaymentTypes = async (_) => {
    let res = await getRequest(ENDPOINTS.getPaymentType);
    setPaymentMethod(res.data.data.paymenttypes);
  };

  const handleBillSaveAction = async (_) => {
    if (isOrderDone) {
      resetAllState();
      navigate("/dashboard");
    } else {
      try {
        let res = await putRequest(ENDPOINTS.updateOrder, {
          subtotal: displayThreeDecimal(subTotal),
          tax: displayThreeDecimal(tax),
          total: total > 0 ? displayThreeDecimal(total) : total,
          order_id: orderId,
          payment_types: usedSettlementMethods.filter(
            (data) => delete data["payment_type"]
          ),
          ...(isReOpenOrder && { reopen: true }),
        });

        if (res.data.data.success) {
          successToast("Order settled successfully!");
          setIsSettleOpen(false);
          resetAllState();
          navigate("/dashboard");
        } else {
          errorToast(res.data.data.msg);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const resetAllState = () => {
    resetCartState();
    resetCustomerState();
    resetCategoryState();
    resetProductState();
    resetSettlementState();
  };
  const ref = useRef(null);

  return (
    <>
      <div className="settle">
        <div className="container-fluid">
          <div className="row main--row">
            <div className="col-sm-12 col-md-12 col-lg-8">
              <p className="text-uppercase m-0 p-2 text-center section-title">
                {language.settlePaymentType}
              </p>
              <PaymentModesList
                selectedMode={selectedMode}
                setSelectedMode={setSelectedMode}
                setIsModalOpen={setIsModalOpen}
                paymentMethod={getPaymentMethod}
              />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <p className="text-uppercase m-0 p-2 text-center section-title">
                {language.settlePaymentDetails}
              </p>
              <div className="settle--payment-details">
                {usedSettlementMethods &&
                  usedSettlementMethods.map((m) => (
                    <PaymentDetails settlementMethod={m} />
                  ))}

                <div className="col-md-12 pb-5">
                  <p className="text-center o-5">
                    Remaining: BD{" "}
                    {displayThreeDecimal(
                      totalPaid === 0
                        ? total
                        : total - totalPaid < 0
                        ? 0
                        : total - totalPaid
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row action--row">
            <div className="col-sm-12 col-md-4 col-lg-4"></div>
            <div className="col-sm-12 col-md-4 col-lg-4"></div>
            <div className="col-sm-12 col-md-4 col-lg-4 action--col">
              <Button
                variant="secondary"
                onClick={() => setIsSettleOpen(false)}
              >
                {language.settleBack}
              </Button>
              <ReactToPrint //this is component syntax for printing purpose
                content={() => ref.current} // ref pass to component which you want to print
                trigger={() => (
                  <Button
                    style={{
                      backgroundColor: "#2C9C9C99",
                      borderColor: "#2C9C9C",
                    }}
                    onClick={(_) => {
                      setIsSettleOpen(true);
                    }}
                  >
                    {language.settlePrintReceipt}
                  </Button>
                )}
              />

              <Button variant="warning" onClick={handleBillSaveAction}>
                {language.settleSave}
              </Button>
            </div>
          </div>
        </div>
        {isModalOpen && (
          <TenderModal
            selectedMode={selectedMode}
            isModalOpen={isModalOpen}
            total={total}
            totalPaid={totalPaid}
            setIsModalOpen={setIsModalOpen}
          />
        )}
      </div>
      <div className="d-none">
        <Invoice ref={ref} />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    totalPaid:
      state.settlementReducer.usedSettlementMethods.length > 0
        ? state.settlementReducer.usedSettlementMethods.reduce(
            (total, num) => Number(total) + Number(num.amount),
            0
          )
        : 0,
    total: state.cartReducer.total,
    tax: state.cartReducer.tax,
    discount: state.cartReducer.discount,
    subTotal: state.cartReducer.subTotal,
    usedSettlementMethods: state.settlementReducer.usedSettlementMethods,
    orderId: state.cartReducer.orderId,
    isOrderDone: state.cartReducer.isOrderDone,
    isReOpenOrder: state.cartReducer.isReOpenOrder,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetCartState: bindActionCreators(resetCartState, dispatch),
    resetCustomerState: bindActionCreators(resetCustomerState, dispatch),
    resetCategoryState: bindActionCreators(resetCategoryState, dispatch),
    resetProductState: bindActionCreators(resetProductState, dispatch),
    resetSettlementState: bindActionCreators(resetSettlementState, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settle);
