import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./Styles/style.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";

import { createBrowserHistory } from "history";

const history = createBrowserHistory();

let app = document.getElementById("root");
if (app) {
  const path = (/#!(\/.*)$/.exec(document.location.hash) || [])[1];
  if (path) {
    history.replace(path);
  }
  const root = ReactDOM.createRoot(app);
  root.render(
    // <React.StrictMode>
    <App />
    // </React.StrictMode>,
  );
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
