import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";

import create from "../Assets/Images/create.png";
import customers from "../Assets/Images/customers.png";
import logo from "../Assets/Images/logo.png";
import orderHistory from "../Assets/Images/order-history.png";
import profile from "../Assets/Images/profile.png";

import { resetCartState } from "../Store/actions/cart/resetCartState";
import { setOrderId } from "../Store/actions/cart/setOrderId";
import { resetCategoryState } from "../Store/actions/category/resetCategoryState";
import { resetProductState } from "../Store/actions/product/resetProductState";
import { logoutUser } from "../Store/actions/user/logOutUser";

import { getRequest, postRequest } from "../Data/ApiService";
import { ENDPOINTS } from "../Data/Endpoint";

import { Button } from "react-bootstrap";
import AlertDialog from "../Components/Common/AlertDialog";
import Link from "../Components/Common/Link";
import MobileStockRfidModal from "../Components/Inventory/MobileStockRfidModal";
import useIsMobile from "../Hooks/useIsMobile";
import { setOrderCreatedDate } from "../Store/actions/cart/setOrderCreatedDate";
import { setOrderNo } from "../Store/actions/cart/setOrderNo";
import { setDailyPrice } from "../Store/actions/price/setDailyPrice";
import { setLoading } from "../Store/actions/price/setLoading";
import language from "../Strings/en";
import version from "../Strings/version";
import { displayThreeDecimal } from "../Utils/displayThreeDecimal";

function Dashboard({
  setOrderId,
  setOrderNo,
  resetCartState,
  resetCategoryState,
  resetProductState,
  logoutUser,
  setOrderCreatedDate,
  userName,
  storeName,
  setDailyPrice,
  setLoading,
  loading,
  dailyPrice,
}) {
  const [stockModal, setStockModal] = useState(false);
  const createNewOrder = async (_) => {
    const res = await postRequest(ENDPOINTS.createOrder);
    setOrderId(res.data.data.order.id);
    setOrderNo(res.data.data.order.order_no);
    setOrderCreatedDate(res.data.data.order.local_created.datetime);
  };
  const navigate = useNavigate();
  const resetStore = (_) => {
    // for clear redux data
    resetCartState(); // reset cart in redux
    resetCategoryState(); // reset category in redux
    resetProductState(); // reset product in redux
    logoutUser(); // reset user data in redux
    navigate("/");
  };
  const [showAlert, setShowAlert] = useState(false);

  const webMenu = [
    // { url: "/profile", icon: profile, name: language.dbMyProfile },
    { url: "/analytics", icon: orderHistory, name: language.dbDashboard },
    { url: "/product", icon: orderHistory, name: language.dbProduct },
    {
      url: "/sale",
      icon: create,
      name: language.dbCreateOrder,
      onClick: () => {
        createNewOrder();
        resetCartState();
        resetProductState();
      },
    },

    { url: "/purchase", icon: orderHistory, name: language.dbPurchase },
    {
      url: "/order-history",
      icon: orderHistory,
      name: language.dbOrderHistory,
    },
    { url: "/customers", icon: customers, name: language.dbCustomer },
    { url: "/inventory", icon: orderHistory, name: language.dbInventory },
    { url: "/stock", icon: orderHistory, name: language.dbStock },
    { url: "/stockreport", icon: orderHistory, name: language.dbStockTake },
    { url: "/suppliers", icon: profile, name: language.dbSuppliers },
    { url: "/bars", icon: orderHistory, name: language.dbBars },
    {
      url: "/dailysales",
      icon: orderHistory,
      name: language.dbReport,
    },
    // { url: "/sales-reports", icon: report, name: language.dbSaleReport },
    // { url: "/hrms", icon: profile, name: language.dbAuricHrms },
    // { url: "/stocktake", icon: profile, name: language.dbStockTake },
  ];
  const mobileMenu = [
    { url: "/inventory", icon: orderHistory, name: language.dbInventory },
    { url: "/linkitems", icon: orderHistory, name: language.dbLinkItems },
    {
      url: "/stocktake",
      icon: orderHistory,
      name: language.dbStockTake,
      onClick: () => {
        setStockModal(true);
      },
      type: "button",
    },
  ];

  const { isMobile } = useIsMobile();
  const menu = isMobile ? mobileMenu : webMenu;

  useEffect(() => {
    setLoading(true);
    const fetchDailyGoldPrice = async () => {
      try {
        const response = await getRequest(`${ENDPOINTS.carats}`);
        setDailyPrice(response.data.data?.reverse());
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchDailyGoldPrice();
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="dashboard-container">
          <div className="row">
            <div className="col-md-12 ">
              <div className=" d-flex flex-column align-items-center">
                <img className="logo" src={logo} alt="Logo" />
                <div className="text-center dashboard-user-name">
                  {loading
                    ? "Loading price..."
                    : `${dailyPrice
                        .map(
                          (item, ind) =>
                            `${item.carat_name} ${displayThreeDecimal(
                              item.goldrate_per_gm.split("BHD")[0]
                            )} BHD`
                        )
                        .join(" | ")}`}
                </div>
                <p className="dashboard-user-name mt-3">
                  <span>Welcome</span> <br />
                  {userName}
                </p>

                <p className="dashboard-user-name">
                  <span>Store</span> <br /> {storeName}
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="dashboard-link-wrap">
              {menu?.map((menu) => {
                return menu?.type === "button" ? (
                  <span
                    className="dashboard-link"
                    onClick={menu?.onClick}
                    key={menu.name}
                  >
                    <div className="dashboard-link-img">
                      <img src={menu?.icon} alt={menu?.name} />
                    </div>
                    {menu?.name}
                  </span>
                ) : (
                  <Link
                    to={menu.url}
                    className="dashboard-link"
                    onClick={menu?.onClick}
                    key={menu.name}
                  >
                    <div className="dashboard-link-img">
                      <img src={menu.icon} alt={menu.name} />
                    </div>
                    {menu.name}
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {/* <Link to={"/analytics"} className="dashboard-link">
            <div className="dashboard-link-img">
              <img src={orderHistory} alt={language.dbDashboard} />
            </div>
            {language.dbDashboard}
          </Link>
          <Link
            to={"/sale"}
            className="dashboard-link"
            onClick={(_) => {
              createNewOrder();
              resetCartState();
              resetProductState();
            }}
          >
            <div className="dashboard-link-img">
              <img src={create} alt={language.dbCreateOrder} />
            </div>
            {language.dbCreateOrder}
          </Link>
          <Link to={"/order-history"} className="dashboard-link">
            <div className="dashboard-link-img">
              <img src={orderHistory} alt={language.dbOrderHistory} />
            </div>
            {language.dbOrderHistory}
          </Link>
          <Link to={"/purchase"} className="dashboard-link">
            <div className="dashboard-link-img">
              <img src={orderHistory} alt={language.dbPurchase} />
            </div>
            {language.dbPurchase}
          </Link>
          <hr />
          <Link to={"/inventory"} className="dashboard-link">
            <div className="dashboard-link-img">
              <img src={orderHistory} alt={language.dbInventory} />
            </div>
            {language.dbInventory}
          </Link>
          <Link to={"/stockreport"} className="dashboard-link">
            <div className="dashboard-link-img">
              <img src={orderHistory} alt={language.dbInventory} />
            </div>
            {language.dbStockReport}
          </Link>
          <Link to={"/stock"} className="dashboard-link">
            <div className="dashboard-link-img">
              <img src={orderHistory} alt={language.dbInventory} />
            </div>
            {language.dbStockTake}
          </Link>

          <Link to={"/customers"} className="dashboard-link">
            <div className="dashboard-link-img">
              <img src={customers} alt={language.dbCustomer} />
            </div>
            {language.dbCustomer}
          </Link>
          <Link to={"/sales-reports"} className="dashboard-link">
            <div className="dashboard-link-img">
              <img src={report} alt={language.dbSaleReport} />
            </div>
            {language.dbSaleReport}
          </Link>

          <Link to={"/suppliers"} className="dashboard-link">
            <div className="dashboard-link-img">
              <img src={profile} alt={language.dbCustomer} />
            </div>
            {language.dbSuppliers}
          </Link>
          <Link to={"/hrms"} className="dashboard-link">
            <div className="dashboard-link-img">
              <img src={profile} alt={language.dbCustomer} />
            </div>
            {language.dbAuricHrms}
          </Link> */}
      {/* <span
            className="dashboard-link"
            onClick={(_) => {
              setShowAlert(true);
            }}
          >
            <div className="dashboard-link-img">
              <img src={logout} alt={language.dbLogout} />
            </div>
            <span className="text-danger">{language.dbLogout}</span>
          </span> */}

      <div className=" mt-5 text-center">
        <Button onClick={() => setShowAlert(true)} variant="contained">
          Logout
        </Button>
      </div>
      <div className="text-center">
        <span className="text-muted">{version}</span>
      </div>
      <AlertDialog
        closeButton={false}
        show={showAlert}
        onHide={() => setShowAlert(false)}
        successAction={resetStore}
        title={<div className="py-3">{language.alertMessageLogout}</div>}
        cancleAction={() => setShowAlert(false)}
      />
      {stockModal && (
        <MobileStockRfidModal
          openModal={stockModal}
          hideModal={() => setStockModal(false)}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userName: state.userReducer.name,
    storeName: state.userReducer.store_name,
    dailyPrice: state.priceReducer.dailyPrice,
    loading: state.priceReducer.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOrderId: bindActionCreators(setOrderId, dispatch),
    setOrderNo: bindActionCreators(setOrderNo, dispatch),
    resetCartState: bindActionCreators(resetCartState, dispatch),
    resetCategoryState: bindActionCreators(resetCategoryState, dispatch),
    resetProductState: bindActionCreators(resetProductState, dispatch),
    logoutUser: bindActionCreators(logoutUser, dispatch),
    setDailyPrice: bindActionCreators(setDailyPrice, dispatch),
    setLoading: bindActionCreators(setLoading, dispatch),
    setOrderCreatedDate: bindActionCreators(setOrderCreatedDate, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
