import React, { useEffect, useState } from "react";
import { getRequest } from "../Data/ApiService";
import { ENDPOINTS } from "../Data/Endpoint";
import { errorToast } from "../Utils/showToast";

const useSuppliers = () => {
  const [suppliers, setSuppliers] = useState();
  const loadSuppliers = async () => {
    try {
      const res = await getRequest(ENDPOINTS.supplierslist, null, null);
      if (res.data?.data?.success) {
        const supplier = res?.data?.data?.suppliers?.map((supplier) => {
          return {
            id: supplier?.id,
            value: supplier?.id,
            label: supplier?.supplier_name,
          };
        });
        setSuppliers(supplier);
      } else {
        errorToast(res.data?.data?.msg);
      }
    } catch (error) {
      errorToast(error.message);
    }
  };

  useEffect(() => {
    loadSuppliers();
  }, []);
  return { suppliers, loadSuppliers };
};

export default useSuppliers;
