import { PRODUCT_ACTIONS } from '../../reducers/productReducer';

export const setLoading = (loading) => {
  return (dispatch) => {
    dispatch({
      type: PRODUCT_ACTIONS.SET_LOADING,
      payload: {
        loading,
      },
    });
  };
};
