import React from "react";

import Carat from "./Carat";
import language from "../../Strings/en";

const CaratSelection = ({
  caratOptions,
  carat,
  setCarat,
  labelStyle = { textTransform: "uppercase" },
  label = language.selectionCarat,
  isBorder = true,
  isRequired = false,
  index = 0,
  error,
  disabled,
}) => {
  return (
    <div
      className="selection--carat selection"
      style={{
        ...(isBorder && {
          borderBottom: "1px solid #ececec",
          marginBottom: "1rem",
          paddingBottom: "1rem",
        }),
      }}
    >
      <label style={labelStyle}>
        {label}

        {isRequired && <span className="text-danger">*</span>}
      </label>
      <div className="mt-2 carat-radio-group radio-group">
        {caratOptions &&
          caratOptions?.map((row) => {
            return (
              <Carat
                key={row?.carat}
                label={row?.carat}
                id={row?.id}
                groupName={`caratSelection-${index}`}
                carat={carat}
                setCarat={setCarat}
                isRequired={isRequired}
                disabled={disabled}
              />
            );
          })}
      </div>
      <span className="error-label">{error}</span>
    </div>
  );
};

export default React.memo(CaratSelection);
