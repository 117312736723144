import React from "react";
import { Table } from "react-bootstrap";

const DynamicSection = ({ title, columnLabel, data, columns, totalData }) => {
  return (
    <div className="card w-100">
      <h5 className="card-title card-header">{title}</h5>
      <div className="card-body">
        {data?.length > 0 ? (
          <Table borderless>
            <thead>
              <tr>
                {columnLabel?.map((column, index) => (
                  <th key={index}>{column}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => {
                return (
                  <tr key={index}>
                    {columns?.map((column, ind) => (
                      <td key={ind}>{item?.[column]}</td>
                    ))}
                  </tr>
                );
              })}
              {totalData && (
                <>
                  <tr>
                    <td colSpan={3}>
                      <div className="divider my-3" />
                    </td>
                  </tr>
                  {totalData?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <th>{item?.label}</th>
                        <td />
                        <th colSpan={2}>{item?.value}</th>
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
          </Table>
        ) : (
          <span>No data available</span>
        )}
      </div>
    </div>
  );
};

export default DynamicSection;
