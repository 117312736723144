import axios from "axios";
import CONFIG from "../Config/BaseURL";
import { errorToast } from "../Utils/showToast";
import { getToken } from "../Utils/getToken";

const checkCondition = (response) => {
  if (response?.data?.data?.success) {
    return response;
  } else {
    errorToast(response?.data?.data?.msg || response?.data?.data?.message);
    return response;
  }
};

const axiosClient = axios.create({
  baseURL: CONFIG.API_URL,
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
});

axiosClient.interceptors.request.use((req) => {
  const token = getToken();
  if (token) {
    req.headers["Authorization"] = `Bearer ${token}`;
    req.headers["Access-Control-Allow-Origin"] = "*";
  }
  return req;
});

export const getRequest = (url, params, headers) => {
  return axiosClient
    .get(`${CONFIG.API_URL}${url}`, {
      method: "GET",
      params: { ...params },
      headers: { ...headers },
    })
    .then((res) => checkCondition(res))
    .catch((err) => {
      errorToast(err.res?.data?.msg || err?.message);
      return err;
    });
};

export const postRequest = (url, payload, params, headers) => {
  return axiosClient
    .post(`${url}`, payload, {
      params,
      headers,
    })
    .then((res) => checkCondition(res))
    .catch((err) => {
      errorToast(err.res?.data?.msg || err?.message);
      return err;
    });
};

export const patchRequest = (url, payload, params, headers) => {
  return axiosClient
    .patch(
      `${CONFIG.API_URL}${url}`,
      payload && {
        payload,
      },
      {
        params,
        headers,
      }
    )
    .then((res) => checkCondition(res))
    .catch((err) => {
      errorToast(err.res?.data?.msg || err?.message);
      return err;
    });
};

export const putRequest = (url, payload, params, headers) => {
  return axiosClient
    .put(
      `${CONFIG.API_URL}${url}`,
      payload && {
        ...payload,
      },
      {
        params,
        headers,
      }
    )
    .then((res) => checkCondition(res))
    .catch((err) => {
      errorToast(err.res?.data?.msg || err?.message);
      return err;
    });
};

export const deleteRequest = (url, payload, params, headers) => {
  return axiosClient
    .delete(`${CONFIG.API_URL}${url}`, {
      ...(payload && { data: payload }),
      ...(params && { params }),
      headers,
    })
    .then((res) => checkCondition(res))
    .catch((err) => {
      errorToast(err.res?.data?.msg || err?.message);
      return err;
    });
};
