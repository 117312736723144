import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";

import logo from "../Assets/Images/logo.png";

import { getRequest, postRequest } from "../Data/ApiService";
import { ENDPOINTS } from "../Data/Endpoint";

import { loginUser } from "../Store/actions/user/loginUser";
import { setLoading } from "../Store/actions/user/setLoading";

import language from "../Strings/en";
import version from "../Strings/version";
import { updateUser } from "../Store/actions/user/updateUser";
import { errorToast } from "../Utils/showToast";

function Login({ loginUser, loading, setLoading, updateUser }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({
    email: null,
    password: null,
    serverError: null,
  });

  const validateInputs = (name, value) => {
    switch (name) {
      case "email":
        if (!value) {
          setErrors({
            ...errors,
            email: "Please enter an email",
          });
        } else if (value && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
          setErrors({
            ...errors,
            email: "Please enter a valid email",
          });
        } else {
          setErrors({
            ...errors,
            email: null,
          });
        }
        break;

      case "password":
        if (!value) {
          setErrors({
            ...errors,
            password: "Please enter a password",
          });
        } else {
          setErrors({
            ...errors,
            password: null,
          });
        }
        break;

      default:
        break;
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
    } else if (e.target.name === "password") {
      setPassword(e.target.value);
    }
    validateInputs(e.target.name, e.target.value);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      let res = await postRequest(ENDPOINTS.login, {
        email,
        password,
      });
      if (res.data.data.success === true) {
        loginUser(res.data.data.user);

        fetchTaxValues();

        setLoading(false);
      } else {
        setErrors({
          ...errors,
          serverError: res.data.data.msg,
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const fetchTaxValues = async () => {
    await getRequest(ENDPOINTS.getTaxs)
      .then((res) => {
        if (res.status === 200) {
          updateUser({ taxes: res.data?.data?.taxes });
        }
      })
      .catch((err) => {
        errorToast(err.response?.data?.message);
      });
  };
  return (
    <>
      <div className="container-fluid login-container">
        <div className="login-form">
          <img src={logo} alt="Logo" />
          <form onSubmit={handleLogin}>
            {errors.serverError && (
              <p className="error">{errors.serverError}</p>
            )}
            <div className="form-group">
              <label>{language.email}</label>
              <input
                type="text"
                name="email"
                className="form-control"
                placeholder={language.loginEmailPlaceholder}
                value={email}
                onChange={handleChange}
              />
              {errors.email && <p className="error">{errors.email}</p>}
            </div>
            <div className="form-group">
              <label>{language.passoword}</label>
              <input
                type="password"
                name="password"
                className="form-control"
                placeholder={language.loginPasswordPlaceholder}
                value={password}
                onChange={handleChange}
              />
              {errors.password && <p className="error">{errors.password}</p>}
            </div>
            <br />
            <Button
              type="submit"
              variant="warning"
              className="login-button"
              disabled={!email || !password || loading}
            >
              {loading ? `${language.loading}...` : language.login}
            </Button>
          </form>
        </div>
      </div>
      <div className="text-center" style={{ backgroundColor: "#f5f5f5" }}>
        <span className="text-muted">{version}</span>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.userReducer.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: bindActionCreators(loginUser, dispatch),
    setLoading: bindActionCreators(setLoading, dispatch),
    updateUser: bindActionCreators(updateUser, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
