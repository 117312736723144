export const initialStateCart = {
  products: [],
  tax: 0.0,
  discount: 0.0,
  goldPrice: 0.0,
  subTotal: 0.0,
  total: 0.0,
  taxName: "VAT",
  loading: false,
  orderId: null,
  orderCreatedDate: null,
  isOrderDone: false,
  orderStatus: "New",
  isReOpenOrder: false,
};

export const CART_ACTIONS = {
  ADD_PRODUCT: "cartReducer.ADD_PRODUCT",
  BULK_ADD_PRODUCTS: "cartReducer.BULK_ADD_PRODUCTS",
  UPDATE_CART: "cartReducer.UPDATE_CART",
  CLEAR_CART: "cartReducer.CLEAR_CART",
  REMOVE_PRODUCT: "cartReducer.REMOVE_PRODUCTS",
  SET_LOADING: "cartReducer.SET_LOADING",
  SET_GOLD_PRICE: "cartReducer.SET_GOLD_PRICE",
  ADD_TAX_DETAILS: "cartReducer.ADD_TAX_DETAILS",
  SET_ORDER_ID: "cartReducer.SET_ORDER_ID",
  SET_ORDER_NO: "cartReducer.SET_ORDER_NO",
  RESET_STATE: "cartReducer.RESET_STATE",
  ADD_ORDER_CREATED_DATE: "cartReducer.ADD_ORDER_CREATED_DATE",
  IS_ORDER_DONE: "cartReducer.IS_ORDER_DONE",
  SET_ORDER_STATUS: "cartReducer.SET_ORDER_STATUS",
  SET_IS_REOPEN_ORDER: "cartReducer.SET_IS_REOPEN_ORDER",
};

export const cartReducer = (state = initialStateCart, action) => {
  switch (action.type) {
    case CART_ACTIONS.BULK_ADD_PRODUCTS:
      return { ...state, products: action.payload };

    case CART_ACTIONS.ADD_PRODUCT:
      return { ...state, products: [...state.products, { ...action.payload }] };

    case CART_ACTIONS.REMOVE_PRODUCT:
      let productsRemoveProduct = [...state.products];
      productsRemoveProduct.splice(
        productsRemoveProduct.findIndex((p) => p.cartId === action.payload.id),
        1
      );

      return { ...state, products: [...productsRemoveProduct] };

    case CART_ACTIONS.UPDATE_CART:
      let productsUpdateCart = [...state.products];

      return {
        ...state,
        products: productsUpdateCart.map((p) =>
          p.cartId !== action.payload.id
            ? { ...p }
            : { ...p, ...action.payload.updates }
        ),
      };

    case CART_ACTIONS.SET_GOLD_PRICE:
      return {
        ...state,
        goldPrice: action.payload,
      };

    case CART_ACTIONS.SET_ORDER_ID:
      return {
        ...state,
        orderId: action.payload,
      };

    case CART_ACTIONS.SET_ORDER_NO:
      return {
        ...state,
        orderNo: action.payload,
      };

    case CART_ACTIONS.CLEAR_CART:
      return {
        ...state,
        products: [],
      };

    case CART_ACTIONS.ADD_TAX_DETAILS:
      return {
        ...state,
        tax: action?.payload?.tax,
        discount: action.payload.discount ? action?.payload?.discount : 0.0,
        subTotal: action?.payload?.subTotal,
        total: action?.payload?.total,
        taxName: action?.payload?.taxName,
      };
    case CART_ACTIONS.ADD_ORDER_CREATED_DATE:
      return {
        ...state,
        orderCreatedDate: action.payload,
      };
    case CART_ACTIONS.IS_ORDER_DONE:
      return {
        ...state,
        isOrderDone: action.payload,
      };

    case CART_ACTIONS.SET_LOADING:
      return { ...state, loading: action.payload.loading };

    case CART_ACTIONS.SET_ORDER_STATUS:
      return {
        ...state,
        orderStatus: action.payload,
      };

    case CART_ACTIONS.SET_IS_REOPEN_ORDER:
      return {
        ...state,
        isReOpenOrder: action.payload,
      };

    case CART_ACTIONS.RESET_STATE:
      return initialStateCart;

    default:
      return state;
  }
};
