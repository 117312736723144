import { CART_ACTIONS } from '../../reducers/cartReducer';

export const setLoading = (loading) => {
  return (dispatch) => {
    dispatch({
      type: CART_ACTIONS.SET_LOADING,
      payload: {
        loading,
      },
    });
  };
};
