import React, { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import Input from "../Common/Input";
import { Button, Form } from "react-bootstrap";
import CaratSelection from "../AttributeSelectors/CaratSelection";
import useVariants from "../../Hooks/useVariants";
import InputNumber from "../Common/InputNumber";
import DatePicker from "../Common/DatePicker";
import language from "../../Strings/en";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../../Data/ApiService";
import { ENDPOINTS } from "../../Data/Endpoint";
import Dropdown from "../Common/Dropdown";
import { FaPlus } from "react-icons/fa";
import { errorToast, successToast } from "../../Utils/showToast";
import { isEmpty } from "../../Utils/empty";
import { useNavigate } from "react-router-dom";
import { get24CaratWeight } from "../../Utils/purchaseCalculation";
import { acceptFileType } from "../../Config";

const BarsForms = ({ barsDetail }) => {
  const [isEdit, setIsEdit] = useState(false);
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    watch,
    clearErrors,
    setError,
  } = useForm({
    defaultValues: {
      bars: [],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "bars",
    keyName: "fieldId",
  });

  const formValues = watch();

  const navigate = useNavigate();
  const submit = async (data) => {
    if (data.bars?.length > 0) {
      if (isEdit) {
        await putRequest(
          `${ENDPOINTS.updateGoldTransaction}/${barsDetail?.id}`,
          [...data.bars]
        ).then((res) => {
          if (res.data?.status === 200) {
            successToast(res.data?.data?.msg);
          } else {
            errorToast(res.data?.data?.message);
          }
        });
      } else {
        await postRequest(`${ENDPOINTS.createGoldTransaction}`, data.bars).then(
          (res) => {
            if (res.status === 200) {
              successToast(res.data?.data?.msg);
            }
          }
        );
      }
    } else {
      navigate(-1);
    }
  };

  const fetchBars = async () => {
    await getRequest(`${ENDPOINTS.getGoldTransaction}/${barsDetail?.id}`).then(
      (res) => {
        const data = res.data?.data?.goldbartransaction;
        if (isEmpty(data)) {
          addNew();
          setIsEdit(false);
        } else {
          reset({ bars: [...data] });
          setIsEdit(true);
        }
      }
    );
  };

  useEffect(() => {
    fetchBars();
  }, []);

  const addNew = () => {
    append({
      id: 0,
      bar_id: barsDetail?.id,
      name: "",
      carat_id: "",
      weight: "",
      caratweight24: "",
      price: "",
      payment_date: "",
      reciept_no: "",
      payment_id: "",
      files: "",
    });
  };
  const { sizes, metals, carats } = useVariants();
  const [paymentTypes, setPaymentTypes] = useState([]);

  const allPaymentTypes = async () => {
    let res = await getRequest(ENDPOINTS.getPaymentType);

    setPaymentTypes(
      res.data?.data?.paymenttypes?.map((method) => ({
        ...method,
        value: method?.id,
        label: method?.payment_type,
      }))
    );
  };

  useEffect(() => {
    allPaymentTypes();
  }, []);

  const removeBars = async (item, index) => {
    if (item?.id) {
      let res = await deleteRequest(
        `${ENDPOINTS.deleteGoldTransaction}/${item?.id}`
      );
      if (res.data?.data?.success) {
        successToast(res.data?.data?.message);
        remove(index);
      } else {
        errorToast(res?.data?.data?.message);
      }
    } else {
      remove(index);
    }
  };

  const setCaratWeight = ({ weight, carat, index }) => {
    const caratInfo = carats?.find((item) => item?.id == carat);
    const value = get24CaratWeight({
      total_weight: weight || 0,
      goldPercentage: caratInfo?.gold_percentage || 0,
    });

    setValue(`bars.${index}.caratweight24`, value);

    return value;
  };

  const fileSelect = (e, index) => {
    if (e.target.value.length > 0) {
      const file = e.target.files[0];
      if (acceptFileType.includes(file.type)) {
        const FR = new FileReader();
        FR.addEventListener("load", function (evt) {
          setValue(`bars.${index}.files`, [
            ...formValues.bars?.[index]?.files,
            { file_name: evt.target.result },
          ]);
          clearErrors([`bars.${index}.files`]);

          // setFile({ file: evt.target.result, displayImage: evt.target.result });
        });
        FR.readAsDataURL(file);
      } else {
        e.target.value = null;
        setValue(`bars.${index}.files`, []);
        setError(`bars[${index}].files`, {
          message: "Please select valid file.",
        });
      }
      // setFile({ file, displayImage: URL.createObjectURL(file) });
    }
    //  else {
    //   setError("image_name", "File is required.");
    // }
  };
  return (
    <div className="row">
      <div className="col-md-12">
        {fields?.map((item, index) => {
          return (
            <div
              key={index}
              className="row background-white pt-4  pb-5 position-relative mt-4"
            >
              {(item?.id || index > 0) && (
                <Button
                  variant="default removeBarsBtn"
                  onClick={() => removeBars(item, index)}
                >
                  Remove
                </Button>
              )}
              <div className="col-md-3">
                <Controller
                  name={`bars.${index}.name`}
                  control={control}
                  rules={{ required: "Name is required" }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      label="Name"
                      error={errors?.bars?.[index]?.name?.message}
                      placeholder="Name"
                    />
                  )}
                />
              </div>
              <div className="col-md-3">
                <Controller
                  name={`bars.${index}.payment_id`}
                  control={control}
                  rules={{ required: "Payment Method is required" }}
                  render={({ field }) => (
                    <Dropdown
                      {...field}
                      dropdownOptions={paymentTypes}
                      onSelect={field.onChange}
                      label="Payment Method"
                      error={errors?.bars?.[index]?.payment_id?.message}
                      placeholder="Payment Method"
                    />
                  )}
                />
              </div>

              <div className="col-md-3">
                <Controller
                  name={`bars.${index}.weight`}
                  control={control}
                  rules={{ required: "Weight is required" }}
                  render={({ field }) => (
                    <InputNumber
                      {...field}
                      label="Item weight (in gram)"
                      error={errors?.bars?.[index]?.weight?.message}
                      placeholder="Weight"
                      isRequired
                      onChange={(e) => {
                        field.onChange(e);
                        setCaratWeight({
                          weight: e.target.value,
                          carat: formValues.bars?.[index]?.carat_id,
                          index,
                        });
                      }}
                    />
                  )}
                />
              </div>
              <div className="col-md-3">
                <Controller
                  name={`bars.${index}.carat_id`}
                  control={control}
                  rules={{ required: "Carat is required" }}
                  render={({ field }) => (
                    <CaratSelection
                      labelStyle={{
                        fontWeight: "bold",
                        color: "#000",
                      }}
                      caratOptions={carats}
                      carat={field.value}
                      index={index}
                      setCarat={(e) => {
                        field.onChange({
                          target: { name: field.name, value: e },
                        });
                        setCaratWeight({
                          weight: formValues.bars?.[index]?.weight,
                          carat: e,
                          index,
                        });
                      }}
                      isBorder={false}
                      isRequired
                      error={errors?.bars?.[index]?.carat_id?.message}
                    />
                  )}
                />
              </div>
              <div className="col-md-3">
                <Controller
                  name={`bars.${index}.caratweight24`}
                  control={control}
                  rules={{ required: "24 Carat Weight is required" }}
                  render={({ field }) => (
                    <InputNumber
                      {...field}
                      label="24 Carat Weight (in grams)"
                      error={errors?.bars?.[index]?.["caratweight24"]?.message}
                      placeholder="24 Carat Weight"
                      isRequired
                      readOnly
                    />
                  )}
                />
              </div>
              <div className="col-md-3">
                <Controller
                  name={`bars.${index}.price`}
                  control={control}
                  rules={{ required: "Price is required" }}
                  render={({ field }) => (
                    <InputNumber
                      {...field}
                      label="Price"
                      error={errors?.bars?.[index]?.price?.message}
                      placeholder="Price"
                      decimal={"false"}
                      isRequired
                    />
                  )}
                />
              </div>
              <div className="col-md-3">
                <Controller
                  name={`bars.${index}.payment_date`}
                  control={control}
                  rules={{ required: "Date is required" }}
                  render={({ field }) => (
                    <DatePicker
                      minDate={new Date()}
                      label={language.paymentDate}
                      isRequired
                      size={"lg"}
                      {...field}
                      error={errors?.bars?.[index]?.payment_date?.message}
                    />
                  )}
                />
              </div>
              <div className="col-md-3">
                <Controller
                  name={`bars.${index}.reciept_no`}
                  control={control}
                  rules={{ required: "Receipt is required" }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      label="Receipt No."
                      error={errors?.bars?.[index]?.reciept_no?.message}
                      placeholder="Receipt"
                      isRequired
                    />
                  )}
                />
              </div>
              <div className="col-md-3">
                <Controller
                  name={`bars.${index}.files`}
                  control={control}
                  // rules={{ required: "Receipt is required" }}
                  render={({ field }) => (
                    <div className="form-group mt-2">
                      <Form.Label className="mb-0">Attachments</Form.Label>
                      <input
                        type="file"
                        className="form-control "
                        id={"fileInput"}
                        accept={acceptFileType?.join(", ")}
                        onChange={(e) => fileSelect(e, index)}
                        name={field.name}
                        ref={field.ref}
                        multiple={true}
                      />
                      {errors?.bars?.[index]?.files?.message && (
                        <span className="error-label">
                          {errors?.bars?.[index]?.files?.message}
                        </span>
                      )}
                    </div>
                  )}
                />
              </div>
            </div>
          );
        })}
        <div className="mt-4 d-flex justify-content-between">
          <Button
            onClick={addNew}
            variant="default"
            className="me-2 d-flex align-items-center addBarBtn"
          >
            <FaPlus size={10} /> &nbsp;Add new
          </Button>
          <Button onClick={handleSubmit(submit)} variant="warning">
            Done
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BarsForms;
