import React from "react";
import language from "../../Strings/en";
import Modal from "../Common/Modal";
import CreateBarForm from "./CreateBarForm";

const CreateBarModal = ({ open, onHide, data, ...props }) => {
  return (
    <Modal
      show={open}
      onHide={onHide}
      size="small"
      header={<h3>{language.dbBars}</h3>}
    >
      <CreateBarForm {...props} />
    </Modal>
  );
};

export default CreateBarModal;
