import { CART_ACTIONS } from "../../reducers/cartReducer";

export const addProductToCart = (product) => {
  return (dispatch) => {
    dispatch({
      type: CART_ACTIONS.ADD_PRODUCT,
      payload: product,
    });
  };
};
