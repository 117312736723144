import React, { useEffect, useState } from "react";
import { Button, Form, Offcanvas } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import CaratSelection from "../AttributeSelectors/CaratSelection";
import MetalSelection from "../AttributeSelectors/MetalSelection";
import SizeSelection from "../AttributeSelectors/SizeSelection";
import GiftWrapModal from "./GiftWrapModal";

import { getRequest, postRequest, putRequest } from "../../Data/ApiService";
import { ENDPOINTS } from "../../Data/Endpoint";

import { addProductToCart } from "../../Store/actions/cart/addProductToCart";
import { addTaxDetails } from "../../Store/actions/cart/addTaxDetails";
import { setLoading } from "../../Store/actions/cart/setLoading";
import { updateCart } from "../../Store/actions/cart/updateCart";

import useVariants from "../../Hooks/useVariants";
import language from "../../Strings/en";
import { removeDuplicated } from "../../Utils/array";
import { successToast } from "../../Utils/showToast";
import Input from "../Common/Input";

function ProductOffCanvas({
  actionType,
  show,
  openAttributeSelection,
  productId,
  cartId,
  storeId,
  orderId,
  productTitle,
  addProductToCart,
  updateCart,
  cartProductIds,
  cartProduct,
  authToken,
  setLoading,
  addTaxDetails,
  placement = "end",
  currentProductDetail,
  ...props
}) {
  const [productDetail, setProductDetail] = useState(currentProductDetail);
  const [carat, setCarat] = useState(null);
  const [metal, setMetal] = useState(null);
  const [size, setSize] = useState(null);
  // const [weight, setWeight] = useState(0);
  const [description, setDescription] = useState("");
  const [isGiftWrap, setIsGiftWrap] = useState(false);
  const [isGiftWrapModalOpen, setIsGiftWrapModalOpen] = useState(false);
  const [giftMessage, setGiftMessage] = useState("");
  const [giftInstructions, setGiftInstructions] = useState("");
  const [variants, setVariants] = useState({
    carats: [],
    sizes: [],
    metals: [],
  });
  const [errors, setErrors] = useState({
    selectionError: null,
    giftMessage: null,
    giftInstructions: null,
  });

  const [weight, setWeight] = useState("");

  const { carats, metals, sizes } = useVariants();

  const handleGiftWrapChange = (e) => {
    if (e.target.name === "giftMessage") {
      setGiftMessage(e.target.value);

      setErrors({
        ...errors,
        giftMessage: e.target.value === "" ? "Please enter gift message" : null,
      });
    } else {
      setGiftInstructions(e.target.value);
    }
  };

  const openAttributeSelectionAction = async (_) => {
    if (actionType === "add") {
      // if (
      //   (!carat || !size || !metal) &&
      //   (productDetail?.producttype == 2 || cartProduct?.producttype == 2)
      // ) {
      //   setErrors({
      //     ...errors,
      //     selectionError: "Please select all the attributes.",
      //   });
      // } else
      if (!weight) {
        setErrors({
          ...errors,
          selectionError: "Please add Weight.",
        });
      } else {
        setErrors({
          ...errors,
          selectionError: null,
        });
        setLoading(true);

        try {
          let res = await postRequest(
            ENDPOINTS.cart,
            {
              store_id: storeId,
              order_id: orderId,
              purchase_id: productId,

              ...(carat && { carat_id: carat }),
              ...(metal && { metal_id: metal }),
              ...(size && { size_id: size }),

              ...(weight && {
                total_weight: weight,
              }),

              is_gift: isGiftWrap ? 1 : 0,
              gift_message: giftMessage,
              gift_instruction: giftInstructions,
            },
            null,
            {
              Authorization: `Bearer ${authToken}`,
            }
          );
          let billRes = await getRequest(ENDPOINTS.cartTotal + orderId);
          addTaxDetails({
            tax: billRes.data.data.tax,
            subTotal: billRes.data.data.subtotal,
            total: billRes.data.data.total,
            taxName: billRes.data?.data?.tax_name,
          });
          if (res.status === 200 && res?.data?.data?.success) {
            const addedCartid = Math.max(
              ...res?.data?.data?.cart_items?.map((item) => item?.id)
            );

            const addItem = res.data?.data?.cart_items.find(
              (item) => item.id === addedCartid
            );

            addProductToCart({
              ...productDetail,
              qty: 1,
              product_qty: 1,
              ...(productDetail?.producttype == 2 && {
                carat_id: carat,
                metal_id: metal,
                size_id: size,
              }),
              // weight,
              description,
              cartId: addItem?.id,
              product_amt: addItem?.product_amt,
              costmk: addItem?.costmk,
              tax_id: addItem?.tax_id,
              tax_amt: addItem?.tax_amt,
              isGiftWrap: isGiftWrap,
              total_weight: addItem?.total_weight,
              price: addItem?.price,
              product_amt: addItem?.product_amt,
              making_charge: addItem?.making_charge,
              total_cost: addItem?.total_cost,
              cost_price: addItem?.cost_price,
              ...(isGiftWrap && { giftMessage, giftInstructions }),
            });
            successToast("Product added to cart successfully");
            setLoading(false);
            openAttributeSelection(false);
          } else {
            setLoading(false);
            // errorToast(res?.data?.message || res?.data?.data?.msg);
          }
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
      }
    } else {
      setLoading(true);

      try {
        const res = await putRequest(ENDPOINTS.cartUpdate, {
          store_id: storeId,
          product_id: productId,
          qty: cartProduct.qty,
          // weight: convertWeightToInteger(weight),
          orderitem_id: cartProduct.cartId,
          order_id: orderId,
          is_gift: cartProduct.isGiftWrap ? 1 : 0,
          gift_message: giftMessage,
          gift_instruction: giftInstructions,
          ...(cartProduct?.producttype == 2 && {
            carat_id: carat,
            size_id: size,
            metal_id: metal,
          }),
          tax_id: cartProduct?.tax_id,
          ...(weight && {
            total_weight: weight,
          }),
          sale_mk: productDetail?.making_charge,
          sale_price: productDetail?.price,
        });
        // console.log("***product canvas***");
        // let billRes = await getRequest(ENDPOINTS.cartTotal + orderId);
        // addTaxDetails({
        //   tax: parseFloat(billRes.data.data.tax),
        //   subTotal: parseFloat(billRes.data.data.subtotal),
        //   total: parseFloat(billRes.data.data.total),
        // });
        if (res.data?.data?.success) {
          let cartObj = res.data.data.cart_items.filter(
            (p) => p.id === cartProduct?.cartId
          )[0];

          updateCart({
            id: cartProduct.cartId,
            updates: {
              ...(cartProduct?.producttype == 2 && {
                carat_id: carat,
                metal_id: metal,
                size_id: size,
              }),

              // weight,
              description,
              isGiftWrap,
              total_weight: cartObj?.total_weight,
              price: cartObj?.price,
              product_amt: cartObj?.product_amt,
              total_cost: cartObj?.total_cost,
              cost_price: cartObj?.cost_price,
              ...(isGiftWrap && { giftMessage, giftInstructions }),
            },
          });
          successToast("Product updated successfully in cart.");
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }

      openAttributeSelection(false);
    }
  };

  useEffect(() => {
    setProductDetail(currentProductDetail);
  }, [currentProductDetail]);

  useEffect(
    (_) => {
      if (productId) {
        (async (_) => {
          const product = currentProductDetail;
          if (product?.producttype == 2) {
            setVariants({
              carats: product?.carats,
              metals: product?.metals,
              sizes: product?.sizes,
            });
            filterProductVariants(product);

            setProductDetail(currentProductDetail);
          }

          console.log("vasim", actionType);
          if (actionType === "edit") {
            // if (cartProduct?.producttype == 2) {
            // filterProductVariants(
            //   "carat_id",
            //   cartProduct.carat_id,
            //   product,
            //   false,
            //   true,
            //   {
            //     metal_id: cartProduct.metal_id,
            //     size_id: cartProduct.size_id,
            //   }
            // );
            setCarat(product.carat_id);

            // setCarat(cartProduct.carat_id || cartProduct.carat);
            setMetal(product.metal_id || product.metal);
            setSize(product.size_id || product.size);
            // }

            setWeight(product?.total_weight);

            // setWeight(
            //   typeof cartProduct.weight === "string"
            //     ? cartProduct.weight
            //     : `${cartProduct.weight} g`
            // );
            setDescription(product.description);
            setIsGiftWrap(product.isGiftWrap);
            setGiftMessage(product.giftMessage);
            setGiftInstructions(product.giftInstructions);
          } else {
            setCarat(product?.carat_id);
            setMetal(product?.metal_id);
            setSize(product?.size_id);
            // filterProductVariants(
            //   "carat_id",
            //   product?.carat_id,
            //   product,
            //   true
            // );
            // setMetal(res.data.data.product?.metals?.[0]?.id);
            // setSize(res.data.data.product?.sizes?.[0]?.id);
            // setWeight(res.data.data.product?.weight);
          }

          setLoading(false);
        })();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentProductDetail]
  );

  // useEffect(
  //   (_) => {
  //     if (productId) {
  //       (async (_) => {
  //         setLoading(true);
  //         let res = await getRequest(
  //           ENDPOINTS.productDetails + productId + "/detail",
  //           null,
  //           null
  //         );

  //         const product = res.data.data.product;
  //         setProductDetail(res.data.data.product);
  //         if (product?.producttype == 2) {
  //           setVariants({
  //             carats: product?.carats,
  //             metals: product?.metals,
  //             sizes: product?.sizes,
  //           });
  //           filterProductVariants(res.data.data.product);
  //         }

  //         if (actionType === "edit") {
  //           if (cartProduct?.producttype == 2) {
  //             filterProductVariants(
  //               "carat_id",
  //               cartProduct.carat_id,
  //               product,
  //               false,
  //               true,
  //               {
  //                 metal_id: cartProduct.metal_id,
  //                 size_id: cartProduct.size_id,
  //               }
  //             );
  //             setCarat(cartProduct.carat_id);

  //             // setCarat(cartProduct.carat_id || cartProduct.carat);
  //             setMetal(cartProduct.metal_id || cartProduct.metal);
  //             setSize(cartProduct.size_id || cartProduct.size);
  //           }

  //           console.log(cartProduct?.total_weight);
  //           setWeight(cartProduct?.total_weight);

  //           // setWeight(
  //           //   typeof cartProduct.weight === "string"
  //           //     ? cartProduct.weight
  //           //     : `${cartProduct.weight} g`
  //           // );
  //           setDescription(cartProduct.description);
  //           setIsGiftWrap(cartProduct.isGiftWrap);
  //           setGiftMessage(cartProduct.giftMessage);
  //           setGiftInstructions(cartProduct.giftInstructions);
  //         } else {
  //           if (product?.producttype == 2) {
  //             setCarat(res.data.data.product?.carats?.[0]?.id);
  //             filterProductVariants(
  //               "carat_id",
  //               res.data.data.product?.carats?.[0]?.id,
  //               product,
  //               true
  //             );
  //           }
  //           // setMetal(res.data.data.product?.metals?.[0]?.id);
  //           // setSize(res.data.data.product?.sizes?.[0]?.id);
  //           // setWeight(res.data.data.product?.weight);
  //         }

  //         setLoading(false);
  //       })();
  //     }
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [productId]
  // );

  const filterProductVariants = (
    type,
    id,
    detail = productDetail,
    initial = false,
    edit = false,
    editField
  ) => {
    const findVariants = detail?.purchase_variants?.filter(
      (item) => item?.[type] == id
    );
    const metals = removeDuplicated(findVariants?.map((item) => item?.metal));
    const sizes = removeDuplicated(findVariants?.map((item) => item?.size));

    let updatedData = {
      ...(type === "carat_id" && { sizes, metals }),
      ...(type === "metal_id" && { sizes }),
    };
    setVariants((prev) => ({ ...prev, ...updatedData }));
    if (type === "carat_id") {
      setMetal(null);
      setSize(null);
      if (initial) {
        setMetal(metals?.[0]?.id);
        setSize(sizes?.[0]?.id);
      }
      if (edit) {
        setMetal(editField?.metal_id);
        setSize(editField?.size_id);
        filterProductVariants(
          "metal_id",
          editField?.metal_id,
          detail,
          false,
          true,
          editField
        );
      }
    }
    if (type === "metal_id") {
      setSize(null);
    }
  };

  useEffect((_) => {
    isGiftWrap === true && setIsGiftWrapModalOpen(true);
  }, []);

  return (
    <Offcanvas
      show={show}
      onHide={(_) => openAttributeSelection(false)}
      placement={placement}
      className="product-offcanvas"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className="h6">
          {actionType === "add" ? "Add to Cart" : "Edit"}:{" "}
          <strong title={productTitle}>
            {productTitle?.substr(0, 24)}
            {productTitle?.length > 25 ? "..." : ""}
          </strong>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {errors.selectionError && (
          <p className="error">{errors.selectionError}</p>
        )}

        {/* <form className="product-offcanvas__form" action=""> */}
        <div className="form__body">
          {productDetail?.carat_id ? (
            <CaratSelection
              caratOptions={[
                carats?.find((item) => productDetail?.carat_id == item?.id),
              ]}
              carat={carat}
              setCarat={(v) => {
                // filterProductVariants("carat_id", v);
                setCarat(v);
              }}
            />
          ) : (
            <CaratSelection
              caratOptions={carats}
              carat={carat}
              setCarat={(v) => {
                // filterProductVariants("carat_id", v);
                setCarat(v);
              }}
            />
          )}
          {productDetail?.metal_id ? (
            <MetalSelection
              metalOptions={[
                metals?.find((item) => productDetail?.metal_id == item?.id),
              ]}
              metal={metal}
              setMetal={(v) => {
                // filterProductVariants("metal_id", v);
                setMetal(v);
              }}
            />
          ) : (
            <MetalSelection
              metalOptions={metals}
              metal={metal}
              setMetal={(v) => {
                // filterProductVariants("metal_id", v);
                setMetal(v);
              }}
            />
          )}
          {productDetail?.size_id && (
            <SizeSelection
              sizeOptions={[
                sizes?.find((item) => productDetail?.size_id == item?.id),
              ]}
              size={size}
              setSize={(v) => {
                // filterProductVariants("size_id", v);
                setSize(v);
              }}
            />
          )}
          <span>{language.totalWeight}</span>
          <Input
            placeholder={language.totalWeight}
            value={weight}
            onChange={(e) => {
              const re = /^[0-9]*[.]?[0-9]*$/;
              if (re.test(e.target.value)) {
                setWeight(e.target.value);
              }
            }}
          />
          {/* <WeightSelection
              weightOptions={productDetail?.weight}
              weight={weight}
              setWeight={setWeight}
            /> */}
          {/* <GeneralDetails detail={productDetail} />

            <Form.Label htmlFor="disabledTextInput" className="commentText">
              {productDetail?.description}
            </Form.Label> */}
          <div>
            <Form.Check
              inline
              label="Gift Wrap?"
              name="giftWrap"
              type="checkbox"
              className="mt-3"
              checked={isGiftWrap}
              onChange={(e) => {
                if (e.target.checked) {
                  setIsGiftWrapModalOpen(true);
                } else {
                  setGiftInstructions("");
                  setGiftMessage("");
                }
                setIsGiftWrap(e.target.checked);
              }}
            />
            {isGiftWrap && (
              <span
                className="text-warning"
                style={{ cursor: "pointer", fontSize: "14px" }}
                onClick={() => {
                  setIsGiftWrapModalOpen(true);
                }}
              >
                Edit
              </span>
            )}
          </div>
          {giftMessage && (
            <div className="d-flex flex-column">
              <strong>Gift Message</strong>
              <span className="giftLabelValue">{giftMessage}</span>
            </div>
          )}
          {giftInstructions && (
            <div className="d-flex flex-column">
              <strong>Gift Instructions</strong>
              <span className="giftLabelValue">{giftInstructions}</span>
            </div>
          )}
        </div>
        {/* </form> */}
      </Offcanvas.Body>
      <div className="form__actions">
        <Button
          className="text-uppercase w-100"
          variant="secondary"
          onClick={(_) => openAttributeSelection(false)}
        >
          Cancel
        </Button>
        <Button
          className="text-uppercase w-100"
          variant="warning"
          type="submit"
          onClick={openAttributeSelectionAction}
          disabled={
            cartProduct?.producttype == 2
              ? !carat ||
                !size ||
                !metal ||
                !weight ||
                !weight ||
                Number(weight) == 0
              : !weight || Number(weight) == 0
          }
        >
          {actionType === "add" ? "Add" : "Save"}
        </Button>
      </div>

      {isGiftWrapModalOpen && (
        <GiftWrapModal
          isGiftWrapModalOpen={isGiftWrapModalOpen}
          giftMessage={giftMessage}
          giftInstructions={giftInstructions}
          errors={errors}
          handleGiftWrapChange={handleGiftWrapChange}
          setIsGiftWrap={setIsGiftWrap}
          setIsGiftWrapModalOpen={setIsGiftWrapModalOpen}
        />
      )}
    </Offcanvas>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    storeId: state.userReducer.store_id,
    orderId: state.cartReducer.orderId,
    authToken: state.userReducer.token,
    cartProductIds: state.cartReducer.products.map((p) => p.id),
    cartProduct:
      state.cartReducer.products.filter(
        (p) => p.cartId === ownProps.cartId
      )[0] || null,
    loading: state.cartReducer.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addProductToCart: bindActionCreators(addProductToCart, dispatch),
    updateCart: bindActionCreators(updateCart, dispatch),
    setLoading: bindActionCreators(setLoading, dispatch),
    addTaxDetails: bindActionCreators(addTaxDetails, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ProductOffCanvas));
