import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { AiFillDelete } from "react-icons/ai";
import { connect } from "react-redux";
import UploadImagePlaceholder from "../../Assets/Images/upload-image-placeholder.png";
import CONFIG from "../../Config/BaseURL";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../../Data/ApiService";
import { ENDPOINTS } from "../../Data/Endpoint";
import useCategories from "../../Hooks/useCategories";
import useSuppliers from "../../Hooks/useSuppliers";
import useVariants from "../../Hooks/useVariants";
import language from "../../Strings/en";
import { displayThreeDecimal } from "../../Utils/displayThreeDecimal";
import { isEmpty } from "../../Utils/empty";
import { errorToast, successToast } from "../../Utils/showToast";
import CaratSelection from "../AttributeSelectors/CaratSelection";
import MetalSelection from "../AttributeSelectors/MetalSelection";
import AlertDialog from "../Common/AlertDialog";
import DivBorderContainer from "../Common/DivBorderContainer";
import Dropdown from "../Common/Dropdown";
import Input from "../Common/Input";
import SelectSearch from "../Common/SelectSearch";
import Switch from "../Common/Switch";
import { convertInputValueToNumber, createError } from "../../Utils/input";
import { debounce } from "../../Utils/search";
import { Link } from "react-router-dom";

const ProductForm = ({
  purchaseData: productData,
  isEdit,
  actionOnPurchase,
  storeId,
}) => {
  const initialState = {
    code: "",
    title_en: "",
    variants: [],
    group_id: "",
    // price_type: "1",
    making_charge: "",
    active: true,
    // producttype: "1",
    sku: "",
    barcode_number: "",
    image_name: null,
    track_inventory: true,
    price: "",
    carat_id: "",
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
    watch,
    reset,
  } = useForm({
    defaultValues: initialState,
  });

  const formValues = watch();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "variants",
  });

  const [formData, setFormData] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const { sizes, metals, carats } = useVariants();
  const { categories } = useCategories();

  const variantCount = () => {
    // append({
    //   carat_id: null,
    //   metal_id: null,
    //   size_id: "",
    //   barcode_number: "",
    //   sku: "",
    //   price: "",
    //   id: 0,
    // });
  };

  useEffect(() => {
    if (isEdit) {
      let data = productData;

      data.price_type = String(data?.price_type);

      data.variants = data?.variants?.map((item) => {
        return {
          carat_id: item.carat_id,
          metal_id: item.metal_id,
          size_id: item.size_id,
          price: item?.price,
          sku: item?.sku,
          barcode_number: item?.barcode_number,
          id: item?.id,
        };
      });

      Object.keys(data).forEach((item) => {
        setValue(item, data[item] || "");
      });

      clearErrors();
    } else {
      reset(initialState);
    }
  }, [productData, isEdit]);

  const numberField = ["making_charge", "price", "producttype", "price_type"];
  const submit = async (e) => {
    let data = { ...e };

    data.variants =
      data?.variants?.length > 0
        ? data?.variants?.map((item) => {
            return {
              ...item,
              price: displayThreeDecimal(item.price),
            };
          })
        : [];

    if (data.image_name?.includes("http") || data.image_name === null) {
      delete data.image_name;
    }

    numberField.forEach((item) => {
      data[item] = Number(data[item]);
    });

    Object.keys(data).forEach((item) => {
      if (isEmpty(data[item]) || data[item] === 0) {
        delete data[item];
      }
    });

    let res;
    if (data?.id) {
      await putRequest(`${ENDPOINTS.updateProductItem}/${data?.id}`, {
        ...data,
        store_product_variant_id: data?.variantId,
      })
        .then((response) => {
          res = response;
        })
        .catch((err) => {
          errorToast(err.response.data.message);
        });
    } else {
      await postRequest(ENDPOINTS.createProductItem, data)
        .then((response) => {
          res = response;
        })
        .catch((err) => {
          errorToast(err.response.data.message);
        });
    }
    if (res?.data?.data?.success) {
      successToast(res.data.data.msg);
      setLoading(false);
      actionOnPurchase();
      resetFields();
    } else {
      createError(res?.data, setError);
      // errorToast(JSON.stringify(res?.data?.data?.errors));
    }
  };

  const resetFields = () => {
    reset(initialState);
  };

  const [options, setOptions] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);

  const triggerSearch = async () => {
    setPage(1);
    searchPurchase(1);
  };

  const searchPurchase = async (newPage) => {
    if (!isEmpty(formValues.title_en)) {
      const res = await getRequest(ENDPOINTS.getProducts, {
        page: newPage,
        storeId,
        ...(!isEmpty(formValues.title_en) && { keyword: formValues.title_en }),
      });
      if (res.data.data.success) {
        if (page > 1) {
          setOptions((prev) => [
            ...prev,
            ...res.data?.data?.products?.map((option) => ({
              ...option,
              key: option.id,
              label: option.title_en,
            })),
          ]);
        } else {
          setOptions(
            res.data?.data?.products?.map((option) => ({
              ...option,
              key: option.id,
              label: option.title_en,
            }))
          );
        }
        setHasMore(res?.data?.data?.hasMorePages === 0 ? false : true);
      }
    }
  };

  useEffect(() => {
    if (page !== 1) {
      searchPurchase(page);
    }
  }, [page]);

  const loadMore = useCallback(() => {
    setPage((prev) => prev + 1);
  }, []);

  const onSearch = () => {
    debounce(triggerSearch());
  };

  const onSelect = (purchase) => {
    let data = {
      ...purchase,
      variants: purchase?.variants.map((item) => {
        return {
          carat_id: item?.carat_id,
          metal_id: item?.metal_id,
          size_id: item?.size_id,
          price: item?.price,
          sku: item?.sku,
          barcode_number: item?.barcode_number,
          id: item?.id,
        };
      }),
    };

    if (!purchase?.image_name) {
      data.image_name = null;
    } else {
      data.image_name = `${CONFIG.IMAGE_ROOT_URL}${purchase?.image_name}`;
    }

    Object.keys(data).forEach((item) => {
      setValue(item, data[item] || "");
    });
  };

  const [file, setFile] = useState({ displayImage: null, file: null });
  const fileSelect = (e) => {
    if (e.target.value.length > 0) {
      const file = e.target.files[0];
      const FR = new FileReader();
      FR.addEventListener("load", function (evt) {
        console.log(evt.target.result);
        setValue("image_name", evt.target.result);
        clearErrors(["image_name"]);
        // setFile({ file: evt.target.result, displayImage: evt.target.result });
      });
      FR.readAsDataURL(file);
      // setFile({ file, displayImage: URL.createObjectURL(file) });
    }
    //  else {
    //   setError("image_name", "File is required.");
    // }
  };

  const removeImage = () => {
    setFile({ file: null, displayImage: null });
    document.getElementById("fileInput").value = null;
    setValue("image_name", null);
  };

  const isDisabled = useMemo(() => {
    let optionalField = [
      "name",
      "sellingPrice",
      "supplier_name",
      "variants",
      "image_name",
    ];

    if (formData.payment === "2") {
      optionalField.push("goldRate");
    } else {
      optionalField.pop();
    }

    return (
      loading ||
      Object.entries(formData).some((data) => {
        if (!optionalField.includes(data[0])) {
          if (data[1] === null || data[1] === "") {
            return true;
          } else {
            return false;
          }
        }
      })
    );
  }, [formData]);

  const [showAlert, setShowAlert] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const deleteProduct = async () => {
    let res = await deleteRequest(`${ENDPOINTS.deleteProductItem}/${deleteId}`);
    if (res.status === 200) {
      actionOnPurchase();
      resetFields();
    }
    setShowAlert(false);
  };

  return (
    <div>
      {/* <form> */}
      <div className="row mb-3">
        {/* <div className="d-flex">
          <Controller
            name="producttype"
            control={control}
            render={({ field }) => (
              <>
                <Form.Check
                  type="radio"
                  id="product-simple"
                  label={language.simpleProduct}
                  className="checkbox-smalllabel"
                  {...field}
                  value={1}
                  onChange={(e) => {
                    setValue("variants", []);
                    field.onChange(convertInputValueToNumber(e));
                  }}
                  checked={formValues.producttype == 1}
                />
                <Form.Check
                  type="radio"
                  id="product-variant"
                  label={language.productWithVariants}
                  className="checkbox-smalllabel ms-3"
                  {...field}
                  value={2}
                  onChange={(e) => {
                    variantCount();

                    field.onChange(convertInputValueToNumber(e));
                  }}
                  checked={formValues.producttype == 2}
                />
              </>
            )}
          />
        </div> */}
      </div>
      <div className="row mb-3">
        <div className="col-sm-12 col-md-4 col-lg-4">
          <Controller
            name="code"
            control={control}
            render={({ field }) => (
              <Input
                label={language.productCode}
                type="text"
                placeholder={language.productCode}
                onChange={(e) => {
                  const re = /^[0-9]*[.]?[0-9]*$/;
                  if (re.test(e.target.value)) {
                    field.onChange(e);
                  }
                }}
                {...field}
              />
            )}
          />
        </div>
        <div className="col-sm-12 col-md-4 col-lg-4">
          {/* <form onSubmit={triggerSearch}> */}
          <Controller
            name="title_en"
            control={control}
            rules={{ required: "Name is required" }}
            render={({ field }) => (
              <SelectSearch
                size="lg"
                onSelect={onSelect}
                options={options}
                placeholder={language.purchaseName}
                label={language.purchaseName}
                onSearch={(value) => {
                  field.onChange({ target: { name: field.name, value } });
                  triggerSearch();
                }}
                loadMore={loadMore}
                hasMore={hasMore}
                clearAction={() => setFormData(initialState)}
                isRequired
                value={field.value}
                error={errors?.title_en?.message}
              />
            )}
          />
          {/* </form> */}
        </div>
        <div className="col-sm-12 col-md-4 col-lg-4 d-flex justify-content-center align-items-center">
          <div className="uploadImageContainer d-flex flex-column">
            <Controller
              name="image_name"
              control={control}
              // rules={{ required: "File is Required." }}
              render={({ field }) => (
                <input
                  type="file"
                  className="d-none"
                  id={"fileInput"}
                  accept="image/png, image/jpeg"
                  onChange={fileSelect}
                  name={field.name}
                  ref={field.ref}
                />
              )}
            />
            <img
              src={formValues.image_name || UploadImagePlaceholder}
              className={"uploadImage"}
              onClick={() => {
                document.getElementById("fileInput").click();
              }}
            />
            {file.displayImage && (
              <button className="removeImageButton" onClick={removeImage}>
                &times;
              </button>
            )}
            <span className="error-label">{errors?.image_name?.message}</span>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-sm-12 col-md-4">
          <Controller
            name="group_id"
            control={control}
            rules={{ required: "Category is required" }}
            render={({ field }) => (
              <SelectSearch
                placeholder="Category"
                options={categories}
                size="lg"
                value={
                  typeof field.value === "string"
                    ? field.value
                    : categories
                        ?.find((category) =>
                          category?.groups?.some(
                            (group) => group?.id === field.value
                          )
                        )
                        ?.groups?.find((group) => group?.id === field.value)
                        .label
                }
                label="Category"
                treeView={true}
                onSelect={(value) => {
                  field.onChange({
                    target: { name: field.name, value: value.value },
                  });
                }}
                isRequired
                searchable={false}
                error={errors?.group_id?.message}
              />
            )}
          />
        </div>
        <div className="col-sm-12 col-md-4">
          <Controller
            name="sku"
            control={control}
            render={({ field }) => (
              <Input
                label={language.sku}
                type="text"
                placeholder={language.sku}
                {...field}
                error={errors?.sku?.message}
              />
            )}
          />
        </div>
        {/* <div className="col-sm-12 col-md-4 ">
          <Controller
            name="barcode_number"
            control={control}
            render={({ field }) => (
              <Input
                label={language.barcode}
                type="text"
                placeholder={language.barcode}
                {...field}
                error={errors?.barcode_number?.message}
              />
            )}
          />
        </div> */}
      </div>

      {/* <div className="row mb-3">
        <div className="col-md-6">
          <DivBorderContainer title={language.priceType} isRequired>
            <Controller
              name="price_type"
              control={control}
              render={({ field }) => (
                <div className="row">
                  <div className="col-sm-12 col-md-4 col-3 ">
                    <Form.Check
                      type="radio"
                      id="fixed"
                      label={language.fixed}
                      className="checkbox-smalllabel"
                      {...field}
                      value={1}
                      checked={formValues.price_type == "1"}
                      required
                    />
                  </div>
                  <div className="col-sm-12 col-md-8 col-3 ">
                    <Form.Check
                      type="radio"
                      id="market"
                      label={language.marketPrice}
                      className="checkbox-smalllabel"
                      {...field}
                      value={2}
                      checked={formValues.price_type == "2"}
                      required
                      // disabled
                    />
                  </div>
                </div>
              )}
            />
          </DivBorderContainer>
        </div>
        {formValues.producttype == "1" && formValues.price_type == "1" && (
          <div className="col-sm-12 col-md-6">
            <Controller
              name={"price"}
              rules={{ required: "Price is required" }}
              control={control}
              render={({ field }) => (
                <Input
                  min={1}
                  label="Selling Price"
                  type="text"
                  placeholder="Selling Price"
                  {...field}
                  isRequired
                  onChange={(e) => {
                    const re = /^[0-9]*[.]?[0-9]*$/;
                    if (re.test(e.target.value)) {
                      field.onChange(e);
                    }
                  }}
                  error={errors?.price?.message}
                />
              )}
            />
          </div>
        )}
        {formValues.price_type === "2" && (
          <div className="col-sm-12 col-md-6">
            <Controller
              name="making_charge"
              control={control}
              rules={{ required: "Making Charge is required" }}
              render={({ field }) => (
                <Input
                  min={1}
                  label="Selling Marking Charge"
                  type="text"
                  placeholder="Selling Marking Charge"
                  {...field}
                  onChange={(e) => {
                    const re = /^[0-9]*[.]?[0-9]*$/;
                    if (re.test(e.target.value)) {
                      field.onChange(e);
                    }
                  }}
                  isRequired
                  error={errors?.making_charge?.message}
                />
              )}
            />
          </div>
        )}
      </div> */}
      {/* {formValues.producttype == 1 && (
        <div className="row mb-3">
          <Controller
            name={`carat_id`}
            control={control}
            rules={{ required: "Carat is required" }}
            render={({ field }) => (
              <CaratSelection
                labelStyle={{
                  fontWeight: "bold",
                  color: "#000",
                }}
                caratOptions={carats}
                carat={field.value}
                // index={index}
                setCarat={(e) =>
                  field.onChange({
                    target: { name: field.name, value: e },
                  })
                }
                isBorder={false}
                isRequired
                error={errors?.carat_id?.message}
              />
            )}
          />
        </div>
      )} */}

      <div className="row mb-3">
        <div className="col-sm-12 col-md-2">
          <Controller
            name="active"
            control={control}
            render={({ field }) => (
              <Switch title={"Active"} {...field} checked={formValues.active} />
            )}
          />
        </div>
        <div className="col-sm-12 col-md-10">
          <Controller
            name="track_inventory"
            control={control}
            render={({ field }) => (
              <Switch
                title={language.trackProduct}
                {...field}
                checked={formValues.track_inventory}
              />
            )}
          />
        </div>
      </div>

      {/* {formValues.producttype == 2 && (
        <DivBorderContainer
          title="Variants"
          isRequired
          addButton={true}
          variantCount={variantCount}
        >
          {fields.map((element, index) => {
            return (
              <div key={index}>
                <div className="custom-grid position-relative mb-3">
                  <div>
                    <div className="form-group">
                      <Controller
                        name={`variants.${index}.carat_id`}
                        control={control}
                        rules={{ required: "Carat is required" }}
                        render={({ field }) => (
                          <CaratSelection
                            labelStyle={{
                              fontWeight: "bold",
                              color: "#000",
                            }}
                            caratOptions={carats}
                            carat={field.value}
                            index={index}
                            setCarat={(e) =>
                              field.onChange({
                                target: { name: field.name, value: e },
                              })
                            }
                            isBorder={false}
                            isRequired
                            error={errors?.variants?.[index]?.carat_id?.message}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="form-group">
                      <Controller
                        name={`variants.${index}.metal_id`}
                        control={control}
                        // rules={{ required: "Metal is required" }}
                        render={({ field }) => (
                          <MetalSelection
                            label={language.purchaseColors}
                            labelStyle={{
                              fontWeight: "bold",
                              color: "#000",
                            }}
                            index={index}
                            metalOptions={metals}
                            metal={field.value}
                            setMetal={(e) => {
                              field.onChange({
                                target: { name: field.name, value: e },
                              });
                            }}
                            isBorder={false}
                            // isRequired
                            error={errors?.variants?.[index]?.metal_id?.message}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <Controller
                      name={`variants.${index}.size_id`}
                      control={control}
                      // rules={{ required: "Size is required" }}
                      render={({ field }) => (
                        <Dropdown
                          label={language.size}
                          dropdownOptions={sizes?.map((size) => {
                            return {
                              id: size.id,
                              value: size.id,
                              label: size.size,
                            };
                          })}
                          onSelect={(e) => {
                            field.onChange({
                              target: {
                                name: field.name,
                                value: Number(e.target.value),
                              },
                            });
                          }}
                          // isRequired
                          {...field}
                          error={errors?.variants?.[index]?.size_id?.message}
                        />
                      )}
                    />
                  </div>

                  <div>
                    <Controller
                      name={`variants.${index}.sku`}
                      control={control}
                      render={({ field }) => (
                        <Input
                          label={language.sku}
                          type="text"
                          placeholder={language.sku}
                          {...field}
                        />
                      )}
                    />
                  </div>

                  <div>
                    <Controller
                      name={`variants.${index}.barcode_number`}
                      control={control}
                      render={({ field }) => (
                        <Input
                          type="text"
                          label={language.barcode}
                          placeholder={language.barcode}
                          {...field}

                          // onChange={(e) => {
                          //   handleChangeProduct(index, e);
                          //   // const re = /^[0-9]*[.]?[0-9]*$/;
                          //   // if (re.test(e.target.value)) {
                          //   // }
                          // }}
                        />
                      )}
                    />
                  </div>
                  <div>
                    <Controller
                      name={`variants.${index}.price`}
                      control={control}
                      rules={{
                        required:
                          formValues.price_type == "1"
                            ? "Price is required"
                            : false,
                      }}
                      render={({ field }) => (
                        <Input
                          min={1}
                          label={language.price}
                          type="text"
                          placeholder={language.price}
                          {...field}
                          onChange={(e) => {
                            const re = /^[0-9]*[.]?[0-9]*$/;
                            if (re.test(e.target.value)) {
                              field.onChange(e);
                            }
                          }}
                          readOnly={formValues.price_type === "2"}
                          error={errors?.variants?.[index]?.price?.message}
                        />
                      )}
                    />
                  </div>

                  {index ? (
                    <div style={{ position: "absolute", top: 0, left: "98%" }}>
                      <Button
                        variant="danger"
                        className="d-flex justify-content-between align-items-center  p-1"
                        onClick={() => remove(index)}
                      >
                        <AiFillDelete size={10} />
                      </Button>
                    </div>
                  ) : null}
                </div>
              </div>
            );
          })}
        </DivBorderContainer>
      )} */}
      <div className={`mt-3 d-flex justify-content-end`}>
        {isEdit && (
          <>
            <Button
              variant="outline-secondary"
              onClick={() => {
                setDeleteId(formValues?.id);
                setShowAlert(true);
              }}
              className="btn-free me-3"
            >
              {"Delete"}
            </Button>
            <Link
              className="btn btn-outline-primary btn-free me-3"
              to={`/purchase`}
            >
              Add Purchase
            </Link>
          </>
        )}
        <Button
          variant="warning"
          onClick={handleSubmit(submit)}
          className="btn-free w-20"
        >
          {"Done"}
        </Button>
      </div>
      <AlertDialog
        show={showAlert}
        successAction={deleteProduct}
        title={language.deleteProduct}
        cancleAction={() => setShowAlert(false)}
        closeButton={false}
      />
      {/* </form> */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    variants: state.userReducer.variants,
    storeId: state.userReducer.store_id,
  };
};

export default connect(mapStateToProps)(ProductForm);
