import React, { useEffect, useMemo, useRef, useState } from "react";
import { Accordion, Button, Table, useAccordionButton } from "react-bootstrap";
import { connect } from "react-redux";
import LoadingBtn from "../Components/Common/Button";
import DatePicker from "../Components/Common/DatePicker";
import Dropdown from "../Components/Common/Dropdown";
import Loading from "../Components/Common/Loading";
import Stats from "../Components/Common/Stats";
import CreatePurchaseModal from "../Components/Purchase/CreatePurchaseModal";
import { deleteRequest, getRequest } from "../Data/ApiService";
import { ENDPOINTS } from "../Data/Endpoint";
import useStats from "../Hooks/useStats";
import useSuppliers from "../Hooks/useSuppliers";
import useVariants from "../Hooks/useVariants";
import Page from "../Layout/Page";
import language from "../Strings/en";
import { BiEdit, BiPrinter, BiTrash } from "react-icons/bi";
import AlertDialog from "../Components/Common/AlertDialog";
import { errorToast, successToast } from "../Utils/showToast";
import { isEmpty } from "lodash";
import { FaBarcode, FaPrint } from "react-icons/fa";
import GenerateBarcode from "../Components/Purchase/GenerateBarcode";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import BarcodePrint from "../Components/Purchase/BarcodePrint";
import CONFIG from "../Config/BaseURL";

const Purchases = ({ storeId }) => {
  const [createModal, setCreateModal] = useState({
    visibility: false,
    isEdit: false,
  });
  const [page, setPage] = useState(1);
  const [purchases, setPurchases] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMorePages, setHasMorePages] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [editPurchase, setEditPurchase] = useState({});
  const [filters, setFilters] = useState({
    supplier: "",
    from: "",
    to: "",
  });

  const fetchPurchases = async () => {
    setLoading(true);
    const res = await getRequest(ENDPOINTS.getPurchase, {
      ...(!isEmpty(filters.supplier) && { supplier_id: filters.supplier }),
      ...(!isEmpty(filters.from) && { from: filters.from }),
      ...(!isEmpty(filters.to) && { to: filters.to }),
      page,
    });
    //   // storeId,
    //
    // });

    if (res.status === 200) {
      setLoading(false);
      if (page > 1) {
        setPurchases((prev) => [
          ...prev,
          ...res?.data?.data?.purchase?.purchases,
        ]);
      } else {
        setPurchases(res?.data?.data?.purchase?.purchases);
      }

      setHasMorePages(
        res?.data?.data?.purchase?.hasMorePages === 0 ? false : true
      );
    }
  };
  useEffect(() => {
    fetchPurchases();
  }, [page, filters]);

  const loadMore = () => {
    setPage(page + 1);
  };

  const openModalForEdit = (purchase) => {
    setEditPurchase({
      ...purchase,
      id: purchase?.id,
      storeproduct_id: {
        value: purchase?.storeproduct_id,
        label: purchase?.title_en,
      },
      supplier_id: purchase?.supplier_id,
      purchase_date: purchase?.purchase_date,
      purchase_type: String(purchase?.purchase_type),
      totalqty: purchase?.totalqty,
      totalvat: purchase?.totalvat,
      totalweight: purchase?.totalweight,
      costmk: purchase?.costmk,
      "24k_goldrate_pergm": purchase?.goldrate_pergm,
      other_cost: purchase?.other_cost,
      supplier_files: purchase?.supplier_files,
      invoice_no: purchase?.invoice_no,
      barcode: purchase?.barcode_number,
      variants: purchase?.purchase_variants,
      image_name:
        !purchase?.image_name || purchase?.image_name?.includes("placeholder")
          ? null
          : `${CONFIG.IMAGE_ROOT_URL}${purchase?.image_name}`,
    });
    setCreateModal({ visibility: true, isEdit: true });
  };

  const actionOnPurchase = (newPurchase, isEdit) => {
    fetchPurchases();
    // isEdit
    //   ? setPurchases(
    //       purchases?.map((purchase) =>
    //         purchase?.id === newPurchase?.id ? newPurchase : purchase
    //       )
    //     )
    //   : setPurchases((prev) => [newPurchase, ...prev]);
  };

  const { sizes, carats, metals } = useVariants();
  const { suppliers } = useSuppliers();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
    setPage(1);
  };

  const { stats } = useStats("purchase");

  const [showAlert, setShowAlert] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const deletePurchase = async () => {
    let res = await deleteRequest(`${ENDPOINTS.deletePurchase}/${deleteId}`);
    if (res.data?.data?.success) {
      actionOnPurchase();
      successToast(res.data?.data?.message);
      setCreateModal({ visibility: false, isEdit: false });
    } else {
      errorToast(res?.data?.data?.message);
    }
    setShowAlert(false);
  };

  const [generateBarcode, setGenerateBarcode] = useState({
    visibility: false,
    data: {},
  });

  const [purchaseToPrint, setPurchaseToPrint] = useState(null);
  const printRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => setBarcodes([]),
  });

  const [barcodes, setBarcodes] = useState([]);
  const fetchBarcodes = async (id) => {
    const response = await getRequest(`${ENDPOINTS.purchaseBarcode}/${id}`);

    if (response.data?.data?.success) {
      setBarcodes(response.data?.data?.purchase_barcodes);
    }
  };

  const barcodePrint = useMemo(() => {
    if (barcodes?.length > 0) {
      return <BarcodePrint ref={printRef} barcodes={barcodes} />;
    }
  }, [barcodes]);

  useEffect(() => {
    if (barcodes?.length > 0) {
      handlePrint();
    }
  }, [barcodes]);

  const [activeToggle, setActiveToggle] = useState(131);

  return (
    <Page>
      <div className="container-fluid background-grey">
        <div className="row">
          <div className="col-sm-12 mb-3">
            <div className="d-flex">
              <Stats
                title="Total Purchases"
                count={stats?.total_purchases}
                col="flex-fill"
              />
              <Stats
                title="Total Rate Amount"
                count={stats?.total_rate_amount}
                col="flex-fill"
              />
              <Stats
                title="Total Weight"
                count={stats?.total_weight}
                col="flex-fill"
              />
              <Stats title="Total MK" count={stats?.total_mk} col="flex-fill" />
              <Stats
                title="Total QTY"
                count={stats?.total_quantity}
                col="flex-fill"
              />
            </div>
          </div>
          <div className="col-sm-12 mb-4">
            <div className="row align-items-center">
              <div className="col-md-3">
                <h1>Purchase</h1>
              </div>
              <div className="col-md-9 "></div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="background-white">
              <h4 className=" mt-2">{language.dbPurchase}</h4>
              <div className="row align-items-end mb-5">
                <div className="col-md-3">
                  <Dropdown
                    label={language.purchaseSupplier}
                    dropdownOptions={suppliers}
                    onSelect={handleChange}
                    name="supplier"
                    value={filters.supplier}
                  />
                </div>
                <div className="col-md-3">
                  {/* {moment(filters.from).format("YYYY-MM-DD")} */}
                  <DatePicker
                    label="From"
                    name="from"
                    value={filters.from}
                    onChange={handleChange}
                    size={"lg"}
                  />
                </div>
                <div className="col-md-3">
                  <DatePicker
                    label="To"
                    name="to"
                    value={filters.to}
                    onChange={handleChange}
                    size={"lg"}
                  />
                </div>
                <div className="col-md-3 justify-content-end d-flex">
                  <Button
                    variant="secondary"
                    onClick={() =>
                      setCreateModal({ visibility: true, isEdit: false })
                    }
                  >
                    {language.createPurchase}
                  </Button>
                </div>
              </div>
              {/* <tr > */}
              <Table
                className="table table--orders"
                striped
                bordered
                // hover
                responsive={true}
              >
                <thead>
                  <tr>
                    <td>{language.purchaseId}</td>
                    <td>{language.name}</td>
                    <td>{language.purchaseWeight}</td>
                    <td>{language.purchaseInvoiceNo}</td>

                    <td>{language.type}</td>
                    <td>{language.purchaseCostmk}</td>
                    <td>{language.purchaseSupplier}</td>
                    <td>{language.date}</td>
                    <td>{language.purchaseQuantity}</td>
                    <td>{language.createdAt}</td>
                    <td>{language.createdBy}</td>
                    <td>{language.action}</td>
                  </tr>
                </thead>
                <tbody>
                  {purchases?.length > 0 ? (
                    purchases.map((o) => (
                      <tr
                        key={o?.id}
                        id={o?.id}

                        //   openModalForEdit(o);
                        // }}
                        // className={
                        //   editPurchase?.id === o?.id ? "active-row" : ""
                        // }
                      >
                        <td>{o?.id}</td>
                        <td>{o?.title_en}</td>
                        <td>{o?.total_weight}</td>
                        <td>{o?.invoice_no || "-"}</td>

                        <td>{o?.purchase_type == "1" ? "Cash" : "Credit"}</td>
                        <td>{o?.costmk}</td>
                        <td>{o?.supplier_name || "-"}</td>
                        <td>{o?.purchase_date ? o?.purchase_date : "--"}</td>
                        <td>{o?.quantity ? o?.quantity : "--"}</td>
                        <td>{o?.created}</td>
                        <td>{o?.created_by || "-"}</td>
                        <td>
                          <div className="d-flex flex-row justify-content-center align-items-center">
                            <Button
                              size="sm"
                              variant="default"
                              className="d-flex align-items-center justify-content-center circle action-btn-table btn-sm"
                              onClick={() => {
                                openModalForEdit(o);
                              }}
                            >
                              <BiEdit />
                            </Button>
                            <Button
                              size="sm"
                              variant="danger"
                              className="d-flex align-items-center justify-content-center circle action-btn-table"
                              onClick={() => {
                                setDeleteId(o?.id);
                                setShowAlert(true);
                              }}
                            >
                              <BiTrash />
                            </Button>
                            <Button
                              size="sm"
                              variant="default"
                              className="d-flex align-items-center justify-content-center circle action-btn-table btn-sm"
                              onClick={() => {
                                setGenerateBarcode({
                                  visibility: true,
                                  data: o,
                                });
                              }}
                            >
                              <FaBarcode />
                            </Button>

                            <Button
                              size="sm"
                              variant="default"
                              className="d-flex align-items-center justify-content-center circle action-btn-table btn-sm"
                              onClick={() => {
                                fetchBarcodes(o?.id);
                              }}
                            >
                              <BiPrinter />
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      {loading ? (
                        <td colSpan={13}>
                          <Loading />
                        </td>
                      ) : (
                        <td colSpan={13}>No records found</td>
                      )}
                    </tr>
                  )}
                </tbody>
              </Table>
              {hasMorePages && (
                <div style={{ textAlign: "center" }}>
                  <LoadingBtn onClick={loadMore}>Load More</LoadingBtn>
                </div>
              )}
              {purchaseToPrint?.id}
              {loading && page > 1 && <Loading />}
            </div>
          </div>
          {/* <div className="col-sm-6">
            <div className="background-white sticky-div">
              <div className="d-flex justify-content-between align-items-center mt-2 mb-5">
                <h4> {createModal.isEdit ? "View" : "Add"}</h4>
                <Button
                  variant="secondary btn-lowerCase"
                  onClick={() => {
                    setCreateModal({ isEdit: false });
                    setEditPurchase({});
                  }}
                >
                  {language.create}
                </Button>
              </div>
            
            </div>
          </div> */}
          <CreatePurchaseModal
            purchaseData={editPurchase}
            isCreateModalOpen={createModal.visibility}
            isEdit={createModal.isEdit}
            hideCreateModal={() =>
              setCreateModal({ visibility: false, isEdit: false })
            }
            actionOnPurchase={actionOnPurchase}
            setEditPurchase={setEditPurchase}
            isModal={true}
          />
          <AlertDialog
            show={showAlert}
            successAction={deletePurchase}
            title={language.deletePurchase}
            cancleAction={() => setShowAlert(false)}
            closeButton={false}
          />
          {generateBarcode.visibility && (
            <GenerateBarcode
              onHide={() => setGenerateBarcode({ visibility: false, data: {} })}
              open={generateBarcode}
              purchase={generateBarcode.data}
            />
          )}

          {barcodePrint}
        </div>
      </div>

      {/* 
      {createModal.visibility && (
       
      )} */}
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    storeId: state.userReducer.store_id,
  };
};

const AccorgianToggle = ({ id, children, ...props }) => {
  const decoratedOnClick = useAccordionButton(id, () =>
    console.log("totally custom!")
  );
  return (
    <tr onClick={decoratedOnClick} {...props}>
      {children}
    </tr>
  );
};

export default connect(mapStateToProps)(Purchases);
