import { CART_ACTIONS } from "../../reducers/cartReducer";

export const setOrderStatus = (orderStatus) => {
  return (dispatch) => {
    dispatch({
      type: CART_ACTIONS.SET_ORDER_STATUS,
      payload: orderStatus,
    });
  };
};
