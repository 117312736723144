import React, { useEffect } from "react";
import { useState } from "react";
import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownButton,
  Table,
} from "react-bootstrap";
import BackButton from "../Components/Common/BackButton";
import DatePicker from "../Components/Common/DatePicker";
import Input from "../Components/Common/Input";
import LoadingBtn from "../Components/Common/Button";
import Loading from "../Components/Common/Loading";
import Page from "../Layout/Page";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../Data/ApiService";
import { ENDPOINTS } from "../Data/Endpoint";
import { isEmpty } from "../Utils/empty";
import Stats from "../Components/Common/Stats";
import language from "../Strings/en";
import { default as DropdownSelect } from "../Components/Common/Dropdown";
import useSuppliers from "../Hooks/useSuppliers";
import useStats from "../Hooks/useStats";
import CreateSupplier from "../Components/Supplier/CreateSupplier";
import { BiEdit, BiMoney, BiPrinter, BiTrash } from "react-icons/bi";
import AlertDialog from "../Components/Common/AlertDialog";
import { displayThreeDecimal } from "../Utils/displayThreeDecimal";
import SupplierForm from "../Components/Supplier/SupplierForm";
import { useNavigate } from "react-router-dom";
import { GiWeight } from "react-icons/gi";
import TooltipButton from "../Components/Common/TooltipButton";

function Supplier() {
  const initialValue = {
    supplier_name: "",
    cr_number: "",
    address_line1: "",
    address_line2: "",
    city: "",
    country: "",
    zipcode: "",
    type_of_supplier: "",
    credit_period: "",
    credit_limit: "",
    contact: "",
    email: "",
  };
  const [supplierDetails, setSupplierDetails] = useState(initialValue);

  const [showAlert, setShowAlert] = useState(false);
  const [openSupplierModal, setOpenSupplierModal] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState(new Date());
  const [hasMorePages, setHasMorePages] = useState(false);
  const [filter, setFilter] = useState({
    supplier: "",
    from: "",
    to: "",
  });

  const fetchSuppliers = async () => {
    setLoading(true);
    const res = await getRequest(ENDPOINTS.suppliers, {
      page: page,
      ...(!isEmpty(filter.supplier) && { supplier_id: filter.supplier }),
      ...(!isEmpty(filter.from) && { from: filter.from }),
      ...(!isEmpty(filter.to) && { to: filter.to }),
    });
    if (res.status === 200) {
      setLoading(false);
      if (page > 1) {
        setSuppliers((prev) => [...prev, ...res?.data?.data?.suppliers]);
      } else {
        setSuppliers(res?.data?.data?.suppliers);
      }
    }
  };
  useEffect(() => {
    fetchSuppliers();
  }, [page, filter]);

  const loadMore = () => {
    setPage(page + 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilter((prev) => ({ ...prev, [name]: value }));
    setPage(1);
  };
  const { suppliers: supplierOption } = useSuppliers();
  const { stats } = useStats("supplier");

  const actionResponse = () => {
    setPage(1);
    fetchSuppliers();
  };

  const saveSupplierDetails = async () => {
    if (supplierDetails?.id) {
      await putRequest(
        `${ENDPOINTS.updateSupplier}/${supplierDetails?.id}`,
        supplierDetails
      ).then((res) => {
        if (res.status === 200) {
          const updatedSupplier = res.data?.data?.supplier;
          const filteredSupplier = suppliers.map((supplier) =>
            supplier.id === updatedSupplier?.id
              ? {
                  ...updatedSupplier,
                  name: updatedSupplier.supplier_name,
                  phone: updatedSupplier.contact,
                }
              : supplier
          );
          setSuppliers(filteredSupplier);
          setOpenSupplierModal(false);
        }
      });
    } else {
      await postRequest(ENDPOINTS.createSupplier, supplierDetails).then(
        (res) => {
          if (res.status === 200) {
            const supplier = res.data?.data?.supplier;
            setSuppliers((prev) => [
              {
                ...supplier,
                name: supplier.supplier_name,
                phone: supplier.contact,
              },
              ...prev,
            ]);
            setOpenSupplierModal(false);
          }
        }
      );
    }
    // setCustomerId(res.data.data.customer.id);
    // setIsEdit(true);
  };

  const handleSupplierDetails = (e) => {
    const { name, value } = e.target;
    setSupplierDetails((prev) => ({ ...prev, [name]: value }));
  };

  const [deleteId, setDeleteId] = useState(null);
  const deleteSupplier = async () => {
    let res = await deleteRequest(`${ENDPOINTS.deleteSupplier}/${deleteId}`);
    if (res.status === 200) {
      setSuppliers(suppliers.filter((supplier) => supplier.id !== deleteId));
    }
    setShowAlert(false);
  };

  const navigate = useNavigate();
  const showDetailPage = ({ supplier, activeTab = undefined }) => {
    navigate(`/suppliers/${supplier?.id}`, {
      state: {
        supplier: {
          ...supplier,
          supplier_name: supplier.name,
          contact: supplier.phone,
        },
        activeTab,
      },
    });
  };

  return (
    <Page>
      <div className="container mt-2">
        <div className="row">
          <div className="col-sm-12 d-flex mb-3">
            <Stats
              title="Total Suppliers"
              count={stats?.total_suppliers}
              col="flex-fill"
            />
            <Stats
              title="Total Weight Purchased"
              count={stats?.total_weight_purchased}
              col="flex-fill"
            />
            <Stats title="Total MK" count={stats?.total_mk} col="flex-fill" />
            <Stats
              title="Total Weight Balance"
              count={stats?.total_weight_balance}
              col="flex-fill"
            />
            <Stats
              title="Total MK Balance"
              count={stats?.total_mk_balance}
              col="flex-fill"
            />
          </div>
          <div className="col-sm-12">
            <div className="row align-items-center mb-4 ">
              <div className="col-md-3">
                <h1>Suppliers</h1>
              </div>
              <div className="col-md-3 ">
                <DropdownSelect
                  label={language.purchaseSupplier}
                  dropdownOptions={supplierOption}
                  onSelect={handleChange}
                  name="supplier"
                  value={filter.supplier}
                />
              </div>
              <div className="col-md-2">
                <DatePicker
                  label="From"
                  name="from"
                  value={filter.from}
                  onChange={handleChange}
                  size={"xl"}
                />
              </div>
              <div className="col-md-2">
                <DatePicker
                  label="To"
                  name="to"
                  value={filter.to}
                  onChange={handleChange}
                  size={"xl"}
                />
              </div>
              <div className="col-md-2">
                <Button
                  variant="secondary mt-4"
                  onClick={() => {
                    setOpenSupplierModal(true);
                    setSupplierDetails(initialValue);
                  }}
                >
                  {language.createSupplier}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className={`col-sm-12`}>
            <div className="background-white">
              <Table
                className="table table--orders"
                striped
                bordered
                hover
                responsive
              >
                <thead>
                  <tr>
                    <td>{language.name}</td>
                    <td>{language.phone}</td>
                    <td>{language.creditPeriod}</td>
                    <td>{language.creditAmount}</td>
                    <td>{language.totalPurchases}</td>
                    <td>{language.totalWeight}</td>
                    <td>{language.totalMK}</td>
                    <td>{language.balanceWeight}</td>
                    <td>{language.balanceMk}</td>
                    <td>{language.createdAt}</td>
                    <td>{language.createdBy}</td>
                    <td>{language.action}</td>
                  </tr>
                </thead>
                <tbody>
                  {suppliers.length > 0 ? (
                    suppliers.map((supplier, index) => (
                      <tr key={index}>
                        <td
                          className="hyperlink"
                          onClick={() => showDetailPage({ supplier })}
                        >
                          {supplier?.name || "-"}
                        </td>
                        <td>{supplier?.phone || "-"}</td>
                        <td>
                          {supplier?.credit_period
                            ? supplier?.credit_period
                            : "-"}
                        </td>
                        <td>
                          {supplier?.credit_limit
                            ? supplier?.credit_limit
                            : "-"}
                        </td>
                        <td>
                          {supplier?.total_purchase
                            ? supplier?.total_purchase
                            : "-"}
                        </td>
                        <td>
                          {displayThreeDecimal(supplier?.total_weight) || "-"}
                        </td>
                        <td>{supplier?.total_mk ? supplier?.total_mk : "-"}</td>
                        <td>
                          {displayThreeDecimal(supplier?.balance_weight) || "-"}
                        </td>
                        <td>
                          {supplier?.balance_mk ? supplier?.balance_mk : "-"}
                        </td>
                        <td>{supplier?.created || "-"}</td>
                        <td>{supplier?.created_by || "-"}</td>
                        <td width={"20%"}>
                          <div className="d-flex flex-row justify-content-center align-items-center flex-wrap ">
                            <TooltipButton
                              size="sm"
                              variant="secondary"
                              className="d-flex align-items-center justify-content-center circle action-btn-table "
                              title={"Edit"}
                              onClick={() => {
                                setSupplierDetails({
                                  ...supplier,
                                  supplier_name: supplier.name,
                                  contact: supplier.phone,
                                });
                                setOpenSupplierModal(true);
                              }}
                            >
                              <BiEdit />
                            </TooltipButton>
                            <TooltipButton
                              size="sm"
                              variant="danger"
                              className="d-flex align-items-center justify-content-center circle action-btn-table ms-1"
                              title={"Delete"}
                              onClick={() => {
                                setDeleteId(supplier?.id);
                                setShowAlert(true);
                              }}
                            >
                              <BiTrash />
                            </TooltipButton>
                            <TooltipButton
                              size="sm"
                              variant="secondary"
                              className="d-flex align-items-center justify-content-center circle action-btn-table ms-1"
                              onClick={() => {}}
                              title={"print supplier statement"}
                            >
                              <BiPrinter />
                            </TooltipButton>
                            <TooltipButton
                              size="sm"
                              variant="secondary"
                              className="d-flex align-items-center justify-content-center circle action-btn-table ms-1"
                              onClick={() =>
                                showDetailPage({
                                  supplier,
                                  activeTab: "payoutmk",
                                })
                              }
                              title="payout supplier MK"
                            >
                              <BiMoney />
                            </TooltipButton>

                            <TooltipButton
                              size="sm"
                              variant="secondary"
                              className="d-flex align-items-center justify-content-center circle action-btn-table ms-1"
                              onClick={() =>
                                showDetailPage({
                                  supplier,
                                  activeTab: "payout",
                                })
                              }
                              title="payout supplier weight"
                            >
                              <GiWeight />
                            </TooltipButton>
                          </div>
                        </td>
                        {/* <td>
                          <DropdownButton
                            as={ButtonGroup}
                            key={"primary"}
                            variant={"primary"}
                            title="Action"
                          >
                            <Dropdown.Item
                              onClick={() => {
                                setSupplierDetails({
                                  ...supplier,
                                  supplier_name: supplier.name,
                                  contact: supplier.phone,
                                });
                                setOpenSupplierModal(true);
                              }}
                            >
                              Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                setDeleteId(supplier?.id);
                                setShowAlert(true);
                              }}
                            >
                              Delete
                            </Dropdown.Item>
                            <Dropdown.Item>
                              Print supplier statement
                            </Dropdown.Item>
                            <Dropdown.Item>Payout supplier MK</Dropdown.Item>
                            <Dropdown.Item>
                              Payout supplier weight
                            </Dropdown.Item>
                          </DropdownButton>
                        </td> */}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      {loading ? (
                        <td colSpan={13}>
                          <Loading />
                        </td>
                      ) : (
                        <td colSpan={13}>No records found</td>
                      )}
                    </tr>
                  )}
                </tbody>
              </Table>
              {hasMorePages && (
                <div style={{ textAlign: "center" }}>
                  <LoadingBtn onClick={loadMore}>Load More</LoadingBtn>
                </div>
              )}
              {loading && page > 1 && <Loading />}
            </div>
          </div>
          {/* {supplierDetails.id && (
            <div className="col-sm-6">
              <div className="background-white sticky-div">
                <CreateSupplier
                  openSupplierModal={openSupplierModal}
                  supplierDetails={supplierDetails}
                  setOpenSupplierModal={setOpenSupplierModal}
                  saveSupplierDetails={saveSupplierDetails}
                  handleSupplierDetails={handleSupplierDetails}
                  actionResponse={actionResponse}
                />
              </div>
            </div>
          )} */}
        </div>
      </div>
      <CreateSupplier
        openSupplierModal={openSupplierModal}
        setSupplierDetails={setSupplierDetails}
        supplierDetails={supplierDetails}
        setOpenSupplierModal={setOpenSupplierModal}
        saveSupplierDetails={saveSupplierDetails}
        handleSupplierDetails={handleSupplierDetails}
        actionResponse={actionResponse}
        isModal={true}
      />
      <AlertDialog
        show={showAlert}
        successAction={deleteSupplier}
        title={language.deleteAlertSupplier}
        cancleAction={() => setShowAlert(false)}
        closeButton={false}
      />
    </Page>
  );
}

export default Supplier;
