import React from "react";

const Switch = React.forwardRef(
  ({ title, isRequired, checked, value, onChange, name, ...props }, ref) => {
    return (
      <div className="d-flex flex-column">
        <span className="border-container-with-title-span mb-1">
          {title} {isRequired && <span className="text-danger ">*</span>}
        </span>
        <label className="switch">
          <input
            type="checkbox"
            onChange={onChange}
            checked={checked}
            value={value}
            name={name}
            {...props}
          />
          <span className="slider round"></span>
        </label>
      </div>
    );
  }
);

export default Switch;
