import { SETTLEMENT_ACTIONS } from '../../reducers/settlementReducer';

export const removeUsedSettlement = (id) => {
  return (dispatch) => {
    dispatch({
      type: SETTLEMENT_ACTIONS.REMOVE_USED_SETTLEMENT_MODE,
      payload: {
        id,
      },
    });
  };
};
