import React from "react";
import ProductWiseSection from "../Analytics/ProductWiseSection";
import CategoryWiseSection from "../Analytics/CategoryWiseSection";
import DynamicSection from "../Analytics/DynamicSection";

const productWiseSale = [
  {
    product_name: "Variant 1",
    total_qty: 10,
    total_sales: 10,
  },
  {
    product_name: "Variant 2",
    total_qty: 10,
    total_sales: 10,
  },
  {
    product_name: "Variant 3",
    total_qty: 10,
    total_sales: 10,
  },
  {
    product_name: "Variant 4",
    total_qty: 10,
    total_sales: 10,
  },
];

const categoryWise = [
  {
    group_name: "Earrings",
    total_sales: "100.00",
    total_qty: 10,
  },
  {
    group_name: "Rings",
    total_sales: "100.00",
    total_qty: 10,
  },
  {
    group_name: "Necklaces",
    total_sales: "100.00",
    total_qty: 10,
  },
  {
    group_name: "Bracelet",
    total_sales: "100.00",
    total_qty: 10,
  },
  {
    group_name: "Chains",
    total_sales: "100.00",
    total_qty: 10,
  },
];
const paymentWise = [
  {
    payment_name: "CASH",
    total_sales: "100.00",
  },
  {
    payment_name: "Credit",
    total_sales: "100.00",
  },
  {
    payment_name: "Debit",
    total_sales: "100.00",
  },
  {
    payment_name: "VISA",
    total_sales: "100.00",
  },
  {
    payment_name: "Master",
    total_sales: "100.00",
  },
];
const personWise = [
  {
    name: "Person 1",
    total_sales: "100.00",
    total_order: "10",
  },
  {
    name: "Person 2",
    total_sales: "200.00",
    total_order: "20",
  },
  {
    name: "Person 3",
    total_sales: "300.00",
    total_order: "30",
  },
];

const DailySaleView = React.forwardRef(
  ({ reportData, start_date, end_date }, ref) => {
    return (
      <div className="row daily-report" ref={ref}>
        <h3 className="mb-4 report-title mt-4">
          Daily Sale between {start_date} to {end_date}
        </h3>
        <div className="col-md-6">
          <ProductWiseSection
            data={reportData.item_sales?.summary}
            totalData={reportData?.item_sales}
          />
        </div>
        <div className="col-md-6">
          <CategoryWiseSection
            data={reportData.group_sales?.summary}
            totalData={reportData.group_sales}
          />
        </div>
        <div className="col-md-6">
          <DynamicSection
            title={"Sales By Payment Type"}
            data={reportData.paymenttype_sales?.summary}
            columnLabel={["Payment Type", "Total Sale"]}
            columns={["payment_type", "total_sales"]}
            totalData={[
              {
                label: "Gross Total",
                value: reportData?.paymenttype_sales?.gross_total,
              },
            ]}
          />
        </div>
        <div className="col-md-6">
          <DynamicSection
            title={"Sales by Person"}
            data={reportData?.user_sales?.summary}
            columnLabel={["Name", "No. of orders.", "Total Sales"]}
            columns={["user_name", "total_orders", "total_sales"]}
            totalData={[
              {
                label: "Gross Sale",
                value: reportData?.user_sales?.gross_sale,
              },
              {
                label: "Tax",
                value: reportData?.user_sales?.total_tax,
              },
              {
                label: "Net Sale",
                value: reportData?.user_sales?.net_sale,
              },
            ]}
          />
        </div>
      </div>
    );
  }
);

export default DailySaleView;
