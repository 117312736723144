import { CART_ACTIONS } from "../../reducers/cartReducer";

export const setIsReopenOrder = (status) => {
  return (dispatch) => {
    dispatch({
      type: CART_ACTIONS.SET_IS_REOPEN_ORDER,
      payload: status,
    });
  };
};
