import React, { useEffect, useMemo, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { getRequest, postRequest, putRequest } from "../../Data/ApiService";
import { ENDPOINTS } from "../../Data/Endpoint";
import { successToast } from "../../Utils/showToast";
import { BARS_TYPE } from "../../Config";
import Loading from "../Common/Loading";

const Payout = ({ detail }) => {
  const [loading, setLoading] = useState({ purchase: false, bars: false });
  const [purchaseData, setPurchaseData] = useState([]);
  const [barsData, setBarsData] = useState([]);
  const [totalWeightBalance, setTotalWeightBalance] = useState(0);
  const [selectedData, setSelectedData] = useState({ payouts: [], bars: [] });
  const fetchPayout = async () => {
    setLoading((prev) => ({ ...prev, purchase: true }));
    await getRequest(`${ENDPOINTS.supplierPayout}/${detail?.id}`).then(
      (res) => {
        setLoading((prev) => ({ ...prev, purchase: false }));
        if (res.status === 200) {
          setPurchaseData(res.data?.data?.payout);
          setTotalWeightBalance(res.data?.data?.total_weight_balance);
        }
      }
    );
  };

  const fetchBars = async () => {
    setLoading((prev) => ({ ...prev, bars: true }));

    await getRequest(`${ENDPOINTS.getGoldTransaction}`).then((res) => {
      setLoading((prev) => ({ ...prev, purchase: false }));

      if (res.status === 200) {
        setBarsData(res.data?.data?.goldbartransaction);
      }
    });
  };

  useEffect(() => {
    fetchPayout();
    fetchBars();
  }, []);

  const onSelect = (e, item, key) => {
    const { checked } = e.target;

    if (
      selectedData[key]?.findIndex((val) =>
        key === "payouts" ? val?.payout_id == item?.id : val === item?.id
      ) > -1
    ) {
      setSelectedData((prev) => ({
        ...prev,
        [key]: prev?.[key]?.filter((val) =>
          key === "payouts" ? val?.payout_id !== item?.id : val !== item?.id
        ),
      }));
    } else {
      setSelectedData((prev) => ({
        ...prev,
        [key]: [
          ...prev[key],
          key === "payouts" ? { payout_id: item?.id } : item?.id,
        ],
      }));
    }
  };

  const submitPayout = async () => {
    let data = { ...selectedData };
    if (data.bars?.length === 0) {
      delete data.bars;
    }

    if (data.payouts?.length === 0) {
      delete data.payouts;
    }
    await putRequest(`${ENDPOINTS.supplierPayout}`, selectedData).then(
      (res) => {
        successToast(res.data?.data?.msg);
        fetchPayout();
        fetchBars();
      }
    );
  };

  const newBalance = useMemo(() => {
    return selectedData.bars
      ?.map((item) => barsData?.find((val) => val?.id === item)?.weight)
      .reduce(function (a, b) {
        return Number(a) + Number(b);
      }, 0);
  }, [selectedData.bars]);

  const isLoading = Object.values(loading).every((item) => item);

  return (
    <div className="row">
      {isLoading ? (
        <div className="col-md-12 mt-4">
          <Loading />
        </div>
      ) : (
        <div className="col-md-12">
          <h5>
            <strong>Total weight balance:</strong> {totalWeightBalance}
          </h5>

          <div className="mt-5">
            <h5>Purchase Balance</h5>
            <Table className="table table--orders" bordered>
              <thead>
                <tr>
                  <td>ID</td>
                  <td>Invoice No.</td>
                  <td>Total Weight</td>
                  <td>Balance</td>
                  <td>Type</td>
                  <td>Due Date</td>
                  <td>Select</td>
                </tr>
              </thead>
              <tbody>
                {purchaseData?.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>{item.invoice_no}</td>
                      <td>{item.total_weight}</td>
                      <td>{item.balance}</td>
                      <td>{item.purchase_type}</td>
                      <td>{item.due_date || "-"}</td>
                      <td>
                        <Form.Check // prettier-ignore
                          type={"checkbox"}
                          onChange={(e) => onSelect(e, item, "payouts")}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          <div className="mt-5">
            <h5>Available Bars/Scrape</h5>
            <Table className="table table--orders" bordered>
              <thead>
                <tr>
                  <td>ID</td>
                  <td>Inv</td>
                  <td>Weight</td>
                  <td>Balance</td>
                  <td>Type</td>
                  <td>Select</td>
                </tr>
              </thead>
              <tbody>
                {barsData?.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>{item.id || "-"}</td>
                      <td>{item.reciept_no || "-"}</td>
                      <td>{item.weight || "-"}</td>
                      <td>{item.balance || "-"}</td>
                      <td>{BARS_TYPE[item.type] || "-"}</td>
                      <td>
                        <Form.Check // prettier-ignore
                          type={"checkbox"}
                          onChange={(e) => onSelect(e, item, "bars")}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          <h5 className="mt-4">
            <strong>New balance:</strong>{" "}
            {Number(totalWeightBalance) - Number(newBalance)}
          </h5>
          <div className="d-flex justify-content-end">
            <Button variant="secondary" className="me-3">
              Cancel
            </Button>
            <Button
              variant="warning"
              // disabled={
              //   selectedData.bars?.length === 0 ||
              //   selectedData.payouts?.length === 0 ||
              //   totalWeightBalance - newBalance < 0
              // }
              onClick={submitPayout}
            >
              Done
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Payout;
