import React from "react";
import { Nav, Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setSelectedCategory } from "../../Store/actions/category/setSelectedCategory";

const InlineCategoryList = ({
  categories,
  selectedCategoryId,
  setSelectedCategory,
}) => {
  return (
    <Tabs
      activeKey={selectedCategoryId}
      onSelect={(k) => {
        console.log(k);
        setSelectedCategory(k);
      }}
      variant="pills"
    >
      {categories.map((tabItems) => {
        return (
          <Tab
            key={tabItems.value}
            eventKey={tabItems.value}
            title={tabItems.label}
            tabClassName="custom-tab"
          />
        );
      })}
    </Tabs>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedCategoryId: state.categoryReducer.selectedCategoryId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedCategory: bindActionCreators(setSelectedCategory, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InlineCategoryList);
