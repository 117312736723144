import { USER_ACTIONS } from '../../reducers/userReducer';

export const loginUser = (user) => {
  return (dispatch) => {
    dispatch({
      type: USER_ACTIONS.LOGIN_USER,
      payload: user,
    });
  };
};
