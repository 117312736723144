import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";

import ProtectedRoute from "./Components/Common/ProtectedRoute";
import PublicRoute from "./Components/Common/PublicRoute";
import Login from "./Pages/Login";
import Dashboard from "./Pages/Dashboard";
import AttributeSelection from "./Pages/AttributeSelection";
import Customers from "./Pages/Customers";
import OrderHistory from "./Pages/OrderHistory";
import Profile from "./Pages/Profile";
import Analytics from "./Pages/Analytics";
import SalesReports from "./Pages/SalesReports";
import Invoice from "./Pages/Invoice";

import configureStore from "./Store/store";

import "./Data/ApiService";
import { getLocalStore } from "./Utils/getLocalStore";
import Purchases from "./Pages/Purchases";
import { ToastContainer } from "react-toastify";
import Inventory from "./Pages/Inventory";
import Supplier from "./Pages/Supplier";
import Stock from "./Pages/Stock";
import StockReport from "./Pages/StockReport";
import LinkItems from "./Pages/LinkItems";
import StockTake from "./Pages/StockTake";
import Products from "./Pages/Products";
import Reports from "./Pages/Reports";
import SupplierDetail from "./Pages/SupplierDetail";
import CustomerDetail from "./Pages/CustomerDetail";
import Bars from "./Pages/Bars";
import BarsDetail from "./Pages/BarsDetail";
import BarcodePrint from "./Components/Purchase/BarcodePrint";

const store = configureStore(getLocalStore());

function App() {
  return (
    <Provider store={store}>
      <ToastContainer
        autoClose={5000}
        hideProgressBar
        newestOnTop
        position="bottom-left"
      />

      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/inventory"
            element={
              <ProtectedRoute>
                <Inventory />
              </ProtectedRoute>
            }
          />
          <Route
            path="/stock"
            element={
              <ProtectedRoute>
                <Stock />
              </ProtectedRoute>
            }
          />
          <Route
            path="/stockreport"
            element={
              <ProtectedRoute>
                <StockReport />
              </ProtectedRoute>
            }
          />
          <Route
            path="/sale"
            element={
              <ProtectedRoute>
                <AttributeSelection />
              </ProtectedRoute>
            }
          />
          <Route
            path="/customers"
            element={
              <ProtectedRoute>
                <Customers />
              </ProtectedRoute>
            }
          />
          <Route
            path={`/customers/:id`}
            element={
              <ProtectedRoute>
                <CustomerDetail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/order-history"
            element={
              <ProtectedRoute>
                <OrderHistory />
              </ProtectedRoute>
            }
          />
          <Route
            path="/purchase"
            element={
              <ProtectedRoute>
                <Purchases />
              </ProtectedRoute>
            }
          >
            <Route path=":id" />
          </Route>
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/sales-reports"
            element={
              <ProtectedRoute>
                <SalesReports />
              </ProtectedRoute>
            }
          />
          <Route
            path="/invoice"
            element={
              <ProtectedRoute>
                <Invoice />
              </ProtectedRoute>
            }
          />
          <Route
            path="/suppliers"
            element={
              <ProtectedRoute>
                <Supplier />
              </ProtectedRoute>
            }
          />
          <Route
            path="/suppliers/:id"
            element={
              <ProtectedRoute>
                <SupplierDetail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/analytics"
            element={
              <ProtectedRoute>
                <Analytics />
              </ProtectedRoute>
            }
          />
          <Route
            path="/linkitems"
            element={
              <ProtectedRoute>
                <LinkItems />
              </ProtectedRoute>
            }
          />
          <Route
            path="/stocktake"
            element={
              <ProtectedRoute>
                <StockTake />
              </ProtectedRoute>
            }
          />
          <Route
            path="/product"
            element={
              <ProtectedRoute>
                <Products />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dailysales"
            element={
              <ProtectedRoute>
                <Reports />
              </ProtectedRoute>
            }
          />
          <Route
            path="/bars"
            element={
              <ProtectedRoute>
                <Bars />
              </ProtectedRoute>
            }
          />
          <Route
            path="/bars/:id"
            element={
              <ProtectedRoute>
                <BarsDetail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/printbarcode"
            element={
              <ProtectedRoute>
                <BarcodePrint />
              </ProtectedRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
