const CONFIG = {
  dev: {
    API_URL: "https://simpelidev.thatsmytask.com/jawahersouq-backend",
    IMAGE_ROOT_URL:
      "https://jawahersouq-dev.s3.ap-south-1.amazonaws.com/staging-pictures/",
  },
  prod: {
    API_URL: "https://jw-prod.thatsmytask.com/jawahersouq-backend",
    IMAGE_ROOT_URL:
      "https://jawahersouq-dev.s3.ap-south-1.amazonaws.com/staging-pictures/",
  },
};

export const env = "dev"; // dev or prod

export default CONFIG[env];
