import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Nav, Row, Tab } from "react-bootstrap";
import { connect } from "react-redux";
import language from "../../Strings/en";
import { default as CustomeModal } from "../Common/Modal";
import PurchaseForm from "./PurchaseForm";
import UploadDocuments from "./UploadDocuments";
import { FormProvider, useForm } from "react-hook-form";
import { deleteRequest, postRequest, putRequest } from "../../Data/ApiService";
import { ENDPOINTS } from "../../Data/Endpoint";
import { errorToast, successToast } from "../../Utils/showToast";
import { isEmpty } from "../../Utils/empty";
import AlertDialog from "../Common/AlertDialog";

const CreatePurchaseModal = (props) => {
  const {
    isCreateModalOpen,
    hideCreateModal,
    purchaseData,
    isEdit,
    actionOnPurchase,
    storeId,
    isModal = false,
    setEditPurchase,
    editPurchase,
  } = props;
  const purchaseRef = useRef(null);

  const initialState = {
    supplier_id: "",
    purchase_date: "",
    purchase_type: "1",
    "24k_goldrate_pergm": "",
    storeproduct_id: "",
    totalqty: "",
    totalweight: "",
    totalvat: "",
    costmk: "",
    other_cost: "",
    invoice_no: "",
    price_type: "1",
    producttype: "1",
    carat_24_weight: 0,
    gold_cost: 0,
    variants: [],
  };
  const methods = useForm({ defaultValues: initialState });
  const { handleSubmit, reset, setValue, watch } = methods;
  const [loading, setLoading] = useState(false);

  const values = watch();

  const resetFields = () => {
    reset();
    isModal && hideCreateModal();
  };

  const [showAlert, setShowAlert] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const deletePurchase = async () => {
    let res = await deleteRequest(`${ENDPOINTS.deletePurchase}/${deleteId}`);
    if (res.data?.data?.success) {
      successToast(res.data?.data?.message);
      actionOnPurchase();
      resetFields();
      hideCreateModal();
    } else {
      errorToast(res?.data?.data?.message);
    }
    setShowAlert(false);
  };

  const numberField = ["making_charge", "price"];
  const submit = async (e) => {
    setLoading(true);
    let data = { ...e };

    data.storeproduct_id =
      data.storeproduct_id?.id || data?.storeproduct_id?.value;

    data.supplier_id = Number(data.supplier_id);

    numberField.forEach((item) => {
      data[item] = Number(data[item]);
    });

    Object.keys(data).forEach((item) => {
      if (isEmpty(data[item]) || data[item] === 0) {
        delete data[item];
      }
    });

    // data.variants = data.variants?.map((item) => {
    //   delete item.carat;
    //   delete item.size;
    //   delete item.metal;
    //   return item;
    // });

    console.log(data);

    if (data?.id) {
      await putRequest(`${ENDPOINTS.updatePurchaseItem}/${data?.id}`, data)
        .then((response) => {
          if (response.data?.data?.success) {
            successToast(response?.data?.data?.msg);
            actionOnPurchase();
            resetFields();
          } else {
            errorToast(response?.data?.data?.msg);
          }
          setLoading(false);
        })
        .catch((err) => {
          errorToast(err.response.data.message);
          setLoading(false);
        });
    } else {
      await postRequest(ENDPOINTS.createPurchaseItem, data)
        .then((response) => {
          if (response.data?.data?.success) {
            successToast(response?.data?.data?.msg);
            actionOnPurchase();
            setValue("id", response?.data?.data?.purchase?.id);
            resetFields();
            // resetFields();
          } else {
            successToast(response?.data?.data?.msg);
          }
          setLoading(false);
        })
        .catch((err) => {
          errorToast(err.response.data.message);
          setLoading(false);
        });
    }
    // purchaseRef.current.submit();
  };

  return isModal ? (
    <FormProvider {...methods}>
      <CustomeModal
        show={isCreateModalOpen}
        onHide={resetFields}
        header={<h3>{language.purchageOrderCreate}</h3>}
        footer={
          <>
            <Button
              variant="warning"
              onClick={handleSubmit(submit)}
              disabled={loading}
            >
              {loading ? "Loading..." : "Done"}
            </Button>
          </>
        }
      >
        <Tab.Container id="left-tabs-example" defaultActiveKey="purchase">
          <Row>
            <Col sm={12} className="tabContainer">
              <Nav variant="pills" className="flex-row">
                <Nav.Item>
                  <Nav.Link eventKey="purchase" className="custom-tab">
                    Purchase
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="upload" className="custom-tab">
                    Upload
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={12}>
              <Tab.Content>
                <Tab.Pane eventKey="purchase">
                  <PurchaseForm {...props} ref={purchaseRef} />
                </Tab.Pane>
                <Tab.Pane eventKey="upload">
                  <UploadDocuments {...props} purchaseData={values} />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </CustomeModal>
    </FormProvider>
  ) : (
    <FormProvider {...methods}>
      <Tab.Container id="left-tabs-example" defaultActiveKey="purchase">
        <Row>
          <Col sm={12} className="tabContainer">
            <Nav variant="pills" className="flex-row">
              <Nav.Item>
                <Nav.Link eventKey="purchase" className="custom-tab">
                  Purchase
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="upload" className="custom-tab">
                  Upload
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={12}>
            <Tab.Content>
              <Tab.Pane eventKey="purchase">
                <PurchaseForm {...props} ref={purchaseRef} />
              </Tab.Pane>
              <Tab.Pane eventKey="upload">
                <UploadDocuments {...props} purchaseData={values} />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>

        <div className={`mt-3 d-flex justify-content-end`}>
          {isEdit && (
            <Button
              variant="outline-secondary"
              onClick={() => {
                setDeleteId(purchaseData?.id);
                setShowAlert(true);
              }}
              className="btn-free me-3"
            >
              {"Delete"}
            </Button>
          )}
          <Button
            variant="warning"
            onClick={handleSubmit(submit)}
            className="btn-free"
          >
            {"Done"}
          </Button>
        </div>

        <AlertDialog
          show={showAlert}
          successAction={deletePurchase}
          title={language.deletePurchase}
          cancleAction={() => setShowAlert(false)}
          closeButton={false}
        />
      </Tab.Container>
    </FormProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    variants: state.userReducer.variants,
    storeId: state.userReducer.store_id,
  };
};

export default connect(mapStateToProps)(CreatePurchaseModal);
