import { useState } from "react";
import { connect } from "react-redux";

import PaymentMode from "./PaymentMode";

function PaymentModesList({
  selectedMode,
  setSelectedMode,
  setIsModalOpen,
  paymentMethod,
}) {
  return (
    <div className="settle--payment-type">
      {paymentMethod &&
        paymentMethod
          .sort(function (a, b) {
            return a.id - b.id;
          })
          .map((m, i) => (
            <PaymentMode
              mode={m}
              key={i}
              setIsModalOpen={setIsModalOpen}
              selectedMode={selectedMode}
              setSelectedMode={setSelectedMode}
            />
          ))}
    </div>
  );
}

// const mapStateToProps = (state) => {
//   return {
//     modes: state.settlementReducer.settlementMethods,
//   };
// };

// export default connect(mapStateToProps)(PaymentModesList);
export default PaymentModesList;
