import { Chart } from "chart.js/auto";
import { Bar, Line } from "react-chartjs-2";

import React from "react";
import moment from "moment";

const BarChart = ({ data }) => {
  const labels = Object.keys(data);
  const values = Object.values(data);
  const chartData = {
    labels: labels,
    datasets: [
      {
        // label: "My First Dataset",
        label: "Order",
        data: values,
        // fill: false,
        backgroundColor: "#f7c74d",
        spanGaps: false,
        borderWidth: 1,

        // barPercentage: 0.25,
        // categoryPercentage: 1.0,
        barThickness: 100,
        space: 0,

        // tension: 0.1,
      },
    ],
  };

  const options = {
    // animation: {},
    // categoryPercentage: 0.5, // here
    // barPercentage: 0.25,

    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,
      },
    },
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },

        ticks: {
          stepSize: 2,
          beginAtZero: true,

          // padding: 20,
          // callback: function(value, index, values) {
          //     return value / 1000 + "k";
          // }
        },
      },
    },
  };
  return (
    <div style={{ height: 500 }}>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default BarChart;
