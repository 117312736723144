import React, { useMemo } from "react";
import Modal from "../Common/Modal";
import PayoutForm from "./PayoutForm";

const PayoutEntryModal = ({ open, onHide, ...props }) => {
  return (
    <Modal show={open} onHide={onHide} size="lg" header={<h3>{"Payout"}</h3>}>
      <PayoutForm {...props} onHide={onHide} />
    </Modal>
  );
};

export default PayoutEntryModal;
