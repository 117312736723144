import { CUSTOMER_DETAILS_ACTIONS } from '../../reducers/customerDetailsReducer';

export const addCustomerDetails = (updates) => {
  return (dispatch) => {
    dispatch({
      type: CUSTOMER_DETAILS_ACTIONS.ADD_DETAILS,
      payload: { ...updates },
    });
  };
};
