import { errorToast } from "./showToast";

export const convertInputValueToNumber = (e) => {
  let event = { ...e, target: { ...e.target, value: Number(e.target.value) } };
  return event;
};

export const createError = (errors, setError) => {
  if (errors?.status === 422) {
    const errorObject = errors?.data?.errors;
    Object.keys(errorObject)?.forEach((error) => {
      setError(error, { message: errorObject[error][0] });
    });
  } else {
    errorToast(JSON.stringify(errors?.data?.message));
  }
};
