import React, { useState } from "react";
import { displayThreeDecimal } from "../../Utils/displayThreeDecimal";
import { putRequest } from "../../Data/ApiService";
import { ENDPOINTS } from "../../Data/Endpoint";
import { successToast } from "../../Utils/showToast";
import { FaCheck } from "react-icons/fa";

const StockReportItem = ({ data, successCallBack }) => {
  const [remark, setRemark] = useState(data?.remark || "");

  const saveRemark = async (e, id) => {
    e.preventDefault();
    await putRequest(`${ENDPOINTS.saveRemark}/${id}`, { remark }).then(
      (res) => {
        successToast(res.data?.data?.message);
      }
    );
  };

  const approveStockTake = async (id) => {
    await putRequest(`${ENDPOINTS.approveStockTake}/${id}`).then((res) => {
      successToast(res.data?.data?.message);
      successCallBack();
    });
  };

  return (
    <tr
      className={
        Number(data?.quantity) < Number(data?.system_quantity)
          ? "column-fonts-red"
          : ""
      }
    >
      <td width={200}>{data?.title_en}</td>
      <td>{data?.system_quantity}</td>
      <td>{displayThreeDecimal(data?.system_weight)}</td>
      <td>{data?.quantity}</td>
      <td>{displayThreeDecimal(data?.total_weight)}</td>
      <td>
        <form
          onSubmit={(e) => {
            saveRemark(e, data?.id);
          }}
        >
          <input
            type="text"
            className="form-control"
            placeholder="Remark"
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
          />
        </form>
      </td>
      <td align="center">
        {Number(data?.quantity) !== Number(data?.system_quantity) &&
        data?.system_weight !== data?.total_weight ? (
          <>
            <button
              className="btn text-success"
              onClick={() => {
                approveStockTake(data?.id);
              }}
              // disabled={data?.is_approved}
            >
              {data?.is_approved == 1 ? "asdsa" : "Approve"}
            </button>
          </>
        ) : data?.is_approved ? (
          <span className="text-success">
            <FaCheck />
          </span>
        ) : (
          <></>
        )}
      </td>
    </tr>
  );
};

export default StockReportItem;
