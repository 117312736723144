import { SETTLEMENT_ACTIONS } from '../../reducers/settlementReducer';

export const addUsedSettlement = (settlement) => {
  return (dispatch) => {
    dispatch({
      type: SETTLEMENT_ACTIONS.ADD_USED_SETTLEMENT_MODE,
      payload: settlement,
    });
  };
};
