import React from "react";

const LinkPerItem = ({ data, onClick }) => {
  return (
    <div className="linkitem-container">
      <p className="linkitem-title">{data?.title_en}</p>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <span className="linkitem-subtitle"> Purchase QTY: </span>
          <span className="linkitem-subtitle-bold">{data?.quantity}</span>
        </div>
        <div>
          <span className="linkitem-subtitle"> Linked Tag: </span>
          <span className="linkitem-subtitle-bold">
            {data?.total_tags_linked}
          </span>
        </div>
      </div>
      <div>
        <span className="linkitem-subtitle">Supplier: </span>
        <span className="linkitem-subtitle-bold">{data?.supplier_name}</span>
      </div>
      <div className="d-flex flex-row justify-content-between">
        <div>
          <div>
            <span className="linkitem-subtitle">
              Purchase Date: {data?.purchase_date}{" "}
            </span>
          </div>
          <div>
            <span className="linkitem-subtitle">
              Created Date: {data?.created}{" "}
            </span>
          </div>
        </div>
        <button className="btn btn-linkItem" onClick={onClick}>
          Link RFID
        </button>
      </div>
    </div>
  );
};

export default LinkPerItem;
