import React, { useEffect, useRef, useState } from "react";
import Page from "../Layout/Page";
import { ENDPOINTS } from "../Data/Endpoint";
import { getRequest, postRequest } from "../Data/ApiService";
import Categories from "../Components/Categories/Categories";
import { connect } from "react-redux";
import Loading from "../Components/Common/Loading";
import { isEmpty } from "../Utils/empty";
import { debounce } from "../Utils/search";
import BackButton from "../Components/Common/BackButton";
import { Table } from "react-bootstrap";
import language from "../Strings/en";
import LoadingBtn from "../Components/Common/Button";
import LinkItemModal from "../Components/Linkitem/LinkItemModal";
import LinkPerItem from "../Components/Linkitem/LinkPerItem";

const LinkItems = ({ selectedCategoryId, storeId }) => {
  const [purchases, setPurchases] = useState([]);
  const [openLinkItemModal, setOpenLinkItemModal] = useState({
    visibility: false,
    detail: null,
  });
  const [hasMorePages, setHasMorePages] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const hasTag = useRef(false);

  const fetchPurchases = async () => {
    setLoading(true);
    const res = await getRequest(ENDPOINTS.getPurchase, {
      page,
    });

    if (res.status === 200) {
      setLoading(false);
      if (page > 1) {
        setPurchases((prev) => [...prev, ...res?.data?.data?.purchase]);
      } else {
        setPurchases(res?.data?.data?.purchase);
      }
      setHasMorePages(res?.data?.data?.hasMorePages === 0 ? false : true);
    }
  };

  useEffect(() => {
    fetchPurchases();
  }, [page]);

  const loadMore = () => {
    setPage(page + 1);
  };

  const onSearch = (e) => {
    debounce(setKeyword(e.target.value));
  };

  const write = async (variant, productId, productName, variants) => {
    try {
      const ndef = new window.NDEFReader();
      await ndef
        .write({
          records: [
            {
              recordType: "text",
              data: JSON.stringify({
                carat: variant?.carat,
                size: variant?.size,
                metal: variant?.metal,
                weight: variant?.weight,
                price: variant?.price,
                productId: productId,
                productName: productName,
              }),
            },
          ],
        })
        .then(async (res) => {
          await postRequest(`${ENDPOINTS.linkRfid}/${variant?.id}`, {
            rfid_linked: 1,
          }).then(async (res) => {
            if (res.status === 200) {
              const updateVariants = variants.map((item) => {
                return item?.id === variant?.id
                  ? { ...item, rfid_linked: 1 }
                  : { ...item };
              });

              setPurchases((prev) =>
                prev?.map((item) => {
                  return item?.storeproduct_id === productId
                    ? { ...item, variants: updateVariants }
                    : { ...item };
                })
              );
              ndef.close();
            }
          });
        })
        .catch((err) => {
          alert(err);
        });
    } catch (error) {
      console.log(`Error! Scan failed to start: ${error}.`);
    }
  };

  return (
    <Page>
      <div className="container-fluid mt-3">
        {/* <div className="row">
          <Categories forMobile={true} />
        </div> */}
        <input
          type=""
          className="form-control mb-4 mt-4"
          placeholder="Search Item"
          value={keyword}
          onChange={onSearch}
        />
        {loading ? (
          <Loading />
        ) : (
          <div className="col-md-12">
            {purchases?.map((item) => {
              return (
                <LinkPerItem
                  key={item?.id}
                  data={item}
                  onClick={() => {
                    setOpenLinkItemModal({
                      visibility: true,
                      detail: item,
                    });
                  }}
                />
              );
            })}
            {/* <Table
              className="table table--orders"
              striped
              bordered
              hover
              responsive={true}
            >
              <thead>
                <tr>
                  <td>{language.purchaseName}</td>
                  <td>{language.purchaseQuantity}</td>
                  <td>{language.purchaseSupplier}</td>
                  <td>{language.purchaseDate}</td>
                  <td>{language.createdAt}</td>
                  <td>{language.createdBy}</td>
                  <td>{language.dbLinkItems}</td>
                </tr>
              </thead>
              <tbody>
                {purchases?.length > 0 ? (
                  purchases.map((o) => (
                    <tr
                      key={o?.id}
                      // className={editPurchase?.id === o?.id ? "active-row" : ""}
                    >
                      <td>{o?.title_en || "-"}</td>
                      <td>{o?.quantity ? o?.quantity : "--"}</td>
                      <td>{o?.supplier_name || "-"}</td>
                      <td>{o?.purchase_date ? o?.purchase_date : "--"}</td>
                      <td>{o?.created}</td>
                      <td>{o?.created_by || "-"}</td>
                      <td>
                        {" "}
                        <button
                          className="btn btn-linkItem"
                          onClick={
                            () => {
                              setOpenLinkItemModal({
                                visibility: true,
                                detail: o,
                              });
                            }
                            // write(
                            //   variant,
                            //   item?.storeproduct_id,
                            //   item?.title_en,
                            //   item?.variants
                            // )
                          }
                        >
                          Link RFID
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    {loading ? (
                      <td colSpan={13}>
                        <Loading />
                      </td>
                    ) : (
                      <td colSpan={13}>No records found</td>
                    )}
                  </tr>
                )}
              </tbody>
            </Table> */}
            {hasMorePages && (
              <div style={{ textAlign: "center" }}>
                <LoadingBtn onClick={loadMore}>Load More</LoadingBtn>
              </div>
            )}
            {loading && page > 1 && <Loading />}
          </div>
        )}
      </div>
      {openLinkItemModal.visibility && (
        <LinkItemModal
          openModal={openLinkItemModal.visibility}
          detail={openLinkItemModal.detail}
          hideModal={() =>
            setOpenLinkItemModal({ visibility: false, detail: null })
          }
        />
      )}
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedCategoryId: state.categoryReducer.selectedCategoryId,
    storeId: state.userReducer.store_id,
  };
};

export default connect(mapStateToProps)(LinkItems);
