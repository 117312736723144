const language = {
  cartDesc: "Description",
  cartWeight: "Weight",
  cartQty: "Qty",
  cartPrice: "Sale Price",
  cartCostPrice: "Cost Price",
  cartCostMK: "Cost MK",
  cartCostMKPGm: "Cost MK per gm",
  cartTotalCost: "Total Cost",
  price: "Price",
  cartMk: "Sale MK/gm",
  cartSubTotal: "Subtotal",
  cartSearchPlaceHolder: "Search Items...",
  carat: "Carat",
  color: "Color",
  size: "Size",
  weight: "Weight",

  billInfo: "Cost Info",
  billDisc: "Discount (LESS)",
  billSubTotal: "SubTotal",
  billTax: "Tax",
  billVat: "VAT",
  billTotal: "Total",
  billCurrency: "BD",
  billSave: "Done",
  billSettle: "Settle",

  dbDashboard: "Dashboard",
  dbReport: "Reports",
  dbCreateOrder: "Sale",
  dbSaleReport: "Sales Report",
  dbOrderHistory: "Orders",
  dbPurchase: "Purchase",
  dbCustomer: "Customers",
  dbMyProfile: "My Profile",
  dbSuppliers: "Suppliers",
  dbBars: "Bars/Scrapes",
  dbInventory: "Inventory",
  dbStockTake: "Stock Take",
  dbStock: "Stock",
  dbStockReport: "Stock Report",
  dbAuricHrms: "Auric HRMS",
  dbLogout: "Logout",
  dbLinkItems: "Link Items",

  login: "Login",
  userName: "User Name",
  email: "Email ID",
  passoword: "Passcode",
  register: "Register",
  loginEmailPlaceholder: "Enter Email ID",
  loginPasswordPlaceholder: "Enter Passcode",
  loading: "Loading",
  totalCostMk: "Total Cost MK",
  totalVat: "Total VAT",
  totalQty: "Total Qty",
  totalSale: "Total Sale",

  selectionCarat: "Carats",
  selectionMatal: "Metals",
  selectionSize: "Size",
  selectionWeight: "Weights",
  selectionStore: "store",
  selectionLength: "length",
  selectionHeight: "height",
  selectionWidth: "width",

  giftWrapModalTitle: "Gift wrap details",
  giftWrapMessge: "Gift wrap Message",
  giftWrapInstructions: "Gift wrap Instructions",

  createCustomer: "Create Customer",
  customerDetailsTitle: "Customer Details",
  customerName: "Customer Name",
  customerPhoneNumber: "Phone Number",
  customerCPR: "CPR",
  customerAddressLine1: "Address Line 1",
  customerAddressLine2: "Address Line 2",
  customerCity: "City",
  customerCountry: "Country",
  customerZip: "Zip/Pin Code",
  customerCreatedDate: "Created Date",
  customerOrder: "Order #",
  customerAmount: "Amount Spends",
  customerCountry: "Country",
  customerAddress: "Address",
  customerAnniversary: "Anniversary",
  customerDateOfBirth: "Date Of Birth",

  settlePaymentType: "Payment Type",
  settlePaymentDetails: "Payment Details",
  settlePrintReceipt: "Print",
  settleSave: "Done",
  settleBack: "Back",

  supplierName: "Supplier Name",
  createSupplier: "Create Supplier",
  purchaseCreateTitle: "Create Item Purchase",
  purchaseId: "Id",
  image: "Image",
  purchaseName: "Product Name",
  purchaseWeight: "Total Weight",
  purchaseInvoiceNo: "Invoice No",
  rfId: "RFID",
  purchaseCarats: "Carats",
  purchaseColors: "Colors",
  purchaseSize: "Size",
  purchaseGroup: "Group",
  purchaseSupplier: "Supplier",
  purchaseCategory: "Category",
  purchaseDate: "Purchase Date",
  purchaseQuantity: "Qty",
  purchaseGoldRate: "Gold Rate",
  purchaseGoldRatePerGram: "24k Gold Rate Per Gram",
  purchaseSellingPrice: "Selling Price",
  purchaseCostmk: "Cost Mk per gm",
  purchaseCash: "Cash",
  purchaseCredit: "Credit",
  purchageOrderCreate: "Create new purchase",
  stockModal: "Stock Take",
  createPurchase: "Create Purchase",
  purchaseType: "Purchase Type",
  priceType: "Price Type",
  purchaseBarcode: "Barcode",
  countryBahrain: "Bahrain",
  action: "Action",
  stockModalRfid: "RFID",
  stockModalManual: "Manual",
  stockOption: "Stock Option",
  addVariant: "Add Variant",
  removeVariant: "Remove",

  orderNo: "Order No.",
  orderCustomerName: "Customer Name",
  orderDate: "Date",
  orderSalesPerson: "Sales Person",
  orderTotalAmount: "Total Amount",
  orderTotalWOTax: "Total w/o Tax",
  orderTax: "Tax",
  orderStatus: "Status",

  inventorySearch: "Search",
  inventoryStock: "Stock",
  inventoryInStock: "In Stock",
  inventoryOutOfStock: "Out of Stock",
  inventoryAll: "All",

  typeOfSupplier: "Type of supplier",
  name: "Name",
  phone: "Phone",
  creditPeriod: "Credit Period (In days)",
  creditAmount: "Credit Amount",
  creditLimit: "Credit Limit (In bhd)",
  totalPurchases: "Total Purchases",
  totalWeight: "Total Weight",
  totalMK: "Total MK",
  balanceWeight: "Balance Weight",
  balanceMk: "Balance MK",
  crNumber: "CR Number",

  stockModalProduct: "Product",

  alertMessage: "The cart is empty. Do you want to delete this order?",
  alertMessageLogout: "Are you sure want to logout?",

  deleteAlertSupplier: "Are you sure want to delete this supplier?",
  deleteAlertCustomer: "Are you sure want to delete this customer?",
  deleteProduct: "Are you sure want to delete this product?",
  deletePurchase: "Are you sure want to delete this purchase?",
  deleteBar: "Are you sure want to delete this Record?",
  sysWeight: "System Weight",
  sysQty: "System QTY",
  onHandWeight: "Physical Weight",
  onHandQty: "Physical QTY",
  stockTakeDate: "Stock Take Date",
  expectedQty: "Expected Qty",
  expectedWeight: "Expected Weight",
  actualQty: "Actual Qty",
  actualWeight: "Actual Weight",
  onnHandQty: "On Hand QTY",
  weightOnHand: "Weight on hand",

  name: "Name",
  note: "Note",
  customer: "Customer",
  supplier: "Supplier",

  cancel: "Cancel",
  placeOrder: "Place order",

  filterBy: "Filter By",

  stockReport: "Stock Report",

  createdBy: "Created By",
  createdAt: "Created At",
  remark: "Remark",
  fixed: "Fixed",
  marketPrice: "Market Price",
  makingCharge: "Making Charge",
  dbProduct: "Products",
  createProduct: "Create Product",
  create: "Create",
  variants: "Variants",
  sku: "SKU",
  barcode: "Barcode",
  productCode: "Product Code",
  active: "Active",
  simpleProduct: "Simple",
  productWithVariants: "Variant",
  trackProduct: "Track inventory for this product",
  weightPerItem: "Weight Per Item",
  productType: "Product Type",
  type: "Type",
  code: "code",
  price: "Price",
  creator: "Creator",
  addNew: "Add New",
  refNo: "Ref No.",
  stockTakeAlert: "Do you want to submit this stock take?",

  refund: "Refund",
  reopen: "Reopen",
  qty: "Qty",
  date: "Date",
  paymentDate: "Payment Date",
  from: "From",
  to: "To",
  otherCost: "Other cost per gm",
  invNo: "Invoice No.",
  purchaseValue: "Purchase Value",
  "24CaratWeight": "24 carat weight",
  goldCost: "Gold Cost",

  totalPayableAmount: "Total Payable Amount",
  payoutDate: "Payout Date",
  amountYouArePaying: "Amont you are paying",
  paymentMethod: "Payment Method",
  recieptNo: "Reciept No.",
  attachment: "Attachment",
  barcode: "Barcode",
  invoiceDate: "Invoice Date",
  generateBarcode: "Generate Barcode",
  weightPerGm: "Weight Per Gm",
  crop: "Crop",

  carats: [
    { id: 1, carat: 18 },
    { id: 2, carat: 20 },
    { id: 3, carat: 22 },
    { id: 4, carat: 24 },
  ],
  metals: [
    { id: 1, metal: "yellow" },
    { id: 2, metal: "silver" },
    { id: 3, metal: "rose" },
  ],
  sizes: [
    { id: 1, size: "S" },
    { id: 2, size: "M" },
    { id: 3, size: "L" },
  ],
};

export default language;
