import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { getRequest, postRequest, putRequest } from "../../Data/ApiService";
import { ENDPOINTS } from "../../Data/Endpoint";
import language from "../../Strings/en";
import { displayThreeDecimal } from "../../Utils/displayThreeDecimal";
import Loading from "../Common/Loading";
import { default as CustomeModal } from "../Common/Modal";
import StockReportItem from "./StockReportItem";
const StockDetailModal = ({ isOpenModal, hideModal, uuid, size = "xl" }) => {
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState([]);
  const fetchStockDetail = async () => {
    setLoading(true);
    const res = await getRequest(`${ENDPOINTS.getStockDetail}?uuid=${uuid}`);

    if (res.status === 200) {
      setLoading(false);
      setDetails(res.data?.data?.stockreport);
    }
  };
  useEffect(() => {
    fetchStockDetail();
  }, []);

  return (
    <CustomeModal
      show={isOpenModal}
      onHide={hideModal}
      size={size}
      header={
        <div className="d-flex align-items-center w-100">
          <h3>{language.stockModal}</h3>
          {loading && (
            <div style={{ height: 40, display: "flex", alignItems: "center" }}>
              <Loading />
            </div>
          )}
        </div>
      }
      footer={
        <>
          {/* <Button variant="secondary" onClick={hideModal}>
            Cancel
          </Button> */}
          {/* <Button
            variant="warning"
            onClick={saveStock}
            disabled={createLoading}
          >
            {createLoading ? "Loading..." : "SAVE"}
          </Button> */}
        </>
      }
    >
      {/* <div style={{ maxHeight: 250, position: "relative" }} > */}
      <div className="row mb-3">
        <div
          className="col-sm-12"
          style={{ height: "100vh", width: "100%", overflow: "auto" }}
        >
          {/* <div style={{ position: "absolute", top: 0, overflow: "auto", height: "100%", width: "100%" }} ref={divRef}> */}
          <Table
            className="table table--orders"
            striped
            bordered
            hover
            responsive="md"
          >
            <thead
              style={{ position: "sticky", top: 0, backgroundColor: "#fff" }}
            >
              <tr>
                <th width={200}>{language.purchaseName}</th>
                <th>{language.expectedQty}</th>
                <th>{language.expectedWeight}</th>
                <th>{language.actualQty}</th>
                <th>{language.actualWeight}</th>
                <th>{language.remark}</th>
                <th style={{ textAlign: "center" }}>{language.action}</th>
              </tr>
            </thead>
            <tbody>
              {details?.map((data) => {
                return (
                  <StockReportItem
                    data={data}
                    successCallBack={fetchStockDetail}
                  />
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </CustomeModal>
  );
};

export default StockDetailModal;
