import { CART_ACTIONS } from '../../reducers/cartReducer';

import { processCartData } from '../../../Utils/processCartData';

export const bulkAddToCart = (products) => {
  const processedCartData = processCartData(products);
  return (dispatch) => {
    dispatch({
      type: CART_ACTIONS.BULK_ADD_PRODUCTS,
      payload: [...processedCartData],
    });
  };
};
