import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import CONFIG from "../../Config/BaseURL";
import { setPage } from "../../Store/actions/category/setPage";

import { setSelectedCategory } from "../../Store/actions/category/setSelectedCategory";
import { setProducts } from "../../Store/actions/product/setProducts";

function CategoryCard({
  category,
  selectedCategoryId,
  setSelectedCategory,
  setPage,
}) {
  return (
    <div
      style={{
        backgroundImage: `url(${CONFIG.IMAGE_ROOT_URL}${category.image_name})`,
      }}
      className={`card pointer category-card ${
        category.id === selectedCategoryId ? "selected-category" : ""
      }`}
      onClick={(_) => {
        setSelectedCategory(category.id);
        setPage(1);
      }}
    >
      <div className="card-body">
        <p className="card-text">{category.name}</p>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    selectedCategoryId: state.categoryReducer.selectedCategoryId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedCategory: bindActionCreators(setSelectedCategory, dispatch),
    setProducts: bindActionCreators(setProducts, dispatch),
    setPage: bindActionCreators(setPage, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryCard);
