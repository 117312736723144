import React from "react";

const CategoryWiseItem = ({ data }) => {
  return (
    <tr>
      <td>{data?.group_name}</td>
      <td>{data?.total_qty}</td>
      <td>{data?.total_sales}</td>
    </tr>
  );
};

export default CategoryWiseItem;
