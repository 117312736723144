import { Button, Modal } from "react-bootstrap";

export default function RemoveConfirmationModal({ handleClose, isOpen = false }) {
  return (
    <Modal show={isOpen} onHide={handleClose}>
      <Modal.Header closeButton>Are you sure, you want to delete product?</Modal.Header>

      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Close
        </Button>
        <Button variant='danger'>Save changes</Button>
      </Modal.Footer>
    </Modal>
  );
}
