import React, { useState } from "react";
// import { Modal } from "react-bootstrap";
import ImageCropper from "./ImageCropper";
import language from "../../Strings/en";
import Modal from "./Modal";

const CroppingImage = ({ open, onHide, image, handleImage }) => {
  return (
    <Modal
      show={open}
      onHide={onHide}
      size="small"
      centered={true}
      header={<h3>{language.crop}</h3>}
    >
      <ImageCropper getCroppedFile={handleImage} src={image} onHide={onHide} />
    </Modal>
  );
};

export default CroppingImage;
