import React, { useEffect, useState } from "react";
import Page from "../Layout/Page";
import BarChart from "../Components/Common/Chart/BarChart";
import Stats from "../Components/Common/Stats";
import { Form, Tab, Table, Tabs } from "react-bootstrap";
import DatePicker from "../Components/Common/DatePicker";
import ProductWiseSaleItem from "../Components/Analytics/ProductWiseSaleItem";
import CategoryWiseItem from "../Components/Analytics/CategoryWiseItem";
import { getRequest } from "../Data/ApiService";
import { ENDPOINTS } from "../Data/Endpoint";
import moment from "moment";
import { DATE_FORMAT } from "../Config";
import { errorToast } from "../Utils/showToast";
import language from "../Strings/en";
import CategoryWiseSection from "../Components/Analytics/CategoryWiseSection";
import ProductWiseSection from "../Components/Analytics/ProductWiseSection";

const charts = [
  {
    value: "sales",
    label: "Sales",
  },
  {
    value: "sales_person",
    label: "Sales Person",
  },
];

function Analytics() {
  const [activeChart, setActiveChart] = useState("sales");
  const [filter, setFilter] = useState({
    start_date: moment().format(DATE_FORMAT.normal),
    end_date: moment().format(DATE_FORMAT.normal),
    type: "",
  });

  const [statsData, setStatsData] = useState([
    {
      label: "Sales",
      field: "total_sales",
      count: 0,
    },
    {
      label: "Net Sale",
      field: "total_net_sales",
      count: 0,
    },
    {
      label: "No of orders",
      field: "total_orders",
      count: 0,
    },
    {
      label: "New customer",
      field: "new_customers",
      count: 0,
    },
  ]);

  const [salesChartData, setSalesChartData] = useState({});
  const [salesPersonChartData, setSalesPersonChartData] = useState({});

  const [productWiseData, setProductWiseData] = useState([]);
  const [categoryWiseData, setCategoryWiseData] = useState([]);

  const fetchStatsData = async () => {
    await getRequest(ENDPOINTS.getDashboardStats, {
      start_date: filter.start_date,
      end_date: filter.end_date,
    }).then((res) => {
      if (res.data.status === 200) {
        const data = res.data?.data;
        setStatsData((prev) =>
          prev.map((item) => ({ ...item, count: data[item?.field] || "0" }))
        );
        setCategoryWiseData(data?.groupwise_sales);
        setProductWiseData(data?.productwise_sales);
        setSalesChartData(data?.sales_chart);
        setSalesPersonChartData(data?.salesperson_chart);
      } else {
        errorToast(res.data?.data?.msg);
      }
    });
  };

  useEffect(() => {
    fetchStatsData();
  }, [filter.start_date, filter.end_date]);

  // console.log(moment().subtract(1, "days").format("YYYY-MM-DD"));

  const dateFilterValue = [
    {
      label: "Today",
      value: JSON.stringify({
        start_date: moment().format(DATE_FORMAT.normal),
        end_date: moment().format(DATE_FORMAT.normal),
      }),
    },
    {
      label: "Yesterday",
      value: JSON.stringify({
        start_date: moment().subtract(1, "days").format(DATE_FORMAT.normal),
        end_date: moment().subtract(1, "days").format(DATE_FORMAT.normal),
      }),
    },
    {
      label: "This week",
      value: JSON.stringify({
        start_date: moment().startOf("week").format(DATE_FORMAT.normal),
        end_date: moment().endOf("week").format(DATE_FORMAT.normal),
      }),
    },
    {
      label: "This month",
      value: JSON.stringify({
        start_date: moment().startOf("month").format(DATE_FORMAT.normal),
        end_date: moment().endOf("month").format(DATE_FORMAT.normal),
      }),
    },
    {
      label: "Custom Date Range",
      value: "custom",
    },
  ];

  const handleChangeDateFilter = (value) => {
    if (value === "custom") {
      setFilter((prev) => ({ ...prev, type: value }));
    } else {
      const data = JSON.parse(value);
      setFilter((prev) => ({ type: value, ...data }));
    }
  };

  return (
    <Page>
      <div className="container">
        <div className="row">
          <div className="col-md-12 mb-4">
            <div className="d-flex flex-row justify-content-between align-items-end">
              <h1>Dashboard</h1>
              <div className="d-flex  align-items-end">
                {filter.type === "custom" && (
                  <>
                    <div className="d-flex align-items-end">
                      <DatePicker
                        label="Start"
                        value={filter.start_date}
                        onChange={(e) =>
                          setFilter((prev) => ({
                            ...prev,
                            start_date: e.target.value,
                          }))
                        }
                      />
                    </div>
                    <div className="d-flex  align-items-end me-2">
                      <DatePicker
                        label="End"
                        className="ms-2"
                        value={filter.end_date}
                        onChange={(e) =>
                          setFilter((prev) => ({
                            ...prev,
                            end_date: e.target.value,
                          }))
                        }
                      />
                    </div>
                  </>
                )}
                <Form.Group className="form-group">
                  <Form.Select
                    className="select"
                    value={filter.type}
                    onChange={(e) => {
                      handleChangeDateFilter(e.target.value);
                    }}
                  >
                    {dateFilterValue?.map((item, index) => (
                      <option value={item.value}>{item.label}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>
            </div>
          </div>
          <div className="col-md-12 mb-4">
            <div className="d-flex flex-wrap">
              {statsData?.map((item, index) => (
                <Stats
                  key={index}
                  title={item.label}
                  count={item.count}
                  col="flex-fill"
                />
              ))}
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-4">
              <Tabs
                activeKey={activeChart}
                onSelect={(k) => {
                  console.log(k);
                  setActiveChart(k);
                }}
                variant="pills"
              >
                {charts.map((tabItems) => {
                  return (
                    <Tab
                      key={tabItems.value}
                      eventKey={tabItems.value}
                      title={tabItems.label}
                      tabClassName="custom-tab"
                    />
                  );
                })}
              </Tabs>
            </div>

            <BarChart
              data={
                activeChart === "sales" ? salesChartData : salesPersonChartData
              }
            />
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-6">
            <ProductWiseSection data={productWiseData} />
          </div>
          <div className="col-md-6">
            <CategoryWiseSection data={categoryWiseData} />
          </div>
        </div>
      </div>
    </Page>
  );
}

export default Analytics;
