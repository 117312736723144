import React, { useEffect, useRef, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { getRequest, postRequest } from "../../Data/ApiService";
import { ENDPOINTS } from "../../Data/Endpoint";
import language from "../../Strings/en";
import { errorToast, successToast } from "../../Utils/showToast";
import { default as CustomeModal } from "../Common/Modal";
import { NFCPlaceholder } from "../../Assets";

const MobileStockRfidModal = ({ openModal, hideModal }) => {
  const [productDetail, setProductDetail] = useState({});
  const [products, setProducts] = useState([]);
  const productRef = useRef([]);

  const abortController = new AbortController();
  abortController.signal.onabort = (event) => {
    // All NFC operations have been aborted.
    console.log(event);
  };

  const scan = async () => {
    // if ("NDEFReader" in window) {
    // alert("Support");
    try {
      const ndef = new window.NDEFReader();
      await ndef.scan();

      console.log("Scan started successfully.");
      ndef.onreadingerror = () => {
        console.log("Cannot read data from the NFC tag. Try another one?");
      };

      ndef.onreading = (event) => {
        console.log("NDEF message read.");
        onReading(event); //Find function below
      };
    } catch (error) {
      console.log(`Error! Scan failed to start: ${error}.`);
    }
  };

  const onReading = ({ serialNumber, message }) => {
    // fetchProductDetailFromRfid(convertSerialNumber(serialNumber));
    for (const record of message.records) {
      switch (record.recordType) {
        case "text":
          const textDecoder = new TextDecoder(record.encoding);

          console.log("Message", textDecoder.decode(record.data));
          const data = JSON.parse(String(textDecoder.decode(record.data)));
          addDataToStack(data);

          break;
        case "url":
          // TODO: Read URL record with record data.
          break;
        default:
        // TODO: Handle other records with record data.
      }
    }
  };

  const addDataToStack = (data) => {
    let index = -1;
    if (data?.storeproduct_variant_id) {
      index = productRef.current?.findIndex(
        (product) =>
          product?.storeproduct_variant_id == data?.storeproduct_variant_id
      );
    } else {
      index = productRef.current?.findIndex(
        (product) => product?.storeproduct_id == data?.storeproduct_id
      );
    }

    if (index > -1) {
      let newProducts = [...productRef.current];
      const temp = {
        ...data,
        quantity: productRef.current[index].quantity + 1,
        total_weight:
          Number(productRef.current[index]?.weight_per_item) *
          (productRef.current[index]?.quantity + 1),
      };
      newProducts.splice(index, 1);
      setProductDetail({
        quantity: temp.quantity,
        weight: temp?.total_weight || "0",
      });
      productRef.current = [temp, ...newProducts];
      setProducts([temp, ...newProducts]);
    } else {
      let newProducts = [
        {
          ...data,
          quantity: 1,
        },
        ...productRef.current,
      ];
      setProductDetail({
        quantity: 1,
        weight: data?.weight_per_item || "0",
      });
      productRef.current = newProducts;
      setProducts(newProducts);
    }
  };

  useEffect(() => {
    scan();
    return () => {
      abortController.abort();
    };
  }, []);

  const fetchProductDetailFromRfid = async (id) => {
    await getRequest(`${ENDPOINTS.getProductByRfid}/${id}`)
      .then((res) => {
        if (res.status === 200) {
          const detail = res.data?.data?.storeproductVariants[0];
          setProductDetail(detail);
          const index = products?.findIndex(
            (product) => product.storeproduct_id === detail?.id
          );
          if (index > -1) {
            let newProducts = [...products];
            newProducts[index] = {
              ...products[index],
              quantity: products[index].quantity + 1,
            };
            setProducts(newProducts);
          } else {
            setProducts((prev) => [
              ...prev,
              {
                storeproduct_id: detail.id,
                total_weight: detail.weight,
                quantity: 1,
              },
            ]);
          }
        } else {
          errorToast(res.data?.data?.message);
        }
      })
      .catch((err) => {
        errorToast(err?.response?.data?.data?.message);
      });
  };

  const submit = async () => {
    const payload = products?.map((item) => ({
      storeproduct_id: item?.storeproduct_id,
      total_weight: item?.total_weight,
      quantity: item?.quantity,
      storeproduct_variant_id: item?.storeproduct_variant_id,
      stocktake: "RFID",
    }));
    const response = await postRequest(ENDPOINTS.setStock, payload);
    // const response = await postRequest(ENDPOINTS.setStock, [
    //   {
    //     storeproduct_id: 315,
    //     total_weight: 200,
    //     quantity: 100,
    //     storeproduct_variant_id: null,
    //     stocktake: "RFID",
    //   },
    // ]);
    if (response.status === 200) {
      successToast(response.data.data.msg);
      hideModal();
    }
  };

  const onHideModal = () => {
    hideModal();
    abortController.abort();
  };

  return (
    <CustomeModal
      show={openModal}
      // closeButton={false}
      onHide={hideModal}
      size={"sm"}
      fullscreen="sm-down"
      header={
        <div className="d-flex align-items-center w-100">
          <h3>{language.stockModal}</h3>
        </div>
      }
      alertDialog
      footer={
        productDetail?.weight && (
          <div className="w-100 px-3 py-1">
            {/* <Button variant="secondary" onClick={onHideModal}>
              Cancel
            </Button> */}
            <Button variant="warning" className="w-100" onClick={submit}>
              {"DONE"}
            </Button>
          </div>
        )
      }
    >
      {productDetail?.weight ? (
        <div className="d-flex justify-content-around flex-row align-items-center ">
          <div className="text-center">
            <p className="stock-label">QTY Scanned</p>
            <p className="stock-value">{productDetail?.quantity || "-"}</p>
          </div>
          <div className="text-center">
            <p className="stock-label">Weight</p>
            <p className="stock-value">{productDetail?.weight || "-"}</p>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center  align-items-center nfc-placeholder-container ">
          <img src={NFCPlaceholder} className="nfc-placeholder" />
        </div>
      )}

      {products?.length > 0 && (
        <div className="scrollable-table">
          <div className="row">
            <div className="col-sm-12">
              <Table
                className="table table--orders sunmi"
                striped
                bordered
                hover
                responsive="md"
              >
                <thead>
                  <tr>
                    <td>{language.purchaseName}</td>
                    <td>{language.purchaseQuantity}</td>
                  </tr>
                </thead>
                <tbody>
                  {products?.map((product) => {
                    return (
                      <tr>
                        <td>{product?.productName}</td>
                        <td>{product?.quantity}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      )}
    </CustomeModal>
  );
};

export default MobileStockRfidModal;
