import React, { useState } from "react";
import SelectComponent from "./SelectComponent";
import language from "../../Strings/en";
import { isEmpty } from "../../Utils/empty";
import { getRequest, postRequest, putRequest } from "../../Data/ApiService";
import { ENDPOINTS } from "../../Data/Endpoint";
import CustomerDetailsModal from "../Cart/CustomerDetailsModal";
import { debounce } from "lodash";
import CreateSupplier from "../Supplier/CreateSupplier";

const SupplierDropdown = React.forwardRef(
  ({ value, onChange, error, ...props }, ref) => {
    const [options, setOptions] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const [isSupplierDetailOpen, setIsSupplierDetailOpen] = useState(false);
    const [supplierDetails, setSupplierDetails] = useState({});

    const triggerSearch = async (search) => {
      if (!isEmpty(search)) {
        setSearchLoading(true);
        let res = await getRequest(ENDPOINTS.suppliers, {
          ...(!isEmpty(search) && {
            name: search,
          }),
        });
        setSearchLoading(false);
        if (res.data.data.success) {
          setOptions(
            res.data?.data?.suppliers?.map((supplier) => ({
              ...supplier,
              value: supplier?.id,
              label: supplier?.name,
            }))
          );
        }
      }
    };
    const searching = debounce((value) => {
      if (value === "") {
        setOptions([]);
      }
      triggerSearch(value);
    }, 400);

    const addNewSupplier = () => {
      setIsSupplierDetailOpen(true);
    };
    const onSelect = (customer) => {
      onChange(customer);
    };

    const actionResponse = (supplier) => {
      onChange({
        ...supplier,
        label: supplier?.supplier_name,
        key: supplier?.id,
      });
    };

    return (
      <>
        <SelectComponent
          label={language.supplier}
          isRequired={true}
          placeholder={"Search Supplier"}
          onSearch={searching}
          options={options}
          addNewButton={true}
          addNewAction={addNewSupplier}
          onChange={onSelect}
          loading={searchLoading}
          value={value}
          error={error}
          ref={ref}
        />
        <CreateSupplier
          openSupplierModal={isSupplierDetailOpen}
          setSupplierDetails={setSupplierDetails}
          supplierDetails={supplierDetails}
          setOpenSupplierModal={setIsSupplierDetailOpen}
          actionResponse={actionResponse}
          isModal={true}
        />
      </>
    );
  }
);

export default SupplierDropdown;
