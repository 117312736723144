import { PRODUCT_ACTIONS } from '../../reducers/productReducer';

export const setProducts = (products) => {
  return (dispatch) => {
    dispatch({
      type: PRODUCT_ACTIONS.ADD_PRODUCTS,
      payload: products,
    });
  };
};
