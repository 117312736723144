import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Nav, Row, Tab } from "react-bootstrap";

import { FormProvider, useForm } from "react-hook-form";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../../Data/ApiService";
import { ENDPOINTS } from "../../Data/Endpoint";
import language from "../../Strings/en";
import { isEmpty } from "../../Utils/empty";
import AlertDialog from "../Common/AlertDialog";
import { default as CustomeModal } from "../Common/Modal";
import SupplierForm from "./SupplierForm";
import { useNavigate } from "react-router-dom";
import Attachments from "./Attachments";
import { successToast } from "../../Utils/showToast";

function CreateSupplier({
  openSupplierModal,
  setOpenSupplierModal,
  supplierDetails,
  handleSupplierDetails,
  saveSupplierDetails,
  setSupplierDetails,
  actionResponse,
  isModal,
}) {
  const searchRef = useRef(null);
  const [options, setOptions] = useState([]);
  const onSelect = (customer) => {
    setSupplierDetails({ ...customer });
  };
  const triggerSearch = async (search) => {
    // e.preventDefault();
    if (!isEmpty(search)) {
      let res = await getRequest(ENDPOINTS.listCustomer, {
        ...(!isEmpty(search) && {
          name: search,
        }),
      });
      if (res.data.data.success) {
        setOptions(
          res.data.data.customers?.map((customer) => ({
            ...customer,
            key: customer?.id,
            label: customer?.customer_name,
          }))
        );
        searchRef.current.setShowOption(true);
      }
    }
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 1000);
    };
  };

  const searching = async (e) => {
    debounce(triggerSearch(e?.target.value));
  };

  const initialState = {
    supplier_name: "",
    cr_number: "",
    address_line1: "",
    address_line2: "",
    city: "",
    country: "",
    zipcode: "",
    type_of_supplier: "",
    credit_period: "",
    credit_limit: "",
    contact: "",
    email: "",
  };

  const methods = useForm({ defaultValues: initialState });
  const { handleSubmit, reset, setValue, watch } = methods;

  useEffect(() => {
    if (Object.values(supplierDetails).some((item) => !isEmpty(item))) {
      reset(supplierDetails);
    } else {
      reset(initialState);
    }
  }, [supplierDetails]);

  const [activeTab, setActiveTab] = useState("purchase");

  const submit = async (data) => {
    if (activeTab !== "upload") {
      if (data?.id) {
        await putRequest(`${ENDPOINTS.updateSupplier}/${data?.id}`, data).then(
          (res) => {
            if (res.status === 200) {
              // setOpenSupplierModal(false);
              actionResponse && actionResponse();
              successToast(res.data?.data?.msg);
            }
          }
        );
      } else {
        await postRequest(ENDPOINTS.createSupplier, data).then((res) => {
          if (res.status === 200) {
            setSupplierDetails(res.data?.data?.supplier);
            // setOpenSupplierModal(false);
            actionResponse && actionResponse(res.data?.data?.supplier);
            successToast(res.data?.data?.msg);
          }
        });
      }
    } else {
      setOpenSupplierModal(false);
    }
  };

  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const deleteSupplier = async () => {
    let res = await deleteRequest(`${ENDPOINTS.deleteSupplier}/${deleteId}`);
    if (res.status === 200) {
      navigate(-1);
      actionResponse && actionResponse();
    }
    setShowAlert(false);
  };

  return isModal ? (
    <FormProvider {...methods}>
      <CustomeModal
        show={openSupplierModal}
        onHide={(_) => setOpenSupplierModal(false)}
        header={<h3>{language.createSupplier}</h3>}
        footer={
          <>
            <Button variant="warning" onClick={handleSubmit(submit)}>
              Done
            </Button>
          </>
        }
      >
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey={activeTab}
          onSelect={(tab) => setActiveTab(tab)}
          activeKey={activeTab}
        >
          <Row>
            <Col sm={12} className="tabContainer">
              <Nav variant="pills" className="flex-row">
                <Nav.Item>
                  <Nav.Link eventKey="purchase" className="custom-tab">
                    Supplier
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="upload" className="custom-tab">
                    Upload
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={12}>
              <Tab.Content>
                <Tab.Pane eventKey="purchase">
                  <SupplierForm />
                </Tab.Pane>
                <Tab.Pane eventKey="upload">
                  <Attachments supplierDetails={supplierDetails} />
                  {/* <UploadAttachment {...props} /> */}
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>

        {/* <div className="row mb-3">
          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="form-group">
              <label>{language.supplierName}</label>

              <SelectSearch
                value={supplierDetails.supplier_name}
                size="lg"
                onSelect={onSelect}
                options={options}
                placeholder={language.supplierName}
                onSearch={(value) => {
                  const event = {
                    target: { name: "supplier_name", value },
                  };
                  handleSupplierDetails(event);
                }}
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="form-group">
              <label>{language.customerPhoneNumber}</label>
              <InputNumber
                value={supplierDetails.contact}
                name="contact"
                placeholder={language.customerPhoneNumber}
                onChange={handleSupplierDetails}
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="form-group">
              <label>{language.email}</label>
              <input
                type="email"
                name="email"
                className="form-control"
                placeholder={language.email}
                value={supplierDetails.email}
                onChange={handleSupplierDetails}
              />
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-sm-4">
            <div className="form-group">
              <label>{language.typeOfSupplier}</label>
              <input
                type="text"
                name="type_of_supplier"
                className="form-control"
                placeholder={language.typeOfSupplier}
                value={supplierDetails.type_of_supplier}
                onChange={handleSupplierDetails}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label>{language.creditPeriod}</label>
              <input
                type="text"
                name="credit_period"
                className="form-control"
                placeholder={language.creditPeriod}
                value={supplierDetails.credit_period}
                onChange={handleSupplierDetails}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label>{language.creditLimit}</label>
              <input
                type="text"
                name="credit_limit"
                className="form-control"
                placeholder={language.creditLimit}
                value={supplierDetails.credit_limit}
                onChange={handleSupplierDetails}
              />
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-sm-4">
            <div className="form-group">
              <label>{language.customerCPR}</label>
              <input
                type="text"
                name="cr_number"
                className="form-control"
                placeholder={language.crNumber}
                value={supplierDetails.cr_number}
                onChange={handleSupplierDetails}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label>{language.customerAddressLine1}</label>
              <input
                type="text"
                name="address_line1"
                className="form-control"
                placeholder={language.customerAddressLine1}
                value={supplierDetails.address_line1}
                onChange={handleSupplierDetails}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label>{language.customerAddressLine2}</label>
              <input
                type="text"
                name="address_line2"
                className="form-control"
                placeholder={language.customerAddressLine2}
                value={supplierDetails.address_line2}
                onChange={handleSupplierDetails}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-4">
            <div className="form-group">
              <label>{language.customerCity}</label>
              <input
                type="text"
                name="city"
                className="form-control"
                placeholder={language.customerCity}
                value={supplierDetails.city}
                onChange={handleSupplierDetails}
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4">
            <div className="form-group">
              <label>{language.customerZip}</label>
              <input
                type="text"
                name="zipcode"
                className="form-control"
                placeholder={language.customerZip}
                value={supplierDetails.zipcode}
                onChange={handleSupplierDetails}
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4">
            <div className="form-group">
              <label>{language.customerCountry}</label>
              <input
                type="text"
                name="country"
                className="form-control"
                placeholder={language.customerCountry}
                value={supplierDetails.country}
                onChange={handleSupplierDetails}
              />
            </div>
          </div>
        </div> */}
      </CustomeModal>
    </FormProvider>
  ) : (
    <FormProvider {...methods}>
      <SupplierForm />
      <div className="mt-4 d-flex justify-content-end">
        <Button
          variant="outline-secondary"
          className="me-4 btn-free"
          onClick={() => {
            setDeleteId(supplierDetails?.id);
            setShowAlert(true);
          }}
        >
          Delete
        </Button>
        <Button
          variant="warning"
          className="btn-free"
          onClick={handleSubmit(submit)}
        >
          Done
        </Button>
      </div>
      <AlertDialog
        show={showAlert}
        successAction={deleteSupplier}
        title={language.deleteAlertSupplier}
        cancleAction={() => setShowAlert(false)}
        closeButton={false}
      />
    </FormProvider>
  );
}

export default React.memo(CreateSupplier);
