import React from "react";
import Form from "react-bootstrap/Form";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
const DatePicker = React.forwardRef(
  (
    {
      label,
      isRequired,
      name,
      value,
      onChange,
      size,
      placeholder,
      error,
      minDate,
      className,
    },
    ref
  ) => {
    return (
      <Form.Group className={`form-group d-flex flex-column mt-1 ${className}`}>
        {label && (
          <Form.Label className="mb-0">
            {label} {isRequired && <span className="text-danger">*</span>}
          </Form.Label>
        )}
        <ReactDatePicker
          dateFormat="dd/MM/yyyy"
          maxDate={minDate}
          placeholderText={label || placeholder}
          value={value}
          selected={new Date(value || new Date())}
          onSelect={(date) => {
            onChange({
              target: { name: name, value: moment(date).format("YYYY-MM-DD") },
            });
          }}
          className={`form-control ${
            size === "xl"
              ? "date-picker-size-xl"
              : size === "lg"
              ? "date-picker-size-lg"
              : "date-picker-size-sm"
          }`}
        />
        <span className="error-label">{error}</span>
      </Form.Group>
    );
  }
);

export default DatePicker;
