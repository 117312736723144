import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";

import BlankRow from "../Cart/BlankRow";
import RemoveConfirmationModal from "../Cart/RemoveConfirmationModal";
import CartItem from "./CartItem";

import { bindActionCreators } from "redux";
import { deleteRequest, getRequest } from "../../Data/ApiService";
import { ENDPOINTS } from "../../Data/Endpoint";
import { bulkAddToCart } from "../../Store/actions/cart/bulkAddCart";
import { setLoading } from "../../Store/actions/cart/setLoading";
import { setSelectedCategory } from "../../Store/actions/category/setSelectedCategory";
import { setProducts } from "../../Store/actions/product/setProducts";
import language from "../../Strings/en";
import Input from "../Common/Input";
import CreatePurchaseModal from "../Purchase/CreatePurchaseModal";

function Cart({
  isOpen,
  cartProducts,
  setIsOpen,
  setCurrentEditProduct,
  setCurrentAddProduct,
  openAttributeSelection,
  clearCart,
  setProducts,
  setSelectedCategory,
  setLoading,
}) {
  const handleClose = (value) => {
    setIsOpen(value);
  };

  const handleEditProduct = (product) => {
    setCurrentEditProduct(product);
  };

  const handleClearCart = async (_) => {
    clearCart();
    await deleteRequest(ENDPOINTS.cartClear);
  };
  const [showPurchaseDialog, setShowPurchaseDialog] = useState(false);

  const onSelect = (value) => {
    openAttributeSelection("add", true);
    setCurrentAddProduct({ title_en: value.label, id: value.value });
  };
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState([]);
  const searching = async (e) => {
    setLoading(true);
    e.preventDefault();
    setSelectedCategory(null);
    const res = await getRequest(ENDPOINTS.search, { text: search });
    setProducts(
      res.data?.data?.products?.map((option) => {
        return {
          ...option,
          key: option?.id,
          label: option?.title_en,
          value: option?.id,
        };
      })
    );
    setOptions(
      res.data?.data?.products?.map((option) => {
        return {
          ...option,
          key: option?.id,
          label: option?.title_en,
          value: option?.id,
        };
      })
    );
    setLoading(false);
  };
  const searchBarcode = async (e) => {
    e.preventDefault();
    const response = await getRequest(
      `${ENDPOINTS.purchaseFromBarcode}?barcode=${search}`
    );

    if (response.data?.data?.success) {
      console.log(response?.data?.data?.purchase[0]);
      setCurrentAddProduct(response?.data?.data?.purchase?.[0]);
      setTimeout(() => {
        openAttributeSelection("add", true);
      }, 100);
    }
  };
  return (
    <div className="cart">
      <Table className="table--cart" striped borderless>
        <thead>
          <tr>
            <th scope="col">{language.cartDesc}</th>
            {/* <th scope="col">{language.cartWeight}</th> */}
            <th scope="col">{language.cartQty}</th>
            <th scope="col">{language.cartCostPrice}</th>
            <th scope="col">{language.cartCostMKPGm}</th>
            <th scope="col">{language.cartTotalCost}</th>
            <th scope="col">{language.cartPrice}</th>
            <th scope="col">{language.cartMk}</th>
            <th scope="col">{language.cartSubTotal}</th>
            <th scope="col">{language.totalWeight}</th>
            <th scope="col">
              <form onSubmit={searching}>
                <Input
                  size="sm"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                {/* <SelectSearch
                  actionOnAddButton={() => setShowPurchaseDialog(true)}
                  onSearch={setSearch}
                  options={options}
                  onSelect={onSelect}
                  // displayButton
                  placeholder="Search by name, sku, barcode"
                  forDropdown={false}
                  renderItem={(data) => (
                    <div>
                      <p className="m-0">{data?.title_en}</p>
                      <div
                        style={{ fontWeight: "normal", fontSize: 12 }}
                        className="d-flex justify-content-between"
                      >
                        <div>
                          {language.cartWeight}: {data?.weight || 0} &nbsp;{" "}
                          {language.price}: {data?.variant_price || 0} &nbsp;{" "}
                        </div>
                        {data?.in_stock === 0 && (
                          <Badge bg="secondary" className="sold-out-badge">
                            Sold Out
                          </Badge>
                        )}
                      </div>
                    </div>
                  )}
                /> */}
              </form>
            </th>
          </tr>
        </thead>
        <tbody>
          {cartProducts.length > 0 ? (
            cartProducts.map((p) => {
              return (
                <CartItem
                  openAttributeSelection={openAttributeSelection}
                  handleEditProduct={handleEditProduct}
                  handleClose={handleClose}
                  key={p.cartId}
                  id={p.id}
                  product={p}
                />
              );
            })
          ) : (
            <BlankRow message="Your cart is empty." />
          )}
        </tbody>
      </Table>
      <RemoveConfirmationModal isOpen={isOpen} handleClose={handleClose} />
      <CreatePurchaseModal
        isCreateModalOpen={showPurchaseDialog}
        hideCreateModal={() => setShowPurchaseDialog(false)}
        isModal={true}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    cartProducts: state.cartReducer.products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    bulkAddToCart: bindActionCreators(bulkAddToCart, dispatch),
    setProducts: bindActionCreators(setProducts, dispatch),
    setLoading: bindActionCreators(setLoading, dispatch),
    setSelectedCategory: bindActionCreators(setSelectedCategory, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Cart));
