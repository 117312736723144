import React, { useEffect, useState } from "react";
import { Breadcrumb, Col, Nav, Row, Tab } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Link from "../Components/Common/Link";
import Attachments from "../Components/Supplier/Attachments";
import CreateSupplier from "../Components/Supplier/CreateSupplier";
import Payout from "../Components/Supplier/Payout";
import PayoutMakingCharge from "../Components/Supplier/PayoutMakingCharge";
import Page from "../Layout/Page";
import PayoutHistory from "../Components/Supplier/PayoutHistory";

const SupplierDetail = () => {
  const { state } = useLocation();
  const supplierDetail = state?.supplier;
  const tab = state?.activeTab;
  const navigate = useNavigate();

  useEffect(() => {
    if (!supplierDetail) {
      navigate(-1);
    }
  }, [supplierDetail]);

  const tabs = [
    {
      key: "about",
      title: "About",
      component: (
        <CreateSupplier isModal={false} supplierDetails={supplierDetail} />
      ),
    },
    {
      key: "attachments",
      title: "Attachments",
      component: (
        <div className="w-80">
          <Attachments detail={supplierDetail} keyName={"supplier"} />
        </div>
      ),
    },
    {
      key: "payout",
      title: "Payout",
      component: <Payout detail={supplierDetail} />,
    },
    {
      key: "payoutmk",
      title: "Making Charge Payout",
      component: <PayoutMakingCharge detail={supplierDetail} />,
    },
    {
      key: "payouthistory",
      title: "Payout History",
      component: <PayoutHistory detail={supplierDetail} />,
    },
  ];

  const [activeTab, setActiveTab] = useState(tab || tabs[0].key);

  return (
    <Page>
      <div className="container">
        <Breadcrumb>
          <Breadcrumb.Item className="custom-breadcrumb">
            <Link to={"/dashboard"}>Dashboard</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item className="custom-breadcrumb">
            <Link to={"/suppliers"}> Suppliers</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Detail</Breadcrumb.Item>
        </Breadcrumb>
        {state?.supplier ? (
          <div className="row mt-5">
            <div className="col-md-12">
              <h5 className="bold-font p-0 m-0">
                {supplierDetail?.supplier_name}
              </h5>
            </div>
            <div className="col-md-12 mt-5">
              <Tab.Container
                id="left-tabs-example"
                className="mt-0"
                defaultActiveKey={activeTab || tabs[0].key}
                activeKey={activeTab}
                onSelect={(key) => setActiveTab(key)}
              >
                <Row>
                  <Col sm={12} className="tabContainer">
                    <Nav variant="pills" className="flex-row">
                      {tabs?.map((tab, index) => {
                        return (
                          <Nav.Item key={index}>
                            <Nav.Link eventKey={tab.key} className="custom-tab">
                              {tab.title}
                            </Nav.Link>
                          </Nav.Item>
                        );
                      })}
                    </Nav>
                  </Col>
                  <Col sm={12}>
                    <Tab.Content>
                      {tabs?.map((tab, index) => {
                        return (
                          <Tab.Pane key={index} eventKey={tab.key}>
                            {activeTab === tab.key && tab.component}
                          </Tab.Pane>
                        );
                      })}
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </Page>
  );
};

export default SupplierDetail;
