import React from "react";
import { Badge } from "react-bootstrap";

import dummyImage from "../../Assets/Images/dummy-jewel.png";

import CONFIG from "../../Config/BaseURL";
import { displayThreeDecimal } from "../../Utils/displayThreeDecimal";
import { showImage } from "../../Utils/empty";

function ProductCard({
  product,
  openAttributeSelection,
  setCurrentAddProduct,
}) {
  return (
    <div
      className={`product-card-wrapper ${
        product.in_stock === 0 ? "disabled" : ""
      }`}
      title={product.title_en}
    >
      <div
        className={`card list-card product-card`}
        onClick={(_) => {
          openAttributeSelection("add", true);
          setCurrentAddProduct(product);
        }}
      >
        {/* {product.in_stock === 0 && (
          <Badge
            bg="danger"
            style={{ fontSize: "1em" }}
            className="sold-out-badge"
          >
            Sold Out
          </Badge>
        )} */}
        <img
          src={showImage(`${CONFIG.IMAGE_ROOT_URL}${product.image_name}`)}
          className="card-img-top"
          alt="cardimage"
        />
        <div className="card-body">
          <p className="card-text">
            {product.title_en?.length > 20
              ? product.title_en.substr(0, 20)
              : product.title_en}
            {product.title_en?.length > 20 ? "..." : ""}
          </p>
          <p className="card-text">{displayThreeDecimal(product.price)}</p>
        </div>
      </div>
    </div>
  );
}

export default ProductCard;
