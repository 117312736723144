import React, { useEffect, useMemo, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { getRequest } from "../../Data/ApiService";
import { ENDPOINTS } from "../../Data/Endpoint";
import PayoutEntryModal from "./PayoutEntryModal";
import Loading from "../Common/Loading";

const PayoutMakingCharge = ({ detail }) => {
  const [loading, setLoading] = useState(false);
  const [purchaseData, setPurchaseData] = useState([]);
  const [isOpenPayoutModal, setIsOpenPayoutModal] = useState(false);
  const [totalMakingCharge, setTotalMakingCharge] = useState(0);
  const [selectedData, setSelectedData] = useState({ payouts: [] });
  const fetchPayout = async () => {
    await getRequest(`${ENDPOINTS.supplierPayout}/${detail?.id}`).then(
      (res) => {
        setLoading(false);
        if (res.status === 200) {
          setPurchaseData(res.data?.data?.payout);
          setTotalMakingCharge(res.data?.data?.total_making_charge);
        }
      }
    );
  };

  useEffect(() => {
    setLoading(true);

    fetchPayout();
    // }, 1000);
  }, []);

  const onSelect = (e, item, key) => {
    if (selectedData[key]?.findIndex((val) => val?.id == item?.id) > -1) {
      setSelectedData((prev) => ({
        ...prev,
        [key]: prev?.[key]?.filter((val) => val?.id !== item?.id),
      }));
    } else {
      setSelectedData((prev) => ({
        ...prev,
        [key]: [...prev[key], { ...item }],
      }));
    }
  };

  const submitPayout = async () => {
    setIsOpenPayoutModal(true);
  };

  return (
    <div className="row">
      {loading ? (
        <div className="col-md-12 mt-4">
          <Loading />
        </div>
      ) : (
        <div className="col-md-12">
          <h5>
            <strong>Total making charge:</strong> {totalMakingCharge}
          </h5>

          <div className="mt-5">
            <h5>Purchase Balance</h5>
            <Table className="table table--orders" bordered>
              <thead>
                <tr>
                  <td>ID</td>
                  <td>Invoice No.</td>
                  <td>Total Making Charges</td>
                  <td>Balance</td>
                  <td>Type</td>
                  <td>Due Date</td>
                  <td>Sold Making Charge</td>
                  <td>Select</td>
                </tr>
              </thead>
              <tbody>
                {purchaseData?.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>{item.invoice_no}</td>
                      <td>{item.total_making_charge}</td>
                      <td>{item.making_charge_balance}</td>
                      <td>{item.purchase_type}</td>
                      <td>{item.due_date || "-"}</td>
                      <td>{item?.sold_mk || "-"}</td>
                      <td>
                        <Form.Check // prettier-ignore
                          type={"checkbox"}
                          onChange={(e) => onSelect(e, item, "payouts")}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          <div className="d-flex justify-content-end">
            <Button variant="secondary" className="me-3">
              Cancel
            </Button>
            <Button
              variant="warning"
              disabled={selectedData.payouts?.length === 0}
              onClick={submitPayout}
            >
              Pay
            </Button>
          </div>
          <PayoutEntryModal
            open={isOpenPayoutModal}
            onHide={() => setIsOpenPayoutModal(false)}
            selectedData={selectedData}
          />
        </div>
      )}
    </div>
  );
};

export default PayoutMakingCharge;
