import React from "react";

import Header from "../Components/Common/Header";
import Footer from "../Components/Common/Footer";
import { ToastContainer } from "react-toastify";

export default function Page({ children }) {
  return (
    <div className="app">
      <div className="flex-shrink">
        <Header />
      </div>
      <div className="flex-expand content-main">{children}</div>
      <div className="flex-shrink">
        <Footer />
      </div>
    </div>
  );
}
