import React from "react";
import Modal from "../Common/Modal";

const BarcodeDialog = ({ src, show, hide }) => {
  return (
    <Modal header={"Barcode"} show={show} onHide={hide} size="sm" centered>
      <div className="py-3">
        <img src={src} />
      </div>
    </Modal>
  );
};

export default BarcodeDialog;
