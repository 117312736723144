import React from "react";
import Form from "react-bootstrap/Form";

const Input = React.forwardRef(
  (
    {
      label,
      placeholder,
      type = "text",
      size = "lg",
      onChange,
      value = "",
      name,
      isRequired = false,
      min,
      error,
      as = "input",
      rows = 1,
      onBlur,
      ...props
    },
    ref
  ) => {
    return (
      <div className="form-group">
        {label && (
          <Form.Label className="mb-0">
            {label} {isRequired && <span className="text-danger">*</span>}
          </Form.Label>
        )}
        <Form.Control
          className={`form-control ${size === "sm" ? "sm" : ""}`}
          type={type}
          size={size}
          name={name}
          ref={ref}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          required={isRequired}
          min={min}
          as={as}
          rows={rows}
          onBlur={onBlur}
          {...props}
        />
        {error && <span className="error-label">{error}</span>}
      </div>
    );
  }
);

export default Input;
