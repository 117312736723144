import React, { useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { getRequest } from "../../Data/ApiService";
import { ENDPOINTS } from "../../Data/Endpoint";
import language from "../../Strings/en";
import Input from "../Common/Input";

const BarcodeForm = ({
  openAttributeSelection,
  setCurrentAddProduct,
  onHide,
}) => {
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({ defaultValues: { barcode: "" } });

  const onSubmit = async (e) => {
    const response = await getRequest(
      `${ENDPOINTS.purchaseFromBarcode}?barcode=${e.barcode}`
    );

    if (response.data?.data?.success) {
      setCurrentAddProduct(response?.data?.data?.purchase[0]);
      openAttributeSelection("add", true);
      onHide();
    }
  };
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);
  return (
    <div className="row">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="col-md-12">
          <Controller
            name={"barcode"}
            control={control}
            rules={{ required: "Barcode is required." }}
            render={({ field }) => (
              <Input
                {...field}
                placeholder={language.barcode}
                label={language.barcode}
                error={errors.barcode?.message}
                ref={inputRef}
              />
            )}
          />
        </div>
        <div className="col-md-12 mt-3 d-flex justify-content-end">
          <Button
            className="btn-free"
            variant="warning"
            type="submit"
            disabled={!isValid}
          >
            Done
          </Button>
        </div>
      </form>
    </div>
  );
};

export default BarcodeForm;
