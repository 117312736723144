import React, { useState } from "react";
import language from "../../Strings/en";
import { Button, Form, Table } from "react-bootstrap";
import { default as CustomeModal } from "../Common/Modal";
import { connect } from "react-redux";
import Input from "../Common/Input";
import { postRequest } from "../../Data/ApiService";
import { ENDPOINTS } from "../../Data/Endpoint";
import { errorToast } from "../../Utils/showToast";
import { useNavigate } from "react-router-dom";

const RefundDialog = ({ isOpen, onHide, cartProducts, orderId }) => {
  const navigate = useNavigate();
  const [carts, setCarts] = useState(
    cartProducts?.map((item) => ({ ...item, refundQty: item?.qty }))
  );

  const [note, setNote] = useState("");
  const handleChange = (id, qty) => {
    const updateCart = carts?.map((item) => {
      if (item?.id === id) {
        return { ...item, refundQty: qty };
      } else {
        return item;
      }
    });
    setCarts(updateCart);
  };

  const isDisabled = carts?.some((item) => item?.refundQty == 0) || !note;

  const [refundedItem, setRefundedItems] = useState([]);

  const toggleRefundedItem = (item) => {
    const index = refundedItem.findIndex((value) => value?.id == item?.id);
    if (index > -1) {
      setRefundedItems((prev) =>
        prev?.filter((value) => value?.id != item?.id)
      );
    } else {
      setRefundedItems((prev) => [...prev, item]);
    }
  };

  const finalRefundOrder = async () => {
    await postRequest(ENDPOINTS.refundOrder, {
      order_id: orderId,
      orderItems: refundedItem?.map((item) => ({
        purchase_id: item?.purchase_id,
        qty: item?.refundQty,
      })),
      refund_note: note,
    }).then((res) => {
      if (res.data.status == 200) {
        navigate(-1);
        onHide();
      } else {
        errorToast(res.data?.data?.msg);
      }
    });
  };

  return (
    <CustomeModal
      show={isOpen}
      onHide={onHide}
      header={
        <div className="d-flex align-items-center w-100">
          <h3>{language.refund}</h3>
        </div>
      }
      alertDialog
      size="lg"
      footer={
        <div style={{ width: "200px" }}>
          {/* <Button variant="secondary">Cancel</Button> */}
          <Button
            variant="warning"
            className="w-100"
            disabled={isDisabled}
            onClick={finalRefundOrder}
          >
            {"Done"}
          </Button>
        </div>
      }
    >
      <div className="row mb-3">
        <div className="col-sm-12" style={{ height: "60vh" }}>
          <Input
            placeholder="Note"
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
          <div className="divider" />
          <Table className="table  mt-4" borderless responsive="md">
            <thead
              style={{ position: "sticky", top: 0, backgroundColor: "#fff" }}
            >
              <tr>
                <th>{language.purchaseName}</th>
                <th width={200}>{language.qty}</th>
              </tr>
            </thead>
            <tbody>
              {carts?.map((item) => {
                return (
                  <tr key={item?.id}>
                    <td>
                      <Form.Check
                        checked={
                          refundedItem.findIndex(
                            (value) => value?.id == item?.id
                          ) > -1
                        }
                        onChange={() => toggleRefundedItem(item)}
                        label={item?.title_en}
                        id={item?.title_en}
                      />
                    </td>
                    <td width={200}>
                      <Input
                        min={1}
                        type="number"
                        placeholder={language.qty}
                        value={item?.refundQty}
                        onChange={(e) => {
                          const re = /^[0-9]*$/;
                          if (re.test(e.target.value)) {
                            handleChange(item?.id, Number(e.target.value));
                          }
                        }}
                        //   error={errors?.variants?.[index]?.totalqty?.message}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </CustomeModal>
  );
};

const mapStateToProps = (state) => {
  return {
    cartProducts: state.cartReducer.products,
    orderId: state.cartReducer.orderId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RefundDialog);
