import React, { useState } from "react";
import Cropper from "react-cropper";

import "cropperjs/dist/cropper.css";
import { Button, ButtonGroup } from "react-bootstrap";

export default function ImageCropper({ src, getCroppedFile, onHide }) {
  const [loading, setLoading] = useState(true);
  const [scaleX, setScaleX] = useState(1);
  const [scaleY, setScaleY] = useState(1);
  const [cropper, setCropper] = useState(null);

  const handleClick = () => {
    const img = cropper.getCroppedCanvas().toDataURL();
    getCroppedFile(img);
    onHide();
  };
  const rotate = () => {
    cropper.rotate(90);
  };
  const flip = (type) => {
    if (type === "h") {
      cropper.scaleX(scaleX === 1 ? -1 : 1);
      setScaleX(scaleX === 1 ? -1 : 1);
    } else {
      cropper.scaleY(scaleY === 1 ? -1 : 1);
      setScaleY(scaleY === 1 ? -1 : 1);
    }
  };

  const [activeRatio, setActiveRatio] = useState(4 / 3);
  const handleChange = (e, value) => {
    setActiveRatio(value);
    cropper.setAspectRatio(value);
  };
  return (
    <>
      {loading && (
        <>
          {/* <Skeleton variant="rectangular" width={"100%"} height={400} /> */}
        </>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* <div display={"flex"} mb={1}>
          <ToggleButtonGroup
            size="small"
            color="primary"
            value={activeRatio}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
          >
            <ToggleButton value={4 / 3}>4:3</ToggleButton>
            <ToggleButton value="dynamic">Freehand</ToggleButton>
          </ToggleButtonGroup>
        </div> */}
        <div className="mb-2">
          <ButtonGroup variant="contained">
            <Button onClick={rotate}>Rotate</Button>
            <Button onClick={() => flip("h")}>Flip H</Button>
            <Button onClick={() => flip("v")}>Flip V</Button>
          </ButtonGroup>
        </div>
      </div>
      <Cropper
        src={src}
        style={{ height: 400, width: "100%" }}
        // Cropper.js options
        guides={false}
        ready={() => {
          setLoading(false);
        }}
        onInitialized={(instance) => {
          setCropper(instance);
        }}
        aspectRatio={activeRatio}
      />
      <div className="mt-4 d-flex justify-content-end">
        <Button
          sx={{
            float: "right",
            mt: 1,
          }}
          onClick={handleClick}
          autoFocus
          variant="primary"
        >
          Crop
        </Button>
      </div>
    </>
  );
}
