import { CART_ACTIONS } from "../../reducers/cartReducer";

export const setOrderNo = (orderNo) => {
  return (dispatch) => {
    dispatch({
      type: CART_ACTIONS.SET_ORDER_NO,
      payload: orderNo,
    });
  };
};
