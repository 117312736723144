export const initialStateSettlement = {
  settlementMethods: [
    { id: 1, payment_type: "Cash" },
    { id: 2, payment_type: "Visa" },
    { id: 3, payment_type: "ATM/Debit Card" },
    { id: 4, payment_type: "NBB" },
    { id: 5, payment_type: "Discover" },
    { id: 6, payment_type: "Master Card" },
    { id: 7, payment_type: "American Express" },
    { id: 8, payment_type: "CRII" },
    { id: 9, payment_type: "Benefit Pay" },
    { id: 10, payment_type: "AUB" },
  ],
  usedSettlementMethods: [],
  loading: false,
};

export const SETTLEMENT_ACTIONS = {
  ADD_SETTLEMENT_MODES: "settlementReducer.ADD_SETTLEMENT_MODES",
  ADD_USED_SETTLEMENT_MODE: "settlementReducer.ADD_USED_SETTLEMENT_MODE",
  UPDATE_SETTLEMENT_MODES: "settlementReducer.UPDATE_SETTLEMENT_MODES",
  UPDATE_USED_SETTLEMENT_MODES: "settlementReducer.UPDATE_USED_SETTLEMENT_MODES",
  REMOVE_USED_SETTLEMENT_MODE: "settlementReducer.REMOVE_USED_SETTLEMENT_MODE",
  RESET_STATE: "settlementReducer.RESET_STATE",
};

export const settlementReducer = (state = initialStateSettlement, action) => {
  switch (action.type) {
    case SETTLEMENT_ACTIONS.ADD_SETTLEMENT_MODES:
      return { ...state, settlementMethods: action.payload };

    case SETTLEMENT_ACTIONS.ADD_USED_SETTLEMENT_MODE:
      return {
        ...state,
        usedSettlementMethods: [...state.usedSettlementMethods, action.payload],
      };

    case SETTLEMENT_ACTIONS.UPDATE_SETTLEMENT_MODES:
      return { ...state, settlementMethods: action.payload };

    case SETTLEMENT_ACTIONS.UPDATE_USED_SETTLEMENT_MODES:
      return { ...state, usedSettlementMethods: action.payload };

    case SETTLEMENT_ACTIONS.REMOVE_USED_SETTLEMENT_MODE:
      let usedSettlementMethods = [...state.usedSettlementMethods];
      usedSettlementMethods.splice(
        usedSettlementMethods.findIndex((p) => p.id === action.payload.id),
        1
      );

      return { ...state, usedSettlementMethods: [...usedSettlementMethods] };

    case SETTLEMENT_ACTIONS.SET_LOADING:
      return { ...state, loading: action.payload.loading };

    case SETTLEMENT_ACTIONS.RESET_STATE:
      return initialStateSettlement;

    default:
      return state;
  }
};
