import React from 'react'

const colors = ["#CA7D6C", "#B6A84D", "#2C9C9C", "#2C5D9C", "#54137F"]

const Stats = ({ title, count, col }) => {
    const random = Math.random() * 5
    return (
        <div className={`${col} px-2`} >
            <div className="shadow-sm px-3 py-4" style={{ border: `1px solid ${colors[Math.floor(random)]}77` }}>
                <h5 style={{ color: colors[Math.floor(random)] }}>{title}</h5>
                <strong style={{ color: colors[Math.floor(random)] }}>{count}</strong>
            </div>
        </div>
    )
}

export default React.memo(Stats)