import { CART_ACTIONS } from "../../reducers/cartReducer";

export const updateCart = (update) => {
  return (dispatch) => {
    dispatch({
      type: CART_ACTIONS.UPDATE_CART,
      payload: update,
    });
  };
};
