import language from "../../Strings/en";

export const initialStateCustomerDetails = {
  customer_name: "",
  email: "",
  phone_number: "",
  address_line1: "",
  address_line2: "",
  city: "",
  country: language.countryBahrain,
  zipcode: "",
  id: 0,
  loading: false,
};

export const CUSTOMER_DETAILS_ACTIONS = {
  ADD_DETAILS: "customerDetailsActions.ADD_DETAILS",
  UPDATE_DETAILS: "customerDetailsActions.UPDATE_DETAILS",
  SET_LOADING: "customerDetailsActions.SET_LOADING",
  RESET_STATE: "customerDetailsActions.RESET_STATE",
};

export const customerDetailsReducer = (
  state = initialStateCustomerDetails,
  action
) => {
  switch (action.type) {
    case CUSTOMER_DETAILS_ACTIONS.ADD_DETAILS:
      return { ...state, ...action.payload };

    case CUSTOMER_DETAILS_ACTIONS.UPDATE_DETAILS:
      return { ...state, ...action.payload };

    case CUSTOMER_DETAILS_ACTIONS.SET_LOADING:
      return { ...state, loading: action.payload.loading };

    case CUSTOMER_DETAILS_ACTIONS.RESET_STATE:
      return initialStateCustomerDetails;

    default:
      return state;
  }
};
