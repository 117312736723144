import { CATEGORY_ACTIONS } from '../../reducers/categoryReducer';

export const setSelectedCategory = (categoryId) => {
  return (dispatch) => {
    dispatch({
      type: CATEGORY_ACTIONS.SET_SELECTED_CATEGORY,
      payload: {
        categoryId,
      },
    });
  };
};
