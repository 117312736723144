import React from "react";

function PaymentMode({ mode, setSelectedMode, selectedMode, setIsModalOpen }) {
  return (
    <div
      onClick={(_) => (setSelectedMode(mode), setIsModalOpen(true))}
      className={`payment-mode ${
        mode.label === selectedMode ? "selected" : ""
      }`}
    >
      {mode.payment_type}
    </div>
  );
}

export default PaymentMode;
