import React, { useEffect, useState } from "react";
import Page from "../Layout/Page";
import language from "../Strings/en";
import { Button, Table } from "react-bootstrap";
import CreateBarForm from "../Components/Bars/CreateBarForm";
import { useNavigate } from "react-router-dom";
import { deleteRequest, getRequest } from "../Data/ApiService";
import { ENDPOINTS } from "../Data/Endpoint";
import { BiEdit, BiTrash } from "react-icons/bi";
import AlertDialog from "../Components/Common/AlertDialog";
import { errorToast, successToast } from "../Utils/showToast";
import CreateBarModal from "../Components/Bars/CreateBarModal";
import { BARS_TYPE } from "../Config";

const Bars = () => {
  const data = [
    {
      id: "1",
      note: "Demo",
      customer_name: "ABC",
    },
  ];
  const navigate = useNavigate();
  const showDetailPage = (bars) => {
    navigate(`/bars/${bars?.id}`, {
      state: {
        bars,
      },
    });
  };

  const [bars, setBars] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState({
    visibility: false,
    data: {},
  });

  const fetchBars = async () => {
    await getRequest(`${ENDPOINTS.getGoldBars}`).then((res) => {
      if (res.status === 200) {
        setBars(res.data?.data?.goldbar);
      }
    });
  };

  useEffect(() => {
    fetchBars();
  }, []);

  const [showAlert, setShowAlert] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const deleteGoldBar = async () => {
    let res = await deleteRequest(`${ENDPOINTS.deleteGoldBars}/${deleteId}`);
    if (res.data?.data?.success) {
      successToast(res.data?.data?.message);
      fetchBars();
    } else {
      errorToast(res?.data?.data?.message);
    }
    setShowAlert(false);
  };

  const openModalForEdit = (value) => {};

  return (
    <Page>
      <div className="container-fluid background-grey">
        <div className="row">
          <div className="col-sm-12 mb-4">
            <div className="row align-items-center">
              <div className="col-md-3">
                <h1>{language.dbBars}</h1>
              </div>
              <div className="col-md-9 "></div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="background-white">
              <div className="d-flex align-items-center justify-content-between">
                <h5 className="mt-2">{language.dbBars}</h5>
                <Button
                  variant="secondary"
                  onClick={() => setIsModalOpen({ visibility: true, data: {} })}
                >
                  Create Bar
                </Button>
              </div>

              <Table
                className="table table--attachment mt-4"
                bordered
                striped
                responsive={true}
              >
                <thead>
                  <tr>
                    <td>{language.name}</td>
                    <td>{language.type}</td>
                    <td>{language.action}</td>
                  </tr>
                </thead>
                <tbody>
                  {bars?.map((value) => {
                    return (
                      <tr key={value.id}>
                        <td
                          className="hyperlink"
                          onClick={() => showDetailPage(value)}
                        >
                          {value?.name || "-"}
                        </td>
                        <td>{BARS_TYPE[value?.type] || "-"}</td>
                        <td width={"10%"}>
                          <div className="d-flex flex-row justify-content-center align-items-center">
                            <Button
                              size="sm"
                              variant="secondary"
                              className="d-flex align-items-center justify-content-center circle action-btn-table me-2 "
                              onClick={() => {
                                showDetailPage(value);
                              }}
                            >
                              <BiEdit />
                            </Button>
                            <Button
                              size="sm"
                              variant="danger"
                              className="d-flex align-items-center justify-content-center circle action-btn-table"
                              onClick={() => {
                                setDeleteId(value?.id);
                                setShowAlert(true);
                              }}
                            >
                              <BiTrash />
                            </Button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            <CreateBarModal
              responseAction={fetchBars}
              open={isModalOpen.visibility}
              data={isModalOpen.data}
              onHide={() => setIsModalOpen({ visibility: false, data: {} })}
            />
          </div>
          {/* <div className="col-sm-7">
            <div className="background-white sticky-div">
              <h5 className="mt-2">Create</h5>
            </div>
          </div> */}
        </div>
      </div>
      <AlertDialog
        show={showAlert}
        successAction={deleteGoldBar}
        title={language.deleteBar}
        cancleAction={() => setShowAlert(false)}
        closeButton={false}
      />
    </Page>
  );
};

export default Bars;
