import React, { useEffect, useState } from "react";
import { FaDownload, FaTrash } from "react-icons/fa";
import { deleteRequest, postRequest } from "../../Data/ApiService";
import { ENDPOINTS } from "../../Data/Endpoint";
import CONFIG from "../../Config/BaseURL";
import { errorToast, successToast } from "../../Utils/showToast";

const acceptFileType = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/pdf",
];

const UploadDocuments = ({ purchaseData, isEdit, actionOnPurchase }) => {
  const [files, setFiles] = useState(purchaseData?.supplier_files || []);

  useEffect(() => {
    setFiles(purchaseData?.supplier_files || []);
  }, [purchaseData?.supplier_files]);
  const handleFile = async (e) => {
    if (e.target.value.length > 0) {
      for (var i = 0; i < e.target.files.length; i++) {
        if (acceptFileType.includes(e.target.files[i].type)) {
          const formData = new FormData();
          formData.append("file_name", e.target.files[i]);
          await postRequest(
            `${ENDPOINTS.uploadPurchaseFiles}/${purchaseData?.id}`,
            formData
          )
            .then((res) => {
              if (res.status === 200) {
                e.target.value = null;
                actionOnPurchase();
                setFiles((prev) => [res.data?.data?.supplier_file, ...prev]);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          e.target.value = null;
          errorToast("Only supported file types are excel or pdf.");
        }
      }
    }
  };
  const onRemoveFile = async (fileObj) => {
    await deleteRequest(`${ENDPOINTS.deletePurchaseFiles}/${fileObj.id}`).then(
      (res) => {
        if (res.status === 200) {
          successToast(res.data?.data.msg);
          setFiles(files.filter((file) => file.id !== fileObj.id));
          actionOnPurchase();
        }
      }
    );
  };
  return (
    <div className="row">
      <div className="col-md-6">
        <input
          type="file"
          className="form-control"
          multiple
          onChange={handleFile}
          accept={acceptFileType.join(", ")}
          onClick={(e) => {
            if (!purchaseData?.id) {
              alert("Please create purchase first.");
              e.preventDefault();
            }
          }}
        />
        <div>
          {files.map((file, index) => {
            return (
              <div className="fileItem">
                <span>{file.file_name.replace("products/", "")}</span>
                <button
                  onClick={() => onRemoveFile(file)}
                  className="removeFileIcon"
                >
                  <FaTrash />
                </button>
                <a
                  href={`${CONFIG.IMAGE_ROOT_URL}${file.file_name}`}
                  download={true}
                  target="_blank"
                  className="fileDownloadIcon"
                >
                  <FaDownload />
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default UploadDocuments;
