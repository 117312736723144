import { initialStateCategories } from '../Store/reducers/categoryReducer';
import { initialStateProducts } from '../Store/reducers/productReducer';
import { initialStateUser } from '../Store/reducers/userReducer';
import { initialStateCart } from '../Store/reducers/cartReducer';

export const getLocalStore = (_) => {
  return localStorage.getItem('app')
    ? JSON.parse(localStorage.getItem('app'))
    : {
        categoryReducer: { ...initialStateCategories },
        cartReducer: { ...initialStateCart },
        productReducer: { ...initialStateProducts },
        userReducer: { ...initialStateUser },
      };
};
