import React, { useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import { FILE_TYPE } from "../../Config";
import CONFIG from "../../Config/BaseURL";
import { deleteRequest, getRequest, postRequest } from "../../Data/ApiService";
import { ENDPOINTS } from "../../Data/Endpoint";
import { errorToast, successToast } from "../../Utils/showToast";
import UploadFile from "../Common/UploadFile";

const types = {
  supplier: {
    get: ENDPOINTS.getSupplierFiles,
    delete: ENDPOINTS.deleteSupplierFiles,
    upload: ENDPOINTS.uploadSupplierFiles,
    keyName: "supplier_file",
    urlReplaceName: "supplier_attachments/",
  },
  bars: {
    get: ENDPOINTS.goldBarAttachments,
    delete: ENDPOINTS.deleteGoldBarAttachments,
    upload: ENDPOINTS.uploadGoldBarAttachments,
    keyName: "bar_file",
    urlReplaceName: "goldbar/",
  },
};

const Attachments = ({ detail, keyName }) => {
  const [files, setFiles] = useState([]);
  const [fileType, setFileType] = useState(FILE_TYPE[0]);
  const detailRef = useRef(detail);

  useEffect(() => {
    detailRef.current = detail;
  }, [detail]);

  const fetchFiles = async () => {
    await getRequest(`${types[keyName].get}/${detail?.id}`)
      .then((res) => {
        setFiles(res.data?.data?.files || []);
      })
      .catch((err) => {
        errorToast(err?.res?.data?.msg);
      });
  };

  useEffect(() => {
    if (detailRef.current?.id) {
      fetchFiles();
    }
  }, []);

  const onRemoveFile = async (fileObj) => {
    await deleteRequest(`${types[keyName].delete}/${fileObj.id}`).then(
      (res) => {
        if (res.status === 200) {
          successToast(res.data?.data.msg);
          setFiles(files.filter((file) => file.id !== fileObj.id));
        }
      }
    );
  };
  const handleFile = async ({ file }) => {
    const formData = new FormData();
    formData.append("file_name", file);
    //   formData.append("file_type", fileType);
    await postRequest(
      `${types[keyName].upload}/${detailRef.current?.id}`,
      formData
    )
      .then((res) => {
        if (res.status === 200) {
          // actionOnPurchase();
          setFiles((prev) => [
            res.data?.data?.[types[keyName].keyName],
            ...prev,
          ]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="">
      <div className="d-flex  flex-column">
        {/* <div className="w-20 me-4">
          <select
            className="form-control"
            value={fileType}
            onChange={(e) => setFileType(e.target.value)}
          >
            {FILE_TYPE?.map((type) => {
              return (
                <option value={type} key={type}>
                  {type}
                </option>
              );
            })}
          </select>
        </div> */}
        <UploadFile onChange={handleFile} detail={detail} />
      </div>

      <div className="mt-4 ">
        {files && files?.length > 0 && (
          <Table bordered responsive className="table table--attachment">
            <thead className="table-dark">
              <tr>
                <td>Name</td>
                <td width={"30%"}>Type</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              {files?.map((file) => {
                return (
                  <tr key={file?.id}>
                    <td width={"70%"}>
                      {file?.file_name?.replace(
                        types[keyName].urlReplaceName,
                        ""
                      )}
                    </td>
                    <td width={"30%"}>{file?.file_type || "-"}</td>
                    <td>
                      <div className="d-flex">
                        <button
                          className="btn btn-outline-danger btn-free btn-sm btn-action me-2"
                          onClick={() => onRemoveFile(file)}
                        >
                          Delete
                        </button>
                        <a
                          href={`${CONFIG.IMAGE_ROOT_URL}${file.file_name}`}
                          download={true}
                          target="_blank"
                          className="fileDownloadIcon btn btn-outline-secondary btn-free btn-sm btn-action"
                        >
                          Download
                        </a>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  );
};

export default Attachments;
