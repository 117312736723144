export const initialStatePrice = {
  loading: false,
  dailyPrice: [],
};

export const PRICE_ACTIONS = {
  SET_LOADING: "priceReducer.SET_LOADING",
  SET_DAILY_PRICE: "priceReducer.SET_DAILY_PRICE",
  RESET_STATE: "priceReducer.RESET_STATE",
};

export const priceReducer = (state = initialStatePrice, action) => {
  switch (action.type) {
    case PRICE_ACTIONS.SET_LOADING:
      return { ...state, loading: action.payload.loading };

    case PRICE_ACTIONS.SET_DAILY_PRICE:
      return { ...state, dailyPrice: action.payload };

    case PRICE_ACTIONS.RESET_STATE:
      return initialStatePrice;

    default:
      return state;
  }
};
