import React, { useRef } from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import language from "../../Strings/en";
import { default as CustomeModal } from "../Common/Modal";
import ProductForm from "./ProductForm";

const CreateProductModal = (props) => {
  const {
    isCreateModalOpen,
    hideCreateModal,
    purchaseData,
    isEdit,
    actionOnPurchase,
    storeId,
  } = props;
  const purchaseRef = useRef(null);

  const submit = () => {
    purchaseRef.current.submit();
  };

  const reset = () => {
    hideCreateModal();
    // purchaseRef.current.resetFields();
  };

  return (
    <CustomeModal
      show={isCreateModalOpen}
      onHide={reset}
      header={<h3>{language.createProduct}</h3>}
    >
      <ProductForm {...props} ref={purchaseRef} />
    </CustomeModal>
  );
};

const mapStateToProps = (state) => {
  return {
    variants: state.userReducer.variants,
    storeId: state.userReducer.store_id,
  };
};

export default connect(mapStateToProps)(CreateProductModal);
