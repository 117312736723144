import React from "react";

export default function BlankRow({ message }) {
  return (
    <tr>
      <td colSpan={10} className="text-center">
        <strong>{message ? message : "No data available"}</strong>
      </td>
    </tr>
  );
}
