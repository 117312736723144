export const initialStateProducts = {
  products: [],
  loading: false,
};

export const PRODUCT_ACTIONS = {
  ADD_PRODUCTS: "productReducer.ADD_PRODUCTS",
  SET_LOADING: "productReducer.SET_LOADING",
  RESET_STATE: "productReducer.RESET_STATE",
};

export const productReducer = (state = initialStateProducts, action) => {
  switch (action.type) {
    case PRODUCT_ACTIONS.ADD_PRODUCTS:
      return { ...state, products: [...action.payload] };

    case PRODUCT_ACTIONS.SET_LOADING:
      return { ...state, loading: action.payload.loading };

    case PRODUCT_ACTIONS.RESET_STATE:
      return initialStateProducts;

    default:
      return state;
  }
};
