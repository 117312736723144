import { USER_ACTIONS } from "../../reducers/userReducer";

export const updateUser = (update) => {
  return (dispatch) => {
    dispatch({
      type: USER_ACTIONS.UPDATE_USER,
      payload: update,
    });
  };
};
