import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Table } from "react-bootstrap";
import BackButton from "../Components/Common/BackButton";
import DatePicker from "../Components/Common/DatePicker";
import Input from "../Components/Common/Input";
import LoadingBtn from "../Components/Common/Button";
import Loading from "../Components/Common/Loading";
import Page from "../Layout/Page";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../Data/ApiService";
import { ENDPOINTS } from "../Data/Endpoint";
import { isEmpty } from "../Utils/empty";
import Stats from "../Components/Common/Stats";
import language from "../Strings/en";
import useStats from "../Hooks/useStats";
import CustomerDetailsModal from "../Components/Cart/CustomerDetailsModal";
import { BiEdit, BiTrash } from "react-icons/bi";
import AlertDialog from "../Components/Common/AlertDialog";
import withOrderNavigation from "../Hoc/withOrderNavigation";
import { successToast } from "../Utils/showToast";
import { useNavigate } from "react-router-dom";

const data = [
  {
    id: 1,
    name: "ABC",
    address: "XYZ",
    phone: "1234567891",
    createdAt: Date(),
  },
];

function Customers({ handleFetchOrderDetails }) {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [hasMorePages, setHasMorePages] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [filter, setFilter] = useState({
    search: "",
    from: "",
    to: "",
  });

  const [isCustomerDetailOpen, setIsCustomerDetailOpen] = useState(false);

  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    allCustomers();
  }, [page, filter]);

  const allCustomers = async () => {
    setLoading(true);
    let res = await getRequest(ENDPOINTS.listCustomer, {
      page: page,
      ...(!isEmpty(filter.search) && { name: filter.search }),
      ...(!isEmpty(filter.from) && { from: filter.from }),
      ...(!isEmpty(filter.to) && { to: filter.to }),
    });
    setLoading(false);
    if (page > 1) {
      setCustomers((prev) => [...prev, ...res.data.data.customers]);
    } else {
      setCustomers(res.data.data.customers);
    }
    setHasMorePages(res.data.data.hasMorePages === 0 ? false : true);
  };

  const loadMore = () => {
    setPage(page + 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilter((prev) => ({ ...prev, [name]: value }));
    setPage(1);
  };
  const { stats } = useStats("customer");
  const [searchString, setSearchString] = useState("");
  const triggerSearch = (e) => {
    e.preventDefault();
    setFilter((prev) => ({ ...prev, search: searchString }));
    setPage(1);
  };

  const defaulValue = {
    customer_name: "",
    phone_number: "",
    address_line1: "",
    address_line2: "",
    city: "",
    country: "",
    zipcode: "",
    cpr_number: "",
    anniversary: "",
    date_of_birth: "",
    value: "",
    label: "",
  };
  const [customerDetails, setCustomerDetails] = useState(defaulValue);
  const handleCustomerDetails = (e) => {
    setCustomerDetails({
      ...customerDetails,
      [e.target.name]: e.target.value,
    });
  };

  const [deleteId, setDeleteId] = useState(null);

  const saveCustomerDetails = async (_) => {
    if (customerDetails?.id) {
      let custId = customerDetails?.id;
      await putRequest(ENDPOINTS.updateCustomer + custId, {
        customer_name: customerDetails.customer_name,
        phone_number: customerDetails.phone_number,
        address_line1: customerDetails.address_line1,
        address_line2: customerDetails.address_line2,
        city: customerDetails.city,
        country: customerDetails.country,
        zipcode: customerDetails.zipcode,
        cpr_number: customerDetails.cpr_number
          ? customerDetails.cpr_number
          : "",
        anniversary: customerDetails.anniversary,
        date_of_birth: customerDetails.date_of_birth,
      }).then((res) => {
        if (res.status === 200) {
          const filteredCustomer = customers.map((customer) =>
            customer.id === res.data?.data?.customer?.id
              ? res.data?.data?.customer
              : customer
          );
          setCustomers(filteredCustomer);
          successToast(res.data?.data?.msg);
          // setCustomerDetails(defaulValue);
        }
      });
    } else {
      if (customerDetails?.customer_name) {
        await postRequest(ENDPOINTS.createCustomer, {
          customer_name: customerDetails.customer_name,
          phone_number: customerDetails.phone_number,
          address_line1: customerDetails.address_line1,
          address_line2: customerDetails.address_line2,
          city: customerDetails.city,
          country: customerDetails.country,
          zipcode: customerDetails.zipcode,
          cpr_number: customerDetails.cpr_number
            ? customerDetails.cpr_number
            : "",
          anniversary: customerDetails.anniversary,
          date_of_birth: customerDetails.date_of_birth,
        }).then((res) => {
          if (res.status === 200) {
            setCustomers((prev) => [res.data?.data?.customer, ...prev]);
            setCustomerDetails((prev) => ({
              ...prev,
              id: res.data?.data?.customer?.id,
            }));
            successToast(res.data?.data?.msg);
          }
        });
      }
    }
  };

  const deleteCustomer = async () => {
    let res = await deleteRequest(`${ENDPOINTS.deleteCustomer}/${deleteId}`);
    if (res.status === 200) {
      setCustomers(customers.filter((customer) => customer.id !== deleteId));
      successToast(res.data?.data?.msg);
    }
    setShowAlert(false);
  };

  const navigate = useNavigate();

  const showDetailPage = (customer) => {
    navigate(`/customers/${customer?.id}`, {
      state: {
        customer: {
          ...customer,
        },
      },
    });
  };

  return (
    <Page>
      <div className="container-fluid mt-2">
        <div className="row">
          <div className="col-sm-12 row mb-3">
            <Stats
              title="Total Customers"
              count={stats?.total_customer}
              col="col-md-4"
            />
            <Stats
              title="Total Orders"
              count={stats?.total_orders}
              col="col-md-4"
            />
            <Stats
              title="Total Amount"
              count={stats?.total_amount}
              col="col-md-4"
            />
          </div>
          <div className="col-sm-12">
            <div className="row align-items-center mb-4 ">
              <div className="col-md-3">
                <h1>Customers</h1>
              </div>
              <div className="col-md-3 ">
                <form onSubmit={triggerSearch}>
                  <Input
                    placeholder="Search Customer"
                    label="Search"
                    name="search"
                    value={searchString}
                    onChange={(e) => setSearchString(e.target.value)}
                  />
                </form>
              </div>
              <div className="col-md-2">
                <DatePicker
                  label="From"
                  name="from"
                  value={filter.from}
                  onChange={handleChange}
                  size={"xl"}
                />
              </div>
              <div className="col-md-2">
                <DatePicker
                  label="To"
                  name="to"
                  value={filter.to}
                  onChange={handleChange}
                  size={"xl"}
                />
              </div>
              <div className="col-md-2">
                <Button
                  variant="secondary mt-4"
                  onClick={() => {
                    setCustomerDetails(defaulValue);
                    setIsCustomerDetailOpen(true);
                  }}
                >
                  {language.createCustomer}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Table className="table table--orders" striped bordered hover>
              <thead>
                <tr>
                  <td>{language.customerName}</td>
                  <td>{language.customerOrder}</td>
                  <td>{language.customerAmount}</td>
                  <td>{language.customerPhoneNumber}</td>
                  <td>{language.customerCPR}</td>
                  <td>{language.customerAnniversary}</td>
                  <td width={150}>{language.customerDateOfBirth}</td>
                  <td>{language.customerCountry}</td>
                  <td>{language.customerAddress}</td>
                  <td>{language.customerCreatedDate}</td>
                  <td>{language.createdBy}</td>
                  <td>{language.action}</td>
                </tr>
              </thead>
              <tbody>
                {customers.length > 0 ? (
                  customers.map((customer) => (
                    <tr key={customer?.id}>
                      <td
                        // className="hyperlink"
                        onClick={() => showDetailPage(customer)}
                      >
                        {customer?.customer_name
                          ? customer?.customer_name
                          : "--"}
                      </td>
                      <td
                        className="hyperlink"
                        onClick={() =>
                          handleFetchOrderDetails(customer?.order_id)
                        }
                      >
                        {customer?.order_no}
                      </td>
                      <td>{customer?.total ? customer?.total : "-"}</td>
                      <td>
                        {customer?.phone_number ? customer?.phone_number : "--"}
                      </td>
                      <td>{customer?.cpr_number}</td>
                      <td>
                        {customer?.anniversary ? customer?.anniversary : "-"}
                      </td>
                      <td>
                        {customer?.date_of_birth
                          ? customer?.date_of_birth
                          : "-"}
                      </td>
                      <td>{customer?.country ? customer?.country : "-"}</td>
                      <td>
                        {customer?.address_line1
                          ? customer?.address_line1
                          : "--"}
                      </td>
                      <td>{customer?.created_at} </td>
                      <td>{customer?.created_by} </td>
                      <td>
                        <div className="d-flex flex-row justify-content-center align-items-center">
                          <Button
                            size="sm"
                            variant="secondary"
                            className="d-flex align-items-center justify-content-center circle action-btn-table"
                            onClick={() => {
                              setCustomerDetails({ ...customer });
                              setIsCustomerDetailOpen(true);
                            }}
                          >
                            <BiEdit />
                          </Button>
                          <Button
                            size="sm"
                            variant="danger"
                            className="d-flex align-items-center justify-content-center circle action-btn-table"
                            onClick={() => {
                              setDeleteId(customer?.id);
                              setShowAlert(true);
                            }}
                          >
                            <BiTrash />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    {loading ? (
                      <td colSpan={10}>
                        <Loading />
                      </td>
                    ) : (
                      <td colSpan={10} align="center">
                        No records found
                      </td>
                    )}
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
        {hasMorePages && (
          <div style={{ textAlign: "center" }}>
            <LoadingBtn onClick={loadMore}>Load More</LoadingBtn>
          </div>
        )}
        {loading && page > 1 && <Loading />}
      </div>
      {isCustomerDetailOpen && (
        <CustomerDetailsModal
          isCustomerDetailOpen={isCustomerDetailOpen}
          setIsCustomerDetailOpen={setIsCustomerDetailOpen}
          customerDetails={customerDetails}
          handleCustomerDetails={handleCustomerDetails}
          saveCustomerDetails={saveCustomerDetails}
          setCustomerDetails={setCustomerDetails}
        />
      )}
      <AlertDialog
        show={showAlert}
        successAction={deleteCustomer}
        title={language.deleteAlertCustomer}
        cancleAction={() => setShowAlert(false)}
        closeButton={false}
      />
    </Page>
  );
}

export default withOrderNavigation(Customers);
