import React, { useState } from "react";
import { Button, NavLink, Table } from "react-bootstrap";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";

import { displayThreeDecimal } from "../../Utils/displayThreeDecimal";

import language from "../../Strings/en";

import { ENDPOINTS } from "../../Data/Endpoint";
import { deleteRequest, getRequest, putRequest } from "../../Data/ApiService";

import { resetCartState } from "../../Store/actions/cart/resetCartState";
import { resetCustomerState } from "../../Store/actions/customerDetails/resetCustomerState";
import { resetCategoryState } from "../../Store/actions/category/resetCategoryState";
import { resetProductState } from "../../Store/actions/product/resetProductState";
import { addTaxDetails } from "../../Store/actions/cart/addTaxDetails";
import { successToast } from "../../Utils/showToast";
import AlertDialog from "../Common/AlertDialog";
import { isEmpty } from "../../Utils/empty";
import { ORDER_STATUS } from "../../Config";
import RefundDialog from "../AttributeSelectors/RefundDialog";
import { setIsReopenOrder } from "../../Store/actions/cart/setIsReopenOrder";
import SelectComponent from "../Common/SelectComponent";
import { debounce } from "../../Utils/search";
import { addCustomerDetails } from "../../Store/actions/customerDetails/addCustomerDetails";
function Bill({
  subTotal,
  total,
  discount,
  tax,
  taxName,
  setIsSettleOpen,
  isSettleOpen,
  setIsCustomerDetailOpen,
  orderId,
  resetCartState,
  resetCustomerState,
  resetCategoryState,
  resetProductState,
  addTaxDetails,
  products,
  orderStatus,
  isReOpenOrder,
  setIsReopenOrder,
  addCustomerDetails,
  customerDetail,
}) {
  const navigate = useNavigate();
  const [isOpenRefundModal, setIsOpenRefundModal] = useState(false);

  const handleBillSaveAction = async (_) => {
    const res = await putRequest(ENDPOINTS.updateOrder, {
      subtotal: displayThreeDecimal(subTotal),
      tax: displayThreeDecimal(tax),
      total: total > 0 ? displayThreeDecimal(total) : total,
      order_id: orderId,
    });
    if (res.data.data.success) {
      successToast(res.data.data.msg);
      resetCartState();
      resetCustomerState();
      resetCategoryState();
      resetProductState();
      navigate("/dashboard");
    } else {
      setShowAlert(true);
    }
  };

  const successAction = async (_) => {
    const res = await deleteRequest(`${ENDPOINTS.removeOrder}${orderId}`);
    if (res.data.status === 200) {
      successToast(res.data.data.msg);
      resetCartState();
      resetCustomerState();
      resetCategoryState();
      resetProductState();
      navigate("/dashboard");
    }
  };

  const [showAlert, setShowAlert] = useState(false);
  const [options, setOptions] = useState([]);

  const triggerSearch = async (search) => {
    if (!isEmpty(search)) {
      // setSearchLoading(true);
      let res = await getRequest(ENDPOINTS.listCustomer, {
        ...(!isEmpty(search) && {
          name: search,
        }),
      });
      // setSearchLoading(false);
      if (res.data.data.success) {
        setOptions(
          res.data?.data?.customers?.map((customer) => ({
            ...customer,
            key: customer?.id,
            label: customer?.customer_name,
          }))
        );
        // searchRef.current.setShowOption(true);
      }
    }
  };

  const searching = async (value) => {
    debounce(triggerSearch(value));
  };

  const addNewCustomer = () => {
    setIsCustomerDetailOpen(true);
  };
  const onSelect = (customer) => {
    addCustomerDetails(customer);
  };

  return (
    <div className="bill">
      <div className="section-title px-2 py-1">
        <p className="m-0  d-flex align-items-center justify-content-between  bill__title  text-uppercase">
          {language.billInfo}

          <div>
            <SelectComponent
              width={"200px"}
              placeholder={"Search Customer"}
              onSearch={searching}
              options={options}
              addNewButton={true}
              addNewAction={addNewCustomer}
              onChange={onSelect}
            />
          </div>

          {/* <NavLink
          className="text-white text-decoration-underline d-block ms-auto"
          onClick={(_) => setIsCustomerDetailOpen(true)}
        >
          Add Customer Details
        </NavLink> */}
        </p>

        {customerDetail.customer_name && (
          <p className="m-0 d-flex flex-column">
            <span>Name: {customerDetail.customer_name}</span>
            {customerDetail.cpr_number && (
              <span>CPR: {customerDetail.cpr_number}</span>
            )}
          </p>
        )}
      </div>

      <Table
        borderless
        className={`bill__list table--bill m-0 ${
          isSettleOpen ? "table-hight-change" : "table-hight"
        }`}
      >
        <tbody>
          <tr>
            <td>{language.billSubTotal}</td>
            <td style={{ textAlign: "right" }}>
              {displayThreeDecimal(subTotal)}
            </td>
          </tr>
          {total > 0 && (
            <>
              <tr>
                <td>{language.billDisc}</td>
                <td style={{ textAlign: "right" }}>
                  {displayThreeDecimal(discount)}
                </td>
              </tr>
              <tr>
                <td>{taxName}</td>
                <td style={{ textAlign: "right" }}>
                  {displayThreeDecimal(tax)}
                </td>
              </tr>
            </>
          )}
          <tr className="row--total">
            <td>
              <h4>{language.billTotal}</h4>
            </td>
            <td style={{ textAlign: "right" }}>
              <h4>
                {language.billCurrency} {displayThreeDecimal(total)}
              </h4>
            </td>
          </tr>
        </tbody>
      </Table>
      {isSettleOpen ? (
        <></>
      ) : (
        <>
          <div className="bill__actions">
            <Button
              variant="secondary"
              className="text-uppercase w-100"
              onClick={() => {
                if (isEmpty(products)) {
                  setShowAlert(true);
                } else {
                  navigate("/dashboard");
                }
              }}
            >
              {language.cancel}
            </Button>
            <Button
              variant="warning"
              className="text-uppercase w-100"
              onClick={() =>
                orderStatus === ORDER_STATUS.orderSettled
                  ? setIsOpenRefundModal(true)
                  : handleBillSaveAction()
              }
            >
              {orderStatus === ORDER_STATUS.orderSettled
                ? language.refund
                : language.placeOrder}
            </Button>
            <Button
              variant="danger"
              className="text-uppercase w-100"
              onClick={(_) => {
                if (
                  orderStatus === ORDER_STATUS.orderSettled &&
                  isReOpenOrder === false
                ) {
                  setIsReopenOrder(true);
                } else {
                  setIsSettleOpen(true);
                  addTaxDetails({
                    tax: tax,
                    subTotal: subTotal,
                    discount: discount,
                    total: total,
                    taxName,
                  });
                }
              }}
            >
              {orderStatus === ORDER_STATUS.orderSettled &&
              isReOpenOrder === false
                ? language.reopen
                : language.billSettle}
            </Button>
          </div>
        </>
      )}
      <AlertDialog
        show={showAlert}
        successAction={successAction}
        title={language.alertMessage}
        cancleAction={() => setShowAlert(false)}
      />

      <RefundDialog
        isOpen={isOpenRefundModal}
        onHide={() => setIsOpenRefundModal(false)}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    total: state.cartReducer.total,
    tax: state.cartReducer.tax,
    discount: state.cartReducer.discount,
    subTotal: state.cartReducer.subTotal,
    taxName: state.cartReducer.taxName,
    products: state.cartReducer.products,
    orderStatus: state.cartReducer.orderStatus,
    isReOpenOrder: state.cartReducer.isReOpenOrder,
    customerDetail: state.customerDetailsReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetCartState: bindActionCreators(resetCartState, dispatch),
    resetCustomerState: bindActionCreators(resetCustomerState, dispatch),
    resetCategoryState: bindActionCreators(resetCategoryState, dispatch),
    resetProductState: bindActionCreators(resetProductState, dispatch),
    addTaxDetails: bindActionCreators(addTaxDetails, dispatch),
    setIsReopenOrder: bindActionCreators(setIsReopenOrder, dispatch),
    addCustomerDetails: bindActionCreators(addCustomerDetails, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Bill));
