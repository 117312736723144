import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { BiEdit, BiTrash } from "react-icons/bi";
import { connect } from "react-redux";
import BackButton from "../Components/Common/BackButton";
import LoadingBtn from "../Components/Common/Button";
import Input from "../Components/Common/Input";
import Loading from "../Components/Common/Loading";
import SelectSearch from "../Components/Common/SelectSearch";
import ProductForm from "../Components/ProductPage/ProductForm";
import CONFIG from "../Config/BaseURL";
import { deleteRequest, getRequest, postRequest } from "../Data/ApiService";
import { ENDPOINTS } from "../Data/Endpoint";
import useCategories from "../Hooks/useCategories";
import useStats from "../Hooks/useStats";
import useSuppliers from "../Hooks/useSuppliers";
import useVariants from "../Hooks/useVariants";
import Page from "../Layout/Page";
import language from "../Strings/en";
import { isEmpty } from "../Utils/empty";
import AlertDialog from "../Components/Common/AlertDialog";
import Switch from "../Components/Common/Switch";
import CreatePurchaseModal from "../Components/Purchase/CreatePurchaseModal";
import CreateProductModal from "../Components/ProductPage/CreateProductModal";
import { successToast } from "../Utils/showToast";

const Products = ({ storeId }) => {
  const [createModal, setCreateModal] = useState({
    visibility: false,
    isEdit: false,
  });
  const [page, setPage] = useState(1);
  const [purchases, setPurchases] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMorePages, setHasMorePages] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [editPurchase, setEditPurchase] = useState({});
  const [filters, setFilters] = useState({
    keyword: "",
    category: "",
  });

  const fetchPurchases = async (loadPage) => {
    setPage(loadPage);

    setLoading(true);
    const res = await getRequest(ENDPOINTS.getProducts, {
      page: loadPage,
      storeId,
      ...(!isEmpty(filters.keyword) && { keyword: filters.keyword }),
      ...(filters.category && { group_id: filters.category }),
    });

    if (res.status === 200) {
      setLoading(false);
      if (loadPage > 1) {
        setPurchases((prev) => [...prev, ...(res?.data?.data?.products || [])]);
      } else {
        setPurchases(res?.data?.data?.products || []);
      }
      setHasMorePages(res?.data?.data?.hasMorePages === 0 ? false : true);
    }
  };
  useEffect(() => {
    fetchPurchases(page);
  }, [filters]);

  const loadMore = () => {
    fetchPurchases(page + 1);
  };

  const openModalForEdit = (product) => {
    setEditPurchase({
      id: product?.id,
      code: product?.code,
      title_en: product.title_en,
      group_id: product.group_id,
      variants: product?.variants || [],
      making_charge: product?.making_charge,
      price_type: product?.price_type,
      // image_name: !product?.image_name?.includes("placeholder")
      //   ? `${CONFIG.IMAGE_ROOT_URL}${product.image_name}`
      //   : null,
      image_name:
        !product?.image_name || product?.image_name?.includes("placeholder")
          ? null
          : `${CONFIG.IMAGE_ROOT_URL}${product.image_name}`,
      active: product?.active,
      producttype: product?.producttype,
      sku: product?.sku,
      producttype: product?.producttype,
      track_inventory: product?.track_inventory,
      barcode_number: product?.barcode_number,
      price: product?.price,
      carat_id: product?.carat_id,
    });
    setCreateModal({ visibility: true, isEdit: true });
  };

  const actionOnPurchase = (newPurchase, isEdit) => {
    fetchPurchases(1);
    setCreateModal({ isEdit: false });
    setEditPurchase({});
  };

  const { sizes, carats, metals } = useVariants();
  const { suppliers } = useSuppliers();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
    setPage(1);
  };

  const { stats } = useStats("purchase");
  const { categories } = useCategories();

  const [showAlert, setShowAlert] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const deleteProduct = async () => {
    let res = await deleteRequest(`${ENDPOINTS.deleteProductItem}/${deleteId}`);
    if (res.status === 200) {
      actionOnPurchase();
      successToast(res.data?.data?.msg);
    }
    setShowAlert(false);
  };

  return (
    <Page>
      <div className="container-fluid background-grey">
        <div className="row">
          {/* <div className="col-sm-12 mb-3">
            <div className="d-flex">
              <Stats
                title="Total Purchases"
                count={stats?.total_purchases}
                col="flex-fill"
              />
              <Stats
                title="Total Rate Amount"
                count={stats?.total_rate_amount}
                col="flex-fill"
              />
              <Stats
                title="Total Weight"
                count={stats?.total_weight}
                col="flex-fill"
              />
              <Stats title="Total MK" count={stats?.total_mk} col="flex-fill" />
              <Stats
                title="Total QTY"
                count={stats?.total_quantity}
                col="flex-fill"
              />
            </div>
          </div> */}
          <div className="col-sm-12 mb-4">
            <div className="row align-items-center">
              {/* <div className="col-md-3">
                <h1>{language.dbProduct}</h1>
              </div> */}
              <div className="col-md-9"></div>
            </div>
          </div>
        </div>
        <div className="row gx-4">
          <div className="col-md-12 ">
            <div className="background-white">
              <h4 className=" mt-2">Products</h4>

              <div className="row align-items-end mb-5">
                <div className="col-md-4">
                  <Input
                    name="keyword"
                    type="text"
                    placeholder={"Search"}
                    value={filters.keyword}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-4">
                  {/* {moment(filters.from).format("YYYY-MM-DD")} */}
                  <SelectSearch
                    placeholder="Category"
                    options={categories}
                    size="lg"
                    value={
                      typeof filters.category === "string"
                        ? filters.category
                        : categories
                            ?.find((category) =>
                              category?.groups?.some(
                                (group) => group?.id === filters.category
                              )
                            )
                            ?.groups?.find(
                              (group) => group?.id === filters.category
                            ).label
                    }
                    label="Category"
                    treeView={true}
                    onSelect={(value) =>
                      handleChange({
                        target: { name: "category", value: value.value },
                      })
                    }
                    isRequired
                    searchable={false}
                  />
                </div>

                <div className="col-md-4 d-flex justify-content-end">
                  <Button
                    variant="secondary"
                    onClick={() =>
                      setCreateModal({ visibility: true, isEdit: false })
                    }
                  >
                    {language.createProduct}
                  </Button>
                </div>
              </div>
              <Table className="table table--orders" responsive={true} striped>
                <thead>
                  <tr>
                    <td>{language.purchaseName}</td>
                    <td>{language.code}</td>
                    {/* <td>{language.type}</td> */}
                    {/* <td>{language.carat}</td> */}

                    <td>{language.sku}</td>
                    {/* <td>{language.price}</td> */}

                    <td>{language.purchaseCategory}</td>
                    {/* <td>{language.variants}</td> */}
                    <td>{language.createdAt}</td>
                    <td>{language.creator}</td>
                    <td>{language.active}</td>
                    <td>{language.action}</td>
                  </tr>
                </thead>
                <tbody>
                  {purchases.length > 0 ? (
                    purchases.map((o) => (
                      <tr
                        key={o?.id}
                        // className={
                        //   editPurchase?.id === o?.id ? "active-row" : ""
                        // }
                      >
                        <td>{o?.title_en || "-"}</td>
                        <td>{o?.code || "-"}</td>
                        {/* <td>
                          {o?.producttype == 1
                            ? language.simpleProduct
                            : language.productWithVariants || "-"}
                        </td> */}
                        {/* <td>
                          {carats?.find((item) => item.id == o?.carat_id)
                            ?.carat || "-"}
                        </td> */}
                        <td>{o?.sku || "-"}</td>
                        {/* <td>{o?.price_type == 1 ? "Fixed" : "Market"}</td> */}

                        <td>{o?.category_name || "-"}</td>
                        {/* <td>{o?.variants?.length || "-"}</td> */}
                        <td>{o?.created}</td>
                        <td>{o?.created_by || "-"}</td>
                        <td>
                          <Switch checked={o?.active} />
                        </td>
                        <td>
                          <div className="d-flex flex-row justify-content-center align-items-center">
                            <Button
                              size="sm"
                              variant="secondary"
                              className="d-flex align-items-center justify-content-center circle action-btn-table me-2"
                              onClick={() => {
                                openModalForEdit(o);
                              }}
                            >
                              <BiEdit />
                            </Button>
                            <Button
                              size="sm"
                              variant="danger"
                              type="button"
                              className="d-flex align-items-center justify-content-center circle action-btn-table"
                              onClick={() => {
                                setDeleteId(o?.id);
                                setShowAlert(true);
                              }}
                            >
                              <BiTrash />
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      {loading ? (
                        <td colSpan={13}>
                          <Loading />
                        </td>
                      ) : (
                        <td colSpan={13}>No records found</td>
                      )}
                    </tr>
                  )}
                </tbody>
              </Table>
              {hasMorePages && (
                <div style={{ textAlign: "center" }}>
                  <LoadingBtn onClick={loadMore}>Load More</LoadingBtn>
                </div>
              )}
              {loading && page > 1 && <Loading />}
            </div>

            <CreateProductModal
              isModal={true}
              isCreateModalOpen={createModal.visibility}
              hideCreateModal={() =>
                setCreateModal({ visibility: false, isEdit: false })
              }
              isEdit={createModal.isEdit}
              purchaseData={editPurchase}
              setEditPurchase={setEditPurchase}
              actionOnPurchase={actionOnPurchase}
            />
          </div>
          <AlertDialog
            show={showAlert}
            successAction={deleteProduct}
            title={language.deleteProduct}
            cancleAction={() => setShowAlert(false)}
            closeButton={false}
          />
          {/* <div className="col-md-6">
            <div className="background-white sticky-div">
              <div className="d-flex justify-content-between align-items-center mt-2 mb-5">
                <h4> {createModal.isEdit ? "View" : "Add"}</h4>
                <Button
                  variant="secondary btn-lowerCase"
                  onClick={() => {
                    setCreateModal({ visibility: true, isEdit: false });
                    setEditPurchase({});
                  }}
                >
                  {language.create}
                </Button>
              </div>
              <ProductForm
                purchaseData={editPurchase}
                isEdit={createModal.isEdit}
                actionOnPurchase={actionOnPurchase}
                setEditPurchase={setEditPurchase}
              />
            </div>
          </div> */}
        </div>
      </div>
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    storeId: state.userReducer.store_id,
  };
};

export default connect(mapStateToProps)(Products);
