import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ProductCard from "./ProductCard";

import { getRequest, postRequest } from "../../Data/ApiService";
import { ENDPOINTS } from "../../Data/Endpoint";

import { setLoading } from "../../Store/actions/product/setLoading";
import { setProducts } from "../../Store/actions/product/setProducts";
import Loading from "../Common/Loading";
import { setPage } from "../../Store/actions/category/setPage";

function Products({
  openAttributeSelection,
  setCurrentAddProduct,
  products,
  loading,
  selectedCategoryId,
  setProducts,
  setLoading,
  storeId,
  setPage,
  page,
}) {
  const [hasMore, setHasMore] = useState(false);
  // const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, []);

  useEffect(
    (_) => {
      if (selectedCategoryId !== null) {
        (async (_) => {
          setLoading(true);
          let res = await getRequest(ENDPOINTS.getProducts, {
            group_id: selectedCategoryId,
            page,
            storeId,
          });
          if (res.status === 200) {
            if (page > 1) {
              setProducts([...products, ...res.data?.data?.products]);
            } else {
              setProducts(res.data?.data?.products);
            }
            setHasMore(res.data?.data?.hasMorePages === 0 ? false : true);
            setLoading(false);
          }
        })();
      }
    },
    // eslint-disable-next-line
    [selectedCategoryId, setProducts, setLoading, storeId, page]
  );

  const handler = (e) => {
    console.log(
      e.target.scrollHeight,
      e.target.offsetHeight,
      e.target.scrollBottom
    );
    if (e.target.scrollTop === e.target.scrollHeight - e.target.offsetHeight) {
      if (hasMore) {
        setPage(page + 1);
      }
    }
  };
  const handlerTouchpad = (e) => {
    var isTouchPad = e.wheelDeltaY
      ? e.wheelDeltaY === -3 * e.deltaY
      : e.deltaMode === 0;
    if (!isTouchPad) {
      ref.current.scrollLeft += e.deltaY;
    }
  };
  useEffect(() => {
    ref.current?.addEventListener("scroll", handler);

    ref.current?.addEventListener("wheel", handlerTouchpad);
    // list?.addEventListener("DOMMouseScroll", handlerTouchpad);
    return () => {
      ref.current?.removeEventListener("scroll", handler);
      // eslint-disable-next-line
      ref.current?.removeEventListener("wheel", handlerTouchpad);
      // list?.removeEventListener("DOMMouseScroll", handlerTouchpad);
    };
    // eslint-disable-next-line
  }, [hasMore]);

  const ref = useRef(null);

  return (
    <div style={{ position: "relative" }}>
      {loading && (
        <div className="loader-wrapper-2">
          <Loading />
        </div>
      )}
      <div
        ref={ref}
        className={`list ${products.length > 12 ? "grid-column" : "grid-row"}`}
      >
        {products.map((p) => (
          <ProductCard
            openAttributeSelection={openAttributeSelection}
            setCurrentAddProduct={setCurrentAddProduct}
            product={p}
            key={p?.id}
          />
        ))}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    products: state.productReducer.products,
    storeId: state.userReducer.store_id,
    selectedCategoryId: state.categoryReducer.selectedCategoryId,
    page: state.categoryReducer.page,
    loading: state.productReducer.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setProducts: bindActionCreators(setProducts, dispatch),
    setLoading: bindActionCreators(setLoading, dispatch),
    setPage: bindActionCreators(setPage, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
