import React, { useState } from "react";
import SelectComponent from "./SelectComponent";
import language from "../../Strings/en";
import { isEmpty } from "../../Utils/empty";
import { getRequest, postRequest, putRequest } from "../../Data/ApiService";
import { ENDPOINTS } from "../../Data/Endpoint";
import CustomerDetailsModal from "../Cart/CustomerDetailsModal";
import { debounce } from "lodash";
import { successToast } from "../../Utils/showToast";

const CustomerDropdown = React.forwardRef(
  ({ value, onChange, error, ...props }, ref) => {
    const [options, setOptions] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const [isCustomerDetailOpen, setIsCustomerDetailOpen] = useState(false);
    const [customerDetails, setCustomerDetails] = useState({
      customer_name: "",
      phone_number: "",
      address_line1: "",
      address_line2: "",
      city: "",
      country: "",
      zipcode: "",
      cpr_number: "",
      anniversary: "",
      date_of_birth: "",
    });

    const triggerSearch = async (search) => {
      if (!isEmpty(search)) {
        setSearchLoading(true);
        let res = await getRequest(ENDPOINTS.listCustomer, {
          ...(!isEmpty(search) && {
            name: search,
          }),
        });
        setSearchLoading(false);
        if (res.data.data.success) {
          setOptions(
            res.data?.data?.customers?.map((customer) => ({
              ...customer,
              value: customer?.id,
              label: customer?.customer_name,
            }))
          );
        }
      }
    };
    const searching = debounce((value) => {
      if (value === "") {
        setOptions([]);
      }
      triggerSearch(value);
    }, 400);

    const addNewCustomer = () => {
      setIsCustomerDetailOpen(true);
    };
    const onSelect = (customer) => {
      onChange(customer);
    };

    const saveCustomerDetails = async () => {
      if (customerDetails?.id) {
        let custId = customerDetails?.id;
        await putRequest(ENDPOINTS.updateCustomer + custId, {
          customer_name: customerDetails.customer_name,
          phone_number: customerDetails.phone_number,
          address_line1: customerDetails.address_line1,
          address_line2: customerDetails.address_line2,
          city: customerDetails.city,
          country: customerDetails.country,
          zipcode: customerDetails.zipcode,
          cpr_number: customerDetails.cpr_number
            ? customerDetails.cpr_number
            : "",
          anniversary: customerDetails.anniversary,
          date_of_birth: customerDetails.date_of_birth,
        });
      } else {
        let res = await postRequest(ENDPOINTS.createCustomer, {
          customer_name: customerDetails.customer_name,
          phone_number: customerDetails.phone_number,
          address_line1: customerDetails.address_line1,
          address_line2: customerDetails.address_line2,
          city: customerDetails.city,
          country: customerDetails.country,
          zipcode: customerDetails.zipcode,
          cpr_number: customerDetails.cpr_number
            ? customerDetails.cpr_number
            : "",
          anniversary: customerDetails.anniversary,
          date_of_birth: customerDetails.date_of_birth,
        });

        if (res.status === 200) {
          successToast(res.data?.data?.msg);
          onChange({
            ...res.data?.data?.customer,
            value: res.data?.data?.customer?.id,
            label: res.data?.data?.customer?.customer_name,
          });
        }
      }
    };

    return (
      <>
        <SelectComponent
          label={language.customer}
          isRequired={true}
          placeholder={"Search Customer"}
          onSearch={searching}
          options={options}
          addNewButton={true}
          addNewAction={addNewCustomer}
          onChange={onSelect}
          loading={searchLoading}
          value={value}
          error={error}
          ref={ref}
        />
        <CustomerDetailsModal
          isCustomerDetailOpen={isCustomerDetailOpen}
          setIsCustomerDetailOpen={setIsCustomerDetailOpen}
          customerDetails={customerDetails}
          saveCustomerDetails={saveCustomerDetails}
          setCustomerDetails={setCustomerDetails}
        />
      </>
    );
  }
);

export default CustomerDropdown;
