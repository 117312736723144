import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import language from "../../Strings/en";
import { isEmpty } from "../../Utils/empty";
import AccordionCustom from "./AccrodianCustom";

const SelectSearch = forwardRef(
  (
    {
      value = "",
      options = [],
      onSearch,
      actionOnAddButton,
      onSelect,
      style,
      searchable = true,
      displayButton = false,
      placeholder,
      size = "sm",
      label,
      treeView = false,
      isRequired = false,
      forDropdown = true,
      renderItem,
      hasMore,
      loadMore,
      clearAction,
      error,
    },
    ref
  ) => {
    const [showOption, setShowOption] = useState(false);
    const [search, setSearch] = useState("");
    const [optionsState, setOptions] = useState(options);

    useEffect(() => {
      setOptions(options);
      // setOptions from props
    }, [options?.length]);

    useEffect(() => {
      setSearch(value);
    }, [value]);

    const Select = (value) => {
      setSearch(value.label); // set searchable string to text input
      !treeView && setOptions([]);
      treeView && setShowOption(false); // clear options
      onSelect && onSelect(value); // if props has onSelect method then pass selected value to onSelect method
    };
    const Searching = (e) => {
      const { value } = e.target;
      if (onSearch) {
        //is onSearch method received from props then pass value to it
        onSearch(value);
      } else {
        if (isEmpty(value)) {
          setOptions(options); //if search string empty then set default options
        } else {
          //search the options through its label and set it into options array
          setOptions(
            optionsState.filter((option) =>
              option.label.toLowerCase().includes(value.toLowerCase())
            )
          );
        }
      }

      setSearch(value);
    };

    useEffect(() => {
      document.addEventListener("click", handleClick, true);
      return () => {
        document.removeEventListener("click", handleClick, true);
      };

      //add event listener to container for showing options on click
    }, []);

    const divRef = useRef(null);
    const handleClick = (e) => {
      if (divRef.current && !divRef.current.contains(e.target)) {
        setShowOption(false); //if click outside the container
      } else {
        setShowOption(true); //if click inside the container
      }
    };

    const handler = (e) => {
      if (
        e.target?.offsetHeight + e.target?.scrollTop >=
        e.target?.scrollHeight
      ) {
        if (hasMore) {
          loadMore();
        }
      }
    };
    useEffect(() => {
      const myDiv = document?.getElementById("optionContainer");
      myDiv?.addEventListener("scroll", handler);
      return () => {
        myDiv?.removeEventListener("scroll", handler);
        // eslint-disable-next-line
        // list?.removeEventListener("DOMMouseScroll", handlerTouchpad);
      };
    }, [hasMore]);

    useImperativeHandle(ref, () => ({
      setShowOption: setShowOption,
    }));

    return (
      <div ref={divRef}>
        {label && (
          <label className="custom-select-search-label">
            {label}
            {isRequired && <span className="text-danger">*</span>}
          </label>
        )}
        <div className="custom-select-search" style={style}>
          <div className="input-container">
            <input
              ref={ref}
              style={size === "lg" ? { paddingBlock: 13 } : {}}
              type="text"
              value={search}
              onChange={Searching}
              className="form-control"
              placeholder={placeholder}
              readOnly={!searchable}
            />
            {!isEmpty(search) && (
              <span
                onClick={() => {
                  setSearch("");
                  setShowOption(false);
                  forDropdown ? setOptions(options) : setOptions([]);
                  treeView && onSelect({ value: null });
                  clearAction && clearAction();
                }}
              >
                &times;
              </span>
            )}
          </div>
          {showOption && (
            <div
              className="select-items-container"
              style={{ top: size === "lg" ? "50px" : "35px" }}
            >
              <div className="select-items" id="optionContainer">
                {!treeView ? (
                  optionsState?.map((option) => (
                    <span key={option?.key} onClick={() => Select(option)}>
                      {renderItem ? renderItem(option) : option?.label}
                    </span>
                  ))
                ) : (
                  <AccordionCustom onSelect={Select} data={optionsState} />
                )}
              </div>
              {displayButton && (
                <span className="button" onClick={actionOnAddButton}>
                  {language.purchageOrderCreate} +
                </span>
              )}
            </div>
          )}
        </div>
        <span className="error-label">{error}</span>
      </div>
    );
  }
);

export default SelectSearch;
