import React, { useEffect } from "react";
import { Button } from "react-bootstrap";

export default function LoadingBtn(props) {
  useEffect(() => {}, [props.loading]);
  const style = props.fullWidth ? { width: "100%", margin: "10px 0px" } : {};
  return (
    <Button
      color={props.type}
      variant="contained"
      style={style}
      onClick={props.onClick}
      disabled={props.disabled || props.loading}
      // endIcon={props.loading ?  /> : <></>}
    >
      {props.children}
    </Button>
  );
}
