import React, { useState } from "react";
import { Button } from "react-bootstrap";
import language from "../../Strings/en";
import { MdAddCircleOutline } from "react-icons/md";
import { FaPlus } from "react-icons/fa";

const DivBorderContainer = ({
  title,
  children,
  className,
  isRequired,
  addButton,
  variantCount,
}) => {
  return (
    <>
      <div
        className={"d-flex  align-items-center mb-1 justify-content-between"}
      >
        <span className="border-container-with-title-span">
          {title} {isRequired && <span className="text-danger ">*</span>}
          {/* display label for div if is required true then display (*) */}
        </span>
        {addButton && (
          <Button
            variant="default"
            onClick={() => variantCount()}
            className={
              "d-flex justify-content-between align-items-center  p-2 add-btn"
            }
          >
            <FaPlus /> &nbsp;Add Variant
          </Button>
        )}
      </div>
      <>
        <div className={`border-container-with-title ${className}`}>
          {children}
          {/* wrapping children */}
        </div>
      </>
    </>
  );
};

export default DivBorderContainer;
