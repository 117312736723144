import React, { useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";

import { HiChevronLeft } from "react-icons/hi";
import logo from "../../Assets/Images/logo-icon.png";
import { resetCartState } from "../../Store/actions/cart/resetCartState";
import { resetCategoryState } from "../../Store/actions/category/resetCategoryState";
import { resetCustomerState } from "../../Store/actions/customerDetails/resetCustomerState";
import { resetProductState } from "../../Store/actions/product/resetProductState";
import { resetSettlementState } from "../../Store/actions/settlement/resetSettlementState";
import configureStore from "../../Store/store";
import language from "../../Strings/en";
import { convertToBD } from "../../Utils/convertToBD";
import { displayThreeDecimal } from "../../Utils/displayThreeDecimal";
import { isEmpty } from "../../Utils/empty";
import { getLocalStore } from "../../Utils/getLocalStore";
import AlertDialog from "./AlertDialog";

function Header({
  loading,
  goldPrice,
  orderNo,
  products,
  resetCartState,
  resetCategoryState,
  resetCustomerState,
  resetProductState,
  resetSettlementState,
  dailyPrice,
}) {
  const store = configureStore(getLocalStore());
  const resetStats = () => {
    resetCartState();
    resetCategoryState();
    resetCustomerState();
    resetProductState();
    resetSettlementState();
    navigate("/dashboard");
  };
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark header">
      <div className="container-fluid">
        <button
          className="btn btn-default back-btn"
          onClick={() => {
            navigate(-1);
          }}
        >
          <HiChevronLeft color="#fff" />
        </button>
        <span
          to="/dashboard"
          className="navbar-brand pt-0"
          onClick={(_) => {
            if (location.pathname === "/sale") {
              if (isEmpty(products)) {
                setShowAlert(true);
              } else {
                navigate("/dashboard");
              }
            } else {
              navigate("/dashboard");
            }
          }}
        >
          <img className="header-logo" alt="Logo" src={logo} />
        </span>

        <div className="collapse navbar-collapse " id="mobileNav">
          <ul className="navbar-nav mb-2 mb-lg-0">
            <li className="nav-item selection-header-text">
              <strong className="ms-2">
                <span>
                  {dailyPrice &&
                    `${dailyPrice
                      .map(
                        (item, ind) =>
                          `${item.carat_name} ${displayThreeDecimal(
                            item.goldrate_per_gm.split("BHD")[0]
                          )} BHD`
                      )
                      .join(" | ")}`}
                </span>
              </strong>
            </li>
          </ul>
          <ul className="navbar-nav  ms-auto mb-2 mb-lg-0">
            <li className="nav-item selection-header-text">
              Today's Price {"  "}
              <strong className="ms-2">
                <span>
                  {loading
                    ? "Loading price..."
                    : `${displayThreeDecimal(convertToBD(goldPrice))} BD`}
                </span>
              </strong>
            </li>

            {orderNo && (
              <li className="nav-item selection-header-text">
                &nbsp;|&nbsp;<strong>OrderNo: {orderNo}</strong>
              </li>
            )}
          </ul>
        </div>
      </div>
      <AlertDialog
        show={showAlert}
        successAction={resetStats}
        title={language.alertMessage}
        cancleAction={() => setShowAlert(false)}
      />
    </nav>
  );
}

const mapStateToProps = (state) => {
  return {
    goldPrice: state.cartReducer.goldPrice,
    loading: state.cartReducer.loading,
    orderNo: state.cartReducer.orderNo,
    products: state.cartReducer.products,
    dailyPrice: state.priceReducer.dailyPrice,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    resetCartState: bindActionCreators(resetCartState, dispatch),
    resetCustomerState: bindActionCreators(resetCustomerState, dispatch),
    resetCategoryState: bindActionCreators(resetCategoryState, dispatch),
    resetProductState: bindActionCreators(resetProductState, dispatch),
    resetSettlementState: bindActionCreators(resetSettlementState, dispatch),
  };
};

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(Header),
  (prevProps, nextProps) =>
    JSON.stringify(prevProps) === JSON.stringify(nextProps)
);
