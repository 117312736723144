import React from "react";

export default function Loading() {
  return (
    // <div className='loader'>
    //   <div className='lds-ellipsis'>
    //     <div></div>
    //     <div></div>
    //     <div></div>
    //     <div></div>
    //   </div>
    // </div>
    <div className="text-center">
      <div className="spinner-border text-warning " role="status" />
    </div>
  );
}
