import { CART_ACTIONS } from '../../reducers/cartReducer';

export const addTaxDetails = (taxUpdates) => {
  return (dispatch) => {
    dispatch({
      type: CART_ACTIONS.ADD_TAX_DETAILS,
      payload: { ...taxUpdates },
    });
  };
};
