export const ORDER_STATUS = {
  orderSettled: 5,
  orderRefund: 9,
};

export const DATE_FORMAT = {
  normal: "YYYY-MM-DD",
};

export const acceptFileType = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/pdf",
];

export const acceptImageType = ["image/jpg", "image/jpeg", "image/png"];

export const FILE_TYPE = [
  "CPR Attachment",
  "Invoice",
  "PO",
  "Receipt",
  "Payment",
  "Insurance",
  "Others",
];

export const BARS_TYPE = {
  Customer: "Scrape",
  Supplier: "Bar",
  customer: "Scrape",
  supplier: "Bar",
};
