import { CART_ACTIONS } from "../../reducers/cartReducer";

export const removeProduct = (id) => {
  return (dispatch) => {
    dispatch({
      type: CART_ACTIONS.REMOVE_PRODUCT,
      payload: {
        id,
      },
    });
  };
};
