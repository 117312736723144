import { CART_ACTIONS } from '../../reducers/cartReducer';

export const setOrderId = (orderId) => {
  return (dispatch) => {
    dispatch({
      type: CART_ACTIONS.SET_ORDER_ID,
      payload: orderId,
    });
  };
};
