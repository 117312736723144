import { Button, Modal } from "react-bootstrap";

import language from "../../Strings/en";

function GiftWrapModal({
  isGiftWrapModalOpen,
  setIsGiftWrapModalOpen,
  giftMessage,
  giftInstructions,
  errors,
  handleGiftWrapChange,
  setIsGiftWrap,
}) {
  return (
    <Modal
      show={isGiftWrapModalOpen}
      onHide={(_) => {
        !giftMessage && setIsGiftWrap(false);
        setIsGiftWrapModalOpen(false);
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{language.giftWrapModalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="form-group">
            <label>{language.giftWrapMessge}</label>
            <textarea
              type="text"
              name="giftMessage"
              className="form-control"
              placeholder={language.giftWrapMessge}
              value={giftMessage}
              onChange={handleGiftWrapChange}
              rows={3}
            />
            {errors.giftMessage && (
              <p className="error">{errors.giftMessage}</p>
            )}
          </div>
          <div className="form-group">
            <label>{language.giftWrapInstructions}</label>
            <textarea
              type="text"
              name="giftInstructions"
              className="form-control"
              placeholder={language.giftWrapInstructions}
              value={giftInstructions}
              onChange={handleGiftWrapChange}
              rows={3}
            />
            {errors.giftInstructions && (
              <p className="error">{errors.giftInstructions}</p>
            )}
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={(_) => {
            !giftMessage && setIsGiftWrap(false);
            setIsGiftWrapModalOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="warning"
          disabled={!giftMessage}
          onClick={(_) => setIsGiftWrapModalOpen(false)}
        >
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default GiftWrapModal;
