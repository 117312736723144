import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { connect } from "react-redux";
import BackButton from "../Components/Common/BackButton";
import LoadingBtn from "../Components/Common/Button";
import Loading from "../Components/Common/Loading";
import StockDetailModal from "../Components/StockReport/StockDetailModal";
import { getRequest } from "../Data/ApiService";
import { ENDPOINTS } from "../Data/Endpoint";
import Page from "../Layout/Page";
import language from "../Strings/en";
import { exportToExcel } from "../Utils/exportExcel";
import { Link } from "react-router-dom";
import DatePicker from "../Components/Common/DatePicker";
import { isEmpty } from "../Utils/empty";
import Input from "../Components/Common/Input";
import DivBorderContainer from "../Components/Common/DivBorderContainer";

const StockReport = ({ storeId }) => {
  const [page, setPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState({
    visibility: false,
    uuid: null,
  });
  const [stockReport, setStockReport] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMorePages, setHasMorePages] = useState(false);
  const [filter, setFilter] = useState({
    from: "",
    ref_id: "",
    to: "",
    type: "",
  });

  const fetchStock = async (page) => {
    setLoading(true);
    const res = await getRequest(ENDPOINTS.getStockReport, {
      ...(!isEmpty(filter.from) && { from: filter.from }),
      ...(!isEmpty(filter.to) && { to: filter.to }),
      ...(!isEmpty(filter.ref_id) && { ref_no: filter.ref_id }),
    });

    if (res.status === 200) {
      setLoading(false);
      setStockReport(res.data?.data?.stockreport);
      //   if (page > 1) {
      //     setStocks((prev) => [...prev, ...res?.data?.data?.store?.products]);
      //   } else {
      //     setStocks(res?.data?.data?.store?.products);
      //   }
      //   setHasMorePages(
      //     res?.data?.data?.store?.hasMorePages === 0 ? false : true
      //   );
    }
  };
  useEffect(() => {
    fetchStock(1);
  }, [filter]);

  const loadMore = () => {
    fetchStock(page + 1);
    setPage(page + 1);
  };

  const exportReport = async (uuid) => {
    const res = await getRequest(`${ENDPOINTS.getStockDetail}?uuid=${uuid}`);
    if (res.status === 200) {
      setLoading(false);
      exportToExcel({
        exportData: { "Stock Report": res.data?.data?.stockreport },
        fileName: "Stock Report",
        skipDefaultTitle: false,
      });
    }
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    setFilter((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <Page>
      <div className="container mt-2">
        <div className="row">
          <div className="col-sm-12 d-flex mb-3">
            {/* <Stats
              title="Total Pcs in Stock"
              count={stats?.total_pcs_instock}
              col="flex-fill"
            />
            <Stats
              title="Total Weight/Amount in Stock"
              count={stats?.total_weight_in_stock}
              col="flex-fill"
            />
            <Stats
              title="Total MK in Stock"
              count={stats?.total_mk_in_stock}
              col="flex-fill"
            />
            <Stats
              title="Total Weight/Amount Sold"
              count={stats?.total_weith_sold}
              col="flex-fill"
            /> */}
          </div>
          <div className="col-sm-12">
            <div className="row align-items-center mb-4">
              <div className="col-md-12">
                <div className="d-flex justify-content-between align-items-end">
                  <h1>{language.stockReport}</h1>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="d-flex align-items-end">
                        <div className=" d-flex align-items-end">
                          <div>
                            <DatePicker
                              minDate={new Date()}
                              label="From"
                              name="from"
                              value={filter.from}
                              onChange={handleChange}
                              className={"me-1"}
                            />
                          </div>
                          <div>
                            <DatePicker
                              minDate={new Date()}
                              label="To"
                              name="to"
                              value={filter.to}
                              onChange={handleChange}
                              className={"me-1"}
                            />
                          </div>
                          <div className={"me-1"}>
                            <Input
                              type="text"
                              label={language.refNo}
                              placeholder={language.refNo}
                              name="ref_id"
                              value={filter.ref_id}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="d-flex flex-column me-1">
                          <DivBorderContainer title={language.type}>
                            <div className="row">
                              <div className="col-lg-5 col-sm-4">
                                <Form.Check
                                  type="radio"
                                  id="manual"
                                  label={language.stockModalManual}
                                  name="type"
                                  value="Manual"
                                  onChange={handleChange}
                                  className="me-2"
                                  checked={filter.type === "Manual"}
                                />
                              </div>
                              <div className="col-lg-4 col-sm-4">
                                <Form.Check
                                  type="radio"
                                  id="rfid"
                                  label={language.rfId}
                                  name="type"
                                  value="RFID"
                                  onChange={handleChange}
                                  checked={filter.type === "RFID"}
                                />
                              </div>
                            </div>
                          </DivBorderContainer>
                        </div>

                        <Link to={"/stocktake"} className="btn btn-warning ">
                          {language.addNew}
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-8"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Table
              className="table table--orders"
              striped
              bordered
              hover
              responsive="md"
            >
              <thead>
                <tr>
                  <td>{language.stockTakeDate}</td>
                  <td>{language.expectedQty}</td>
                  <td>{language.expectedWeight}</td>
                  <td>{language.actualQty}</td>
                  <td>{language.actualWeight}</td>
                  <td>{language.createdBy}</td>
                  <td>{language.refNo}</td>
                  <td>{language.type}</td>
                  <td>{language.action}</td>
                </tr>
              </thead>
              <tbody>
                {stockReport.length > 0 ? (
                  stockReport.map((report) => (
                    <tr key={report?.date}>
                      <td
                        className="hyperlink"
                        onClick={() =>
                          setIsModalOpen({
                            visibility: true,
                            uuid: report?.uuid,
                          })
                        }
                      >
                        {report?.date || "-"}
                      </td>
                      <td>{report?.system_quantity || "-"}</td>
                      <td>{report?.system_weight || "-"}</td>
                      <td>{report?.quantity || "-"}</td>
                      <td>{report?.total_weight || "-"}</td>
                      <td>{report?.created_by}</td>
                      <td>{report?.ref_no || "-"}</td>
                      <td>{report?.stocktake || "-"}</td>
                      <td>
                        <div className="d-flex">
                          <Button
                            size="sm"
                            variant="secondary"
                            className="d-flex mx-1 align-items-center justify-content-center "
                            onClick={() =>
                              setIsModalOpen({
                                visibility: true,
                                uuid: report?.uuid,
                              })
                            }
                          >
                            View
                          </Button>
                          <Button
                            size="sm"
                            variant="primary"
                            className="d-flex align-items-center justify-content-center "
                            onClick={() => exportReport(report?.uuid)}
                          >
                            Export
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    {loading ? (
                      <td colSpan={11}>
                        <Loading />
                      </td>
                    ) : (
                      <td colSpan={11}>No records found</td>
                    )}
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
        {hasMorePages && (
          <div style={{ textAlign: "center" }}>
            <LoadingBtn onClick={loadMore}>Load More</LoadingBtn>
          </div>
        )}
        {loading && page > 1 && <Loading />}
      </div>

      {isModalOpen.visibility && (
        <StockDetailModal
          isOpenModal={isModalOpen.visibility}
          uuid={isModalOpen.uuid}
          hideModal={() => setIsModalOpen({ visibility: false, uuid: null })}
        />
      )}
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    storeId: state.userReducer.store_id,
  };
};
export default connect(mapStateToProps)(StockReport);
