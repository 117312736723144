import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import LoadingBtn from "../Components/Common/Button";
import Loading from "../Components/Common/Loading";
import BarcodeDialog from "../Components/Inventory/BarcodeDialog";
import { getRequest } from "../Data/ApiService";
import { ENDPOINTS } from "../Data/Endpoint";
import useCategories from "../Hooks/useCategories";
import useSuppliers from "../Hooks/useSuppliers";
import useVariants from "../Hooks/useVariants";
import Page from "../Layout/Page";
import language from "../Strings/en";

const Stock = ({ storeId }) => {
  const [page, setPage] = useState(1);
  const [stocks, setStocks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMorePages, setHasMorePages] = useState(false);

  const fetchStock = async () => {
    setLoading(true);
    const res = await getRequest(ENDPOINTS.getStock, {
      page,
      storeId,
    });

    if (res.status === 200) {
      setLoading(false);
      if (page > 1) {
        setStocks((prev) => [...prev, ...res?.data?.data?.store?.products]);
      } else {
        setStocks(res?.data?.data?.store?.products);
      }
      setHasMorePages(
        res?.data?.data?.store?.hasMorePages === 0 ? false : true
      );
    }
  };
  useEffect(() => {
    fetchStock();
  }, [page]);

  const loadMore = () => {
    setPage(page + 1);
  };
  const { sizes, carats, metals } = useVariants();
  const { categories } = useCategories();
  const { suppliers } = useSuppliers();
  const [showBarcode, setShowBarcode] = useState({
    visibility: false,
    src: "",
  });
  //   const { stats } = useStats("inventory");
  const [search, setSearch] = useState("");
  const enterSearch = (e) => {
    e.preventDefault();
    setPage(1);
  };
  return (
    <Page>
      <div className="container mt-2">
        <div className="row">
          <div className="col-sm-12 d-flex mb-3">
            {/* <Stats
              title="Total Pcs in Stock"
              count={stats?.total_pcs_instock}
              col="flex-fill"
            />
            <Stats
              title="Total Weight/Amount in Stock"
              count={stats?.total_weight_in_stock}
              col="flex-fill"
            />
            <Stats
              title="Total MK in Stock"
              count={stats?.total_mk_in_stock}
              col="flex-fill"
            />
            <Stats
              title="Total Weight/Amount Sold"
              count={stats?.total_weith_sold}
              col="flex-fill"
            /> */}
          </div>
          <div className="col-sm-12">
            <div className="row align-items-center mb-4">
              <div className="col-md-1">
                <h1>Stocks</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Table
              className="table table--orders"
              striped
              bordered
              hover
              responsive="md"
            >
              <thead>
                <tr>
                  {/* <td>{language.purchaseId}</td> */}
                  <td>{language.purchaseName}</td>
                  <td>{language.purchaseWeight}</td>
                  <td>{language.purchaseCarats}</td>
                  <td>{language.purchaseColors}</td>
                  {/* <td>{language.purchaseSize}</td> */}
                  <td>{language.purchaseCostmk}</td>
                  <td>{language.purchaseSupplier}</td>
                  <td>{language.purchaseCategory}</td>
                  <td>{language.purchaseDate}</td>
                  <td>{language.purchaseQuantity}</td>
                  <td>{language.purchaseGoldRate}</td>
                </tr>
              </thead>
              <tbody>
                {stocks?.length > 0 ? (
                  stocks?.map((o) => (
                    <tr key={o?.id}>
                      <td>{o?.title_en ? o?.title_en : "--"}</td>
                      <td>{o?.total_weight}</td>
                      <td>
                        {
                          carats?.find((carat) => carat?.id === o?.carat_id)
                            .carat
                        }
                      </td>
                      <td>
                        {
                          metals?.find((metal) => metal?.id === o?.metal_id)
                            .metal
                        }
                      </td>
                      {/* <td>
                        {sizes?.find((size) => size?.id === o?.size_id).size}
                      </td> */}
                      <td>{o?.costmk}</td>
                      <td>
                        <span
                          style={{ width: "100%", wordBreak: "break-word" }}
                        >
                          {
                            suppliers?.find(
                              (supplier) => supplier?.id === o?.supplier_id
                            )?.label
                          }
                        </span>
                      </td>
                      <td>{o?.category_name}</td>
                      <td>{o?.purchase_date ? o?.purchase_date : "--"}</td>
                      <td>{o?.quantity ? o?.quantity : "--"}</td>
                      <td>{o?.gold_rate}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    {loading ? (
                      <td colSpan={11}>
                        <Loading />
                      </td>
                    ) : (
                      <td colSpan={11}>No records found</td>
                    )}
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
        {hasMorePages && (
          <div style={{ textAlign: "center" }}>
            <LoadingBtn onClick={loadMore}>Load More</LoadingBtn>
          </div>
        )}
        {loading && page > 1 && <Loading />}
      </div>
      <BarcodeDialog
        show={showBarcode.visibility}
        src={showBarcode.src}
        hide={() => setShowBarcode({ visibility: false, src: "" })}
      />

      {/* {createModal.visibility && (
        <CreatePurchaseModal
          isCreateModalOpen={createModal.visibility}
          isEdit={createModal.isEdit}
          hideCreateModal={() => setCreateModal(false)}
        />
      )}
      {stockModal.visibility && (
        <StockModal
          isCreateModalOpen={stockModal.visibility}
          isEdit={stockModal.isEdit}
          hideCreateModal={() => setStockModal(false)}
        />
      )} */}
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    storeId: state.userReducer.store_id,
  };
};
export default connect(mapStateToProps)(Stock);
