import React from "react";
import version from "../../Strings/version";

function Footer() {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark justify-content-center">
      <span className="text-muted px-3">{version}</span>
    </nav>
  );
}

export default Footer;
