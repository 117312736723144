export default function Carat({
  label,
  id,
  groupName,
  setCarat,
  carat,
  isRequired,
  disabled,
}) {
  return (
    <div className="custom-radio">
      <input
        type="radio"
        name={groupName}
        label={label}
        id={`${groupName}_${label}`}
        onChange={(_) => setCarat(id)}
        checked={carat == id}
        required={isRequired}
        disabled={disabled}
      />
      <label htmlFor={`${groupName}_${label}`}>{label}</label>
    </div>
  );
}
