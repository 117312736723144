export const processCartData = (products) => {
  // TODO: Map the data to store that is being bulk added from orders API having a wild difference of the object
  let updatedProducts = products.map((p) => {
    return {
      ...p,
      carat_id: p.carat_id,
      size_id: p.size_id,
      metal_id: p.metal_id,
      carat: p?.carat?.carat,
      size: p?.size?.size,
      metal: p?.metal?.metal,
      cartId: p.id,
      image_name: p.image_name || p?.product?.image_name,
      id: p.product_id || p?.product?.id,
      description: p?.product?.description,
      isGiftWrap: p.is_gift === 0 ? false : true,
      giftMessage: p?.gift_message,
      giftInstructions: p?.gift_instruction,
    };
  });

  return updatedProducts;
};
