import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { getRequest } from "../../Data/ApiService";
import { ENDPOINTS } from "../../Data/Endpoint";
import moment from "moment";
import Loading from "../Common/Loading";

const PayoutHistory = ({ detail }) => {
  const [loading, setLoading] = useState(false);
  const [hostoryData, setHistoryData] = useState([]);
  const fetchHistory = async () => {
    setLoading(true);
    await getRequest(`${ENDPOINTS.supplierPayout}/${detail?.id}?status=1`).then(
      (res) => {
        setLoading(false);
        if (res.status === 200) {
          setHistoryData(res.data?.data?.payout);
        }
      }
    );
  };

  useEffect(() => {
    fetchHistory();
  }, []);

  return (
    <div className="row">
      {loading ? (
        <div className="col-md-12 mt-4">
          <Loading />
        </div>
      ) : (
        <div className="col-md-12">
          <div className="mt-5">
            <h5>Payout History</h5>
            <Table className="table table--orders" bordered>
              <thead>
                <tr>
                  <td>ID</td>
                  <td>Invoice No.</td>
                  <td>Weight (gm)</td>
                  <td>Balance (gm)</td>
                  <td>Status</td>
                  <td>Paid By</td>
                  <td>Paid Date</td>
                </tr>
              </thead>
              <tbody>
                {hostoryData?.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>{item.invoice_no}</td>
                      <td>{item.total_weight}</td>
                      <td>{item.balance}</td>
                      <td>{item.status == 1 ? "Paid" : "Partial"}</td>
                      <td>{item?.paid_by || "-"}</td>
                      <td>
                        {moment(item.updated).format("YYYY-MM-DD hh:mm") || "-"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      )}
    </div>
  );
};

export default PayoutHistory;
