import { combineReducers } from 'redux';

import { cartReducer } from "./cartReducer";
import { categoryReducer } from "./categoryReducer";
import { customerDetailsReducer } from "./customerDetailsReducer";
import { priceReducer } from "./priceReducer";
import { productReducer } from "./productReducer";
import { settlementReducer } from './settlementReducer';
import { userReducer } from "./userReducer";

const rootReducer = combineReducers({
  cartReducer,
  userReducer,
  categoryReducer,
  productReducer,
  customerDetailsReducer,
  settlementReducer,
  priceReducer,
});

export default rootReducer;
