import React, { useRef, useState } from "react";
import language from "../../Strings/en";
import SelectSearch from "../Common/SelectSearch";
import { isEmpty } from "../../Utils/empty";
import { debounce } from "../../Utils/search";
import { ENDPOINTS } from "../../Data/Endpoint";
import { getRequest } from "../../Data/ApiService";
import DatePicker from "../Common/DatePicker";

const CustomerForm = ({
  customerDetailsError,
  isCustomerDetailOpen,
  setIsCustomerDetailOpen,
  customerDetails,

  saveCustomerDetails,
  updateCustomerDetails,
  setCustomerDetails,
  defaulValue,
  ...props
}) => {
  const searchRef = useRef(null);
  const [search, setSearch] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const onSelect = (customer) => {
    setCustomerDetails({
      ...customer,
    });
  };
  const triggerSearch = async (search) => {
    if (!isEmpty(search)) {
      setSearchLoading(true);
      let res = await getRequest(ENDPOINTS.listCustomer, {
        ...(!isEmpty(search) && {
          name: search,
        }),
      });
      setSearchLoading(false);
      if (res.data.data.success) {
        setOptions(
          res.data?.data?.customers?.map((customer) => ({
            ...customer,
            key: customer?.id,
            label: customer?.customer_name,
          }))
        );
        // searchRef.current.setShowOption(true);
      }
    }
  };

  const searching = async (e) => {
    debounce(triggerSearch(e?.target?.value));
  };

  const handleCustomerDetails = (e) => {
    setCustomerDetails({
      ...customerDetails,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <div>
      <div className="row mb-3">
        <div className="col-sm-12 col-md-6 col-lg-6">
          <div className="form-group">
            <label>{language.customerName}</label>
            <SelectSearch
              value={customerDetails.customer_name}
              size="lg"
              onSelect={onSelect}
              options={options}
              placeholder={language.customerName}
              // label={language.customerName}
              onSearch={(value) => {
                const event = {
                  target: { name: "customer_name", value },
                };
                handleCustomerDetails(event);
                searching(event);
              }}
              ref={searchRef}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6">
          <div className="form-group">
            <label>{language.customerPhoneNumber}</label>
            <SelectSearch
              value={customerDetails.phone_number}
              size="lg"
              onSelect={onSelect}
              options={options}
              placeholder={language.customerPhoneNumber}
              // label={language.customerName}
              onSearch={(value) => {
                const event = {
                  target: { name: "phone_number", value },
                };
                handleCustomerDetails(event);
                searching(event);
              }}
              ref={searchRef}
            />
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-sm-9">
          <div className="form-group">
            <label>{language.customerAddressLine1}</label>
            <input
              type="text"
              name="address_line1"
              className="form-control"
              placeholder={language.customerAddressLine1}
              value={customerDetails.address_line1}
              onChange={handleCustomerDetails}
            />
          </div>
        </div>
        <div className="col-sm-3">
          <div className="form-group">
            <label>{language.customerCPR}</label>
            <input
              type="text"
              name="cpr_number"
              className="form-control"
              placeholder={language.customerCPR}
              value={customerDetails.cpr_number}
              onChange={handleCustomerDetails}
            />
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-sm-4">
          <div className="form-group">
            <label>{language.customerAddressLine2}</label>
            <input
              type="text"
              name="address_line2"
              className="form-control"
              placeholder={language.customerAddressLine2}
              value={customerDetails.address_line2}
              onChange={handleCustomerDetails}
            />
          </div>
        </div>
        <div className="col-sm-4">
          <div className="form-group">
            <label>{language.customerAnniversary}</label>
            <DatePicker
              minDate={new Date()}
              name="anniversary"
              value={customerDetails.anniversary}
              onChange={handleCustomerDetails}
              size={"lg"}
              placeholder={language.customerAnniversary}
            />
          </div>
        </div>
        <div className="col-sm-4">
          <div className="form-group">
            <label>{language.customerDateOfBirth}</label>
            <DatePicker
              minDate={new Date()}
              name="date_of_birth"
              value={customerDetails.date_of_birth}
              onChange={handleCustomerDetails}
              size={"lg"}
              placeholder={language.customerDateOfBirth}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-4">
          <div className="form-group">
            <label>{language.customerCity}</label>
            <input
              type="text"
              name="city"
              className="form-control"
              placeholder={language.customerCity}
              value={customerDetails.city}
              onChange={handleCustomerDetails}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4">
          <div className="form-group">
            <label>{language.customerZip}</label>
            <input
              type="text"
              name="zipcode"
              className="form-control"
              placeholder={language.customerZip}
              value={customerDetails.zipcode}
              onChange={handleCustomerDetails}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4">
          <div className="form-group">
            <label>{language.customerCountry}</label>
            <input
              type="text"
              name="country"
              className="form-control"
              placeholder={language.customerCountry}
              value={customerDetails.country}
              onChange={handleCustomerDetails}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerForm;
