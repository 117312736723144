import React from "react";

const ProductWiseSaleItem = ({ data }) => {
  return (
    <tr>
      <td>{data?.product_name}</td>
      <td>{data?.total_qty}</td>
      <td>{data?.total_sales}</td>
    </tr>
    // <tr>
    //   <div className="d-flex justify-content-between">
    //     <p className="productWiseItemBoldText">{data?.product_name}</p>
    //     <p className="productWiseItemBoldText">
    //       Total sold: {data?.total_sales}
    //     </p>
    //   </div>
    //   <p>Price: {data?.product_price}</p>
    // </tr>
  );
};

export default ProductWiseSaleItem;
