import React, { useEffect, useState } from "react";
import { getRequest } from "../Data/ApiService";
import { ENDPOINTS } from "../Data/Endpoint";

const useVariants = () => {
  const [variants, setVariant] = useState({});
  useEffect(() => {
    const fetchVariant = async () => {
      const res = await getRequest(ENDPOINTS.variants, null, null);
      if (res?.data?.data?.success) {
        setVariant(res?.data?.data?.variants);
      }
    };
    fetchVariant();
  }, []);
  return {
    carats: variants?.carats,
    metals: variants?.metals,
    sizes: variants?.sizes,
  };
};

//return all variants value from api

export default useVariants;
