import React from "react";
import Select, { components } from "react-select";

const SelectComponent = React.forwardRef(
  (
    {
      options,
      onChange,
      onSearch = () => {},
      value,
      loading,
      isClearable,
      placeholder,
      label,
      isRequired,
      isDropdown = true,
      error,
      hasMore,
      loadMore,
      inputValue,
      notFoundMessage,
      width,
      component,
      addNewButton,
      addNewAction,
    },
    ref
  ) => {
    const customerStyle = {
      control: (baseStyles, state) => ({
        ...baseStyles,
        width,
      }),
      input: (baseStyles, state) => ({
        ...baseStyles,
        padding: !width && "0.500rem 0rem",
        fontSize: "0.8rem",
        lineHeight: 1.5,
      }),
      singleValue: (baseStyles, { data }) => ({
        ...baseStyles,
        fontSize: "0.8rem",
      }),
      placeholder: (baseStyles, state) => ({
        ...baseStyles,
        color: "#888",
        fontSize: "0.8rem",
        textAlign: "left",
        textTransform: "capitalize",
      }),
      dropdownIndicator: (prevStyle, state) =>
        !isDropdown
          ? {
              ...prevStyle,
              display: "none",
            }
          : { ...prevStyle },

      indicatorSeparator: (prevStyle, state) =>
        !isDropdown
          ? {
              ...prevStyle,
              display: "none",
            }
          : { ...prevStyle },
      menuList: (prevStyle, state) => ({ ...prevStyle, maxHeight: 150 }),
    };

    const filterOption = (options) => {
      return options;
    };

    return (
      <>
        {label && (
          <label className="custom-select-search-label mb-1">
            {label}
            {isRequired && <span className="text-danger">*</span>}
          </label>
        )}
        <Select
          onInputChange={(value, event) => {
            if (event.action === "input-change") {
              onSearch(value);
            }
          }}
          value={[value]}
          onChange={(value, event) => {
            if (event.action === "clear") {
              onChange(null);
            } else {
              onChange(value);
            }
          }}
          // menuLi
          options={options}
          isLoading={loading}
          isClearable={isClearable}
          placeholder={placeholder}
          styles={customerStyle}
          onMenuScrollToBottom={(e) => {
            if (hasMore) {
              loadMore();
            }
          }}
          filterOption={filterOption}
          components={{
            NoOptionsMessage: (props) => (
              <NoOptionsMessage
                {...props}
                addNewButton={addNewButton}
                addNewAction={addNewAction}
              />
            ),
          }}
          ref={ref}
        />
        <span className="error-label">{error}</span>
      </>
    );
  }
);

const NoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage
      {...props}
      className={props.addNewButton && "selectAddNewButtonContainer"}
    >
      {props.addNewButton ? (
        <button
          className="selectAddNewButton btn btn-sm"
          onClick={props.addNewAction}
        >
          Add +
        </button>
      ) : (
        <span className="custom-css-class">No Option</span>
      )}
    </components.NoOptionsMessage>
  );
};

export default SelectComponent;
