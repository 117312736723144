import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

const TooltipButton = (props) => {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props.title}
    </Tooltip>
  );
  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={(prop) => renderTooltip({ ...prop, ...props })}
    >
      <Button {...props}>{props.children}</Button>
    </OverlayTrigger>
  );
};

export default TooltipButton;
