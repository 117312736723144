import React from "react";
import Modal from "../Common/Modal";
import language from "../../Strings/en";
import BarcodeForm from "./BarcodeForm";

const BarcodeModal = ({ open, onHide, ...props }) => {
  return (
    <Modal
      show={open}
      onHide={onHide}
      size="small"
      centered={true}
      header={<h3>{language.barcode}</h3>}
    >
      <BarcodeForm {...props} onHide={onHide} />
    </Modal>
  );
};

export default BarcodeModal;
