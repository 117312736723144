import React from "react";

import CategoryCard from "./CategoryCard";

function CategoriesList({ category }) {
  return (
    <table>
      <tbody>
        <tr>
          {category.groups.map((group) => (
            <td key={group.id}>
              <CategoryCard key={group.id} category={group} />
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
}

export default CategoriesList;
