import React, { useEffect, useMemo } from "react";
import { Button } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import language from "../../Strings/en";
import DatePicker from "../Common/DatePicker";
import Input from "../Common/Input";
import InputNumber from "../Common/InputNumber";
import Dropdown from "../Common/Dropdown";
import usePaymentMethods from "../../Hooks/usePaymentMethods";
import { acceptFileType } from "../../Config";
import { errorToast, successToast } from "../../Utils/showToast";
import { BiPaperclip } from "react-icons/bi";
import { postRequest } from "../../Data/ApiService";
import { isEmpty } from "lodash";
import { ENDPOINTS } from "../../Data/Endpoint";

const PayoutForm = ({ responseAction, selectedData, onHide }) => {
  const defaultValues = {
    total_payable_amount: "",
    payout_date: "",
    paying_amount: "",
    payment_id: "",
    receipt_no: "",
    file_type: "",
  };
  const {
    control,
    watch,
    formState: { errors },
    clearErrors,
    reset,
    handleSubmit,
    setError,
    setValue,
  } = useForm({ defaultValues });
  const formValues = watch();

  const { paymentTypes } = usePaymentMethods();

  useEffect(() => {
    if (selectedData?.payouts?.length > 0) {
      setValue(
        "total_payable_amount",
        selectedData.payouts?.reduce(function (a, b) {
          return (
            Number(a?.making_charge_balance) ||
            0 + Number(b?.making_charge_balance) ||
            0
          );
        }, 0) || 0
      );
    }
  }, []);

  const submit = async (data) => {
    data.payout_id = selectedData?.payouts?.map((item) => item?.id);
    delete data.total_payable_amount;
    const formData = new FormData();
    Object.keys(data)?.forEach((key) => {
      if (data[key] && data[key] !== null && data[key] !== "") {
        formData.append(key, data[key]);
      }
    });

    await postRequest(`${ENDPOINTS.supplierMKPayout}`, formData).then((res) => {
      successToast(res.data?.data?.msg);
      onHide();
      reset();
    });
  };

  const fileSelect = (e) => {
    if (e.target.value.length > 0) {
      if (e.target.files[0].size <= 1024 * 1024 * 2) {
        if (acceptFileType.includes(e.target.files[0].type)) {
          clearErrors(["file_type"]);
          setValue("file_type", e.target.files[0]);
        } else {
          setValue("file_type", "");
          setError("file_type", {
            type: "validate",
            message: "This file not supported.",
          });
        }
      } else {
        setValue("file_type", "");
        setError("file_type", {
          type: "validate",
          message: "File size limit to 2MB",
        });
      }
    } else {
      setValue("file_type", "");
      setError("file_type", {
        type: "required",
        message: "File is required",
      });
    }
  };

  return (
    <div className="mt-4">
      <div className="row">
        <div className="col-md-6">
          <Controller
            name={"total_payable_amount"}
            rules={{ required: "Total payable amount is required." }}
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                label={language.totalPayableAmount}
                placeholder={language.totalPayableAmount}
                isRequired={true}
                error={errors?.total_payable_amount?.message}
                readOnly
              />
            )}
          />
        </div>
        <div className="col-md-6">
          <Controller
            name={"payout_date"}
            rules={{ required: "Payout Date is required." }}
            control={control}
            render={({ field }) => (
              <DatePicker
                minDate={new Date()}
                label={language.payoutDate}
                isRequired
                size={"lg"}
                {...field}
                error={errors?.payout_date?.message}
              />
            )}
          />
        </div>
        <div className="col-md-6">
          <Controller
            name={"paying_amount"}
            rules={{ required: "This field is required." }}
            control={control}
            render={({ field }) => (
              <InputNumber
                {...field}
                label={language.amountYouArePaying}
                error={errors?.paying_amount?.message}
                placeholder={language.amountYouArePaying}
                decimal={"false"}
                isRequired
              />
            )}
          />
        </div>
        <div className="col-md-6">
          <Controller
            name={"payment_id"}
            rules={{ required: "Payment Method is required." }}
            control={control}
            render={({ field }) => (
              <Dropdown
                {...field}
                dropdownOptions={paymentTypes}
                onSelect={field.onChange}
                label={language.paymentMethod}
                error={errors?.payment_id?.message}
                placeholder={language.paymentMethod}
              />
            )}
          />
        </div>
        <div className="col-md-6">
          <Controller
            name={"receipt_no"}
            rules={{ required: "Reciept No. is required." }}
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                label={language.recieptNo}
                placeholder={language.recieptNo}
                isRequired={true}
                error={errors?.receipt_no?.message}
              />
            )}
          />
        </div>
        <div className="col-md-6">
          <Controller
            name={"file_type"}
            rules={{ required: "File is required." }}
            control={control}
            render={({ field }) => (
              <div className="mt-1 d-flex flex-column">
                <Input
                  style={{ display: "none" }}
                  label={language.attachment}
                  type="file"
                  placeholder={language.attachment}
                  isRequired={true}
                  onChange={fileSelect}
                  // value={formValues.file_type?.name}
                  name={field.name}
                  ref={field.ref}
                  id={"fileUpload"}
                />
                <label
                  className="btn btn-outline-secondary btn-free btn-sm"
                  htmlFor="fileUpload"
                >
                  {field.value?.name || "Select File"} <BiPaperclip />
                </label>
                {errors?.file_type?.message && (
                  <span className="error-label">
                    {errors?.file_type?.message}
                  </span>
                )}
              </div>
            )}
          />
        </div>

        <div className="col-md-12 my-3 mt-4 justify-content-end d-flex">
          <Button
            variant="warning"
            onClick={handleSubmit(submit)}
            className="btn-free "
          >
            {"Done"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PayoutForm;
