import React, { useState } from "react";
import { Button, CloseButton, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { addUsedSettlement } from "../../Store/actions/settlement/addUsedSettlement";
import { updateUsedSettlementModes } from "../../Store/actions/settlement/updateUsedSettlement";

import { displayThreeDecimal } from "../../Utils/displayThreeDecimal";

function TenderModal({
  isModalOpen,
  setIsModalOpen,
  selectedMode,
  total,
  totalPaid,
  addUsedSettlement,
  usedSettlements,
  updateUsedSettlements,
}) {
  // const currentValue = usedSettlements?.findIndex((data) => {
  //   data.payment_type === selectedMode.payment_type
  // })

  const currentValue = usedSettlements.find(
    (data) => data.payment_type === selectedMode.payment_type
  );
  const [payPrice, setPayPrice] = useState(
    currentValue ? currentValue?.amount : 0
  );

  const addPaymentMethod = (type) => {
    if (currentValue) {
      const updatedSettlements = usedSettlements.map((data) => {
        if (data.id === currentValue.id) {
          return {
            ...currentValue,
            amount:
              type === "total"
                ? parseFloat(total - totalPaid) + payPrice
                : payPrice,
          };
        } else {
          return data;
        }
      });
      updateUsedSettlements(updatedSettlements);
    } else {
      addUsedSettlement({
        id: selectedMode.id,
        amount: type === "total" ? parseFloat(total - totalPaid) : payPrice,
        payment_type: selectedMode.payment_type,
      });
    }
    setIsModalOpen(false);
  };

  return (
    <Modal
      show={isModalOpen}
      onHide={(_) => setIsModalOpen(false)}
      className="tender-modal"
      centered
    >
      <Modal.Body className="p-0 tender-modal-body">
        <CloseButton
          style={{ right: 10, top: 10, position: "absolute" }}
          onClick={() => setIsModalOpen(false)}
        />
        <div className="tender-modal__body">
          <h5 className="text-center mb-2">
            {selectedMode.payment_type} Tender
          </h5>
          <div className="form-group">
            <label htmlFor="amount">Amount to Tendor</label>
            <input
              className="form-control"
              type="number"
              placeholder="Amount to pay"
              min="0"
              value={payPrice}
              onChange={(e) =>
                setPayPrice(
                  e.target.value !== "" ? parseFloat(e.target.value) : null
                )
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="change">Change</label>
            <input
              readOnly
              className="form-control"
              type="number"
              placeholder="Change"
              value={
                currentValue
                  ? payPrice >= currentValue?.amount
                    ? totalPaid + (payPrice - currentValue?.amount) > total
                      ? displayThreeDecimal(
                          totalPaid + (payPrice - currentValue?.amount) - total
                        )
                      : 0
                    : 0
                  : payPrice > total - totalPaid
                  ? displayThreeDecimal(payPrice - (total - totalPaid))
                  : 0
              }
            />
          </div>
        </div>
        <div className="tender-modal__actions">
          <Button
            variant="secondary"
            onClick={(_) => addPaymentMethod("total")}
            disabled={
              currentValue?.amount > total - totalPaid ||
              totalPaid - total === 0
            }
          >
            BD{" "}
            {displayThreeDecimal(
              totalPaid === 0
                ? total
                : total - totalPaid < 0
                ? 0
                : total - totalPaid
            )}
          </Button>
          <Button
            disabled={
              currentValue ? payPrice === currentValue?.amount : payPrice < 1
            }
            variant="warning"
            onClick={addPaymentMethod}
          >
            DONE
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    addUsedSettlement: bindActionCreators(addUsedSettlement, dispatch),
    updateUsedSettlements: bindActionCreators(
      updateUsedSettlementModes,
      dispatch
    ),
  };
};

const mapStateToProps = (state) => {
  return {
    usedSettlements: state.settlementReducer.usedSettlementMethods,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TenderModal);
