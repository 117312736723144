import React, { useState } from "react";
import { Button, Col, Nav, Row, Tab } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import { deleteRequest } from "../../Data/ApiService";
import { ENDPOINTS } from "../../Data/Endpoint";
import language from "../../Strings/en";
import { successToast } from "../../Utils/showToast";
import AlertDialog from "../Common/AlertDialog";
import Modal from "../Common/Modal";
import Attachments from "../Customer/Attachments";
import CustomerForm from "../Customer/CustomerForm";

function CustomerDetailsModal({ isModal = true, ...props }) {
  const defaulValue = {
    customer_name: "",
    phone_number: "",
    address_line1: "",
    address_line2: "",
    city: "",
    country: "",
    zipcode: "",
    cpr_number: "",
    anniversary: "",
    date_of_birth: "",
  };

  const [showAlert, setShowAlert] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const navigate = useNavigate();
  const deleteCustomer = async () => {
    let res = await deleteRequest(`${ENDPOINTS.deleteCustomer}/${deleteId}`);
    if (res.status === 200) {
      successToast(res.data?.data?.msg);
      navigate(-1);
    }
    setShowAlert(false);
  };
  const onHide = () => {
    props.setIsCustomerDetailOpen(false);
    props.setCustomerDetails(defaulValue);
  };

  const [activeTab, setActiveTab] = useState("customer");

  return isModal ? (
    <Modal
      show={props.isCustomerDetailOpen}
      onHide={onHide}
      size="lg"
      header={<h3>{language.customerDetailsTitle}</h3>}
      footer={
        <>
          <Button
            variant="warning"
            onClick={(_) => {
              props.saveCustomerDetails();
              props.setIsCustomerDetailOpen(false);
            }}
            disabled={!props.customerDetails?.customer_name}
          >
            Done
          </Button>
        </>
      }
    >
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey={activeTab}
        activeKey={activeTab}
        onSelect={(tab) => setActiveTab(tab)}
      >
        <Row>
          <Col sm={12} className="tabContainer">
            <Nav variant="pills" className="flex-row">
              <Nav.Item>
                <Nav.Link eventKey="customer" className="custom-tab">
                  Customer
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="upload" className="custom-tab">
                  Upload
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={12}>
            <Tab.Content>
              <Tab.Pane eventKey="customer">
                <CustomerForm {...props} />
              </Tab.Pane>
              <Tab.Pane eventKey="upload">
                <Attachments customerDetail={props.customerDetails} />
                {/* <UploadAttachment {...props} /> */}
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Modal>
  ) : (
    <div>
      <CustomerForm {...props} />
      <div className="d-flex justify-content-end mt-3">
        <Button
          variant="outline-secondary"
          className="me-4 btn-free"
          onClick={() => {
            setDeleteId(props.customerDetails?.id);
            setShowAlert(true);
          }}
        >
          Delete
        </Button>
        <Button
          variant="warning"
          className="btn-free"
          onClick={(_) => {
            props.saveCustomerDetails();
          }}
        >
          Done
        </Button>
        <AlertDialog
          show={showAlert}
          successAction={deleteCustomer}
          title={language.deleteAlertCustomer}
          cancleAction={() => setShowAlert(false)}
          closeButton={false}
        />
      </div>
    </div>
  );
}

export default React.memo(CustomerDetailsModal);
