export const initialStateCategories = {
  categories: [],
  selectedCategoryId: null,
  page: 1,
};

export const CATEGORY_ACTIONS = {
  SET_SELECTED_CATEGORY: "categoryReducer.SET_SELECTED_CATEGORY",
  ADD_CATEGORIES: "categoryReducer.ADD_CATEGORIES",
  SET_LOADING: "categoryReducer.SET_LOADING",
  SET_PAGE: "categoryReducer.SET_PAGE",
  RESET_STATE: "categoryReducer.RESET_STATE",
};

export const categoryReducer = (state = initialStateCategories, action) => {
  switch (action.type) {
    case CATEGORY_ACTIONS.SET_SELECTED_CATEGORY:
      return { ...state, selectedCategoryId: action.payload.categoryId };

    case CATEGORY_ACTIONS.ADD_CATEGORIES:
      return { ...state, categories: [...action.payload] };

    case CATEGORY_ACTIONS.REMOVE_CATEGORIES:
      let productsRemoveProduct = [...state.products];
      productsRemoveProduct.splice(
        productsRemoveProduct.findIndex((p) => p.id === action.payload.id),
        1
      );

      return { ...state, products: [...productsRemoveProduct] };

    case CATEGORY_ACTIONS.UPDATE_CART:
      let productsUpdateCart = [...state.products];

      return {
        ...state,
        products: productsUpdateCart.map((p) =>
          p.id !== action.payload.id
            ? { ...p }
            : { ...p, ...action.payload.updates }
        ),
      };

    case CATEGORY_ACTIONS.SET_GOLD_PRICE:
      return {
        ...state,
        goldPrice: action.payload,
      };

    case CATEGORY_ACTIONS.SET_LOADING:
      return { ...state, loading: action.payload.loading };
    case CATEGORY_ACTIONS.SET_PAGE:
      return { ...state, page: action.payload };
    case CATEGORY_ACTIONS.RESET_STATE:
      return initialStateCategories;

    default:
      return state;
  }
};
