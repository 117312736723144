import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setOrderId } from "../Store/actions/cart/setOrderId";
import { setOrderNo } from "../Store/actions/cart/setOrderNo";
import { bulkAddToCart } from "../Store/actions/cart/bulkAddCart";
import { addCustomerDetails } from "../Store/actions/customerDetails/addCustomerDetails";
import { addTaxDetails } from "../Store/actions/cart/addTaxDetails";
import { setOrderCreatedDate } from "../Store/actions/cart/setOrderCreatedDate";
import { updateUsedSettlementModes } from "../Store/actions/settlement/updateUsedSettlement";
import { setIsOrderDone } from "../Store/actions/cart/setIsOrderDone";
import { getRequest } from "../Data/ApiService";
import { ENDPOINTS } from "../Data/Endpoint";
import { useNavigate } from "react-router-dom";

const mapStateToProps = (state) => {
  return {
    settlementMethods: state.settlementReducer.settlementMethods,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOrderId: bindActionCreators(setOrderId, dispatch),
    setOrderNo: bindActionCreators(setOrderNo, dispatch),
    bulkAddToCart: bindActionCreators(bulkAddToCart, dispatch),
    addCustomerDetails: bindActionCreators(addCustomerDetails, dispatch),
    addTaxDetails: bindActionCreators(addTaxDetails, dispatch),
    setOrderCreatedDate: bindActionCreators(setOrderCreatedDate, dispatch),
    updateUsedSettlementModes: bindActionCreators(
      updateUsedSettlementModes,
      dispatch
    ),
    setIsOrderDone: bindActionCreators(setIsOrderDone, dispatch),
  };
};
const withOrderNavigation = (OriginalComponent) => {
  const NewComponent = (props) => {
    const {
      addCustomerDetails,
      bulkAddToCart,
      setOrderId,
      setOrderNo,
      addTaxDetails,
      setOrderCreatedDate,
      updateUsedSettlementModes,
      settlementMethods,
      setIsOrderDone,
    } = props;

    const navigate = useNavigate();

    const handleFetchOrderDetails = async (id) => {
      const res = await getRequest(`${ENDPOINTS.getOrder}/${id}`);
      const products = res.data.data.orders.order_items;
      const customerDetails = res.data.data.orders.ordercustomer;
      const orderId = res.data.data.orders.id;
      const orderDate = res.data.data.orders.local_created.datetime;
      const orderStatus = res.data.data.orders.order_status_id;

      const usedSettlements = res.data?.data?.orders?.orderpaymenttypes?.map(
        (settlements) => {
          return {
            id: settlements?.payment_type_id,
            amount: settlements?.amount,
            payment_type: settlementMethods.find(
              (settlement) => settlement.id === settlements?.payment_type_id
            ).payment_type,
          };
        }
      );

      //create usedSettlement array of obejct format  from api response
      if (orderStatus === 5) {
        updateUsedSettlementModes(usedSettlements);
        setIsOrderDone(true);
      }

      setOrderId(orderId);
      setOrderNo(res.data.data.orders.order_no);
      setOrderCreatedDate(orderDate);
      bulkAddToCart(products);
      addCustomerDetails(customerDetails);
      let billRes = await getRequest(ENDPOINTS.cartTotal + orderId);
      addTaxDetails({
        tax: billRes.data.data.tax,
        subTotal: billRes.data.data.subtotal,
        total: billRes.data.data.total,
        taxName: billRes.data?.data?.tax_name,
      });

      navigate("/sale");
    };
    return (
      <OriginalComponent
        {...props}
        handleFetchOrderDetails={handleFetchOrderDetails}
      />
    );
  };
  return connect(mapStateToProps, mapDispatchToProps)(NewComponent);
};

export default withOrderNavigation;
