import React from "react";
import { Breadcrumb, Col, Nav, Row, Tab } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Link from "../Components/Common/Link";
import Attachments from "../Components/Supplier/Attachments";
import CreateSupplier from "../Components/Supplier/CreateSupplier";
import Payout from "../Components/Supplier/Payout";
import Page from "../Layout/Page";
import BarsForms from "../Components/Bars/BarsForms";

const BarsDetail = () => {
  const { state } = useLocation();
  const barsDetail = state.bars;
  const tabs = [
    {
      key: "entry",
      title: "Entry",
      component: <BarsForms barsDetail={barsDetail} />,
    },
    {
      key: "attachments",
      title: "Attachments",
      component: (
        <div className="w-80">
          <Attachments detail={barsDetail} keyName={"bars"} />
        </div>
      ),
    },
  ];
  return (
    <Page>
      <div className="container">
        <Breadcrumb>
          <Breadcrumb.Item className="custom-breadcrumb">
            <Link to={"/dashboard"}>Dashboard</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item className="custom-breadcrumb">
            <Link to={"/bars"}> Bars</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Detail</Breadcrumb.Item>
        </Breadcrumb>
        <div className="row mt-5">
          <div className="col-md-12">
            <h5 className="bold-font p-0 m-0">{barsDetail?.name}</h5>
          </div>
          <div className="col-md-12 mt-5">
            <Tab.Container
              id="left-tabs-example"
              className="mt-0"
              defaultActiveKey={tabs[0].key}
            >
              <Row>
                <Col sm={12} className="tabContainer">
                  <Nav variant="pills" className="flex-row">
                    {tabs?.map((tab, index) => {
                      return (
                        <Nav.Item key={index}>
                          <Nav.Link eventKey={tab.key} className="custom-tab">
                            {tab.title}
                          </Nav.Link>
                        </Nav.Item>
                      );
                    })}
                  </Nav>
                </Col>
                <Col sm={12}>
                  <Tab.Content>
                    {tabs?.map((tab, index) => {
                      return (
                        <Tab.Pane key={index} eventKey={tab.key}>
                          {tab.component}
                        </Tab.Pane>
                      );
                    })}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default BarsDetail;
