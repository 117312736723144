import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

function PublicRoute({ token, children }) {
  if (token) {
    return <Navigate to='/dashboard' />;
  }

  return children;
}
const mapStateToProps = (state) => {
  return {
    token: state.userReducer.token,
  };
};

export default connect(mapStateToProps)(PublicRoute);
