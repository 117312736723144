import React, { useEffect, useState } from "react";
import { getRequest } from "../Data/ApiService";
import { ENDPOINTS } from "../Data/Endpoint";
import { errorToast } from "../Utils/showToast";

const useCategories = () => {
  const [categories, setCategories] = useState();
  const loadCategories = async () => {
    try {
      const res = await getRequest(ENDPOINTS.categories, null, null);
      if (res.data?.data?.success) {
        const categories = res?.data?.data?.categories?.map((category) => {
          const groups = category?.groups?.map((group) => {
            return { id: group?.id, value: group?.id, label: group?.name };
          });
          return { id: category?.id, label: category?.name, groups };
        });
        setCategories(categories);
      } else {
        errorToast(res.data?.data?.msg);
      }
    } catch (error) {
      errorToast(error.message);
    }
  };

  useEffect(() => {
    loadCategories();
  }, []);
  return { categories, loadCategories };
};

export default useCategories;
