import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Button, Form } from "react-bootstrap";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { AiFillDelete } from "react-icons/ai";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getRequest, postRequest, putRequest } from "../../Data/ApiService";
import { ENDPOINTS } from "../../Data/Endpoint";
import useSuppliers from "../../Hooks/useSuppliers";
import useVariants from "../../Hooks/useVariants";
import language from "../../Strings/en";
import { isEmpty } from "../../Utils/empty";
import { convertInputValueToNumber } from "../../Utils/input";
import { debounce } from "../../Utils/search";
import { errorToast, successToast } from "../../Utils/showToast";
import CaratSelection from "../AttributeSelectors/CaratSelection";
import MetalSelection from "../AttributeSelectors/MetalSelection";
import DatePicker from "../Common/DatePicker";
import DivBorderContainer from "../Common/DivBorderContainer";
import Dropdown from "../Common/Dropdown";
import Input from "../Common/Input";
import SelectComponent from "../Common/SelectComponent";
import { get24CaratWeight, getGoldCost } from "../../Utils/purchaseCalculation";
import UploadImagePlaceholder from "../../Assets/Images/upload-image-placeholder.png";
import useCategories from "../../Hooks/useCategories";
import SelectSearch from "../Common/SelectSearch";
import CroppingImage from "../Common/CroppingImage";
import { acceptImageType } from "../../Config";

const PurchaseForm = React.forwardRef(
  (
    {
      isCreateModalOpen,
      hideCreateModal,
      purchaseData,
      isEdit,
      actionOnPurchase,
      storeId,
      setEditPurchase,
      isModal,
    },
    ref
  ) => {
    const [openCropper, setOpenCropper] = useState(false);
    const focusInput = useRef(null);
    const params = useParams();
    const navigate = useNavigate();
    const {
      control,
      formState: { errors, defaultValues },
      watch,
      setValue,
      clearErrors,
      reset,
    } = useFormContext();

    const { fields, append, remove } = useFieldArray({
      control,
      name: "variants",
    });

    const formValues = watch();

    const fetchProductDetailFromId = async () => {
      await getRequest(ENDPOINTS.productDetails + params?.id + "/detail").then(
        (res) => {
          const data = res.data?.data?.product;
          if (res.data?.status === 200) {
            onSelect({
              ...data,
              label: data?.title_en,
              value: data?.storeproduct_id,
            });
          } else {
            navigate(-1);
          }
        }
      );
    };

    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);

    const { sizes, metals, carats } = useVariants();

    const { suppliers } = useSuppliers();

    useEffect(() => {
      if (params?.id && carats?.length > 0) {
        fetchProductDetailFromId();
      }
    }, [carats?.length]);

    useEffect(() => {
      if (isEdit) {
        let data = purchaseData;
        Object.keys(data).forEach((item) => {
          setValue(item, data[item]);
        });
      } else {
        reset(defaultValues);
      }
    }, [isEdit, purchaseData]);

    const submit = async (_) => {
      let data = {
        // title_en: formData.name,
        // group_id:
        //   typeof formData.category === "number"
        //     ? formData.category
        //     : categories
        //         ?.find((category) =>
        //           category?.groups?.some(
        //             (group) => group?.label === formData.category
        //           )
        //         )
        //         ?.groups?.find((group) => group?.label === formData.category)
        //         .id,
        // supplier_name: formData.supplier_name,
        // supplier_id: formData.supplier,
        // totalvat: formData.total_vat,
        // "24k_goldrate_pergm": formData.goldRate,
        // costmk: formData.costmk,
        // totalweight: formData.weight,
        // totalqty: formData.purchaseQty,
        // purchase_date: formData.purchaseDate,
        // purchase_type: Number(formData.payment),
        // storeproduct_id: formData.storeproduct_id,
      };

      let res;
      if (formData?.id) {
        await putRequest(`${ENDPOINTS.updatePurchaseItem}/${formData?.id}`, {
          ...data,
          store_product_variant_id: formData?.variantId,
        })
          .then((response) => {
            res = response;
          })
          .catch((err) => {
            errorToast(err.response.data.message);
          });
      } else {
        await postRequest(ENDPOINTS.createPurchaseItem, data)
          .then((response) => {
            res = response;
          })
          .catch((err) => {
            errorToast(err.response.data.message);
          });
      }
      if (res?.data?.status === 200) {
        successToast(res.data.data.msg);
        setLoading(false);

        const product = res?.data?.data?.product;

        actionOnPurchase(data, formData?.id ? true : false);
        resetFields({});
      } else {
        errorToast(res.data?.data?.message);
      }

      // setLoading(true);
    };

    const resetFields = () => {
      reset(defaultValues);
      isModal && hideCreateModal();
    };

    const [options, setOptions] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [page, setPage] = useState(1);

    const triggerSearch = async (value) => {
      setPage(1);
      searchPurchase(1, value);
    };

    const searchPurchase = async (newPage, value) => {
      setSearchLoading(true);
      const res = await getRequest(ENDPOINTS.getProducts, {
        page: newPage,
        storeId,
        ...(!isEmpty(value) && { keyword: value }),
      });
      if (res.data.data.success) {
        setSearchLoading(false);

        if (page > 1) {
          setOptions((prev) => [
            ...prev,
            ...res.data?.data?.products?.map((option) => ({
              ...option,
              value: option?.id,
              label: option.title_en,
            })),
          ]);
        } else {
          setOptions(
            res.data?.data?.products?.map((option) => ({
              ...option,
              value: option?.id,
              label: option.title_en,
            }))
          );
        }
        setHasMore(res?.data?.data?.hasMorePages === 0 ? false : true);
      } else {
        setSearchLoading(false);
      }
    };

    useEffect(() => {
      if (page !== 1) {
        searchPurchase(page);
      }
    }, [page]);

    const loadMore = useCallback(() => {
      setPage((prev) => prev + 1);
    }, []);

    const onSelect = (purchase) => {
      clearErrors(["storeproduct_id"]);
      // const variants = purchase?.variants
      //   ? purchase?.variants?.map((item) => {
      //       return {
      //         sku: item?.sku,
      //         totalqty: "",
      //         totalweight: "",
      //         id: item?.id,
      //         carat: carats?.find((value) => value?.id === item?.carat_id)
      //           .carat,
      //         metal: metals?.find((value) => value?.id === item?.metal_id)
      //           .metal,
      //         size: sizes?.find((value) => value?.id === item?.size_id).size,
      //       };
      //     })
      //   : [];

      // console.log(variants);

      // reset({
      //   ...defaultValues,
      //   storeproduct_id: purchase,
      //   // variants: variants,
      // });
      setValue("storeproduct_id", purchase);
      // setValue("variants", variants);
    };

    useImperativeHandle(ref, () => ({
      submit,
      resetFields: () => resetFields({ hideModal: false }),
    }));

    const searching = async (e) => {
      debounce(triggerSearch(e?.target.value));
    };

    const variantCount = () => {
      append({
        // carat_id: null,
        // metal_id: null,
        size_id: "",
        // barcode_number: "",
        rfid: "",
        price: "",
        id: 0,
        variant_qty: "",
        variant_weight_perpc: "",
      });
    };

    const setCaratWeight = ({
      weight = formValues.totalweight || 0,
      carat = formValues.carat_id || undefined,
      index = null,
    }) => {
      const caratInfo = carats?.find((item) => item?.id == carat);
      const value = get24CaratWeight({
        total_weight: weight || 0,
        goldPercentage: caratInfo?.gold_percentage || 0,
      });

      setValue("carat_24_weight", value);

      return value;
    };

    const setGoldCost = ({
      weight = formValues?.["carat_24_weight"] || 0,
      price = formValues?.["24k_goldrate_pergm"] || 0,
      index = null,
    }) => {
      setValue(
        "gold_cost",
        getGoldCost({ weight: weight || 0, caratprice: price || 0 })
      );
    };
    const [file, setFile] = useState({ displayImage: null, file: null });
    const [croppingImg, setCroppingImg] = useState(null);
    const fileSelect = (e) => {
      if (e.target.value.length > 0) {
        if (acceptImageType.includes(e.target.files[0].type)) {
          const file = e.target.files[0];
          const FR = new FileReader();
          FR.addEventListener("load", function (evt) {
            // setValue("image_name", evt.target.result);
            clearErrors(["image_name"]);
            setCroppingImg(evt.target.result);
            // setFile({ file: evt.target.result, displayImage: evt.target.result });
            setOpenCropper(true);
          });

          FR.readAsDataURL(file);
        }
        // setFile({ file, displayImage: URL.createObjectURL(file) });
      }
      //  else {
      //   setError("image_name", "File is required.");
      // }
    };

    const removeImage = () => {
      setFile({ file: null, displayImage: null });
      document.getElementById("fileInput").value = null;
      setValue("image_name", null);
    };

    const { categories } = useCategories();

    const newValue = (index, value) => {
      setValue(`variants.${index}.price`, value);
    };

    useEffect(() => {
      focusInput.current?.focus();
    }, [formValues.producttype]);

    return (
      <div>
        {/* <form> */}
        <CroppingImage
          open={openCropper}
          onHide={() => setOpenCropper(false)}
          image={croppingImg}
          handleImage={(img) => {
            setValue("image_name", img);
          }}
        />
        <div className="row mb-3">
          <div className="col-md-6 mb-4">
            <div className="d-flex">
              <Controller
                name="producttype"
                control={control}
                render={({ field }) => (
                  <>
                    <Form.Check
                      type="radio"
                      id="product-simple"
                      label={language.simpleProduct}
                      className="checkbox-smalllabel"
                      {...field}
                      value={1}
                      onChange={(e) => {
                        if (!isEdit) {
                          setValue("variants", []);
                          field.onChange(convertInputValueToNumber(e));
                        }
                      }}
                      checked={formValues.producttype == 1}
                      readOnly={isEdit}
                    />
                    <Form.Check
                      type="radio"
                      id="product-variant"
                      label={language.productWithVariants}
                      className="checkbox-smalllabel ms-3"
                      {...field}
                      value={2}
                      onChange={(e) => {
                        if (!isEdit) {
                          variantCount();
                          const resetField = [
                            "cost_price",
                            "rfid",
                            "barcode",
                            "totalweight",
                            "totalqty",
                            "carat_id",
                            "price",
                          ];
                          resetField.forEach((key) => setValue(key, ""));
                          field.onChange(convertInputValueToNumber(e));
                        }
                      }}
                      checked={formValues.producttype == 2}
                      readOnly={isEdit}
                    />
                  </>
                )}
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center align-items-center mb-4">
            <div className="uploadImageContainer d-flex flex-column">
              <Controller
                name="image_name"
                control={control}
                // rules={{ required: "File is Required." }}
                render={({ field }) => (
                  <input
                    type="file"
                    className="d-none"
                    id={"fileInput"}
                    accept={acceptImageType.join(", ")}
                    onChange={fileSelect}
                    name={field.name}
                    ref={field.ref}
                  />
                )}
              />
              <img
                src={formValues.image_name || UploadImagePlaceholder}
                className={"uploadImage"}
                onClick={() => {
                  document.getElementById("fileInput").click();
                }}
              />
              {file.displayImage && (
                <button className="removeImageButton" onClick={removeImage}>
                  &times;
                </button>
              )}
              <span className="error-label">{errors?.image_name?.message}</span>
            </div>
          </div>
          <div className="col-sm-12 col-md-4 mb-3">
            <Controller
              name={"supplier_id"}
              control={control}
              rules={{
                required: "Supplier is required.",
              }}
              render={({ field }) => (
                <Dropdown
                  label={language.purchaseSupplier}
                  dropdownOptions={suppliers}
                  usedAsValue="id"
                  onSelect={field.onChange}
                  isRequired
                  {...field}
                  error={errors?.supplier_id?.message}
                  ref={focusInput}
                />
              )}
            />
          </div>
          <div className="col-sm-12 col-md-4 mb-3">
            <Controller
              name="group_id"
              control={control}
              rules={{ required: "Category is required" }}
              render={({ field }) => (
                <SelectSearch
                  placeholder="Category"
                  options={categories}
                  size="lg"
                  value={
                    typeof field.value === "string"
                      ? field.value
                      : categories
                          ?.find((category) =>
                            category?.groups?.some(
                              (group) => group?.id === field.value
                            )
                          )
                          ?.groups?.find((group) => group?.id === field.value)
                          .label
                  }
                  label="Category"
                  treeView={true}
                  onSelect={(value) => {
                    field.onChange({
                      target: { name: field.name, value: value.value },
                    });
                  }}
                  isRequired
                  searchable={false}
                  error={errors?.group_id?.message}
                />
              )}
            />
          </div>

          <div className="col-sm-12 col-md-4 col-lg-4 mb-3">
            <Controller
              name={"storeproduct_id"}
              control={control}
              rules={{ required: "You can only select product from list." }}
              render={({ field }) => (
                <SelectComponent
                  options={options}
                  onChange={onSelect}
                  onSearch={(value) => {
                    const event = { target: { name: "name", value } };
                    searching(event);
                  }}
                  value={field.value}
                  isClearable={true}
                  placeholder={language.purchaseName}
                  label={language.purchaseName}
                  loading={searchLoading}
                  isDropdown={false}
                  error={errors?.storeproduct_id?.message}
                  hasMore={hasMore}
                  loadMore={loadMore}
                />
              )}
            />
          </div>

          <div className="col-sm-12 col-md-3 mb-3">
            <Controller
              name={"purchase_type"}
              control={control}
              render={({ field }) => (
                <DivBorderContainer title={language.purchaseType} isRequired>
                  <div className="row">
                    <div className="col-sm-12 col-md-5 col-3 ">
                      <Form.Check
                        type="radio"
                        id="cash"
                        label={language.purchaseCash}
                        {...field}
                        value={1}
                        checked={formValues.purchase_type === "1"}
                        required
                      />
                    </div>
                    <div className="col-sm-12 col-md-5 col-3 ">
                      <Form.Check
                        type="radio"
                        id="credit"
                        label={language.purchaseCredit}
                        {...field}
                        value={2}
                        checked={formValues.purchase_type === "2"}
                        required
                        // disabled
                      />
                    </div>
                  </div>
                </DivBorderContainer>
              )}
            />
          </div>

          <div className="col-md-3 col-sm-12 mb-3">
            <Controller
              name={"invoice_no"}
              control={control}
              rules={{
                required: "Invoice No. is required.",
              }}
              render={({ field }) => (
                <Input
                  label={language.invNo}
                  type="text"
                  placeholder={language.invNo}
                  {...field}
                  isRequired={true}
                  error={errors?.[field.name]?.message}
                  readOnly={isEdit}
                />
              )}
            />
          </div>
          <div className="col-sm-12 col-md-3 mb-3">
            <Controller
              name={"purchase_date"}
              control={control}
              rules={{
                required: "Purchase Date is required.",
              }}
              render={({ field }) => (
                <DatePicker
                  minDate={new Date()}
                  label={language.invoiceDate}
                  isRequired
                  size={"lg"}
                  {...field}
                  error={errors?.purchase_date?.message}
                />
              )}
            />
          </div>
          <div className="col-sm-12 col-md-3 mb-3">
            <Controller
              name={"purchase_value"}
              control={control}
              // rules={{ required: "othercost is required" }}
              render={({ field }) => (
                <Input
                  label={language.purchaseValue}
                  placeholder={language.purchaseValue}
                  {...field}
                  // isRequired
                  error={errors?.cost_price?.message}
                />
              )}
            />
          </div>

          <div className="col-sm-12 col-md-3 mb-3">
            <Controller
              name={"totalqty"}
              control={control}
              rules={{ required: "Qty is required" }}
              render={({ field }) => (
                <Input
                  min={1}
                  label={language.purchaseQuantity}
                  type="number"
                  placeholder={language.purchaseQuantity}
                  {...field}
                  onChange={(e) => {
                    const re = /^[0-9]*$/;
                    if (re.test(e.target.value)) {
                      field.onChange(e);
                    }
                  }}
                  isRequired
                  error={errors?.totalqty?.message}
                />
              )}
            />
          </div>
          <div className="col-sm-12 col-md-3 mb-3">
            <Controller
              name={"totalweight"}
              control={control}
              rules={{ required: "Weight is required" }}
              render={({ field }) => (
                <Input
                  label={language.totalWeight}
                  placeholder={language.totalWeight}
                  {...field}
                  isRequired
                  onChange={(e) => {
                    const re = /^[0-9]*[.]?[0-9]*$/;
                    if (re.test(e.target.value)) {
                      field.onChange(e);
                      setCaratWeight({ weight: e.target.value });
                      setGoldCost({
                        weight: setCaratWeight({ weight: e.target.value }),
                      });
                    }
                  }}
                  error={errors?.totalweight?.message}
                />
              )}
            />
          </div>

          <div className="col-lg-3 col-sm-12 mb-3">
            <Controller
              name={"costmk"}
              control={control}
              // rules={{ required: "Weight is required" }}
              render={({ field }) => (
                <Input
                  label={language.purchaseCostmk}
                  type="text"
                  placeholder={language.purchaseCostmk}
                  {...field}
                  onChange={(e) => {
                    const re = /^[0-9]*[.]?[0-9]*$/;
                    if (re.test(e.target.value)) {
                      field.onChange(e);
                    }
                  }}
                  error={errors?.costmk?.message}
                  // isRequired
                />
              )}
            />
          </div>

          <div className="col-md-3 col-sm-12 mb-3">
            <Controller
              name={"totalvat"}
              control={control}
              // rules={{ required: "Weight is required" }}
              render={({ field }) => (
                <Input
                  label={language.totalVat}
                  type="text"
                  placeholder={language.totalVat}
                  {...field}
                  onChange={(e) => {
                    const re = /^[0-9]*[.]?[0-9]*$/;
                    if (re.test(e.target.value)) {
                      field.onChange(e);
                    }
                  }}
                  error={errors?.totalvat?.message}
                />
              )}
            />
          </div>
          <div className="col-sm-12 col-md-3 mb-3">
            <Controller
              name={"metal_id"}
              control={control}
              // rules={{ required: "othercost is required" }}
              render={({ field }) => (
                <MetalSelection
                  labelStyle={{
                    fontWeight: "bold",
                    color: "#000",
                  }}
                  metalOptions={metals}
                  metal={field.value}
                  // index={index}
                  setMetal={(e) => {
                    field.onChange({
                      target: { name: field.name, value: e },
                    });
                  }}
                  isBorder={false}
                  isRequired
                  error={errors?.metal_id?.message}
                />
              )}
            />
          </div>
          {/* <div className="col-sm-12 col-md-3 mb-3">
                <Controller
                  name={"barcode"}
                  control={control}
                  // rules={{ required: "othercost is required" }}
                  render={({ field }) => (
                    <Input
                      label={language.barcode}
                      placeholder={language.barcode}
                      {...field}
                      // isRequired
                      error={errors?.barcode?.message}
                    />
                  )}
                />
              </div> */}

          <div className="col-sm-12 col-md-3 mb-3">
            <Controller
              name={`carat_id`}
              control={control}
              rules={{ required: "Carat is required" }}
              render={({ field }) => (
                <CaratSelection
                  labelStyle={{
                    fontWeight: "bold",
                    color: "#000",
                  }}
                  caratOptions={carats}
                  carat={field.value}
                  // index={index}
                  setCarat={(e) => {
                    field.onChange({
                      target: { name: field.name, value: e },
                    });
                    setCaratWeight({ carat: e });
                    setGoldCost({
                      weight: setCaratWeight({ carat: e }),
                    });
                  }}
                  isBorder={false}
                  isRequired
                  error={errors?.carat_id?.message}
                />
              )}
            />
          </div>

          <div className="col-md-3 col-sm-12 mb-3">
            <Controller
              name={"24k_goldrate_pergm"}
              control={control}
              rules={{
                required:
                  formValues.purchase_type === "1"
                    ? "Gold rate is required."
                    : false,
              }}
              render={({ field }) => (
                <Input
                  label={language.purchaseGoldRatePerGram}
                  type="text"
                  placeholder={language.purchaseGoldRatePerGram}
                  {...field}
                  onChange={(e) => {
                    const re = /^[0-9]*[.]?[0-9]*$/;
                    if (re.test(e.target.value)) {
                      field.onChange(e);
                      setGoldCost({ price: e.target.value });
                    }
                  }}
                  isRequired={formValues.purchase_type === "1" ? true : false}
                  error={errors?.["24k_goldrate_pergm"]?.message}
                />
              )}
            />
          </div>
          <div className="col-sm-12 col-md-3 mb-3">
            <Controller
              name={"other_cost"}
              control={control}
              // rules={{ required: "othercost is required" }}
              render={({ field }) => (
                <Input
                  label={language.otherCost}
                  placeholder={language.otherCost}
                  {...field}
                  // isRequired
                  onChange={(e) => {
                    const re = /^[0-9]*[.]?[0-9]*$/;
                    if (re.test(e.target.value)) {
                      field.onChange(e);
                    }
                  }}
                  error={errors?.other_cost?.message}
                />
              )}
            />
          </div>

          <div className="col-sm-12 col-md-4 mb-3">
            <Controller
              name={"carat_24_weight"}
              control={control}
              // rules={{ required: "othercost is required" }}
              render={({ field }) => (
                <Input
                  label={language["24CaratWeight"]}
                  placeholder={language["24CaratWeight"]}
                  {...field}
                  // isRequired
                  error={errors?.[field.name]?.message}
                  readOnly={true}
                />
              )}
            />
          </div>
          <div className="col-sm-12 col-md-4 mb-3">
            <Controller
              name={"gold_cost"}
              control={control}
              // rules={{ required: "othercost is required" }}
              render={({ field }) => (
                <Input
                  label={language.goldCost}
                  placeholder={language.goldCost}
                  {...field}
                  // isRequired
                  error={errors?.[field.name]?.message}
                  // value={getGoldCost({
                  //   weight: get24CaratWeight({
                  //     total_weight: formValues.totalweight || 0,
                  //     carat:
                  //       carats?.find((item) => item?.id == formValues?.carat_id)
                  //         ?.carat || 0,
                  //   }),
                  //   caratprice: formValues["24k_goldrate_pergm"] || 0,
                  // })}
                  readOnly={true}
                />
              )}
            />
          </div>
          <div className="col-lg-4 col-sm-12 mb-3">
            <Input
              label={language.totalCostMk}
              name="costmk"
              type="text"
              placeholder={language.totalCostMk}
              value={Number(formValues.costmk) * Number(formValues.totalweight)}
              readOnly
            />
          </div>

          <div className="col-md-4 mb-3">
            <DivBorderContainer title={language.priceType} isRequired>
              <Controller
                name="price_type"
                control={control}
                render={({ field }) => (
                  <div className="row">
                    <div className="col-sm-12 col-md-4 col-3 ">
                      <Form.Check
                        type="radio"
                        id="fixed"
                        label={language.fixed}
                        className="checkbox-smalllabel"
                        {...field}
                        onChange={(e) => {
                          if (!isEdit) {
                            field.onChange(e);
                            setValue("making_charge", "");
                          }
                        }}
                        value={1}
                        checked={formValues.price_type == "1"}
                        required
                      />
                    </div>
                    <div className="col-sm-12 col-md-8 col-3 ">
                      <Form.Check
                        type="radio"
                        id="market"
                        label={language.marketPrice}
                        className="checkbox-smalllabel"
                        {...field}
                        onChange={(e) => {
                          if (!isEdit) {
                            field.onChange(e);
                            setValue("price", "");
                          }
                        }}
                        value={2}
                        checked={formValues.price_type == "2"}
                        required
                        // disabled
                      />
                    </div>
                  </div>
                )}
              />
            </DivBorderContainer>
          </div>

          {formValues.producttype == "1" && formValues.price_type == "1" && (
            <div className="col-sm-12 col-md-4 mb-3">
              <Controller
                name={"price"}
                // rules={{ required: "Price is required" }}
                control={control}
                render={({ field }) => (
                  <Input
                    min={1}
                    label="Selling Price"
                    type="text"
                    placeholder="Selling Price"
                    {...field}
                    isRequired
                    onChange={(e) => {
                      const re = /^[0-9]*[.]?[0-9]*$/;
                      if (re.test(e.target.value)) {
                        field.onChange(e);
                      }
                    }}
                    error={errors?.price?.message}
                  />
                )}
              />
            </div>
          )}
          {formValues.price_type == "2" && (
            <div className="col-sm-12 col-md-4 mb-3">
              <Controller
                name="making_charge"
                control={control}
                rules={{ required: "Making Charge is required" }}
                render={({ field }) => (
                  <Input
                    min={1}
                    label="Sale MK per gm"
                    type="text"
                    placeholder="Sale MK per gm"
                    {...field}
                    onChange={(e) => {
                      const re = /^[0-9]*[.]?[0-9]*$/;
                      if (re.test(e.target.value)) {
                        field.onChange(e);
                      }
                    }}
                    isRequired
                    error={errors?.making_charge?.message}
                  />
                )}
              />
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-6 mb-3">
            <Controller
              name={"note"}
              control={control}
              // rules={{ required: "othercost is required" }}
              render={({ field }) => (
                <Input
                  label={language.note}
                  placeholder={language.note}
                  {...field}
                  // isRequired
                  error={errors?.note?.message}
                  as="textarea"
                  rows={4}
                />
              )}
            />
          </div>
        </div>

        {/* {fields?.length > 0 && (
          <DivBorderContainer title={`Variants (${fields.length})`} isRequired>
            {fields.map((element, index) => {
              return (
                <div key={index}>
                  <span>{`Carat: ${element?.carat}  Metal: ${element?.metal}  Size: ${element?.size}`}</span>
                  <div className="divider mt-2" />
                  <div className="custom-grid custom-grid-3 position-relative mb-3">
                    <div>
                      <div className="form-group">
                        <Controller
                          name={`variants.${index}.sku`}
                          control={control}
                          // rules={{ required: "Carat is required" }}
                          render={({ field }) => (
                            <Input
                              label={language.sku}
                              type="text"
                              placeholder={language.sku}
                              {...field}
                              readOnly
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="form-group">
                        <Controller
                          name={`variants.${index}.totalqty`}
                          control={control}
                          rules={{ required: "Qty is required" }}
                          render={({ field }) => (
                            <Input
                              min={1}
                              label={language.totalQty}
                              type="number"
                              placeholder={language.totalQty}
                              {...field}
                              onChange={(e) => {
                                const re = /^[0-9]*$/;
                                if (re.test(e.target.value)) {
                                  field.onChange(e);
                                }
                              }}
                              isRequired
                              error={
                                errors?.variants?.[index]?.totalqty?.message
                              }
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <Controller
                        name={`variants.${index}.totalweight`}
                        control={control}
                        rules={{ required: "Weight is required" }}
                        render={({ field }) => (
                          <Input
                            label={language.weightPerItem}
                            type="number"
                            placeholder={language.weightPerItem}
                            {...field}
                            onChange={(e) => {
                              const re = /^[0-9]*$/;
                              if (re.test(e.target.value)) {
                                field.onChange(e);
                              }
                            }}
                            isRequired
                            error={
                              errors?.variants?.[index]?.totalweight?.message
                            }
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </DivBorderContainer>
        )} */}

        {formValues.producttype == 2 && (
          <DivBorderContainer
            title="Variants"
            isRequired
            addButton={true}
            variantCount={variantCount}
          >
            {fields.map((element, index) => {
              return (
                <div key={index}>
                  <div className="custom-grid custom-grid-5 position-relative mb-3">
                    {/* <div>
                      <div className="form-group">
                        <Controller
                          name={`variants.${index}.carat_id`}
                          control={control}
                          rules={{ required: "Carat is required" }}
                          render={({ field }) => (
                            <CaratSelection
                              labelStyle={{
                                fontWeight: "bold",
                                color: "#000",
                              }}
                              caratOptions={carats}
                              carat={field.value}
                              index={index}
                              setCarat={(e) => {
                                field.onChange({
                                  target: { name: field.name, value: e },
                                });
                                setCaratWeight({
                                  carat: e,
                                  index,
                                  weight:
                                    formValues?.variants[index]?.totalweight,
                                });
                                setGoldCost({
                                  weight: setCaratWeight({
                                    carat: e,
                                    index,
                                    weight:
                                      formValues?.variants[index]?.totalweight,
                                  }),
                                });
                              }}
                              isBorder={false}
                              isRequired
                              error={
                                errors?.variants?.[index]?.carat_id?.message
                              }
                            />
                          )}
                        />
                      </div>
                    </div> */}

                    {/* <div>
                      <div className="form-group">
                        <Controller
                          name={`variants.${index}.metal_id`}
                          control={control}
                          rules={{ required: "Metal is required" }}
                          render={({ field }) => (
                            <MetalSelection
                              label={language.purchaseColors}
                              labelStyle={{
                                fontWeight: "bold",
                                color: "#000",
                              }}
                              index={index}
                              metalOptions={metals}
                              metal={field.value}
                              setMetal={(e) => {
                                field.onChange({
                                  target: { name: field.name, value: e },
                                });
                              }}
                              isBorder={false}
                              isRequired
                              error={
                                errors?.variants?.[index]?.metal_id?.message
                              }
                            />
                          )}
                        />
                      </div>
                    </div> */}
                    <div className="form-group">
                      <Controller
                        name={`variants.${index}.size_id`}
                        control={control}
                        // rules={{ required: "Size is required" }}
                        render={({ field }) => (
                          <Dropdown
                            label={language.size}
                            dropdownOptions={sizes?.map((size) => {
                              return {
                                id: size.id,
                                value: size.id,
                                label: size.size,
                              };
                            })}
                            onSelect={(e) => {
                              field.onChange({
                                target: {
                                  name: field.name,
                                  value: Number(e.target.value),
                                },
                              });
                            }}
                            // isRequired
                            {...field}
                            error={errors?.variants?.[index]?.size_id?.message}
                            size="sm"
                          />
                        )}
                      />
                    </div>

                    <div>
                      <div className="form-group">
                        <Controller
                          name={`variants.${index}.variant_qty`}
                          control={control}
                          rules={{ required: "Qty is required" }}
                          render={({ field }) => (
                            <Input
                              min={1}
                              label={language.qty}
                              type="number"
                              placeholder={language.qty}
                              {...field}
                              onChange={(e) => {
                                const re = /^[0-9]*$/;
                                if (re.test(e.target.value)) {
                                  field.onChange(e);
                                }
                              }}
                              isRequired
                              error={
                                errors?.variants?.[index]?.variant_qty?.message
                              }
                              size="sm"
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="form-group">
                        <Controller
                          name={`variants.${index}.variant_weight_perpc`}
                          control={control}
                          rules={{ required: "Weight is required" }}
                          render={({ field }) => (
                            <Input
                              label={language.weightPerGm}
                              type="number"
                              placeholder={language.weightPerGm}
                              {...field}
                              onChange={(e) => {
                                const re = /^[0-9]*$/;
                                if (re.test(e.target.value)) {
                                  field.onChange(e);
                                }
                              }}
                              isRequired
                              error={
                                errors?.variants?.[index]?.variant_weight_perpc
                                  ?.message
                              }
                              size="sm"
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div>
                      <Controller
                        name={`variants.${index}.rfid`}
                        control={control}
                        render={({ field }) => (
                          <Input
                            label={language.rfId}
                            type="text"
                            placeholder={language.rfId}
                            {...field}
                            size="sm"
                          />
                        )}
                      />
                    </div>

                    <div>
                      <Controller
                        name={`variants.${index}.price`}
                        control={control}
                        rules={{
                          required:
                            formValues.price_type == "1"
                              ? "Price is required"
                              : false,
                        }}
                        render={({ field }) => (
                          <Input
                            min={1}
                            label={language.price}
                            type="text"
                            placeholder={language.price}
                            {...field}
                            onChange={(e) => {
                              const re = /^[0-9]*[.]?[0-9]*$/;
                              if (re.test(e.target.value)) {
                                field.onChange(e);
                              }
                            }}
                            readOnly={formValues.price_type === "2"}
                            error={errors?.variants?.[index]?.price?.message}
                            size="sm"
                            isRequired
                          />
                        )}
                      />
                    </div>

                    {/* <div>
                      <Controller
                        name={`variants.${index}.costmk`}
                        control={control}
                        rules={{
                          required: "CostMK is required",
                        }}
                        render={({ field }) => (
                          <Input
                            min={1}
                            label={language.purchaseCostmk}
                            type="text"
                            placeholder={language.purchaseCostmk}
                            {...field}
                            onChange={(e) => {
                              const re = /^[0-9]*[.]?[0-9]*$/;
                              if (re.test(e.target.value)) {
                                field.onChange(e);
                              }
                            }}
                            error={errors?.variants?.[index]?.costmk?.message}
                            size="sm"
                          />
                        )}
                      />
                    </div> */}
                    {/* <div>
                      <Controller
                        name={`variants.${index}.carat_24_weight`}
                        control={control}
                        rules={{
                          required: "carat_24_weight is required",
                        }}
                        render={({ field }) => (
                          <Input
                            min={1}
                            label={language["24CaratWeight"]}
                            type="text"
                            placeholder={language["24CaratWeight"]}
                            {...field}
                            onChange={(e) => {
                              const re = /^[0-9]*[.]?[0-9]*$/;
                              if (re.test(e.target.value)) {
                                field.onChange(e);
                              }
                            }}
                            readOnly={true}
                            error={
                              errors?.variants?.[index]?.carat_24_weight
                                ?.message
                            }
                            size="sm"
                          />
                        )}
                      />
                    </div> */}
                    {/* <div>
                      <Controller
                        name={`variants.${index}.gold_cost`}
                        control={control}
                        rules={{
                          required: "Gold cost is required",
                        }}
                        render={({ field }) => (
                          <Input
                            min={1}
                            label={language.goldCost}
                            type="text"
                            placeholder={language.goldCost}
                            {...field}
                            onChange={(e) => {
                              const re = /^[0-9]*[.]?[0-9]*$/;
                              if (re.test(e.target.value)) {
                                field.onChange(e);
                              }
                            }}
                            readOnly={true}
                            error={
                              errors?.variants?.[index]?.carat_24_weight
                                ?.message
                            }
                            size="sm"
                          />
                        )}
                      />
                    </div> */}

                    {/* <div>
                      <Input
                        label={language.totalCostMk}
                        name="totalCost"
                        type="text"
                        placeholder={language.totalCostMk}
                        value={
                          (Number(formValues?.variants[index].costmk) || 0) *
                          (Number(formValues?.variants[index].totalweight) || 0)
                        }
                        readOnly
                        size="sm"
                      />
                    </div> */}

                    {index ? (
                      <div
                        style={{ position: "absolute", top: 0, left: "98%" }}
                      >
                        <Button
                          variant="danger"
                          className="d-flex justify-content-between align-items-center  p-1"
                          onClick={() => remove(index)}
                        >
                          <AiFillDelete size={10} />
                        </Button>
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            })}
          </DivBorderContainer>
        )}
      </div>
    );
  }
);

const mapStateToProps = (state) => {
  return {
    variants: state.userReducer.variants,
    storeId: state.userReducer.store_id,
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  PurchaseForm
);
