import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { postRequest } from "../../Data/ApiService";
import { ENDPOINTS } from "../../Data/Endpoint";
import language from "../../Strings/en";
import { successToast } from "../../Utils/showToast";
import InputNumber from "../Common/InputNumber";
import Modal from "../Common/Modal";

const GenerateBarcode = ({ open, onHide, purchase }) => {
  const inputRef = useRef(null);
  const [barcodes, setBarcodes] = useState([]);
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({ defaultValues: { qty: "1" } });

  const onSubmit = async (data) => {
    const response = await postRequest(
      `${ENDPOINTS.purchaseBarcode}/${purchase?.id}`,
      data
    );

    if (response.data?.data?.success) {
      successToast("Barcode created.");
    }

    onHide();
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <Modal
      show={open}
      onHide={onHide}
      size="small"
      centered={true}
      header={<h3>{language.generateBarcode}</h3>}
    >
      <div className="row">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="col-md-12">
            <Controller
              name={"qty"}
              control={control}
              rules={{ required: "Qty is required." }}
              render={({ field }) => (
                <InputNumber
                  {...field}
                  placeholder={language.qty}
                  label={language.qty}
                  error={errors.barcode?.message}
                  ref={inputRef}
                  onChange={(e) => {
                    if (
                      Number(e.target.value) > 0 &&
                      Number(purchase?.quantity) >= Number(e.target.value)
                    ) {
                      field.onChange(e);
                    }
                  }}
                />
              )}
            />
          </div>
          <div className="col-md-12 mt-3 d-flex justify-content-end">
            <Button
              className="btn-free"
              variant="warning"
              type="submit"
              disabled={!isValid}
            >
              Done
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default GenerateBarcode;
