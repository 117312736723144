import React, { useEffect, useRef, useState } from "react";
import { Button, Nav, Tab, Tabs } from "react-bootstrap";
import DatePicker from "../Components/Common/DatePicker";
import DailySaleView from "../Components/DailySale/DailySaleView";
import Page from "../Layout/Page";
import language from "../Strings/en";
import ReactToPrint from "react-to-print";
import { getRequest } from "../Data/ApiService";
import { ENDPOINTS } from "../Data/Endpoint";
import moment from "moment";
import { DATE_FORMAT } from "../Config";
import { errorToast } from "../Utils/showToast";
import { exportToExcel } from "../Utils/exportExcel";

const Reports = () => {
  const ref = useRef(null);
  const [filter, setFilter] = useState({
    start_date: moment().format(DATE_FORMAT.normal),
    end_date: moment().format(DATE_FORMAT.normal),
  });

  const [reportData, setReportData] = useState({
    group_sales: {},
    item_sales: {},
    paymenttype_sales: {},
    user_sales: {},
  });

  const fetchClosingReport = async () => {
    await getRequest(ENDPOINTS.getClosingReport, {
      start_date: filter.start_date,
      end_date: filter.end_date,
    }).then((res) => {
      const data = res.data?.data;
      if (data.success) {
        setReportData({
          group_sales: data?.group_sales,
          item_sales: data?.item_sales,
          paymenttype_sales: data?.paymenttype_sales,
          user_sales: data?.user_sales,
        });
      } else {
        errorToast(data.msg);
      }
    });
  };

  useEffect(() => {
    fetchClosingReport();
  }, []);

  const exportView = () => {
    let filteredData = {};
    Object.keys(reportData).forEach((key) => {
      let summary = reportData[key].summary;
      summary.push({});
      Object.keys(reportData[key])?.forEach((item) => {
        if (item != "summary") {
          const subKeys = Object.keys(summary[0]);

          summary.push({
            [subKeys[0]]: item,
            [subKeys[1]]: reportData[key][item],
          });
        }
      });
      filteredData = { ...filteredData, [key]: summary };
    });

    exportToExcel({
      exportData: filteredData,
      fileName: "Report",
      skipDefaultTitle: false,
    });
  };

  const [activeTab, setActiveTab] = useState("dailyreport");
  const [reports, setReports] = useState([
    {
      label: "Daily Report",
      value: "dailyreport",
    },
    {
      label: "Closing Report",
      value: "closingreport",
    },
    {
      label: "Weekly Report",
      value: "weeklyreport",
    },
  ]);

  const isDisabled = Object.keys(reportData)?.some((item) => {
    if (item?.summary?.length > 0) {
      return false;
    } else {
      return true;
    }
  });

  return (
    <Page>
      <div className="container">
        <div className="row ">
          <div className="col-md-12">
            <h1>{language.dbReport}</h1>
          </div>
          <div className="col-md-2 col-sm-12 gx-4 sidebar-menu mt-4 ">
            <div>
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="dailyreport"
              >
                <Nav variant="pills" className="flex-column">
                  {reports.map((tabItems, index) => {
                    return (
                      <Nav.Item key={index}>
                        <Nav.Link
                          eventKey={tabItems.value}
                          className="custom-tab-2"
                        >
                          {tabItems.label}
                        </Nav.Link>
                      </Nav.Item>
                    );
                  })}
                </Nav>
              </Tab.Container>
            </div>
          </div>
          <div className="col-md-10 col-sm-12 gx-5">
            <div className="row">
              <div className="col-md-12">
                <div className="row align-items-end">
                  <div className="col-md-4">
                    <div className="d-flex align-items-end">
                      <div>
                        <DatePicker
                          minDate={new Date()}
                          label={language.from}
                          value={filter.start_date}
                          onChange={(e) =>
                            setFilter((prev) => ({
                              ...prev,
                              start_date: e.target.value,
                            }))
                          }
                        />
                      </div>
                      <div className="ms-2">
                        <DatePicker
                          minDate={new Date()}
                          label={language.to}
                          value={filter.end_date}
                          onChange={(e) =>
                            setFilter((prev) => ({
                              ...prev,
                              end_date: e.target.value,
                            }))
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <Button variant="warning" onClick={fetchClosingReport}>
                      Search
                    </Button>
                    <Button
                      variant="secondary ms-2"
                      onClick={exportView}
                      disabled={isDisabled}
                    >
                      Export
                    </Button>

                    <ReactToPrint
                      content={() => ref.current}
                      documentTitle="auric-report"
                      trigger={() => (
                        <Button variant="secondary ms-2" disabled={isDisabled}>
                          Print
                        </Button>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12 mt-5">
                <DailySaleView
                  ref={ref}
                  reportData={reportData}
                  start_date={filter.start_date}
                  end_date={filter.end_date}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Reports;
