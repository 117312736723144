import React, { useState } from "react";
import InputNumber from "../Common/InputNumber";

const RedIndicatorInput = ({ name, value, onChange, compareTo }) => {
  const [isLessThan, setIsLessThan] = useState(false);
  return (
    <InputNumber
      value={value}
      style={{
        backgroundColor: isLessThan ? "red" : "transparent",
        color: isLessThan ? "#fff" : "#000",
      }}
      onChange={(e) => {
        onChange(e);
        if (
          Number(e.target.value) < Number(compareTo) &&
          e.target.value !== null &&
          e.target.value !== ""
        ) {
          setIsLessThan(true);
        } else {
          setIsLessThan(false);
        }
      }}
      name={name}
    />
  );
};

export default RedIndicatorInput;
