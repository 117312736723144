import { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";

function ContextAwareToggle({
  children,
  eventKey,
  setActiveEventKey,
  activeEventKey,
}) {
  const decoratedOnClick = useAccordionButton(eventKey, (e) => {
    setActiveEventKey(eventKey === activeEventKey ? 0 : eventKey);
    // set active accordion
  });

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <span
      type="button"
      className={`accordian-item-custom ${isCurrentEventKey ? "active" : ""}`}
      onClick={decoratedOnClick}
    >
      {children}
      <i className="arrow down"></i>
    </span>
  );
}

function AccordionCustom({ data, onSelect }) {
  const [activeEventKey, setActiveEventKey] = useState("0");

  return (
    <Accordion className="custom-accordion" defaultActiveKey="0">
      {data?.map((data) => {
        return (
          <div key={data?.id} className="accordion-item-container">
            <ContextAwareToggle
              eventKey={data?.id}
              setActiveEventKey={setActiveEventKey}
              activeEventKey={activeEventKey}
            >
              {data?.label}
            </ContextAwareToggle>

            <Accordion.Collapse eventKey={data?.id}>
              <Card.Body>
                {data?.groups?.map((group) => {
                  return (
                    <span key={group?.id} onClick={() => onSelect(group)}>
                      {group?.label}
                    </span>
                  );
                })}
              </Card.Body>
            </Accordion.Collapse>
          </div>
        );
      })}
    </Accordion>
  );
}

export default AccordionCustom;
