import { PRICE_ACTIONS } from "../../reducers/priceReducer";

export const setDailyPrice = (price) => {
  return (dispatch) => {
    dispatch({
      type: PRICE_ACTIONS.SET_DAILY_PRICE,
      payload: price,
    });
  };
};
