import React from "react";
import { Button } from "react-bootstrap";
import Modal from "./Modal";

const AlertDialog = ({
  show,
  onHide,
  title,
  button = ["Yes", "No"],
  successAction,
  cancleAction,
  closeButton,
}) => {
  return (
    <Modal
      closeButton={closeButton}
      show={show}
      size="md"
      onHide={onHide}
      centered
      className="AlertDialog"
      header={<h5>{title}</h5>}
      alertDialog={true}
      footer={
        <>
          <Button
            variant="warning"
            className="alert-success-btn"
            onClick={successAction}
          >
            {button[0]}
          </Button>
          <Button
            variant="secondary"
            className="alert-cancle-btn"
            onClick={cancleAction}
          >
            {button[1]}
          </Button>
        </>
      }
    ></Modal>
  );
};

export default AlertDialog;
