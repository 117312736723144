export default function Metal({
  color,
  id,
  groupName,
  metal,
  setMetal,
  isRequired,
}) {
  return (
    <div className={`custom-radio ${color?.toLowerCase()}`}>
      <input
        type="radio"
        name={groupName}
        color={color?.toLowerCase()}
        id={`${groupName}_${color?.toLowerCase()}`}
        checked={metal === id}
        onChange={(_) => {
          setMetal(id);
        }}
        required={isRequired}
      />
      <label
        className={`metal-${color?.toLowerCase()} metal`}
        htmlFor={`${groupName}_${color?.toLowerCase()}`}
      ></label>
    </div>
  );
}
