import React, { useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { connect } from "react-redux";
import LoadingBtn from "../Components/Common/Button";
import DivBorderContainer from "../Components/Common/DivBorderContainer";
import Input from "../Components/Common/Input";
import Loading from "../Components/Common/Loading";
import SelectSearch from "../Components/Common/SelectSearch";
import Stats from "../Components/Common/Stats";
import CONFIG from "../Config/BaseURL";
import { getRequest } from "../Data/ApiService";
import { ENDPOINTS } from "../Data/Endpoint";
import useCategories from "../Hooks/useCategories";
import useIsMobile from "../Hooks/useIsMobile";
import useStats from "../Hooks/useStats";
import useSuppliers from "../Hooks/useSuppliers";
import useVariants from "../Hooks/useVariants";
import Page from "../Layout/Page";
import language from "../Strings/en";
import { isEmpty } from "../Utils/empty";

const Inventory = ({ storeId }) => {
  const [filters, setFilters] = useState({
    text: "",
    instock: "",
    category: "",
  });
  const [page, setPage] = useState(1);
  const [inventory, setInventory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMorePages, setHasMorePages] = useState(false);
  const [editInventory, setEditInventory] = useState({});

  const [createModal, setCreateModal] = useState({
    visibility: false,
    isEdit: false,
  });
  const [stockModal, setStockModal] = useState({
    visibility: false,
    isEdit: false,
  });

  const fetchInventory = async () => {
    console.log(filters);
    setLoading(true);
    const res = await getRequest(ENDPOINTS.getInventory, {
      page,
      storeId,
      ...(!isEmpty(filters.text) && { text: filters.text }),
      ...(!isEmpty(filters.instock) && { instock: filters.instock }),
      ...(!isEmpty(filters.category) && { group_id: filters.category }),
    });

    if (res.status === 200) {
      setLoading(false);
      if (page > 1) {
        setInventory((prev) => [...prev, ...res?.data?.data?.products?.data]);
      } else {
        setInventory(res?.data?.data?.products?.data);
      }
      setHasMorePages(res?.data?.data?.products?.next_page ? true : true);
    }
  };

  const actionOnInventory = (newPurchase, isEdit) => {
    // isEdit
    //   ? setInventory(
    //       inventory?.map((purchase) =>
    //         purchase?.id === newPurchase?.id ? newPurchase : purchase
    //       )
    //     )
    //   : setInventory((prev) => [newPurchase, ...prev]);
    fetchInventory();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
    setPage(1);
  };

  useEffect(() => {
    fetchInventory();
  }, [page, filters]);

  const loadMore = () => {
    setPage(page + 1);
  };
  const { sizes, carats, metals } = useVariants();
  const { categories } = useCategories();
  const { suppliers } = useSuppliers();
  const [showBarcode, setShowBarcode] = useState({
    visibility: false,
    src: "",
  });
  const { stats } = useStats("inventory");
  const [search, setSearch] = useState("");
  const enterSearch = (e) => {
    e.preventDefault();
    setFilters((prev) => ({ ...prev, text: search }));
    setPage(1);
  };

  const openModalForEdit = (purchase) => {
    setEditInventory({
      id: purchase?.product_id,
      variantId: purchase?.store_product_variant_id,
      name: purchase.title_en,
      weight: purchase.weight,
      carat: purchase.carat_id,
      color: purchase.metal_id,
      size: purchase.size_id,
      supplier: purchase.supplier_id,
      category: purchase.category_name,
      purchaseDate: purchase.purchase_date,
      purchaseQty: purchase.quantity,
      goldRate: purchase.gold_rate,
      costmk: purchase.costmk,
      payment: String(purchase.purchase_type),
      sellingPrice: purchase.product_price,
      variants: purchase?.variants,
      image_name: !purchase?.image_name?.includes("placeholder")
        ? `${CONFIG.IMAGE_ROOT_URL}${purchase.image_name}`
        : null,
      supplier_files: purchase?.supplierfiles,
      total_vat: purchase?.total_vat,
    });
    setCreateModal({ visibility: true, isEdit: true });
  };

  const { isMobile } = useIsMobile();

  return (
    <Page>
      <div className="container mt-2">
        <div className="row">
          <div className="col-sm-12 d-flex mb-3 flex-wrap">
            <Stats
              title="Total Products"
              count={stats?.total_products}
              col="flex-fill"
            />
            <Stats
              title="Total Quantity"
              count={stats?.total_quantity}
              col="flex-fill"
            />
            <Stats
              title="Total Variants"
              count={stats?.total_variants}
              col="flex-fill"
            />
            <Stats
              title="Total Weight"
              count={stats?.total_weight}
              col="flex-fill"
            />
            <Stats
              title="Products with fixed price"
              count={stats?.products_fixed_price}
              col="flex-fill"
            />
            <Stats
              title="Products with market price"
              count={stats?.products_market_price}
              col="flex-fill"
            />
          </div>
          <div className="col-sm-12">
            <div className="row align-items-center mb-4">
              <div className="col-md-1">
                <h1>Inventory</h1>
              </div>
              <div className="col-md-2 offset-md-1">
                {/* <form onSubmit={enterSearch}> */}
                <Input
                  placeholder="Search title"
                  label="Search"
                  name="text"
                  value={filters.text}
                  onChange={handleChange}
                />
                {/* </form> */}
              </div>
              <div className="col-md-4">
                <DivBorderContainer title={language.filterBy}>
                  <div className="row">
                    <div className="col-lg-3 col-sm-4">
                      <Form.Check
                        type="radio"
                        id="allstock"
                        label={language.inventoryAll}
                        name="instock"
                        value=""
                        onChange={handleChange}
                        checked={filters.instock === ""}
                      />
                    </div>
                    <div className="col-lg-5 col-sm-4">
                      <Form.Check
                        type="radio"
                        id="outstock"
                        label={language.inventoryOutOfStock}
                        name="instock"
                        value="0"
                        onChange={handleChange}
                        checked={filters.instock === "0"}
                      />
                    </div>
                    <div className="col-lg-4 col-sm-4">
                      <Form.Check
                        type="radio"
                        id="instock"
                        label={language.inventoryInStock}
                        name="instock"
                        value="1"
                        onChange={handleChange}
                        checked={filters.instock === "1"}
                      />
                    </div>
                  </div>
                </DivBorderContainer>
              </div>
              <div className="col-md-2">
                <SelectSearch
                  placeholder="Category"
                  options={categories}
                  size="lg"
                  value={
                    typeof filters.category === "string"
                      ? filters.category
                      : categories
                          ?.find((category) =>
                            category?.groups?.some(
                              (group) => group?.id === filters.category
                            )
                          )
                          ?.groups?.find(
                            (group) => group?.id === filters.category
                          ).label
                  }
                  label="Category"
                  treeView={true}
                  onSelect={(value) =>
                    handleChange({
                      target: { name: "category", value: value.value },
                    })
                  }
                  searchable={false}
                />
              </div>
              <div className="col-md-2 mt-4">
                <Button
                  variant="secondary"
                  onClick={() =>
                    setCreateModal({ visibility: true, isEdit: false })
                  }
                >
                  {language.createPurchase}
                </Button>
              </div>
              {/* <div className="col-md-2 mt-4">
                <Button
                  variant="secondary"
                  onClick={() =>
                    setStockModal({ visibility: true, isEdit: false })
                  }
                >
                  {language.stockModal}
                </Button>
              </div> */}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Table
              className="table table--orders"
              hover
              responsive="md"
              striped
            >
              <thead>
                <tr>
                  <td>{language.purchaseName}</td>
                  <td>{language.sku}</td>
                  <td>{language.barcode}</td>
                  <td>{language.onnHandQty}</td>
                  <td>{language.weightOnHand}</td>
                </tr>
              </thead>
              <tbody>
                {inventory?.length > 0 ? (
                  inventory.map((o, index) => (
                    <tr key={index}>
                      <td>{o?.product_name || "-"}</td>
                      <td>{o?.sku || "-"}</td>
                      <td>{o?.barcode_number || "-"}</td>
                      <td>{o?.quantity || "-"}</td>
                      <td>{o?.total_weight || "-"}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    {loading ? (
                      <td colSpan={13}>
                        <Loading />
                      </td>
                    ) : (
                      <td colSpan={13}>No records found</td>
                    )}
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
        {hasMorePages && (
          <div style={{ textAlign: "center" }}>
            <LoadingBtn onClick={loadMore}>Load More</LoadingBtn>
          </div>
        )}
        {loading && page > 1 && <Loading />}
      </div>
      {/* <BarcodeDialog
        show={showBarcode.visibility}
        src={showBarcode.src}
        hide={() => setShowBarcode({ visibility: false, src: "" })}
      /> */}

      {/* {createModal.visibility && (
        <CreatePurchaseModal
          isCreateModalOpen={createModal.visibility}
          isEdit={createModal.isEdit}
          hideCreateModal={() =>
            setCreateModal({ visibility: false, isEdit: false })
          }
          purchaseData={editInventory}
          actionOnPurchase={actionOnInventory}
        />
      )} */}
      {/* {stockModal.visibility && isMobile ? (
        <MobileStockRfidModal
          openModal={stockModal.visibility}
          hideModal={() => setStockModal({ visibility: false, isEdit: false })}
        />
      ) : (
        <StockModal
          isCreateModalOpen={stockModal.visibility}
          isEdit={stockModal.isEdit}
          hideCreateModal={() =>
            setStockModal({ visibility: false, isEdit: false })
          }
          size={"xl"}
        />
      )} */}
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    storeId: state.userReducer.store_id,
  };
};
export default connect(mapStateToProps)(Inventory);
