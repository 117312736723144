import React from "react";
import Form from "react-bootstrap/Form";
import { isEmpty } from "../../Utils/empty";
const Dropdown = React.forwardRef(
  (
    {
      label,
      dropdownOptions,
      size = "lg",
      usedAsValue = "value",
      onSelect,
      value,
      isRequired = false,
      name,
      error,
    },
    ref
  ) => {
    return (
      <Form.Group className="form-group">
        <Form.Label className="mb-0">
          {label && label}
          {isRequired && <span className="text-danger">*</span>}
        </Form.Label>
        <Form.Select
          size={size}
          className="select"
          value={value}
          onChange={onSelect}
          required={isRequired}
          name={name}
          ref={ref}
        >
          <option value="">{`Select ${label}`}</option>
          {!isEmpty(dropdownOptions) &&
            dropdownOptions?.map((option) => (
              <option key={option.id} value={option[usedAsValue]}>
                {option.label}
              </option>
            ))}
        </Form.Select>
        <span className="error-label">{error}</span>
      </Form.Group>
    );
  }
);

export default Dropdown;
