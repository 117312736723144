export const ENDPOINTS = {
  login: "/public/api/pos/v1/login",
  categories: "/public/api/categories",
  groups: "/public/api/groups",
  products: "/public/api/products",
  productDetails: "/public/api/products/",
  cartUpdate: "/public/api/pos/v1/updateorderitem",
  cart: "/public/api/pos/v1/additemtoorder",
  cartRemove: "/public/api/pos/v1/removeorderitem/remove/",
  cartClear: "/public/api/cart/clear/",
  cartTotal: "/public/api/pos/v1/carttotal/",
  createOrder: "/public/api/pos/v1/createorder",
  removeOrder: "/public/api/pos/v1/order/",
  updateOrder: "/public/api/pos/v1/placeorder",
  listOrders: "/public/api/orders",
  getOrder: "/public/api/pos/v1/order",
  createCustomer: "/public/api/pos/v1/customer/create",
  updateCustomer: "/public/api/pos/v1/customer/update/",
  getPaymentType: "/public/api/pos/v1/getpaymenttype",
  listCustomer: "/public/api/pos/v1/customers",
  createSupplier: "/public/api/pos/v1/suppliers/create",
  deleteCustomer: "/public/api/pos/v1/customer/delete",
  deleteSupplier: "/public/api/pos/v1/suppliers/delete",
  updateSupplier: "/public/api/pos/v1/suppliers/update",
  uploadPurchaseFiles: "/public/api/pos/v1/purchase/upload-file",
  deletePurchaseFiles: "/public/api/pos/v1/purchase/delete-file",
  getProductByRfid: "/public/api/pos/v1/product/rfid",
  // purchase
  createPurchaseItem: "/public/api/pos/v1/purchase",
  updatePurchaseItem: "/public/api/pos/v1/purchase",

  updateProductItem: "/public/api/pos/v1/updateproduct",
  createProductItem: "/public/api/pos/v1/product",
  deleteProductItem: "/public/api/pos/v1/deleteproduct",
  suppliers: "/public/api/pos/v1/suppliers",
  supplierslist: "/public/api/pos/v1/supplierslist",
  variants: "/public/api/pos/v1/variants",
  search: "/public/api/pos/v1/search",
  carats: "/public/api/pos/v1/carats",
  //Inventory
  getInventory: "/public/api/pos/v1/inventory",
  setStock: "/public/api/pos/v1/stock",
  getStock: "/public/api/pos/v1/stockproducts",
  getStockReport: "/public/api/pos/v1/stockreport",
  getStockDetail: "/public/api/pos/v1/stockreport/detail",

  //Stats

  getStats: "/public/api/pos/v1/stats",
  getTaxs: "/public/api/pos/v1/getstoretax",
  linkRfid: "/public/api/pos/v1/writetorfid",
  saveRemark: "/public/api/pos/v1/stocktake/saveremark",
  approveStockTake: "/public/api/pos/v1/stocktake/approve",
  getPurchase: "/public/api/pos/v1/purchase",
  deletePurchase: "/public/api/pos/v1/purchase",
  getProducts: "/public/api/pos/v1/products",

  getPurchaseTargetQty: "/public/api/pos/v1/rfid",
  submitPurchaseTargetQty: "/public/api/pos/v1/rfid/save",
  refundOrder: "/public/api/pos/v1/refundorder",

  getDashboardStats: "/public/api/pos/v1/dashboard/stats",
  getClosingReport: "/public/api/pos/v1/closingreport",

  uploadCustomerFiles: "/public/api/pos/v1/customer/upload-file",
  getCustomerFiles: "/public/api/pos/v1/customer/attachments",
  deleteCustomerFiles: "/public/api/pos/v1/customer/delete-file",

  uploadSupplierFiles: "/public/api/pos/v1/suppliers/upload-file",
  getSupplierFiles: "/public/api/pos/v1/suppliers/attachments",
  deleteSupplierFiles: "/public/api/pos/v1/suppliers/delete-file",

  // bars
  createGoldBars: "/public/api/pos/v1/goldbar",
  getGoldBars: "/public/api/pos/v1/goldbar",
  deleteGoldBars: "/public/api/pos/v1/goldbar",
  goldBarAttachments: "/public/api/pos/v1/goldbar/attachments",
  uploadGoldBarAttachments: "/public/api/pos/v1/goldbar/upload-file",
  deleteGoldBarAttachments: "/public/api/pos/v1/goldbar/delete-file",

  // bars transaction
  createGoldTransaction: "/public/api/pos/v1/goldbartransaction",
  getGoldTransaction: "/public/api/pos/v1/goldbartransaction",
  deleteGoldTransaction: "/public/api/pos/v1/goldbartransaction",
  updateGoldTransaction: "/public/api/pos/v1/goldbartransaction",

  supplierPayout: "/public/api/pos/v1/payout",
  supplierMKPayout: "/public/api/pos/v1/mkpayout",

  purchaseFromBarcode: "/public/api/pos/v1/purchase-list",
  purchaseBarcode: "/public/api/pos/v1/barcode",
};

export const EXTERNAL_ENDPOINTS = {
  goldRates:
    "https://forex-data-feed.swissquote.com/public-quotes/bboquotes/instrument/XAU/USD",
};
