import React, { useEffect, useState } from 'react'
import { getRequest } from '../Data/ApiService';
import { ENDPOINTS } from '../Data/Endpoint';
import { errorToast } from '../Utils/showToast';

const useStats = (type) => {
    const [stats, setStats] = useState({})
    const loadStats = async () => {
        try {
            const res = await getRequest(ENDPOINTS.getStats, { type }, null);
            if (res.data?.data?.success) {
                setStats(res.data?.data)
            } else {
                errorToast(res.data?.data?.msg);
            }
        } catch (error) {
            errorToast(error.message);
        }
    };

    useEffect(() => {
        loadStats();
    }, []);

    return { stats }
}

export default useStats