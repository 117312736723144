import React from "react";
import { Button, Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import language from "../../Strings/en";
import CustomerDropdown from "../Common/CustomerDropdown";
import DivBorderContainer from "../Common/DivBorderContainer";
import SelectComponent from "../Common/SelectComponent";
import SupplierDropdown from "../Common/SupplierDropdown";
import { postRequest } from "../../Data/ApiService";
import { ENDPOINTS } from "../../Data/Endpoint";
import { isEmpty } from "../../Utils/empty";

const CreateBarForm = ({ responseAction }) => {
  const defaultValues = {
    supplier_id: null,
    customer_id: null,
    type: "Customer",
  };
  const {
    control,
    watch,
    formState: { errors },
    reset,
    handleSubmit,
    setValue,
  } = useForm({ defaultValues });
  const formValues = watch();

  const submit = async (data) => {
    let payload = {};
    data.supplier_id = data?.supplier_id?.id;
    data.customer_id = data?.customer_id?.id;
    delete data.type;

    Object.keys(data)?.forEach((key) => {
      if (!isEmpty(data[key])) {
        payload[key] = data[key];
      }
    });

    await postRequest(`${ENDPOINTS.createGoldBars}`, payload).then((res) => {
      responseAction();
      reset();
    });
  };
  return (
    <div className="mt-4">
      <div className="row">
        <div className="col-md-12">
          <Controller
            name={"type"}
            control={control}
            render={({ field }) => (
              <DivBorderContainer title={language.type} isRequired>
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-3 ">
                    <Form.Check
                      type="radio"
                      id={language.supplier}
                      label={"Bar from supplier"}
                      onChange={(e) => {
                        field.onChange(e);
                        setValue("customer_id", null);
                      }}
                      value={"Supplier"}
                      checked={formValues.type === "Supplier"}
                      required
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-3 ">
                    <Form.Check
                      type="radio"
                      id={language.customer}
                      label={"Scrape from customer"}
                      onChange={(e) => {
                        field.onChange(e);
                        setValue("supplier_id", null);
                      }}
                      value={"Customer"}
                      checked={formValues.type === "Customer"}
                      required
                    />
                  </div>
                </div>
              </DivBorderContainer>
            )}
          />
        </div>
        {formValues.type === "Customer" ? (
          <div className="col-md-12 mt-3" key={"customer_id"}>
            <Controller
              name={"customer_id"}
              rules={{ required: "Please select customer." }}
              control={control}
              render={({ field }) => (
                <CustomerDropdown
                  {...field}
                  error={errors?.[field.name]?.message}
                />
              )}
            />
          </div>
        ) : (
          <div className="col-md-12 mt-3" key={"supplier_id"}>
            <Controller
              name={"supplier_id"}
              control={control}
              rules={{ required: "Please select Supplier." }}
              render={({ field }) => (
                <SupplierDropdown
                  {...field}
                  error={errors?.[field.name]?.message}
                />
              )}
            />
          </div>
        )}
        <div className="col-md-12 my-3 mt-4 justify-content-end d-flex">
          <Button
            variant="warning"
            onClick={handleSubmit(submit)}
            className="btn-free "
          >
            {"Done"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreateBarForm;
