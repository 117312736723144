import { CART_ACTIONS } from '../../reducers/cartReducer';

export const setGoldPrice = (price) => {
  return (dispatch) => {
    dispatch({
      type: CART_ACTIONS.SET_GOLD_PRICE,
      payload: price,
    });
  };
};
