import React from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import Link from "../Components/Common/Link";
import language from "../Strings/en";
import strings from "../Strings/en";

import { displayThreeDecimal } from "../Utils/displayThreeDecimal";
import useVariants from "../Hooks/useVariants";

const Invoice = React.forwardRef(
  (
    {
      total,
      tax,
      subTotal,
      taxName,
      cartProducts,
      customerDetails,
      usedSettlementMethods,
      orderCreatedDate,
      storeName,
      storeAddress,
      storeNumber,
      orderNo,
      userName,
    },
    ref
  ) => {
    const { carats, metals, sizes } = useVariants();
    return (
      <div className="pt-5 invoice-print" ref={ref} id="invoice">
        <div className="invoice-body">
          <p className="d-print-none">
            <Link to="/sale">Back</Link>
          </p>
          <h1 className="text-center invoice-title m-0 mb-3">{storeName}</h1>
          {/* Invoice items */}
          <div className="store-detail text-center fs-0">
            <small>
              <p className="m-0">Address - {storeAddress}</p>
              <p>Phone - {storeNumber}</p>
            </small>
          </div>
          <div className="invoice-to mt-4">
            <h3 className="invoice-section-title">Invoice to</h3>
            <div className="invoice-item">
              <strong>Order No.</strong>
              <p className="m-0">{orderNo}</p>
            </div>
            <div className="invoice-item">
              <strong>Name</strong>
              <p className="m-0">{customerDetails.customer_name || "-"}</p>
            </div>
            <div className="invoice-item">
              <strong>Address</strong>
              <p className="m-0">
                {customerDetails.address_line1 && customerDetails.address_line2
                  ? `${customerDetails.address_line1} ${customerDetails.address_line2} ${customerDetails.city} ${customerDetails.zipcode}`
                  : "-"}
              </p>
            </div>
            <div className="invoice-item">
              <strong>Date</strong>
              <p className="m-0">{orderCreatedDate}</p>
            </div>
            <div className="invoice-item">
              <strong>Payment Methods</strong>
              <p className="m-0">
                {usedSettlementMethods.length === 0 ? (
                  "-"
                ) : (
                  <div>
                    {usedSettlementMethods.map((p) => (
                      <div className="payment-method">
                        {p.payment_type} - BD {displayThreeDecimal(p.amount)}
                      </div>
                    ))}
                  </div>
                )}
              </p>
            </div>
          </div>

          {/* Products */}
          <div className="invoice-products mt-3">
            <Table className="table--products mt-2" striped borderless>
              <thead>
                <tr>
                  <td style={{ width: "50%" }}>Item</td>
                  <td style={{ width: "15%" }}>Qty</td>
                  <td style={{ width: "15%" }}>Sale price</td>
                  <td style={{ width: "15%" }}>Making charge</td>
                  <td style={{ width: "15%" }}>TAX</td>
                  <td style={{ width: "30%", textAlign: "right" }}>Subtotal</td>
                </tr>
              </thead>

              <tbody>
                {cartProducts.map((p, index) => {
                  return (
                    <tr key={index}>
                      <td className="invoice-product-detail">
                        <strong>{p.product_name || p.title_en}</strong>
                        {p?.producttype == 2 && (
                          <div className="subitem">
                            {strings.carat}:{" "}
                            {carats?.find((c) =>
                              [p.carat_id, p.carat].includes(c.id)
                            )?.carat || 0}{" "}
                            &nbsp; {strings.color}:{" "}
                            {metals?.find((c) =>
                              [p.metal_id, p.metal].includes(c.id)
                            )?.metal || "-"}{" "}
                            &nbsp; {strings.size}:{" "}
                            {sizes?.find((c) =>
                              [p.size_id, p.size].includes(c.id)
                            )?.size || 0}
                          </div>
                        )}
                      </td>
                      <td>{p.qty}</td>
                      <td>
                        {displayThreeDecimal(p?.product_amt / p?.product_qty)}
                      </td>
                      <td>{displayThreeDecimal(p?.mk || p?.making_charge)}</td>
                      <td>
                        {p?.tax_amt == 0 ? "" : displayThreeDecimal(p?.tax_amt)}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {displayThreeDecimal(p?.product_amt)}
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td colSpan={6}>
                    <h5 className="m-0 p-2 pe-0 total">
                      <strong>
                        <span>{language.billSubTotal}</span>:&nbsp;
                        BD&nbsp;&nbsp;
                        {displayThreeDecimal(subTotal)}
                      </strong>
                    </h5>
                    <h5 className="m-0 p-2 pe-0 total">
                      <strong>
                        <span>{taxName}</span>:&nbsp; BD&nbsp;&nbsp;
                        {displayThreeDecimal(tax)}
                      </strong>
                    </h5>
                    <h5 className="m-0 p-2 pe-0 total">
                      <strong>
                        <span>Total</span>:&nbsp; BD&nbsp;&nbsp;
                        {displayThreeDecimal(total)}
                      </strong>
                    </h5>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
        <div className="invoice-footer">
          <hr />
          <p className="m-0">This is a computer generated invoice.</p>
          <p>Created by - {userName}</p>
        </div>
      </div>
    );
  }
);
const mapStateToProps = (state) => {
  return {
    cartProducts: state.cartReducer.products,
    total: state.cartReducer.total,
    tax: state.cartReducer.tax,
    subTotal: state.cartReducer.subTotal,
    taxName: state.cartReducer.taxName,
    orderCreatedDate: state.cartReducer.orderCreatedDate,
    customerDetails: state.customerDetailsReducer,
    usedSettlementMethods: state.settlementReducer.usedSettlementMethods,
    storeName: state.userReducer.store_name,
    storeNumber: state.userReducer.userstore.store.contact_no,
    storeAddress: state.userReducer.userstore.store.contact_address,
    userName: state.userReducer.name,
    orderNo: state.cartReducer.orderNo,
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  Invoice
);
