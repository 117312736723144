import React, { useMemo } from "react";

const BarcodePrint = React.forwardRef(({ barcodes }, ref) => {
  const UI = useMemo(() => {
    if (barcodes?.length > 0) {
      return barcodes?.map((barcode, index) => {
        return <div key={index}>{barcode?.barcode}</div>;
      });
    } else {
      return <p>No Data Found</p>;
    }
  }, [barcodes]);

  return (
    <div ref={ref} className="p-4 barcode-print d-none d-print-grid">
      {UI}
    </div>
  );
});

export default BarcodePrint;
