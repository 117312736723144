export default function Size({
  label,
  id,
  groupName,
  setSize,
  size,
  isRequired,
}) {
  return (
    <div className="custom-radio custom-radio--square">
      <input
        type="radio"
        name={groupName}
        label={label}
        id={`${groupName}_${label}`}
        onChange={(_) => setSize(id)}
        checked={size === id}
        required={isRequired}
      />
      <label htmlFor={`${groupName}_${label}`}>{label}</label>
    </div>
  );
}
