import React, { useRef, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { getRequest } from "../../Data/ApiService";
import { ENDPOINTS } from "../../Data/Endpoint";
import language from "../../Strings/en";
import { isEmpty } from "../../Utils/empty";
import Input from "../Common/Input";
import InputNumber from "../Common/InputNumber";
import SelectSearch from "../Common/SelectSearch";

const SupplierForm = () => {
  const {
    control,
    formState: { errors, defaultValues },
    watch,
    setValue,
    clearErrors,
    reset,
  } = useFormContext();
  const searchRef = useRef(null);

  const [options, setOptions] = useState([]);

  const onSelect = (customer) => {
    setValue({ ...customer });
  };

  const triggerSearch = async (search) => {
    // e.preventDefault();
    if (!isEmpty(search)) {
      let res = await getRequest(ENDPOINTS.listCustomer, {
        ...(!isEmpty(search) && {
          name: search,
        }),
      });
      if (res.data.data.success) {
        setOptions(
          res.data.data.customers?.map((customer) => ({
            ...customer,
            key: customer?.id,
            label: customer?.customer_name,
          }))
        );
        searchRef.current.setShowOption(true);
      }
    }
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 1000);
    };
  };

  const searching = async (e) => {
    debounce(triggerSearch(e?.target.value));
  };

  return (
    <div>
      <div className="row mb-3">
        <div className="col-sm-12 col-md-6 col-lg-4">
          <div className="form-group">
            <label>{language.supplierName}</label>

            <Controller
              name={"supplier_name"}
              control={control}
              rules={{ required: "Supplier name is required." }}
              render={({ field }) => (
                <SelectSearch
                  value={field.value}
                  size="lg"
                  onSelect={onSelect}
                  options={options}
                  placeholder={language.supplierName}
                  onSearch={(value) => {
                    const event = {
                      target: { name: "supplier_name", value },
                    };
                    field.onChange(event);
                    // handleSupplierDetails(event);
                  }}
                  error={errors?.supplier_name?.message}
                />
              )}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-4">
          <div className="form-group">
            <label>{language.customerPhoneNumber}</label>
            <Controller
              name={"contact"}
              control={control}
              //   rules={{ required: "You can only select product from list." }}
              render={({ field }) => (
                <InputNumber
                  placeholder={language.customerPhoneNumber}
                  {...field}
                  error={errors?.[field.name]?.message}
                />
              )}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-4">
          <div className="form-group">
            <label>{language.email}</label>
            <Controller
              name={"email"}
              control={control}
              //   rules={{ required: "You can only select product from list." }}
              render={({ field }) => (
                <Input
                  type="email"
                  className="form-control"
                  placeholder={language.email}
                  {...field}
                  error={errors?.[field.name]?.message}
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-sm-4">
          <div className="form-group">
            <label>{language.typeOfSupplier}</label>
            <Controller
              name={"type_of_supplier"}
              control={control}
              //   rules={{ required: "You can only select product from list." }}
              render={({ field }) => (
                <Input
                  type="text"
                  className="form-control"
                  placeholder={language.typeOfSupplier}
                  {...field}
                  error={errors?.[field.name]?.message}
                />
              )}
            />
          </div>
        </div>
        <div className="col-sm-4">
          <div className="form-group">
            <label>{language.creditPeriod}</label>
            <Controller
              name={"credit_period"}
              control={control}
              //   rules={{ required: "You can only select product from list." }}
              render={({ field }) => (
                <Input
                  type="text"
                  className="form-control"
                  placeholder={language.creditPeriod}
                  {...field}
                  error={errors?.[field.name]?.message}
                />
              )}
            />
          </div>
        </div>
        <div className="col-sm-4">
          <div className="form-group">
            <label>{language.creditLimit}</label>
            <Controller
              name={"credit_limit"}
              control={control}
              //   rules={{ required: "You can only select product from list." }}
              render={({ field }) => (
                <Input
                  type="text"
                  className="form-control"
                  placeholder={language.creditLimit}
                  {...field}
                  error={errors?.[field.name]?.message}
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-sm-4">
          <div className="form-group">
            <label>{language.customerCPR}</label>
            <Controller
              name={"cr_number"}
              control={control}
              //   rules={{ required: "You can only select product from list." }}
              render={({ field }) => (
                <Input
                  type="text"
                  className="form-control"
                  placeholder={language.crNumber}
                  {...field}
                  error={errors?.[field.name]?.message}
                />
              )}
            />
          </div>
        </div>
        <div className="col-sm-4">
          <div className="form-group">
            <label>{language.customerAddressLine1}</label>
            <Controller
              name={"address_line1"}
              control={control}
              //   rules={{ required: "You can only select product from list." }}
              render={({ field }) => (
                <Input
                  type="text"
                  className="form-control"
                  placeholder={language.customerAddressLine1}
                  {...field}
                  error={errors?.[field.name]?.message}
                />
              )}
            />
          </div>
        </div>
        <div className="col-sm-4">
          <div className="form-group">
            <label>{language.customerAddressLine2}</label>
            <Controller
              name={"address_line2"}
              control={control}
              //   rules={{ required: "You can only select product from list." }}
              render={({ field }) => (
                <Input
                  type="text"
                  className="form-control"
                  placeholder={language.customerAddressLine2}
                  {...field}
                  error={errors?.[field.name]?.message}
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-4">
          <div className="form-group">
            <label>{language.customerCity}</label>
            <Controller
              name={"city"}
              control={control}
              //   rules={{ required: "You can only select product from list." }}
              render={({ field }) => (
                <Input
                  type="text"
                  className="form-control"
                  placeholder={language.customerCity}
                  {...field}
                  error={errors?.[field.name]?.message}
                />
              )}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4">
          <div className="form-group">
            <label>{language.customerZip}</label>
            <Controller
              name={"zipcode"}
              control={control}
              //   rules={{ required: "You can only select product from list." }}
              render={({ field }) => (
                <Input
                  type="text"
                  className="form-control"
                  placeholder={language.customerZip}
                  {...field}
                  error={errors?.[field.name]?.message}
                />
              )}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4">
          <div className="form-group">
            <label>{language.customerCountry}</label>
            <Controller
              name={"country"}
              control={control}
              //   rules={{ required: "You can only select product from list." }}
              render={({ field }) => (
                <Input
                  type="text"
                  className="form-control"
                  placeholder={language.customerCountry}
                  {...field}
                  error={errors?.[field.name]?.message}
                />
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupplierForm;
