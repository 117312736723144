import { toast } from "react-toastify";

export const successToast = (message) => {
  message &&
    toast(message, {
      type: "success",
    });
};

export const warningToast = (message) => {
  message &&
    toast(message, {
      type: "warning",
    });
};

export const errorToast = (message) => {
  message &&
    toast(message, {
      type: "error",
    });
};

export const filterSimpleError = (errors) => {
  if (errors?.status === 422) {
    const errorObject = errors?.data?.errors;
    Object.keys(errorObject)?.forEach((error) => {
      // setError(error, { message: errorObject[error][0] });
    });
  } else {
    errorToast(JSON.stringify(errors?.data?.message));
  }
};
