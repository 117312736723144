import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getRequest } from "../../Data/ApiService";
import { ENDPOINTS } from "../../Data/Endpoint";

import { setLoading } from "../../Store/actions/category/setLoading";
import { setCategories } from "../../Store/actions/category/setCategories";
import { setSelectedCategory } from "../../Store/actions/category/setSelectedCategory";
import CategoryTabs from "./CategoryTabs";

function Categories({
  categories,
  setLoading,
  setCategories,
  setSelectedCategory,
  forMobile,
}) {
  const [activeTab, setActiveTab] = useState(0);
  useEffect(() => {
    (async (_) => {
      if (categories.length === 0) {
        setLoading(true);
        try {
          const res = await getRequest(ENDPOINTS.categories, null, null);
          setCategories([...res.data.data.categories]);
          if (res.data.data.categories?.length > 0) {
            setSelectedCategory(res.data.data.categories[0].groups[0].id);
          }

          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CategoryTabs
      categories={categories}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      forMobile={forMobile}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    categories: state.categoryReducer.categories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: bindActionCreators(setLoading, dispatch),
    setSelectedCategory: bindActionCreators(setSelectedCategory, dispatch),
    setCategories: bindActionCreators(setCategories, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
