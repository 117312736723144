import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { BiEdit, BiMinusCircle, BiPlusCircle, BiTrash } from "react-icons/bi";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { displayThreeDecimal } from "../../Utils/displayThreeDecimal";

import { addTaxDetails } from "../../Store/actions/cart/addTaxDetails";
import { removeProduct } from "../../Store/actions/cart/removeProductFromCart";
import { updateCart } from "../../Store/actions/cart/updateCart";

import CONFIG from "../../Config/BaseURL";
import strings from "../../Strings/en";

import { deleteRequest, getRequest, putRequest } from "../../Data/ApiService";
import { ENDPOINTS } from "../../Data/Endpoint";

import useVariants from "../../Hooks/useVariants";
import { showImage } from "../../Utils/empty";
import Input from "../Common/Input";

function CartItem({
  product,
  updateCart,
  removeProduct,
  openAttributeSelection,
  handleEditProduct,
  authToken,
  storeId,
  orderId,
  addTaxDetails,
  taxes,
}) {
  const { carats, metals, sizes } = useVariants();
  const [inputFields, setInputFields] = useState({
    product_price: product?.price,
    making_charge: product?.making_charge,
    total_weight: product?.total_weight,
  });

  useEffect(() => {
    setInputFields({
      product_price: product?.price,
      making_charge: product?.making_charge,
      total_weight: product?.total_weight,
    });
  }, [product]);

  const getCarat = (_) => {
    return carats?.find((c) =>
      [product?.carat_id, product?.carat].includes(c.id)
    )?.carat;
  };

  const getSize = (_) => {
    return sizes?.filter((s) =>
      [product.size_id, product.size].includes(s.id)
    )[0]?.size;
  };
  const getMetal = (_) => {
    return metals?.filter((m) =>
      [product.metal_id, product.metal].includes(m.id)
    )[0]?.metal;
  };

  const handleChanegField = (e) => {
    const { name, value } = e.target;

    setInputFields((prev) => ({ ...prev, [name]: value }));
  };

  const handleQtyUpdate = async ({
    qty = product?.qty,
    tax_id = product?.tax_id,
    total_weight = product?.total_weight,
    sale_mk = product?.making_charge,
    sale_price = product?.price,
  }) => {
    try {
      let res = await putRequest(ENDPOINTS.cartUpdate, {
        qty: qty,
        is_gift: "isGiftWrap" in product ? (product.isGiftWrap ? 1 : 0) : 0,
        gift_message: product?.giftMessage ? product.giftMessage : "",
        gift_instruction: product?.giftInstructions
          ? product.giftInstructions
          : "",
        order_id: orderId,
        store_id: storeId,
        purchase_id: product.id,
        carat_id: product?.carat || product?.carat_id,
        size_id: product?.size || product?.size_id,
        metal_id: product?.metal || product?.metal_id,
        // weight: convertWeightToInteger(product.weight),
        tax_id: tax_id,
        orderitem_id: product.cartId,
        total_weight: total_weight,
        sale_mk: sale_mk,
        sale_price: sale_price,
      });
      let billRes = await getRequest(ENDPOINTS.cartTotal + orderId);
      addTaxDetails({
        tax: parseFloat(billRes.data.data.tax),
        subTotal: parseFloat(billRes.data.data.subtotal),
        total: parseFloat(billRes.data.data.total),
        taxName: billRes.data?.data?.tax_name,
      });

      if (res.data.data.success) {
        let cartObj = res.data.data.cart_items.filter(
          (p) => p.id === product?.cartId
        )[0];
        updateCart({
          id: product?.cartId,
          updates: {
            qty: qty,
            product_qty: qty,
            product_amt: cartObj?.product_amt,
            tax_id: tax_id,
            tax_amt: cartObj.tax_amt,
            total_weight: cartObj.total_weight,
            price: cartObj?.price,
            making_charge: cartObj?.making_charge,
          },
        });
        setInputFields({
          product_price: cartObj?.price,
          making_charge: cartObj?.making_charge,
          total_weight: cartObj?.total_weight,
        });
      } else {
        setInputFields({
          product_price: product?.price,
          making_charge: product?.making_charge,
          total_weight: product?.total_weight,
        });
        // errorToast(res.data.data.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <tr>
      <td width={200}>
        <div className="product-info d-flex">
          <div className="image">
            <img
              width="40"
              alt={
                product?.productImageUrl
                  ? product.title_en || product.product.title_en
                  : "Product Image"
              }
              src={showImage(`${CONFIG.IMAGE_ROOT_URL}${product.image_name}`)}
            />
          </div>
          <div className="item">
            <strong>{product?.title_en || product?.product_name || "-"}</strong>
            {(product?.carat_id || product?.metal_id || product?.size_id) && (
              <div className="subitem">
                {product?.carat_id && `${strings.carat}: ${getCarat() || 0}`}{" "}
                &nbsp;{" "}
                {product?.metal_id && `${strings.color}: ${getMetal() || "-"}`}{" "}
                &nbsp;{" "}
                {product?.size_id && `${strings.size}: ${getSize() || 0}`}
              </div>
            )}
          </div>
        </div>
      </td>
      {/* <td>{product?.weight}</td> */}
      <td>
        <div className="product-qty">
          <div className={`btn-wrapper ${product.qty < 2 ? "disabled" : ""}`}>
            <BiMinusCircle
              size="1.7em"
              onClick={(_) => handleQtyUpdate({ qty: product?.qty - 1 })}
            />
          </div>
          <span>{product?.qty}</span>
          <BiPlusCircle
            size="1.7em"
            onClick={(_) => handleQtyUpdate({ qty: product?.qty + 1 })}
          />
        </div>
      </td>
      <td>{product?.cost_price}</td>
      <td width={"100px"}>{product?.costmk}</td>
      <td>{product?.total_cost}</td>
      <td width={50}>
        {/* {displayThreeDecimal(product?.price || product?.product_price)} */}
        <div style={{ width: "80px" }}>
          <Input
            size="sm"
            name={"product_price"}
            value={inputFields.product_price}
            onChange={handleChanegField}
            onBlur={(e) => {
              handleQtyUpdate({ sale_price: e.target.value });
            }}
          />
          {/* <InputWithButton
            name={"product_price"}
            defaultValue={inputFields.product_price}
            onChange={handleChanegField}
            onClick={(value) => {
              handleQtyUpdate({ sale_price: value });
            }}
          /> */}
        </div>
      </td>
      <td width={50}>
        {/* {product?.mk || product?.making_charge || "-"} */}
        <div style={{ width: "80px" }}>
          <Input
            size="sm"
            name={"making_charge"}
            value={inputFields.making_charge}
            onChange={handleChanegField}
            onBlur={(e) => {
              handleQtyUpdate({ sale_mk: e.target.value });
            }}
          />
          {/* <InputWithButton
            name={"making_charge"}
            defaultValue={inputFields.making_charge}
            onChange={handleChanegField}
            onClick={(value) => handleQtyUpdate({ sale_mk: value })}
          /> */}
        </div>
      </td>
      <td>{displayThreeDecimal(product?.product_amt)}</td>
      <td width={50}>
        <div style={{ width: "80px" }}>
          {/* <InputWithButton
            name={"total_weight"}
            defaultValue={inputFields.total_weight}
            onChange={handleChanegField}
            onClick={(value) => handleQtyUpdate({ total_weight: value })}
          /> */}
          <Input
            size="sm"
            name={"total_weight"}
            value={inputFields.total_weight}
            onChange={handleChanegField}
            onBlur={(e) => {
              handleQtyUpdate({ total_weight: e.target.value });
            }}
          />
        </div>
      </td>
      <td
        className=""
        width={200}
        style={{ minHeight: "auto", height: "auto" }}
      >
        <div className="d-flex align-items-center flex-wrap">
          <div
            className="d-flex align-items-center justify-content-center me-2"
            style={{ flex: 1 }}
          >
            <select
              className="drop-down"
              value={String(product?.tax_id) || ""}
              onChange={(e) => {
                handleQtyUpdate({ tax_id: Number(e.target.value) });
              }}
            >
              {taxes?.map((tax) => {
                return (
                  <option value={tax?.id} key={tax?.id}>
                    {tax?.value}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="d-flex align-items-center">
            <Button
              size="lg"
              variant="secondary"
              className="me-1 d-flex align-items-center justify-content-center circle action-btn-table"
              onClick={(_) => {
                handleEditProduct(product);
                openAttributeSelection("edit", true);
              }}
            >
              <BiEdit />
            </Button>
            <Button
              size="lg"
              variant="danger"
              className="d-flex align-items-center justify-content-center circle action-btn-table"
              onClick={async (_) => {
                removeProduct(product.cartId);
                await deleteRequest(
                  `${ENDPOINTS.cartRemove}${product.cartId || product.id}`,
                  {
                    order_id: orderId,
                  }
                );
                let billRes = await getRequest(ENDPOINTS.cartTotal + orderId);
                addTaxDetails({
                  tax: parseFloat(billRes.data.data.tax),
                  subTotal: parseFloat(billRes.data.data.subtotal),
                  total: parseFloat(billRes.data.data.total),
                  taxName: billRes.data?.data?.tax_name,
                });
              }}
            >
              <BiTrash size="1em" />
            </Button>
          </div>
        </div>
      </td>
    </tr>
  );
}

const mapStateToProps = (state) => {
  return {
    authToken: state.userReducer.token,
    storeId: state.userReducer.store_id,
    orderId: state.cartReducer.orderId,
    taxes: state.userReducer.taxes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCart: bindActionCreators(updateCart, dispatch),
    removeProduct: bindActionCreators(removeProduct, dispatch),
    addTaxDetails: bindActionCreators(addTaxDetails, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  React.memo(
    CartItem,
    (prevProps, nextProps) =>
      JSON.stringify(prevProps.product) === JSON.stringify(nextProps.product)
  )
);
