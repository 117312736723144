import React, { useEffect, useRef, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { getRequest, postRequest } from "../../Data/ApiService";
import { ENDPOINTS } from "../../Data/Endpoint";
import language from "../../Strings/en";
import { errorToast, successToast } from "../../Utils/showToast";
import { default as CustomeModal } from "../Common/Modal";

const LinkItemModal = ({ openModal, hideModal, detail }) => {
  const [productDetail, setProductDetail] = useState({});
  const [products, setProducts] = useState([]);
  const quantityRef = useRef(0);
  const targetQtyRef = useRef(0);
  const [qty, setQty] = useState(0);
  const [targetQty, setTargetQty] = useState(0);
  const [purchases, setPurchases] = useState([]);

  const writerAbortController = new AbortController();
  writerAbortController.signal.onabort = (event) => {
    console.log("All NFC Write operations have been aborted.");
  };

  const readerAbortController = new AbortController();
  readerAbortController.signal.onabort = (event) => {
    console.log("All NFC Read operations have been aborted.");
  };

  const scan = async () => {
    try {
      const ndef = new window.NDEFReader();
      await ndef.scan({ signal: readerAbortController.signal });

      ndef.onreadingerror = () => {
        console.log("Cannot read data from the NFC tag. Try another one?");
      };

      ndef.onreading = (event) => {
        console.log("NDEF message read.");
        if (targetQtyRef.current > 0) {
          if (
            quantityRef?.current > 0 &&
            quantityRef.current == targetQtyRef.current
          ) {
            alert("You can only write target quantity.");
          } else {
            write();
          }
          // write(event); //Find function below
          // onReading(event);
        } else {
          alert("You cannot write this product.");
        }
      };
    } catch (error) {
      console.log(`Error! Scan failed to start: ${error}.`);
    }
  };

  const fetchTargetQty = async () => {
    await getRequest(`${ENDPOINTS.getPurchaseTargetQty}/${detail?.id}`)
      .then((response) => {
        setTargetQty(response?.data?.data?.target_qty);
        targetQtyRef.current = response?.data?.data?.target_qty;
        scan();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchTargetQty();
    // scan();
    return () => {
      writerAbortController.abort();
      readerAbortController.abort();
    };
  }, []);

  // useEffect(() => {
  //   const abortController = new AbortController();
  //   abortController.signal.onabort = (event) => {
  //     // All NFC operations have been aborted.
  //     console.log(event);
  //   };
  //   scan();
  //   return () => {
  //     abortController.abort();
  //   };
  // }, []);

  const onReading = ({ serialNumber, message }) => {
    write();
    // fetchProductDetailFromRfid(convertSerialNumber(serialNumber));
    //   for (const record of message.records) {
    //     switch (record.recordType) {
    //       case "text":
    //         const textDecoder = new TextDecoder(record.encoding);
    //         console.log("Message", textDecoder.decode(record.data));
    //         const data = JSON.parse(String(textDecoder.decode(record.data)));
    //         addDataToStack(data);
    //         break;
    //       case "url":
    //         // TODO: Read URL record with record data.
    //         break;
    //       default:
    //       // TODO: Handle other records with record data.
    //     }
    //   }
  };

  //   const abortController = new AbortController();
  //   abortController.signal.onabort = (event) => {
  //     // All NFC operations have been aborted.
  //   };

  const submitQty = async () => {
    await postRequest(ENDPOINTS.submitPurchaseTargetQty, {
      purchase_id: detail?.id,
      qty_scanned: qty,
      note: "",
    }).then((res) => {
      hideModal();
    });
  };

  const write = async () => {
    let write = new window.NDEFReader();
    await write
      .write({
        records: [
          {
            recordType: "text",
            data: JSON.stringify({
              storeproduct_variant_id: detail?.variant_id,
              storeproduct_id: detail?.storeproduct_id,
              productName: detail?.title_en,
              weight_per_item: Number(detail?.totalweight),
            }),
          },
        ],
      })
      .then((res) => {
        setQty((prev) => {
          quantityRef.current = prev + 1;
          return prev + 1;
        });
        // writerAbortController.abort();

        //   write.cancel();
        //   abortController.abort();
        //   ndef.stop;
        //   await postRequest(`${ENDPOINTS.linkRfid}/${variant?.id}`, {
        //     rfid_linked: 1,
        //   }).then(async (res) => {
        //     if (res.status === 200) {
        //       const updateVariants = variants.map((item) => {
        //         return item?.id === variant?.id
        //           ? { ...item, rfid_linked: 1 }
        //           : { ...item };
        //       });
        //       setPurchases((prev) =>
        //         prev?.map((item) => {
        //           return item?.storeproduct_id === productId
        //             ? { ...item, variants: updateVariants }
        //             : { ...item };
        //         })
        //       );
        //       ndef.close();
        //     }
        //   });
      })
      .catch((err) => {
        // alert(err);
      });
  };

  return (
    <CustomeModal
      show={openModal}
      onHide={hideModal}
      size={"sm"}
      fullscreen="sm-down"
      header={
        <div className="d-flex align-items-center w-100">
          <h3>{language.dbLinkItems}</h3>
        </div>
      }
      closeButton={false}
      alertDialog
      footer={
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            paddingInline: 10,
          }}
        >
          <Button variant="warning" onClick={submitQty}>
            {"DONE"}
          </Button>
        </div>
      }
    >
      <div className="d-flex justify-content-around flex-column align-items-center  link-item-modal">
        <div className="text-center d-flex flex-column mb-5">
          <span style={{ fontSize: 25, fontWeight: 300 }}>
            {detail?.title_en}
          </span>
          <span style={{ fontSize: 15, fontWeight: 300 }}>{`SKU: ${
            detail?.sku || "-"
          }`}</span>
        </div>
        <div className="text-center">
          <p style={{ fontWeight: 400, fontSize: 23 }}>TARGET QTY</p>
          <p style={{ fontWeight: 700, fontSize: 45 }}>{targetQty}</p>
        </div>
        <div className="text-center">
          <p style={{ fontWeight: 400, fontSize: 23 }}>QTY SCANNED</p>
          <p style={{ fontWeight: 700, fontSize: 45, color: "#F5C344" }}>
            {qty}
          </p>
        </div>
      </div>
    </CustomeModal>
  );
};

export default LinkItemModal;
