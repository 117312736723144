import { CATEGORY_ACTIONS } from '../../reducers/categoryReducer';

export const setCategories = (categories) => {
  return (dispatch) => {
    dispatch({
      type: CATEGORY_ACTIONS.ADD_CATEGORIES,
      payload: categories,
    });
  };
};
