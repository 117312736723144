import React from "react";

import Size from "./Size";
import language from "../../Strings/en";
import { removeDuplicates } from "../../Utils/removeDuplicates";

export default function SizeSelection({
  sizeOptions,
  size,
  setSize,
  labelStyle = { textTransform: "uppercase" },
  label = language.selectionSize,
  isBorder = true,
  isRequired = false,
}) {
  return (
    <div
      className="selection--size selection"
      style={{
        ...(isBorder && {
          borderBottom: "1px solid #ececec",
          marginBottom: "1rem",
          paddingBottom: "1rem",
        }),
      }}
    >
      <label style={labelStyle}>
        {label} {isRequired && <span className="text-danger">*</span>}
      </label>
      <div className="mt-2 size-radio-group radio-group">
        {sizeOptions &&
          removeDuplicates(sizeOptions, "size").map((row, index) => (
            <Size
              key={index}
              id={row?.id}
              label={row?.size}
              groupName="sizeSelection"
              size={size}
              setSize={setSize}
              isRequired
            />
          ))}
      </div>
    </div>
  );
}
